import { injectable } from 'inversify';
import { AccreditationsSegmentsQuery, ALL_ICON, FilterType } from '../../generated/types';
import { CountriesService } from '../../services/countriesService';
import { DateTimeService } from '../../services/dateTimeService';
import { TranslationService } from '../../services/translationService';
import { isNonEmptyArray } from '../../util/array';
import { Filter, PossibleColumn, SegmentService } from '../segment';

@injectable()
export class AccreditationsSegmentsService extends SegmentService {
    constructor(
        countriesService: CountriesService,
        dateTimeService: DateTimeService,
        translationService: TranslationService
    ) {
        super(countriesService, dateTimeService, translationService);
    }

    getAccreditationsFilters(eventInfos: AccreditationsSegmentsQuery['event']): Filter[] {
        const filters: Filter[] = [
            {
                color: 'warning',
                slug: 'name',
                name: this.t('nom_de_l_accr_d_11908'),
                fieldType: FilterType.Text,
                hideEmpty: true
            }
        ];

        if (isNonEmptyArray(eventInfos.accreditationsCategories)) {
            filters.push({
                color: 'warning',
                slug: 'accreditationCategory',
                name: this.t('cat_gorie_00291'),
                fieldType: FilterType.Select,
                values: eventInfos.accreditationsCategories,
                hideEmpty: true
            });
        }

        filters.push({
            color: 'warning',
            slug: 'tag',
            name: 'Tag',
            fieldType: FilterType.Select,
            needLoading: true,
            organizationId: eventInfos.organizationId
        });

        filters.push(
            {
                color: 'warning',
                slug: 'acronym',
                name: this.t('Acronym'),
                fieldType: FilterType.Text
            },
            {
                color: 'warning',
                slug: 'color',
                name: this.t('Color'),
                fieldType: FilterType.Text,
                hideEmpty: true
            },
            {
                color: 'warning',
                slug: 'icon',
                name: this.t('ic_ne_55554'),
                fieldType: FilterType.Select,
                values: ALL_ICON.map((icon) => ({
                    id: icon,
                    name: this.t(icon)
                })),
                hideEmpty: true
            },
            {
                color: 'warning',
                slug: 'address',
                name: this.t('Address'),
                fieldType: FilterType.Text
            }
        );

        return filters;
    }

    getAccreditationsPossibleColumns(): PossibleColumn[] {
        return [
            {
                slug: 'name',
                name: this.t('nom_de_l_accr_d_11908')
            },
            {
                slug: 'category',
                name: this.t('cat_gorie_00291')
            },
            {
                slug: 'tags',
                name: this.t('tags_79499')
            },
            {
                slug: 'address',
                name: this.t('Address')
            },
            {
                slug: 'latitude',
                name: this.t('latitude_46176')
            },
            {
                slug: 'longitude',
                name: this.t('longitude_01695')
            },
            {
                slug: 'acronym',
                name: this.t('Acronym')
            },
            {
                slug: 'color',
                name: this.t('Color')
            },
            {
                slug: 'icon',
                name: this.t('ic_ne_55554')
            }
        ];
    }
}
