import { Box } from 'common/src/designSystem/components/box';
import { theme } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

export function percentColor(percent: number, isErrorIfOver?: boolean): string {
    if (percent <= 0.2 || (isErrorIfOver && percent > 1.0)) {
        return theme.colors.error500.value;
    } else if (percent < 0.7) {
        return theme.colors.warning500.value;
    } else {
        return theme.colors.success500.value;
    }
}

interface IProgressBarProps {
    isErrorIfOver?: boolean;
    percent: number;
    prospectivePercent?: number;
}

export const ProgressBar = ({ isErrorIfOver, percent, prospectivePercent }: IProgressBarProps) => (
        <Box
            height={8}
            width={1}
            css={{
                background: '$gray100',
                borderRadius: '$1',
                position: 'relative'
            }}
        >
            <Box
                height={1}
                css={{
                    background: percentColor(percent, isErrorIfOver),
                    borderRadius: '$1',
                    left: 0,
                    maxWidth: '100%',
                    position: 'absolute',
                    top: 0,
                    width: `${percent * 100}%`
                }}
            />
            {prospectivePercent !== undefined && (
                <Box
                    height={1}
                    css={{
                        background: percentColor(prospectivePercent, isErrorIfOver),
                        borderRadius: '$1',
                        left: 0,
                        opacity: 0.4,
                        maxWidth: '100%',
                        position: 'absolute',
                        top: 0,
                        width: `${prospectivePercent * 100}%`
                    }}
                />
            )}
        </Box>
    );
