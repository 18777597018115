import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AccreditationsSlotId,
    DelegationAccreditationFragment,
    FormAccreditationsOptionsFragment,
    FormAccreditationsQuery,
    VolunteersRegistrationsSlotInput
} from 'common/src/generated/types';
import { UserInfoFields } from 'common/src/vo/field';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { FormAccreditationsSlotsCategory } from './formAccreditationsSlotsCategory';

interface IFormAccreditationsSlotsProps {
    accreditationsSlotsIds: AccreditationsSlotId[];
    categories: FormAccreditationsQuery['event']['accreditationsCategories'];
    delegationAccreditations: DelegationAccreditationFragment[];
    options: FormAccreditationsOptionsFragment;
    prefix: string;
    slots: VolunteersRegistrationsSlotInput[];
    userInfoFields: UserInfoFields;

    change(name: string, value: any): void;
}

export const FormAccreditationsSlots = (props: IFormAccreditationsSlotsProps) => {
    const categories = React.useMemo(() => sortBy(props.categories, (c) => c.name.toLowerCase()), [props.categories]);

    return (
        <>
            {categories.map((category, index) => (
                    <React.Fragment key={category.id}>
                        {index !== 0 && <Spacer height="7" />}

                        <FormAccreditationsSlotsCategory
                            accreditationsSlotsIds={props.accreditationsSlotsIds}
                            category={category}
                            delegationAccreditations={props.delegationAccreditations}
                            options={props.options}
                            prefix={props.prefix}
                            slots={props.slots}
                            userInfoFields={props.userInfoFields}
                            change={props.change}
                        />
                    </React.Fragment>
                ))}
        </>
    );
};
