import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IClosedProps {
    formClosedMessage: string;
}

export const Closed = (props: IClosedProps) => {
    const translate = useTranslate();

    return (
        <div>
            {isNonEmptyString(props.formClosedMessage) ? (
                <RichText text={props.formClosedMessage} />
            ) : (
                translate('les_inscription_89805')
            )}
        </div>
    );
};
