import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { Field } from 'react-final-form';
import {
    Checkbox as CheckboxComponent,
    CheckboxText as CheckboxTextComponent,
    ICheckboxSize
} from '../components/checkbox';

interface ICheckboxProps {
    name: string;
    size?: ICheckboxSize;
    disabled?: boolean;
}

export const Checkbox = (props: ICheckboxProps) => (
        <Field
            name={props.name}
            render={(fieldProps) => {
                const state = fieldProps.input.value === true ? 'checked' : 'unchecked';

                return (
                    <CheckboxComponent
                        size={props.size}
                        disabled={props.disabled}
                        state={state}
                        onClick={() => {
                            fieldProps.input.onChange(!fieldProps.input.value);
                        }}
                    />
                );
            }}
        />
    );

interface ICheckboxTextProps {
    css?: CSS;
    name: string;
    size?: ICheckboxSize;
    disabled?: boolean;
    children: React.ReactNode;
    subtext?: React.ReactNode;
}

export const CheckboxText = (props: ICheckboxTextProps) => (
        <Field
            name={props.name}
            render={(fieldProps) => {
                const state = fieldProps.input.value === true ? 'checked' : 'unchecked';

                return (
                    <CheckboxTextComponent
                        css={props.css}
                        size={props.size}
                        disabled={props.disabled}
                        subtext={props.subtext}
                        state={state}
                        onClick={() => {
                            fieldProps.input.onChange(!fieldProps.input.value);
                        }}
                    >
                        {props.children}
                    </CheckboxTextComponent>
                );
            }}
        />
    );
