import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import { range } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';

const _Day = styled('div', {
    alignItems: 'center',
    display: 'flex',
    height: '32px',
    justifyContent: 'center',
    width: '32px',
    variants: {
        before: {
            true: {
                opacity: 0.5
            }
        },
        now: {
            true: {
                fontWeight: '$oldBold'
            }
        },
        selected: {
            true: {
                background: '$oldBlue',
                borderRadius: '32px',
                color: 'white'
            }
        },
        selectable: {
            true: {
                cursor: 'pointer',
                '&:hover': {
                    background: '$oldBlue',
                    borderRadius: '32px',
                    color: 'white'
                }
            }
        },
        between: {
            true: {
                background: '$oldPurpleLight',
                borderRadius: '32px'
            }
        }
    },
    compoundVariants: [
        {
            selected: true,
            selectable: true,
            css: {
                '&:hover': {
                    border: '2px solid $oldBlack'
                }
            }
        }
    ]
});

const equals = (date1: DateTime, date2: DateTime) => date1.year === date2.year && date1.month === date2.month && date1.day === date2.day;

const isBefore = (date: DateTime, min?: DateTime): boolean => {
    if (min) {
        return (
            date.year < min.day ||
            (date.year === min.year && date.month < min.month) ||
            (date.year === min.year && date.month === min.month && date.day < min.day)
        );
    } else {
        return false;
    }
};

const isAfter = (date: DateTime, max?: DateTime): boolean => {
    if (max) {
        return (
            date.year > max.year ||
            (date.year === max.year && date.month > max.month) ||
            (date.year === max.year && date.month === max.month && date.day > max.day)
        );
    } else {
        return false;
    }
};

interface IDaysProps {
    now: DateTime;
    numberOfDaysInMonth: number;
    firstMondayOfMonth: DateTime;
    firstDayOfMonth: DateTime;
    currentHoverDate: DateTime | null;
    startDate: DateTime | null;
    endDate: DateTime | null;
    min?: DateTime;
    max?: DateTime;

    setCurrentHoverDate(date: DateTime | null): void;
    selectDate(date: DateTime): void;
}

export const Days = (props: IDaysProps) => (
        <Flex justify="between" wrap="wrap">
            <_Day css={{ color: '$oldBlueBayoux' }}>L</_Day>
            <_Day css={{ color: '$oldBlueBayoux' }}>M</_Day>
            <_Day css={{ color: '$oldBlueBayoux' }}>M</_Day>
            <_Day css={{ color: '$oldBlueBayoux' }}>J</_Day>
            <_Day css={{ color: '$oldBlueBayoux' }}>V</_Day>
            <_Day css={{ color: '$oldBlueBayoux' }}>S</_Day>
            <_Day css={{ color: '$oldBlueBayoux' }}>D</_Day>

            {range(0, props.numberOfDaysInMonth).map((i: number) => {
                const day = props.firstMondayOfMonth.plus({ days: i });
                const isBeforeOrAfter =
                    day < props.now || isBefore(day, props.min) || isAfter(day, props.max);
                const isSameMonth = day.month === props.firstDayOfMonth.month;
                const isSelectable = !isBeforeOrAfter && isSameMonth;
                const isSelected: boolean =
                    isSelectable &&
                    ((props.startDate !== null && equals(day, props.startDate)) ||
                        (props.endDate !== null && equals(day, props.endDate)));
                const isBetween: boolean =
                    isSelectable &&
                    props.currentHoverDate !== null &&
                    ((props.startDate !== null &&
                        props.currentHoverDate > props.startDate &&
                        day > props.startDate &&
                        day < props.currentHoverDate) ||
                        (props.endDate !== null &&
                            props.currentHoverDate < props.endDate &&
                            day < props.endDate &&
                            day > props.currentHoverDate));

                return (
                    <_Day
                        key={i}
                        before={isBeforeOrAfter}
                        now={equals(day, props.now)}
                        selected={isSelected}
                        selectable={isSelectable}
                        between={isBetween}
                        onClick={() => {
                            if (isSelectable) {
                                props.selectDate(day);
                            }
                        }}
                        onMouseEnter={() => {
                            if (isSelectable) {
                                props.setCurrentHoverDate(day);
                            }
                        }}
                        onMouseLeave={() => {
                            props.setCurrentHoverDate(null);
                        }}
                    >
                        {isSameMonth ? day.day : ''}
                    </_Day>
                );
            })}
        </Flex>
    );
