import { Spacer } from 'common/src/designSystem/components/spacer';
import { RegisterDaysDisplay, VolunteersRegistrationsSlotInput } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats, toInterval } from 'common/src/util/luxon';
import { addRemoveSlot, getDays } from 'common/src/vo/volunteerRegistrationSlot';
import { DateTime, Interval } from 'luxon';
import * as React from 'react';
import { CheckboxText } from '../../../designSystem/components/checkbox';

interface IFormSlotsDaysProps {
    currentSlots: VolunteersRegistrationsSlotInput[];
    daysDisplay: RegisterDaysDisplay;
    endAt: DateTime;
    prefix: string;
    ranges: Interval[];
    startAt: DateTime;

    change(name: string, value: any): void;
}

export const FormSlotsDays = (props: IFormSlotsDaysProps) => {
    const days = React.useMemo(() => getDays(props.startAt, props.endAt, props.ranges, props.daysDisplay), [props.startAt, props.endAt, props.ranges, props.daysDisplay]);
    const dateTimeService = useService(DateTimeService);
    const [selected, setSelected] = React.useState<Record<string, boolean>>(
        Object.fromEntries(
            days.map((day, index) => {
                const interval = Interval.fromDateTimes(day, day.plus({ day: 1 }));
                const isSelected = props.currentSlots.find((slot) => toInterval(slot).equals(interval));

                return [`day${index}`, isSelected !== undefined];
            })
        )
    );

    return (
        <>
            {days.map((day, index) => (
                    <React.Fragment key={index}>
                        {index !== 0 && <Spacer height="3" />}

                        <CheckboxText
                            state={selected[`day${index}`] ? 'checked' : 'unchecked'}
                            onClick={(state) => {
                                setSelected({
                                    ...selected,
                                    [`day${index}`]: state === 'checked'
                                });

                                props.change(
                                    `${props.prefix}slots`,
                                    addRemoveSlot(
                                        props.currentSlots,
                                        Interval.fromDateTimes(day, day.plus({ day: 1 })),
                                        state === 'checked'
                                    )
                                );
                            }}
                        >
                            {dateTimeService.toLocaleString(
                                day,
                                LocaleFormats.DateOnly.WeekdayLongMonthLong
                            )}
                        </CheckboxText>
                    </React.Fragment>
                ))}
        </>
    );
};
