import { Box } from 'common/src/designSystem/components/box';
import * as React from 'react';

interface ILabelProps {
    children?: React.ReactNode;
    htmlFor?: string;
}

export const Label = ({ children, htmlFor }: ILabelProps) => {
    if (children) {
        return (
            <label htmlFor={htmlFor} style={{ display: 'block' }}>
                <Box color="gray700" fontWeight="medium">
                    {children}
                </Box>
            </label>
        );
    } else {
        return null;
    }
};
