import { FlexedButton } from 'common-front/src/components/button/button';
import { FormContext } from 'common-front/src/components/form/formContext';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { executeQuery } from 'common-front/src/components/graphql/graphql';
import { Submit } from 'common-front/src/components/reactFinalForm/input/submit';
import { TextInput } from 'common-front/src/components/reactFinalForm/input/text';
import { Separator } from 'common-front/src/components/separator/separator';
import {
    Mutations,
    useLoginCodeEmailCreateMutation
} from 'common-front/src/generated/graphqlHooks';
import { useUserContext } from 'common-front/src/userContext';
import {
    getToken,
    IInitiateAuthResult,
    initiateAuthWithSignUp,
    reportAuthError,
    sendCustomChallengeAnswer,
    signOutNoRedirect
} from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { LoginCodeSource, UserCreateMutation } from 'common/src/generated/types';
import { AuthInputService, IFormEmailValues } from 'common/src/input/authInput';
import { ValidateService } from 'common/src/services/validateService';
import { useParams, useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { noop } from 'lodash-es';
import { Duration } from 'luxon';
import * as React from 'react';
import { Form } from 'react-final-form';
import { useEventPageContext } from '../common/eventPageContext';
import { executeUserExistsQuery } from '../generated/graphqlHooks';

interface IEmailProps {
    buttonText: string;
    source: LoginCodeSource;
    description?: any;

    onSuccess(email: string, token?: string): Promise<any>;
}

export const Email = (props: IEmailProps) => {
    const translate = useTranslate();
    const authInput = useService(AuthInputService);
    const { organizationId, eventId } = useParams();
    const validateService = useService(ValidateService);
    const { user: currentUser } = useUserContext();
    const { isEmbed } = useEventPageContext();
    const [currentInitiateAuthResult, setInitiateAuthResult] =
        React.useState<IInitiateAuthResult | null>(null);
    const [isCodeVisible, setIsCodeVisible] = React.useState(false);
    const { mutate } = useLoginCodeEmailCreateMutation();
    const onSuccess = React.useCallback(async (token: string) => {
        const { userCreate } = await executeQuery<UserCreateMutation>(Mutations.UserCreate, {
            token
        });

        await props.onSuccess(userCreate.email, token);
    }, []);
    const [currentAttempt, setCurrentAttempt] = React.useState(0);
    const [isRestartVisible, setIsRestartVisible] = React.useState(false);
    const [challengeStartTime, setChallengeStartTime] = React.useState(Date.now());
    const restart = () => {
        location.reload();
    };

    return (
        <Form
            initialValues={{
                email: currentUser?.email ?? '',
                emailConfirm: currentUser?.email ?? '',
                code: ''
            }}
            validate={(values: IFormEmailValues) => {
                if (isCodeVisible) {
                    return validateService.validateForForm(authInput.emailCodeSchema())(values);
                } else {
                    return validateService.validateForForm(authInput.emailConfirmSchema())(values);
                }
            }}
            onSubmit={async (values: IFormEmailValues) => {
                if (isNonEmptyString(values.code)) {
                    const { user, callbacks } = currentInitiateAuthResult!;

                    return sendCustomChallengeAnswer({ code: values.code, user, callbacks }).then(
                        onSuccess,
                        (error) => {
                            if (error.type === 'RETRY') {
                                setCurrentAttempt(currentAttempt + 1);

                                return {
                                    code: translate(
                                        'le_code_n_est_p_95065',
                                        3 - (currentAttempt + 1)
                                    )
                                };
                            } else {
                                reportAuthError(error.message, values.email, challengeStartTime);
                                setIsRestartVisible(true);

                                let FORM_ERROR;
                                if (
                                    Duration.fromMillis(Date.now() - challengeStartTime).as(
                                        'minutes'
                                    ) > 3
                                ) {
                                    FORM_ERROR = translate('le_code_a_expir_45975');
                                } else {
                                    FORM_ERROR = translate('le_code_n_est_p_12317');
                                }

                                return {
                                    FORM_ERROR
                                };
                            }
                        }
                    );
                } else if (currentUser) {
                    await onSuccess((await getToken())!);
                } else {
                    await signOutNoRedirect().catch(() => true);
                    const { userExists } = await executeUserExistsQuery(
                        { email: values.email },
                        null
                    );

                    if (userExists) {
                        await mutate({
                            loginCodeEmail: {
                                email: values.email,
                                organizationId,
                                eventId,
                                isEmbed,
                                source: props.source
                            }
                        });

                        setChallengeStartTime(Date.now());
                        const initiateAuthResult = await initiateAuthWithSignUp(
                            values.email,
                            noop,
                            noop
                        );

                        setInitiateAuthResult(initiateAuthResult);
                        setIsCodeVisible(true);
                    } else {
                        await props.onSuccess(values.email);
                    }
                }
            }}
            render={({
                handleSubmit,
                submitting,
                values,
                hasValidationErrors,
                errors,
                submitErrors,
                submitFailed
            }) => (
                    <FormContext.Provider
                        value={{
                            errors,
                            showErrors: submitFailed,
                            submitErrors,
                            setShowErrors: noop
                        }}
                    >
                        <form onSubmit={handleSubmit}>
                            {isNonEmptyString(props.description) && (
                                <>
                                    <div>
                                        <RichText text={props.description} />
                                    </div>

                                    <Separator margin={32} />
                                </>
                            )}
                            <FormErrors />

                            <TextInput
                                label={translate('Email')}
                                name="email"
                                isEmail={true}
                                disabled={isCodeVisible || isRestartVisible}
                            />

                            <TextInput
                                label={translate('confirmer_l_e_m_31556')}
                                name="emailConfirm"
                                isEmail={true}
                                disabled={isCodeVisible || isRestartVisible}
                            />

                            {isCodeVisible && (
                                <>
                                    <Box
                                        css={{
                                            background: '#ecf7fe',
                                            borderRadius: '3px',
                                            marginBottom: '$6',
                                            overflowWrap: 'break-word',
                                            padding: '$4',
                                            '& > div:nth-child(1)': {
                                                fontWeight: '$oldBold',
                                                marginBottom: '$1'
                                            },
                                            '& > div:nth-child(3)': {
                                                fontStyle: 'italic'
                                            }
                                        }}
                                    >
                                        <div>{translate('cet_e_mail_est_03145')}</div>

                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: translate(
                                                    'un_code_6_chi_86367',
                                                    values.email
                                                )
                                            }}
                                        />

                                        <div>{translate('attention_ne_16312')}</div>
                                    </Box>

                                    <TextInput
                                        label={translate('code_20843')}
                                        name="code"
                                        disabled={isRestartVisible}
                                    />
                                </>
                            )}

                            <Submit />

                            {isRestartVisible ? (
                                <FlexedButton onClick={restart} isLoading={submitting}>
                                    {translate('recommencer_14712')}
                                </FlexedButton>
                            ) : (
                                <FlexedButton
                                    onClick={handleSubmit}
                                    isInactive={hasValidationErrors}
                                    isLoading={submitting}
                                >
                                    {props.buttonText}
                                </FlexedButton>
                            )}
                        </form>
                    </FormContext.Provider>
                )}
        />
    );
};
