import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IMemberRegistrationAssignmentInfoProps {
    children: React.ReactNode;
    title: string;
}

export const MemberRegistrationAssignmentInfo = (props: IMemberRegistrationAssignmentInfoProps) => (
        <Flex
            direction={{ '@initial': 'column', '@desktop': 'row' }}
            gap={{ '@initial': '1', '@desktop': '6' }}
        >
            <Box
                color="gray500"
                css={{
                    width: '100%',
                    '@desktop': {
                        width: '200px'
                    }
                }}
            >
                {props.title}
            </Box>

            <Box
                color="gray800"
                css={{
                    width: '100%',
                    '@desktop': {
                        flex: '1'
                    }
                }}
            >
                {props.children}
            </Box>
        </Flex>
    );
