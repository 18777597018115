import { Box } from 'common/src/designSystem/components/box';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { isInvalid } from '../isInvalid';
import { Label } from '../label/label';
import { fieldSubscriptions } from '../subscriptions';
import { Subtitle } from './subtitle';
import { _OldTextareaStyled } from './text';

interface ITextareaComponentProps extends FieldRenderProps<any, HTMLElement> {
    label: string;
    placeholder: string;
    isOptional?: boolean;
    subtitle?: string;
}

const TextareaComponent = (props: ITextareaComponentProps) => {
    const invalid = isInvalid(props.meta);

    return (
        <div>
            <Label htmlFor={props.input.name} isOptional={props.isOptional}>
                {props.label}
            </Label>

            {isNonEmptyString(props.subtitle) && <Subtitle text={props.subtitle} />}

            <_OldTextareaStyled {...props.input} invalid={invalid} />

            {invalid && (
                <Box
                    css={{
                        color: '$oldRed',
                        marginBottom: '$6'
                    }}
                >
                    {props.meta.error}
                </Box>
            )}
        </div>
    );
};

interface ITextareaProps {
    label: string;
    name: string;
    placeholder?: string;
    isOptional?: boolean;
    subtitle?: string;
}

export const Textarea = (props: ITextareaProps) => (
        <Field
            {...props}
            component={TextareaComponent as any}
            placeholder={props.placeholder || props.label}
            subscription={fieldSubscriptions}
        />
    );
