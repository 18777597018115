import * as React from 'react';
import { convertToPercentOrPx } from '../util/unit';
import { Gap } from './flex';
import { CSS, ResponsiveProperty, styled } from './stitches';

const _Grid = styled('div', {
    display: 'grid',
    variants: {
        align: {
            start: {
                alignItems: 'start'
            },
            center: {
                alignItems: 'center'
            },
            end: {
                alignItems: 'end'
            }
        },
        gap: {
            1: {
                gap: '$1'
            },
            2: {
                gap: '$2'
            },
            3: {
                gap: '$3'
            },
            4: {
                gap: '$4'
            },
            5: {
                gap: '$5'
            },
            6: {
                gap: '$6'
            },
            7: {
                gap: '$7'
            },
            8: {
                gap: '$8'
            },
            9: {
                gap: '$9'
            },
            10: {
                gap: '$10'
            },
            11: {
                gap: '$11'
            },
            12: {
                gap: '$12'
            },
            13: {
                gap: '$13'
            }
        },
        columnGap: {
            1: {
                columnGap: '$1'
            },
            2: {
                columnGap: '$2'
            },
            3: {
                columnGap: '$3'
            },
            4: {
                columnGap: '$4'
            },
            5: {
                columnGap: '$5'
            },
            6: {
                columnGap: '$6'
            },
            7: {
                columnGap: '$7'
            },
            8: {
                columnGap: '$8'
            },
            9: {
                columnGap: '$9'
            },
            10: {
                columnGap: '$10'
            },
            11: {
                columnGap: '$11'
            },
            12: {
                columnGap: '$12'
            },
            13: {
                columnGap: '$13'
            }
        },
        rowGap: {
            1: {
                rowGap: '$1'
            },
            2: {
                rowGap: '$2'
            },
            3: {
                rowGap: '$3'
            },
            4: {
                rowGap: '$4'
            },
            5: {
                rowGap: '$5'
            },
            6: {
                rowGap: '$6'
            },
            7: {
                rowGap: '$7'
            },
            8: {
                rowGap: '$8'
            },
            9: {
                rowGap: '$9'
            },
            10: {
                rowGap: '$10'
            },
            11: {
                rowGap: '$11'
            },
            12: {
                rowGap: '$12'
            },
            13: {
                rowGap: '$13'
            }
        }
    }
});

interface IGridProps {
    align?: ResponsiveProperty<'start' | 'center' | 'end'>;
    children: React.ReactNode;
    className?: string;
    columnGap?: ResponsiveProperty<Gap>;
    css?: CSS;
    gap?: ResponsiveProperty<Gap>;
    gridtemplate?: string;
    gridtemplatecolumns?: string;
    gridtemplaterows?: string;
    height?: number | string;
    rowGap?: ResponsiveProperty<Gap>;
    width?: number | string;
}

export const Grid = React.forwardRef((props: IGridProps, ref: React.Ref<HTMLDivElement>) => {
    const css: any = {
        gridTemplate: props.gridtemplate,
        gridTemplateColumns: props.gridtemplatecolumns,
        gridTemplateRows: props.gridtemplaterows
    };

    if (props.height) {
        css.height = convertToPercentOrPx(props.height);
    }

    if (props.width) {
        css.width = convertToPercentOrPx(props.width);
    }

    return (
        <_Grid
            {...(props as any)}
            ref={ref}
            css={{
                ...css,
                ...props.css
            }}
        />
    );
});
