import { compact, difference, maxBy, minBy, range } from 'lodash-es';
import { DateTime, Interval } from 'luxon';
import {
    AccreditationsSlot,
    AccreditationsSlotId,
    AccreditationsSlotInput,
    DelegationAccreditationFragment,
    FormAccreditationsOptionsFragment,
    FormRegisterAccreditationSlotFragment,
    RegisterAccreditationDisplay,
    RegisterPositionFilter,
    RegisterSlotDisplay,
    VolunteersRegistrationsSlotInput
} from '../generated/types';
import { DateTimeService } from '../services/dateTimeService';
import { mergeOverlapping } from '../util/interval';
import { fromRange, LocaleFormats, LocaleFormatType } from '../util/luxon';
import { isNonEmptyString } from '../util/string';

interface IFullNameOptions {
    defaultName?: string;
    format?: LocaleFormatType;
    includeAccreditationName?: boolean;
}

export function fullName(
    dateTimeService: DateTimeService,
    slot: Pick<AccreditationsSlot, 'name' | 'date'>,
    accreditationName: string,
    { defaultName, format, includeAccreditationName }: IFullNameOptions = {}
) {
    const name = isNonEmptyString(slot.name) ? slot.name : '';
    const date = slot.date
        ? dateTimeService.toLocaleString(slot.date, format || LocaleFormats.DateOnly.Numeric)
        : '';
    const fullName = compact([includeAccreditationName ? accreditationName : '', name, date]).join(
        ' - '
    );

    return isNonEmptyString(fullName)
        ? fullName
        : isNonEmptyString(defaultName)
        ? defaultName
        : accreditationName;
}

export function canSelect(
    slot: FormRegisterAccreditationSlotFragment,
    ranges: Array<Interval | VolunteersRegistrationsSlotInput>,
    options: FormAccreditationsOptionsFragment,
    delegationAccreditations: DelegationAccreditationFragment[]
): boolean {
    return (
        (options.accreditationDisplay !== RegisterAccreditationDisplay.None &&
            !options.hiddenAccreditationsSlotsIds.includes(slot.id) &&
            (options.displayedAccreditationsSlotsIds.length === 0 ||
                options.displayedAccreditationsSlotsIds.includes(slot.id)) &&
            (options.showFullAccreditation || !slot.isFull) &&
            (options.slotDisplay === RegisterSlotDisplay.Hide ||
                options.accreditationFilter === RegisterPositionFilter.None ||
                !slot.date ||
                mergeOverlapping(ranges.map(fromRange)).some((interval) => interval.contains(slot.date!)))) ||
        delegationAccreditations.some((da) => (
                da.accreditationSlotId === slot.id &&
                (options.slotDisplay === RegisterSlotDisplay.Hide ||
                    options.accreditationFilter === RegisterPositionFilter.None ||
                    !da.accreditationSlot?.date ||
                    mergeOverlapping(ranges.map(fromRange)).some((interval) => interval.contains(da.accreditationSlot.date!)))
            ))
    );
}

export function repeatSlot(
    slot: AccreditationsSlotInput,
    value: number,
    unit: string
): AccreditationsSlotInput[] {
    if (slot.date?.isValid) {
        return range(1, value + 1).map((i) => {
            if (unit === 'day') {
                const newDate = slot.date!.plus({ day: i });

                return {
                    name: '',
                    date: newDate,
                    maxResources: slot.maxResources
                };
            } else if (unit === 'week') {
                const newDate = slot.date!.plus({ week: i });

                return {
                    name: '',
                    date: newDate,
                    maxResources: slot.maxResources
                };
            } else {
                throw new Error('Wrong unit');
            }
        });
    } else {
        throw new Error('Can not repeat slot with wrong date');
    }
}

interface IDateSlotsStatsReturn {
    minDate: DateTime;
    maxDate: DateTime;
    numberOfMonths: number;
}

export function dateSlotsStats(
    slots: Array<Pick<AccreditationsSlot, 'date'>>
): IDateSlotsStatsReturn {
    const slotsWithDates = slots.filter((s) => s.date?.isValid);
    const minDate = minBy(
        slotsWithDates.map((s) => s.date!),
        (d) => d.toMillis()
    )!;
    const maxDate = maxBy(
        slotsWithDates.map((s) => s.date!),
        (d) => d.toMillis()
    )!;
    const numberOfMonths = Math.ceil(
        maxDate.endOf('month').diff(minDate.startOf('month'), 'months').months
    );

    return {
        minDate,
        maxDate,
        numberOfMonths
    };
}

export function newCalendarsAccreditationsSlotsIds(
    currentAccreditationsSlotsIds: AccreditationsSlotId[],
    accreditationsSlots: Array<Pick<AccreditationsSlot, 'id' | 'date'>>,
    firstDayOfMonth: DateTime,
    newDateTimes: DateTime[]
) {
    const accreditationsSlodsIdsToKeep = difference(
        currentAccreditationsSlotsIds,
        accreditationsSlots.flatMap((slot) => slot.date?.isValid && firstDayOfMonth.equals(slot.date!.startOf('month'))
                ? [slot.id]
                : [])
    );
    const newAccreditationsSlotsIds = accreditationsSlots.flatMap((slot) => {
        const isSelected = newDateTimes.some((date) => slot.date?.isValid && date.startOf('day').equals(slot.date!.startOf('day')));

        return isSelected ? [slot.id] : [];
    });

    return newAccreditationsSlotsIds.concat(accreditationsSlodsIdsToKeep);
}

export function filterByAvailabilities(
    accreditationsSlots: AccreditationsSlot[],
    slotDisplay: RegisterSlotDisplay,
    slots: Array<{ startDate: DateTime; endDate: DateTime }>
): AccreditationsSlotId[] {
    if (slotDisplay === RegisterSlotDisplay.Hide) {
        return accreditationsSlots.map((as) => as.id);
    } else {
        return accreditationsSlots
            .filter((as) => {
                if (as.date) {
                    return slots.some((s) => (
                            s.startDate.toISODate() === as.date!.toISODate() ||
                            s.endDate.toISODate() === as.date!.toISODate() ||
                            Interval.fromDateTimes(s.startDate, s.endDate).contains(as.date!)
                        ));
                } else {
                    return true;
                }
            })
            .map((as) => as.id);
    }
}
