import { Button } from 'common-front/src/designSystem/components/button';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Form, FormId, OrganizationMembersSpaceFragment } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { SpaceBanner } from '../../spaces/spaceBanner';

export type UserUpdateLayoutOrigin = 'organization' | 'event' | 'members-space';

interface IUserUpdateLayoutProps {
    children: React.ReactNode;
    extraTabs?: React.ReactNode;
    forms: Array<Pick<Form, 'id' | 'name'>>;
    isLoading: boolean;
    origin: UserUpdateLayoutOrigin;
    organization?: OrganizationMembersSpaceFragment;
    title: string;

    getTabPath(formId: FormId): string;
    onCancel(): void;
    onUpdate(): void;
}

export const UserUpdateLayout = (props: IUserUpdateLayoutProps) => {
    const translate = useTranslate();

    return (
        <Flex direction="column" height={1} width={1} css={{ overflow: 'hidden' }}>
            <Box
                css={{
                    flex: '1',
                    overflow: 'hidden'
                }}
            >
                <Flex
                    direction="column"
                    align="center"
                    height={1}
                    width={1}
                    css={{
                        margin: 'auto',
                        overflowY: 'auto'
                    }}
                >
                    {props.origin === 'members-space' && (
                        <SpaceBanner
                            bannerUrl={props.organization?.membersSpaceDesign?.banner?.url}
                            isLoading={false}
                        />
                    )}

                    <Flex
                        direction="column"
                        css={{
                            padding: '$6 $4',
                            width: '100%',
                            '@tablet': {
                                padding: '$6 $8'
                            },
                            '@desktop': {
                                padding: '$7',
                                width: props.origin === 'event' ? '100%' : '$container'
                            }
                        }}
                    >
                        <Box font="gray900 displayXs semiBold">{props.title}</Box>

                        <Spacer height="6" />

                        <Tabs>
                            <TabList>
                                {props.forms.map((form) => (
                                        <Tab key={form.id} path={props.getTabPath(form.id)}>
                                            {form.name}
                                        </Tab>
                                    ))}

                                {props.extraTabs}
                            </TabList>

                            {props.children}
                        </Tabs>
                    </Flex>
                </Flex>
            </Box>

            <Flex
                justify="center"
                css={{
                    boxShadow:
                        '0px 0px 0px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08)',
                    padding: '$4 $7'
                }}
            >
                <Flex
                    align="center"
                    gap="4"
                    css={{
                        width: '100%',
                        '@desktop': {
                            width: props.origin === 'event' ? '100%' : '$container'
                        }
                    }}
                >
                    <Button isLoading={props.isLoading} onClick={props.onUpdate}>
                        {translate('mettre_jour_85141')}
                    </Button>

                    <Box
                        color="gray700"
                        fontWeight="medium"
                        css={{ cursor: 'pointer' }}
                        onClick={props.onCancel}
                    >
                        {translate('annuler_48254')}
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    );
};
