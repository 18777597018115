import { injectable } from 'inversify';
import * as yup from 'yup';
import { DelegationEventToEditQuery, DelegationInput, FormId } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { Emptyable } from '../util/emptyable';
import { DateTimeSchemaType } from '../util/yup/dateTimeSchemaType';
import { getDefaultColumns } from '../vo/delegation';
import { InputService } from './inputService';

export interface ICreateUpdateDelegationValues {
    delegation: DelegationInput;
}

@injectable()
export class DelegationInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    delegationInputDefault(
        delegation: Emptyable<DelegationEventToEditQuery['event']['delegation']>
    ): DelegationInput {
        return {
            parentId: delegation?.parentId ?? null,
            name: delegation?.name ?? '',
            leadersIds: delegation?.leadersIds ?? [],
            formId: delegation?.formId ?? (-1 as FormId),
            deadlineDate: delegation?.deadlineDate ?? null,
            maxResources: delegation?.maxResources ?? null,
            columns: delegation?.columns ?? getDefaultColumns(),
            privateNote: delegation?.privateNote ?? '',
            accreditations:
                delegation?.accreditations?.map((da) => ({
                        accreditationCategoryId: da.accreditationSlot.accreditationCategoryId,
                        accreditationId: da.accreditationSlot.accreditation.id,
                        accreditationSlotId: da.accreditationSlot.id,
                        isDefault: da.isDefault,
                        maxResources: da.maxResources || Infinity,
                        isVisible: da.isVisible
                    })) ?? [],
            addDefaultAccreditationsByAvailability:
                delegation?.addDefaultAccreditationsByAvailability ?? false,
            tagsIds: delegation?.tagsIds ?? []
        };
    }

    delegationInputSchema() {
        return yup.object().shape({
            parentId: yup.number().nullable(),
            name: yup.string().when('parentId', {
                is: null,
                then: (n) => n.required(this.t('le_nom_de_la_d_10042'))
            }),
            leadersIds: yup.array().of(yup.number()),
            formId: yup
                .number()
                .required(this.t('le_formulaire_e_77404'))
                .typeError(this.t('le_formulaire_e_77404')),
            deadlineDate: DateTimeSchemaType.typeError(this.t('la_date_limite_91708')).nullable(),
            maxResources: yup.number().nullable(),
            columns: yup
                .array()
                .of(yup.string())
                .required(this.t('vous_devez_choi_82605'))
                .min(1, this.t('vous_devez_choi_82605')),
            privateNote: yup.string(),
            accreditations: yup.array().of(
                yup.object().shape({
                    accreditationCategoryId: yup.number().required(),
                    accreditationId: yup.number().required(),
                    accreditationSlotId: yup.number().required(),
                    isDefault: yup.boolean().required(),
                    maxResources: yup.number().nullable()
                })
            )
        });
    }

    createUpdateDelegationSchema() {
        return yup.object().shape({
            delegation: this.delegationInputSchema()
        });
    }

    adminAddSchema() {
        return yup.object().shape({
            usersInfosIds: yup.array().of(yup.number())
        });
    }
}
