import { Box } from 'common/src/designSystem/components/box';
import * as React from 'react';

interface IRightPanelHeaderProps {
    children: React.ReactNode;
}

export const RightPanelHeader = (props: IRightPanelHeaderProps) => (
        <Box
            color="gray800"
            fontSize="textXl"
            fontWeight="medium"
            css={{
                borderBottom: '1px solid $gray200',
                padding: '$6'
            }}
        >
            {props.children}
        </Box>
    );
