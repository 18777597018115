import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { isValidTime } from 'common/src/util/time';
import * as luxon from 'luxon';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { isInvalid } from '../../isInvalid';
import { Label } from '../../label/label';
import { displayDate } from './slotPickerUtil';

const _DateTimeContainer = styled('div', {
    alignItems: 'center',
    border: '1px solid $oldGrayBorder',
    borderRadius: '$1',
    display: 'flex',
    height: '40px',
    marginBottom: '$6',
    width: '238px',
    variants: {
        invalid: {
            true: {
                border: '1px solid $oldRed',
                marginBottom: '$2'
            }
        }
    }
});

const _DateTime = styled('div', {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flex: '1',
    height: '100%',
    justifyContent: 'center',
    variants: {
        time: {
            true: {
                padding: '0 $2',
                '& > input': {
                    border: 'none',
                    cursor: 'pointer',
                    height: '100%',
                    outline: 'none',
                    textAlign: 'center',
                    width: '100%',
                    '&::-webkit-inner-spin-button': {
                        '-webkit-inner-spin-button': 'none',
                        margin: '0'
                    },
                    '&::-webkit-outer-spin-button': {
                        '-webkit-inner-spin-button': 'none',
                        margin: '0'
                    },
                    '&::-webkit-clear-button': {
                        display: 'none'
                    },
                    '&::-ms-clear': {
                        height: '0',
                        width: '0'
                    }
                }
            }
        }
    }
});

const _Logo = styled('div', {
    alignItems: 'center',
    color: '$oldGrayIcon',
    display: 'flex',
    padding: '0 $3'
});

interface IDateTimeProps {
    htmlFor: string;
    label: string;
    dateProps: FieldRenderProps<luxon.DateTime, HTMLInputElement>;
    timeProps: FieldRenderProps<string, HTMLInputElement>;
    placeholder: string;
    areHoursOpen: boolean;

    onDateClick(): void;

    onTimeClick(): void;

    onPlaceholderClick(): void;
}

export const DateTime = (props: IDateTimeProps) => {
    const translate = useTranslate();
    const date = props.dateProps.input.value;
    const time = props.timeProps.input.value;
    const dateInvalid = isInvalid(props.dateProps.meta);
    const timeInvalid = isInvalid(props.timeProps.meta);
    const invalid = dateInvalid || timeInvalid;
    let error = '';

    if (dateInvalid) {
        error = props.dateProps.meta.error;
    } else if (timeInvalid) {
        error = props.timeProps.meta.error;
    }

    return (
        <div>
            <Label htmlFor={props.htmlFor}>{props.label}</Label>

            <_DateTimeContainer invalid={invalid}>
                {(date && date.isValid) || isNonEmptyString(time) ? (
                    <Flex
                        align="center"
                        css={{
                            flex: '1',
                            height: '100%',
                            width: '100%'
                        }}
                    >
                        <Flex
                            css={{
                                borderRight: '1px solid $oldGrayBorder',
                                height: '100%',
                                width: '60%'
                            }}
                            onClick={props.onDateClick}
                        >
                            <_Logo>
                                <i className="far fa-calendar-alt" />
                            </_Logo>

                            <_DateTime>{displayDate(translate, date)}</_DateTime>
                        </Flex>

                        <_DateTime time={true} onClick={props.onTimeClick}>
                            {isValidTime(time) || props.areHoursOpen ? (
                                <input
                                    type="time"
                                    required={true}
                                    value={time}
                                    onChange={(e) => {
                                        props.timeProps.input.onChange(e.target.value);
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            ) : (
                                translate('heure_28539')
                            )}
                        </_DateTime>
                    </Flex>
                ) : (
                    <Flex
                        align="center"
                        css={{
                            color: '$oldGrayIcon',
                            cursor: 'pointer',
                            flex: '1',
                            height: '100%'
                        }}
                        onClick={props.onPlaceholderClick}
                    >
                        <_Logo>
                            <i className="far fa-calendar-alt" />
                        </_Logo>

                        <div>{props.placeholder}</div>
                    </Flex>
                )}
            </_DateTimeContainer>

            {invalid && (
                <Box
                    css={{
                        color: '$oldRed',
                        marginBottom: '$6'
                    }}
                >
                    {error}
                </Box>
            )}
        </div>
    );
};
