import {
    FormMissionsOptionsFragment,
    FormMissionsQuery,
    FormPositionRanking,
    PositionId,
    PositionsCategoryId,
    PositionsSlotId,
    RegisterPositionDisplay,
    VolunteersRegistrationsSlotInput
} from 'common/src/generated/types';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import { UserInfoFields } from 'common/src/vo/field';
import { canSelectV2 } from 'common/src/vo/positionCategory';
import { min, sortBy } from 'lodash-es';
import * as React from 'react';
import { FormMissionsCategories } from './categories/formMissionsCategories';
import { FormMissionsPositions } from './positions/formMissionsPositions';
import { FormMissionsRanked } from './ranked/formMissionsRanked';
import { FormMissionsSlots } from './slots/formMissionsSlots';

interface IFormMissionsProps {
    event: FormMissionsQuery['event'];
    options: FormMissionsOptionsFragment;
    positionsCategoriesIds: PositionsCategoryId[];
    positionsIds: PositionId[];
    positionsSlotsIds: PositionsSlotId[];
    prefix: string;
    slots: VolunteersRegistrationsSlotInput[];
    userInfoFields: UserInfoFields;

    change(name: string, value: any): void;
}

export const FormMissions = (props: IFormMissionsProps) => {
    const positionsCategories = React.useMemo(() => sortBy(
            props.event.positionsCategories.filter((positionCategory) => canSelectV2(
                    positionCategory,
                    props.slots,
                    props.userInfoFields,
                    props.options
                )),
            (pc) => min(
                    pc.positions.flatMap((p) => p.slots.flatMap((s) => s.range.start!.toMillis()))
                )
        ), [props.event.positionsCategories, props.slots, props.userInfoFields, props.options]);

    if (props.options.positionRanking === FormPositionRanking.Ranked) {
        return (
            <FormMissionsRanked
                categories={positionsCategories}
                options={props.options}
                positionsCategoriesIds={props.positionsCategoriesIds}
                positionsIds={props.positionsIds}
                prefix={props.prefix}
                slots={props.slots}
                userInfoFields={props.userInfoFields}
                change={props.change}
            />
        );
    } else {
        switch (props.options.positionDisplay) {
            case RegisterPositionDisplay.Category:
                return (
                    <FormMissionsCategories
                        categories={positionsCategories}
                        options={props.options}
                        positionsCategoriesIds={props.positionsCategoriesIds}
                        prefix={props.prefix}
                        change={props.change}
                    />
                );
            case RegisterPositionDisplay.Position:
                return (
                    <FormMissionsPositions
                        categories={positionsCategories}
                        options={props.options}
                        positionsIds={props.positionsIds}
                        prefix={props.prefix}
                        slots={props.slots}
                        userInfoFields={props.userInfoFields}
                        change={props.change}
                    />
                );
            case RegisterPositionDisplay.Slot:
                return (
                    <FormMissionsSlots
                        categories={positionsCategories}
                        options={props.options}
                        positionsSlotsIds={props.positionsSlotsIds}
                        prefix={props.prefix}
                        slots={props.slots}
                        userInfoFields={props.userInfoFields}
                        change={props.change}
                    />
                );
            case RegisterPositionDisplay.None:
                return null;
            default:
                return assertUnreachable(props.options.positionDisplay);
        }
    }
};
