import { Flex } from 'common/src/designSystem/components/flex';
import { Span } from 'common/src/designSystem/components/span';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Accept, acceptExtensions, acceptString } from '../../../../util/accept';

interface IFileSimpleUploaderProps {
    accept: Accept | Accept[];

    onSelect(file: File): void;
}

export const FileSimpleUploader = (props: IFileSimpleUploaderProps) => {
    const translate = useTranslate();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [isDraggingOver, setIsDraggingOver] = React.useState(false);
    const extensions = acceptExtensions(props.accept);

    return (
        <Flex
            direction="column"
            align="center"
            justify="center"
            css={{
                background: isDraggingOver ? 'white' : '$oldGray',
                border: '1px dashed $oldGrayBorder',
                borderRadius: '3px',
                cursor: 'pointer',
                height: '150px',
                width: '100%',
                '& > div:nth-child(2)': {
                    color: '$oldBlueBayoux',
                    marginTop: '$2'
                },
                '& > input': {
                    visibility: 'hidden'
                }
            }}
            onDragEnter={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setIsDraggingOver(true);
            }}
            onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (!isDraggingOver) {
                    setIsDraggingOver(true);
                }
            }}
            onDragLeave={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setIsDraggingOver(false);
            }}
            onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setIsDraggingOver(false);

                props.onSelect(e.dataTransfer.files.item(0)!);
            }}
            onClick={() => {
                inputRef.current?.click();
            }}
        >
            <div>{translate('s_lectionnez_un_87142')}</div>
            <div>
                {translate('format_accept_22544', extensions.length)}{' '}
                <Span css={{ textTransform: 'uppercase' }}>{extensions.join(', ')}</Span>
            </div>

            <input
                type="file"
                accept={acceptString(props.accept)}
                ref={inputRef}
                onChange={() => {
                    props.onSelect(inputRef.current!.files![0]);
                }}
            />
        </Flex>
    );
};
