import { Avatar } from 'common/src/components/avatar/avatar';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { ExistingVolunteerFragment } from 'common/src/generated/types';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { IBadgeColorIcon } from 'common/src/vo/volunteerRegistration';
import { noop } from 'lodash-es';
import * as React from 'react';
import { Checkbox, ICheckboxState } from '../../designSystem/components/checkbox';

const getBadgeProps = (source: ExistingUserSource): IBadgeColorIcon => {
    switch (source) {
        case ExistingUserSource.Event:
            return { color: 'success', leftIcon: 'calendar' };
        case ExistingUserSource.ParentDelegation:
            return { color: 'pink', leftIcon: 'people-group' };
        default:
            return assertUnreachable(source);
    }
};

export enum ExistingUserSource {
    Event = 'event',
    ParentDelegation = 'parent-delegation'
}

interface IExistingUserRowProps {
    state: ICheckboxState;
    userInfo: {
        source?: ExistingUserSource;
    } & ExistingVolunteerFragment;

    onClick(): void;
}

export const ExistingUserRow = ({ state, userInfo, onClick }: IExistingUserRowProps) => {
    const translate = useTranslate();
    const hasName = isNonEmptyString(userInfo.name);

    return (
        <Flex
            align="center"
            css={{
                borderBottom: '1px solid $gray200',
                cursor: 'pointer',
                py: '$3',
                userSelect: 'none',
                width: '100%',
                '&:last-child': {
                    border: 'none'
                }
            }}
            onClick={onClick}
        >
            <Checkbox state={state} onClick={noop} />

            <Spacer width="3" />

            <Avatar
                size={40}
                email={userInfo.email}
                name={userInfo.name}
                image={userInfo.picture?.url}
            />

            <Spacer width="4" />

            <Flex direction="column" css={{ flex: '1', overflow: 'hidden' }}>
                <Box color="gray700" ellipsis>
                    {hasName ? userInfo.name : userInfo.email}
                </Box>

                {hasName && (
                    <Box color="gray500" ellipsis>
                        {userInfo.email}
                    </Box>
                )}
            </Flex>

            {userInfo.source && (
                <Box>
                    <Badge {...getBadgeProps(userInfo.source)} leftIconStyle="solid">
                        {userInfo.source === ExistingUserSource.Event
                            ? translate('_v_nement_22345')
                            : translate('d_l_gation_pare_68507')}
                    </Badge>
                </Box>
            )}
        </Flex>
    );
};
