import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { IIcon } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import { IconBackground } from './iconBackground';

interface IKpiCardProps {
    button?: React.ReactNode;
    color: 'primary' | 'purple' | 'success' | 'warning';
    count: string | number;
    icon: IIcon;
    text: string;
}

export const KpiCard = (props: IKpiCardProps) => (
        <Flex
            direction="column"
            css={{
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                flex: '1',
                overflow: 'hidden'
            }}
        >
            <Flex
                direction="column"
                css={{
                    borderBottom: props.button ? '1px solid $gray200' : 'none',
                    padding: '$6'
                }}
            >
                <IconBackground color={props.color} icon={props.icon} />

                <Spacer height="6" />

                <Box color="gray500">{props.text}</Box>

                <Spacer height="2" />

                <Box color="gray900" fontSize="displayMd" fontWeight="semiBold">
                    {props.count}
                </Box>
            </Flex>

            {props.button && (
                <Flex justify="end" gap="4" css={{ background: '$gray50', padding: '$4 $6' }}>
                    {props.button}
                </Flex>
            )}
        </Flex>
    );
