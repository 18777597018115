import * as React from 'react';
import { PositionsSlot } from '../generated/types';
import { IntervalService } from '../services/intervalService';
import { useService } from '../util/dependencies/dependencies';
import { isNonEmptyString } from '../util/string';

interface IUsePositionSlotIsRenamedRangeResult {
    isRenamed: boolean;
    finalName: string;
    rangeString: string;
}

export function usePositionSlotIsRenamedRange(
    slot: Pick<PositionsSlot, 'name' | 'range'>
): IUsePositionSlotIsRenamedRangeResult {
    const intervalService = useService(IntervalService);
    const rangeString = React.useMemo(() => intervalService.toDisplayString(slot.range, {
            formats: {
                displayWeekday: true
            }
        }), [slot]);
    const isRenamed = isNonEmptyString(slot.name);

    return {
        isRenamed,
        finalName: isRenamed ? slot.name : rangeString,
        rangeString
    };
}
