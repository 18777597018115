import { debugLog } from 'common/src/util/debug';
import { fileInfos } from 'common/src/util/file';
import { mimeType } from 'common/src/util/mimeType';
import { slug } from 'common/src/util/string';
import { uuidv4 } from 'common/src/util/uuid';
import { executeAwsS3PostCredentialsQuery } from '../generated/graphqlHooks';
import { getToken } from './aws/cognito';

export function download(url: string, name: string) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = name;
    link.click();
}

export type Acl = 'public-read' | 'private';

export interface IUploadFileResult {
    acl: Acl;
    key: string;
    name: string;
    size: number;
}

export async function uploadFile(
    file: File,
    acl: Acl,
    onProgress?: (progress: number) => void
): Promise<IUploadFileResult> {
    const token = await getToken();
    const {
        awsS3PostCredentials: { endpointUrl, policy, amzCredential, amzSignature, amzDate }
    } = await executeAwsS3PostCredentialsQuery(
        {
            acl
        },
        token!
    );
    const { name, extension } = fileInfos(file.name);
    const key = `temp/${uuidv4()}/${slug(name)}.${extension}`;
    const formData = new FormData();
    formData.append('key', key);
    formData.append('acl', acl);
    formData.append('success_action_status', '201');
    formData.append('X-Amz-Credential', amzCredential);
    formData.append('X-Amz-Algorithm', 'AWS4-HMAC-SHA256');
    formData.append('X-Amz-Date', amzDate);
    formData.append('Policy', policy);
    formData.append('X-Amz-Signature', amzSignature);
    formData.append('Content-Type', mimeType(extension));
    formData.append('file', file);

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.upload.onprogress = (e) => {
            if (e.lengthComputable) {
                const progress = Math.round((e.loaded / e.total) * 100);

                onProgress?.(progress);
            }
        };
        xhr.onerror = () => {
            reject(new Error('Could not upload file to S3'));
        };
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                const errorCode = xhr.responseXML?.querySelector('Error Code');

                if (errorCode !== null) {
                    debugLog('Could not upload file to S3', {
                        code: errorCode?.textContent ?? '',
                        message: xhr.responseXML?.querySelector('Error Message')?.textContent ?? ''
                    });

                    reject(new Error('Could not upload file to S3'));
                } else {
                    resolve({
                        acl,
                        key,
                        name: file.name,
                        size: file.size
                    });
                }
            }
        };
        xhr.open('POST', endpointUrl, true);
        xhr.send(formData);
    });
}
