import { Flex } from 'common/src/designSystem/components/flex';
import { maxBy, minBy, range } from 'lodash-es';
import { DateTime, Interval } from 'luxon';
import * as React from 'react';
import { CalendarInput } from '../../../designSystem/components/date/calendarInput';

interface IFormCustomDayCalendarProps {
    intervalsGrouped: Array<[string, Array<{ name: string; interval: Interval }>]>;

    isSelected(inteval: Interval): boolean;
    setSelectedBatch(intervals: Array<{ interval: Interval; isSelected: boolean }>): void;
}

export const FormCustomDayCalendar = (props: IFormCustomDayCalendarProps) => {
    const { intervals, minDate, maxDate, numberOfMonths } = React.useMemo(() => {
        const intervals = props.intervalsGrouped.flatMap(([_day, intervals]) =>
            intervals.map(({ interval }) => interval)
        );
        const minDate = minBy(intervals, (i) => i.start!.toMillis())!.start!.startOf('day');
        const maxDate = maxBy(intervals, (i) => i.end!.toMillis())!.end!.endOf('day');
        const numberOfMonths = Math.ceil(
            maxDate.endOf('month').diff(minDate.startOf('month'), 'months').months
        );

        return { intervals, minDate, maxDate, numberOfMonths };
    }, [props.intervalsGrouped]);
    const selecteds = intervals.flatMap((interval) => props.isSelected(interval) ? [interval.start!.startOf('day')] : []);
    const onChange = (firstDayOfMonth: DateTime, newDateTimes: DateTime[]) => {
        props.setSelectedBatch(
            intervals.flatMap((interval) => {
                if (interval.start!.startOf('month').equals(firstDayOfMonth)) {
                    const isSelected = newDateTimes.some((d) =>
                        d.equals(interval.start!.startOf('day'))
                    );

                    return [{ interval, isSelected }];
                } else {
                    return [];
                }
            })
        );
    };

    return (
        <Flex gap="6" wrap="wrap">
            {range(0, numberOfMonths).map((i) => {
                const firstDayOfMonth = minDate.plus({ month: i }).startOf('month');
                const min = i === 0 ? minDate : firstDayOfMonth;
                const max = i === numberOfMonths - 1 ? maxDate : firstDayOfMonth.endOf('month');
                const values = selecteds.filter(
                    (s) => s.month === firstDayOfMonth.month && s.year === firstDayOfMonth.year
                );

                return (
                    <CalendarInput
                        key={i}
                        firstDayOfMonth={firstDayOfMonth}
                        min={min}
                        max={max}
                        values={values}
                        onChange={(newDateTimes: DateTime[]) => {
                            onChange(firstDayOfMonth, newDateTimes);
                        }}
                    />
                );
            })}
        </Flex>
    );
};
