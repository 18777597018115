import { styled } from './stitches';

export type SpacerHeight =
    | 'default'
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12'
    | '13';

export type SpacerWidth =
    | 'default'
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12'
    | '13';

export const Spacer = styled('div', {
    variants: {
        height: {
            default: { height: '100%' },
            1: { minHeight: '$space$1' },
            2: { minHeight: '$space$2' },
            3: { minHeight: '$space$3' },
            4: { minHeight: '$space$4' },
            5: { minHeight: '$space$5' },
            6: { minHeight: '$space$6' },
            7: { minHeight: '$space$7' },
            8: { minHeight: '$space$8' },
            9: { minHeight: '$space$9' },
            10: { minHeight: '$space$10' },
            11: { minHeight: '$space$11' },
            12: { minHeight: '$space$12' },
            13: { minHeight: '$space$13' }
        },
        width: {
            default: { width: '100%' },
            1: { minWidth: '$space$1' },
            2: { minWidth: '$space$2' },
            3: { minWidth: '$space$3' },
            4: { minWidth: '$space$4' },
            5: { minWidth: '$space$5' },
            6: { minWidth: '$space$6' },
            7: { minWidth: '$space$7' },
            8: { minWidth: '$space$8' },
            9: { minWidth: '$space$9' },
            10: { minWidth: '$space$10' },
            11: { minWidth: '$space$11' },
            12: { minWidth: '$space$12' },
            13: { minWidth: '$space$13' }
        }
    },
    defaultVariants: {
        height: 'default',
        width: 'default'
    }
});
