import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Fields } from '../../../components/form/fields';
import { Accept } from '../../../util/accept';
import { Acl } from '../../../util/file';
import { FileS3Input as FileS3InputComponent } from '../../components/file/fileS3Input';
import { FileS3InputValue } from '../../components/file/fileS3InputValue';
import { BaseInputProps } from '../../components/input/baseInputProps';
import { isInvalid } from '../../../components/reactFinalForm/isInvalid';

interface IFileS3InputProps
    extends Pick<BaseInputProps, 'css' | 'label' | 'description' | 'hint' | 'state'> {
    accept: Accept | Accept[];
    acl: Acl;
    prefix: string;
    withFileField?: boolean;
}

export const FileS3Input = ({ withFileField, ...props }: IFileS3InputProps) => {
    const key = `${props.prefix}key`;
    const name = `${props.prefix}name`;
    const acl = `${props.prefix}acl`;
    const names = [key, name, acl];
    let file: string;
    if (withFileField) {
        file = `${props.prefix}file`;
        names.push(file);
    }

    return (
        <Fields
            names={names}
            render={(fieldsProps) => {
                let value: FileS3InputValue | null = null;

                if (
                    isNonEmptyString(fieldsProps[key].input.value) &&
                    isNonEmptyString(fieldsProps[name].input.value) &&
                    isNonEmptyString(fieldsProps[acl].input.value)
                ) {
                    value = {
                        key: fieldsProps[key].input.value,
                        name: fieldsProps[name].input.value,
                        acl: fieldsProps[acl].input.value
                    };
                }

                const { invalid, hint } = Object.values(fieldsProps).reduce(
                    (acc, fieldProps) => {
                        if (acc.invalid) {
                            return acc;
                        } else {
                            if (isInvalid(fieldProps.meta)) {
                                return {
                                    invalid: true,
                                    hint: fieldProps.meta.error || fieldProps.meta.submitError
                                };
                            } else {
                                return acc;
                            }
                        }
                    },
                    { invalid: false, hint: undefined }
                );

                return (
                    <FileS3InputComponent
                        {...props}
                        state={invalid ? 'error' : props.state}
                        hint={invalid ? hint : props.hint}
                        value={value}
                        onChange={(newValue) => {
                            fieldsProps[key].input.onChange(newValue?.key ?? '');
                            fieldsProps[key].input.onBlur();
                            fieldsProps[name].input.onChange(newValue?.name ?? '');
                            fieldsProps[name].input.onBlur();
                            fieldsProps[acl].input.onChange(newValue?.acl ?? '');
                            fieldsProps[acl].input.onBlur();
                            if (withFileField) {
                                fieldsProps[file].input.onChange(newValue?.file);
                                fieldsProps[file].input.onBlur();
                            }
                        }}
                    />
                );
            }}
        />
    );
};
