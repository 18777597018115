import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    Event,
    FormRegisterCustomSlotPeriodFragment,
    RegisterDaysDisplay
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import { getIntervals } from 'common/src/vo/volunteerRegistrationSlot';
import { groupBy, sortBy } from 'lodash-es';
import { Interval } from 'luxon';
import * as React from 'react';
import { FormCustomDayCalendar } from './formCustomDayCalendar';
import { FormSlotsCustomDay } from './formSlotsCustomDay';
import { FormSlotsCustomDaySingle } from './formSlotsCustomDaySingle';

interface IFormSlotsCustomPeriodProps {
    customSlotPeriod: FormRegisterCustomSlotPeriodFragment;
    daysDisplay: RegisterDaysDisplay;
    event: Pick<Event, 'startAt' | 'endAt' | 'ranges'>;

    isSelected(inteval: Interval): boolean;
    setSelected(interval: Interval, isSelected: boolean): void;
    setSelectedBatch(intervals: Array<{ interval: Interval; isSelected: boolean }>): void;
}

export const FormSlotsCustomPeriod = ({
    customSlotPeriod,
    daysDisplay,
    event,
    isSelected,
    setSelected,
    setSelectedBatch
}: IFormSlotsCustomPeriodProps) => {
    const dateTimeService = useService(DateTimeService);
    const allIntervals = React.useMemo(() => getIntervals(
            customSlotPeriod.startDate ?? event.startAt,
            customSlotPeriod.endDate ?? event.endAt,
            customSlotPeriod.customSlots,
            event.ranges,
            daysDisplay
        ), [customSlotPeriod, event, daysDisplay]);
    const intervalsGrouped = React.useMemo(() => sortBy(
            Object.entries(
                groupBy(allIntervals, ({ interval }) =>
                    dateTimeService.toLocaleString(
                        interval.start!.startOf('day'),
                        LocaleFormats.DateOnly.WeekdayLongMonthLong
                    )
                )
            ),
            ([_, [{ interval }]]) => interval.start!.startOf('day').toMillis()
        ), [allIntervals]);

    return (
        <Flex direction="column" gap="2" width={1}>
            {isNonEmptyString(customSlotPeriod.name) && (
                <Box font="gray900 textSm semiBold">{customSlotPeriod.name}</Box>
            )}

            {customSlotPeriod.customSlots.length === 1 ? (
                customSlotPeriod.displayAsCalendar ? (
                    <FormCustomDayCalendar
                        intervalsGrouped={intervalsGrouped}
                        isSelected={isSelected}
                        setSelectedBatch={setSelectedBatch}
                    />
                ) : (
                    <FormSlotsCustomDaySingle
                        hideNameDates={customSlotPeriod.hideNameDates}
                        intervalsGrouped={intervalsGrouped}
                        isSelected={isSelected}
                        setSelected={setSelected}
                    />
                )
            ) : (
                intervalsGrouped.map(([day, intervals], index) => (
                        <FormSlotsCustomDay
                            key={index}
                            day={day}
                            intervals={intervals}
                            isSelected={isSelected}
                            setSelected={setSelected}
                        />
                    ))
            )}
        </Flex>
    );
};
