import * as React from 'react';
import { Flex } from '../flex';
import { CSS } from '../stitches';
import { TableContextProvider } from './tableContext';

interface ITableProps {
    css?: CSS;
    hideBorder?: boolean;
    children: React.ReactNode;
}

export const Table = (props: ITableProps) => (
        <TableContextProvider>
            <Flex
                className={props.hideBorder === true ? 'table-no-border' : 'table'}
                direction="column"
                css={{
                    width: '100%',
                    '& > div:first-child': {
                        borderTopLeftRadius: '$1',
                        borderTopRightRadius: '$1'
                    },
                    '&.table > div:last-child': {
                        borderBottomLeftRadius: '$1',
                        borderBottomRightRadius: '$1'
                    },
                    '&.table-no-border .table-header-row': {
                        borderLeft: 'none',
                        borderRight: 'none',
                        borderTop: 'none'
                    },
                    '&.table-no-border .table-row': {
                        borderLeft: 'none',
                        borderRight: 'none'
                    },
                    ...(props.css as any)
                }}
            >
                {props.children}
            </Flex>
        </TableContextProvider>
    );
