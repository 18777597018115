import { Empty } from 'common-front/src/components/empty/empty';
import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { FormAccreditations } from 'common-front/src/forms/accreditations/formAccreditations';
import {
    useUserInfoAccreditationsUpdateMutation,
    useUserUpdateAccreditationsInfosQuery
} from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { IUserUpdateAccreditationsValues } from 'common-front/src/users/updateAccreditations/userUpdateAccreditationsValues';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    RegisterAccreditationDisplay,
    RegisterSlotDisplay,
    UserUpdateMissionsInfosQuery
} from 'common/src/generated/types';
import { VolunteerRegistrationInputService } from 'common/src/input/volunteerRegistrationInput';
import { ValidateService } from 'common/src/services/validateService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { MembersPaths } from 'common/src/util/membersPaths';
import { FormIdPathParam } from 'common/src/util/pathsTypes';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { UpdateForm } from '../../../../common/updateForm';

interface IMemberRegistrationUpdateWishedAccreditationsProps {
    reload(): void;
}

export const MemberRegistrationUpdateWishedAccreditations = ({
    reload
}: IMemberRegistrationUpdateWishedAccreditationsProps) => {
    const {
        translate,
        params: { eventId, organizationId, userInfoId }
    } = useHeavent();
    const validateService = useService(ValidateService);
    const volunteerRegistrationInput = useService(VolunteerRegistrationInputService);

    const { data, loader: queryLoader } = useUserUpdateAccreditationsInfosQuery({
        organizationId,
        eventId,
        userInfoId,
        loadDelegation: false
    });
    const { mutate, loader: mutationLoader } = useUserInfoAccreditationsUpdateMutation();

    const forms = React.useMemo(() => (data.organization?.userInfo?.formsUsersInfos ?? []).flatMap((fui) => {
            if (fui.form.accreditationDisplay !== RegisterAccreditationDisplay.None) {
                return [fui.form];
            } else {
                return [];
            }
        }), [data?.organization?.userInfo?.formsUsersInfos]);
    const editableForms = React.useMemo(
        () => forms.filter((form) => form.isEditableInMemberSpace),
        [forms]
    );
    const initialSelectedAccreditations = React.useMemo(() => forms.flatMap((form) => {
            const formUserInfo = data.organization.userInfo.formsUsersInfos.find(
                (fui) => fui.formId === form.id
            );

            return [
                {
                    formId: form.id,
                    ...volunteerRegistrationInput.volunteersRegistrationsWishedAccreditationsUpdateInputDefault(
                        formUserInfo
                    )
                }
            ];
        }), [forms, data?.organization?.userInfo]);

    return (
        queryLoader || (
            <Switch>
                <Route
                    path={MembersPaths.MEMBER_REGISTRATION_WISHED_ACCREDITATIONS_EDIT_FORM(
                        ':organizationId',
                        ':userInfoId',
                        ':eventId',
                        ':formId'
                    )}
                    children={
                        <UpdateForm
                            backPath={MembersPaths.MEMBER_REGISTRATION_ACCREDITATIONS(
                                organizationId,
                                userInfoId,
                                eventId
                            )}
                            child={(
                                currForm: UserUpdateMissionsInfosQuery['event']['forms'][number],
                                index: number
                            ) => (
                                    <Form
                                        direction="column"
                                        height={1}
                                        width={1}
                                        initialValues={{
                                            userInfo: {
                                                accreditations: initialSelectedAccreditations
                                            }
                                        }}
                                        onSubmit={async (
                                            values: IUserUpdateAccreditationsValues
                                        ) => {
                                            await mutate({
                                                eventId,
                                                userId: data.organization.userInfo.userId,
                                                userInfoId,
                                                userInfoAccreditations:
                                                    values.userInfo.accreditations
                                            });

                                            reload();
                                        }}
                                        render={({ form, handleSubmit, values }) => (
                                                <Flex direction="column" gap="5">
                                                    <Box>
                                                        <FormAccreditations
                                                            change={form.change}
                                                            delegationAccreditations={[]}
                                                            event={data.event}
                                                            options={{
                                                                ...currForm,
                                                                showFullAccreditation: true,
                                                                slotDisplay:
                                                                    RegisterSlotDisplay.Hide
                                                            }}
                                                            accreditationsSlotsIds={
                                                                values.userInfo.accreditations[
                                                                    index
                                                                ].accreditationsSlotsIds
                                                            }
                                                            prefix={`userInfo.accreditations[${index}].`}
                                                            slots={[]}
                                                            userInfosFields={
                                                                data.organization.userInfo.fields
                                                            }
                                                        />
                                                    </Box>

                                                    <Separator direction="horizontal" />

                                                    <Button
                                                        textAlign="center"
                                                        color="primary"
                                                        onClick={handleSubmit}
                                                    >
                                                        {mutationLoader || translate('update')}
                                                    </Button>
                                                </Flex>
                                            )}
                                        validate={validateService.validateForForm(
                                            volunteerRegistrationInput.userInfoAccreditationsSchema(
                                                currForm.accreditationDisplay
                                            )
                                        )}
                                    />
                                )}
                            forms={editableForms}
                            getEditPath={(formId) =>
                                MembersPaths.MEMBER_REGISTRATION_WISHED_ACCREDITATIONS_EDIT_FORM(
                                    organizationId,
                                    userInfoId,
                                    eventId,
                                    formId
                                )
                            }
                            title={translate('update_desired_accreditations')}
                            userInfo={data.organization.userInfo}
                        />
                    }
                />

                <Route
                    children={
                        <Empty
                            path={MembersPaths.MEMBER_REGISTRATION_WISHED_ACCREDITATIONS_EDIT_FORM(
                                organizationId,
                                userInfoId,
                                eventId,
                                isNonEmptyArray(editableForms)
                                    ? editableForms[0].id
                                    : (-1 as FormIdPathParam)
                            )}
                            replace={true}
                        />
                    }
                />
            </Switch>
        )
    );
};
