import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IImageProps {
    label: React.ReactNode;
    hint?: string;
    url: string;

    onDelete(): void;
}

export const Image = (props: IImageProps) => {
    const translate = useTranslate();

    return (
        <Flex gap="4" width={1}>
            <Box
                height={126}
                width={126}
                css={{
                    border: '1px solid $gray200',
                    borderRadius: '$1',
                    overflow: 'hidden'
                }}
            >
                <img src={props.url} height="126px" width="126px" />
            </Box>

            <Flex direction="column">
                <Box color="gray700" fontWeight="medium">
                    {props.label}
                </Box>

                {props.hint && (
                    <>
                        <Spacer height="1" />

                        <Box color="gray400">{props.hint}</Box>
                    </>
                )}

                <Spacer height="3" />

                <Box
                    color="error700"
                    fontWeight="medium"
                    css={{ cursor: 'pointer' }}
                    onClick={props.onDelete}
                >
                    {translate('supprimer_43083')}
                </Box>
            </Flex>
        </Flex>
    );
};
