import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Fields } from '../../form/fields';
import { TextInputComponent } from './text';

interface IAddressInputComponentProps {
    label: string;
    isOptional?: boolean;
    formattedAddressProps: FieldRenderProps<string, HTMLInputElement>;
    latitudeProps?: FieldRenderProps<number, HTMLInputElement>;
    longitudeProps?: FieldRenderProps<number, HTMLInputElement>;
    placeProps?: FieldRenderProps<google.maps.places.PlaceResult, HTMLInputElement>;
}

const AddressInputComponent = (props: IAddressInputComponentProps) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        const autocomplete = new google.maps.places.Autocomplete(inputRef.current!, {
            types: ['geocode']
        });

        autocomplete.addListener('place_changed', async () => {
            const place = autocomplete.getPlace();
            const lat = place.geometry!.location!.lat();
            const lng = place.geometry!.location!.lng();

            props.formattedAddressProps.input.onChange(place.formatted_address);
            props.latitudeProps?.input.onChange(lat);
            props.longitudeProps?.input.onChange(lng);
            props.placeProps?.input.onChange(place);
        });
    }, []);

    return (
        <TextInputComponent
            label={props.label}
            placeholder={props.label}
            isOptional={props.isOptional}
            forwardedRef={inputRef}
            {...props.formattedAddressProps}
        />
    );
};

interface IAddressInputProps {
    addressName: string;
    latitudeName?: string;
    longitudeName?: string;
    placeName?: string;
    label: string;
    isOptional?: boolean;
}

export const AddressInput = (props: IAddressInputProps) => {
    const fieldsNames = [props.addressName];

    if (props.latitudeName) {
        fieldsNames.push(props.latitudeName);
    }

    if (props.longitudeName) {
        fieldsNames.push(props.longitudeName);
    }

    if (props.placeName) {
        fieldsNames.push(props.placeName);
    }

    return (
        <Fields
            names={fieldsNames}
            render={(fields) => (
                    <AddressInputComponent
                        label={props.label}
                        isOptional={props.isOptional}
                        formattedAddressProps={fields[props.addressName]}
                        latitudeProps={props.latitudeName ? fields[props.latitudeName] : undefined}
                        longitudeProps={
                            props.longitudeName ? fields[props.longitudeName] : undefined
                        }
                        placeProps={props.placeName ? fields[props.placeName] : undefined}
                    />
                )}
        />
    );
};
