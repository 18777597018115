import { DesignSytemColor } from './color';

export type DesignSystemFontSize =
    | 'inherit'
    | 'textXs'
    | 'textSm'
    | 'textMd'
    | 'textLg'
    | 'textXl'
    | 'displayXs'
    | 'displaySm'
    | 'displayMd'
    | 'displayLg'
    | 'displayXl'
    | 'display2Xl';

export type DesignSystemFontWeight = 'inherit' | 'regular' | 'medium' | 'semiBold' | 'bold';

export type DesignSystemFont =
    `${DesignSytemColor} ${DesignSystemFontSize} ${DesignSystemFontWeight}`;

export type IConvertFontStringToPropertiesResult =
    | {
          color: DesignSytemColor;
          fontSize: DesignSystemFontSize;
          fontWeight: DesignSystemFontWeight;
      }
    | {};

export function convertFontStringToProperties(
    font?: DesignSystemFont
): IConvertFontStringToPropertiesResult {
    if (font) {
        const [color, fontSize, fontWeight] = font.split(' ');

        return { color, fontSize, fontWeight };
    } else {
        return {};
    }
}
