import { SpaceBanner } from 'common-front/src/spaces/spaceBanner';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    MemberRegistrationUserInfoFragment,
    OrganizationDelegationsSpaceFragment,
    OrganizationMembersSpaceFragment
} from 'common/src/generated/types';
import * as React from 'react';
import { addCustomTheme } from '../addCustomTheme';
import { SpaceHeader } from './spaceHeader';

export type SpaceDesign = {
    bannerSrc: string | undefined;
    logoSrc: string | undefined;
    themeColors: any | undefined;
};

interface ISpaceContainerProps {
    children: React.ReactNode;
    extraItems?: React.ReactNode;
    isLoading: boolean;
    organization?: OrganizationMembersSpaceFragment | OrganizationDelegationsSpaceFragment;
    spaceDesign: SpaceDesign;
    userInfo?: MemberRegistrationUserInfoFragment;
}

export const SpaceContainer = ({
    children,
    extraItems,
    isLoading,
    organization,
    spaceDesign,
    userInfo
}: ISpaceContainerProps) => {
    React.useEffect(() => {
        if (organization) {
            addCustomTheme(spaceDesign.themeColors ?? {});
        }
    }, [organization]);

    return (
        <Box
            height={1}
            width={1}
            css={{
                overflowY: 'auto'
            }}
        >
            {userInfo && (
                <SpaceHeader
                    extraItems={extraItems}
                    logoSrc={spaceDesign.logoSrc}
                    userInfo={userInfo}
                />
            )}

            <Box
                css={{
                    height: 'fit-content',
                    maxWidth: '1170px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    '@desktop': {
                        backgroundColor: '$white',
                        border: '1px solid $gray200',
                        borderTop: 'unset',
                        borderRadius: '0px 0px $2 $2',
                        marginBottom: '$8'
                    }
                }}
            >
                {!(window.location.pathname.split('/').at(-2) === 'edit') && (
                    <SpaceBanner bannerUrl={spaceDesign.bannerSrc} isLoading={isLoading} />
                )}

                <Flex
                    direction="column"
                    gap="7"
                    css={{
                        height: '100%',
                        position: 'relative',
                        padding: '$4',
                        width: '100%',
                        paddingTop: '$3',
                        '@tablet': {
                            padding: '$5 $7'
                        },
                        '@desktop': {
                            paddingBottom: '$7',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width: '$container'
                        }
                    }}
                >
                    {children}
                </Flex>
            </Box>
        </Box>
    );
};
