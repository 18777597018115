import { loadScriptPromise } from '../../util/loadScript';

interface IViewport {
    height: number;
    width: number;
}

export interface IPdfPage {
    getViewport(zoomLevel: number): IViewport;
    render(args: { canvasContext: CanvasRenderingContext2D; viewport: IViewport }): Promise<any>;
}

export interface IPdf {
    numPages: number;
    getPage(page: number): IPdfPage;
}

function loadScript(): Promise<boolean> {
    if ((window as any).pdfjsLib === undefined) {
        return loadScriptPromise(
            'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/build/pdf.min.js'
        );
    } else {
        return Promise.resolve(true);
    }
}

export function loadDocument(src: string): Promise<IPdf> {
    return loadScript().then(() => (window as any).pdfjsLib.getDocument(src));
}

export async function renderPages(
    pdf: IPdf,
    el: HTMLDivElement,
    zoomLevel: number,
    rotate: number
): Promise<any> {
    Array.from(el.childNodes).forEach((node) => {
        el.removeChild(node);
    });

    for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const viewport = page.getViewport(zoomLevel);
        const div = document.createElement('div');
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const styles = rotateStyles(rotate, viewport.height, viewport.width);

        if (rotate === 25 || rotate === 75) {
            div.style.height = `${viewport.width}px`;
            div.style.width = `${viewport.height}px`;
        } else {
            div.style.height = `${viewport.height}px`;
            div.style.width = `${viewport.width}px`;
        }

        canvas.height = viewport.height;
        canvas.width = viewport.width;
        canvas.style.transform = styles.transform || '';
        canvas.style.transformOrigin = styles.transformOrigin || '';

        div.appendChild(canvas);
        el.appendChild(div);

        page.render({
            canvasContext: context!,
            viewport
        });
    }
}

export function rotateStyles(
    rotate: number,
    height: number,
    width: number
): { transform?: string; transformOrigin?: string } {
    if (rotate === 25) {
        return {
            transform: `translateX(${height}px) rotate(0.25turn)`,
            transformOrigin: 'top left'
        };
    } else if (rotate === 50) {
        return {
            transform: 'rotate(0.5turn)'
        };
    } else if (rotate === 75) {
        return {
            transform: `translateY(${width}px) rotate(0.75turn)`,
            transformOrigin: 'top left'
        };
    } else {
        return {};
    }
}
