import { DelegationMembers } from 'common-front/src/delegations/members/show/delegationMembers';
import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { ProgressGauge } from 'common-front/src/designSystem/components/progressGauge';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { DelegationsPaths } from 'common/src/delegationsPaths';
import { Flex } from 'common/src/designSystem/components/flex';
import { DelegationState } from 'common/src/generated/types';
import * as React from 'react';
import { useDelegationSpaceDashboardQuery } from '../../../generated/graphqlHooks';
import { DelegationDetailsCard, DelegationDetailsCardSkeleton } from './delegationDetailsCard';
import { DelegationManagersCard, DelegationManagersCardSkeleton } from './delegationManagersCard';

export const DelegationDashboard = () => {
    const {
        params: { organizationId, eventId, delegationId },
        translate
    } = useHeavent();
    const { data, isLoading, loader } = useDelegationSpaceDashboardQuery({
        organizationId,
        delegationId
    });

    return (
        <Flex direction={{ '@initial': 'column', '@desktop': 'row' }} gap={{ '@initial': '9' }}>
            <Flex direction="column" gap="4" width={1}>
                <Flex gap="4" width={1}>
                    <Flex width={400}>
                        <DetailBox title={translate('limite_de_membr_42978')}>
                            <Flex align="center" justify="center" height={1} width={1}>
                                {isLoading ? (
                                    loader
                                ) : (
                                    <ProgressGauge
                                        count={data.organization.delegation.numberOfResources}
                                        isErrorIfOver={true}
                                        total={
                                            data.organization.delegation.maxResources ?? Infinity
                                        }
                                    />
                                )}
                            </Flex>
                        </DetailBox>
                    </Flex>

                    <Flex direction="column" gap="4" css={{ flex: '1' }}>
                        <DetailBox title={translate('responsables_84923')}>
                            {isLoading ? (
                                <DelegationManagersCardSkeleton />
                            ) : (
                                <DelegationManagersCard
                                    leaders={data.organization.delegation.leaders}
                                />
                            )}
                        </DetailBox>

                        <DetailBox title={translate('delegation_details')}>
                            {isLoading ? (
                                <DelegationDetailsCardSkeleton />
                            ) : (
                                <DelegationDetailsCard delegation={data.organization.delegation} />
                            )}
                        </DetailBox>
                    </Flex>
                </Flex>

                {isLoading ? (
                    <Flex height={300} width={1}>
                        {loader}
                    </Flex>
                ) : (
                    <DelegationMembers
                        canEditMembers={
                            data.organization.delegation.state !== DelegationState.Locked
                        }
                        customFields={data.organization.customFields.nodes}
                        delegation={data.organization.delegation}
                        deleteFromEvent={true}
                        showAccreditActions={false}
                        showDeleteFromEvent={false}
                        showDocumentsDownload={data.organization.allowDelegationSpaceDownload}
                        getEditPath={(userInfoId, formId) =>
                            DelegationsPaths.MEMBER_EDIT({
                                delegationId,
                                eventId,
                                organizationId,
                                userInfoId,
                                formId
                            })
                        }
                    />
                )}
            </Flex>
        </Flex>
    );
};
