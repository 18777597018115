import { DateTime } from 'luxon';
import * as React from 'react';
import { Fields } from '../../../form/fields';
import { useMediaQueryContext } from '../../../mediaQuery/mediaQueryContext';
import { SlotPicker } from './slotPicker';
import { SlotPickerMobile } from './slotPickerMobile';

const isDateTimeSupported = (() => {
    try {
        const input = document.createElement('input');
        input.setAttribute('type', 'datetime-local');
        input.value = 'Hello World';

        return input.type !== 'text' && input.value !== 'Hello World';
    } catch {
        return false;
    }
})();

interface ISlotInputProps {
    prefix: string;
    min?: DateTime;
    max?: DateTime;
}

export const SlotInput = (props: ISlotInputProps) => {
    const { isMobile } = useMediaQueryContext();

    return (
        <Fields
            names={[
                `${props.prefix}startDate`,
                `${props.prefix}startTime`,
                `${props.prefix}endDate`,
                `${props.prefix}endTime`
            ]}
            render={(fields) => {
                if (isMobile && isDateTimeSupported) {
                    return (
                        <SlotPickerMobile
                            startDateProps={fields[`${props.prefix}startDate`]}
                            startTimeProps={fields[`${props.prefix}startTime`]}
                            endDateProps={fields[`${props.prefix}endDate`]}
                            endTimeProps={fields[`${props.prefix}endTime`]}
                            prefix={props.prefix}
                            min={props.min}
                            max={props.max}
                        />
                    );
                } else {
                    return (
                        <SlotPicker
                            startDateProps={fields[`${props.prefix}startDate`]}
                            startTimeProps={fields[`${props.prefix}startTime`]}
                            endDateProps={fields[`${props.prefix}endDate`]}
                            endTimeProps={fields[`${props.prefix}endTime`]}
                            min={props.min}
                            max={props.max}
                        />
                    );
                }
            }}
        />
    );
};
