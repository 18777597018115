import { FlexedButton } from 'common-front/src/components/button/button';
import { Checkbox } from 'common-front/src/components/reactFinalForm/input/checkbox';
import { Select } from 'common-front/src/components/reactFinalForm/input/select';
import { UserForm } from 'common-front/src/components/users/create/userForm';
import { Alert } from 'common-front/src/designSystem/components/alert';
import { Spacer } from 'common/src/components/spacer/spacer';
import { Box } from 'common/src/designSystem/components/box';
import {
    CustomFieldWithConditionFragment,
    RegisterInfosQuery,
    RegisterPositionDisplay,
    RegisterSlotDisplay,
    UserInfoEditFragment,
    UsersInfoId
} from 'common/src/generated/types';
import {
    IRegisterValues,
    VolunteerRegistrationInputService
} from 'common/src/input/volunteerRegistrationInput';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { FormApi, ValidationErrors } from 'final-form';
import { uniq } from 'lodash-es';
import * as React from 'react';
import { OnChange } from 'react-final-form-listeners';

const getErrors = (errors: any): string[] => uniq(
        Object.values(errors)
            .map((value) => {
                if (typeof value === 'string') {
                    return value;
                } else {
                    return getErrors(value);
                }
            })
            .flat()
    );

interface IInformationsProps {
    event: RegisterInfosQuery['event'];
    form: FormApi<IRegisterValues>;
    values: IRegisterValues;
    invalid: boolean;
    customFields: CustomFieldWithConditionFragment[];
    usersInfos: UserInfoEditFragment[];
    email: string;
    errors: ValidationErrors;

    handleSubmit(): void;
}

export const Informations = (props: IInformationsProps) => {
    const translate = useTranslate();
    const volunteerRegistrationInput = useService(VolunteerRegistrationInputService);
    const [isFirstTime, setIsFirstTime] = React.useState(true);
    const customFields = React.useMemo(() => props.event.eventsFields.map(({ field }) => field), [props.event.eventsFields]);
    const boxRef = React.useRef<HTMLDivElement | null>(null);
    const [showErrors, setShowErrors] = React.useState(false);
    const errors = getErrors(props.errors);
    let nextMessage: string = translate('suivant_dispo_94398');

    if (
        props.event.slotDisplay === RegisterSlotDisplay.Hide &&
        props.event.positionDisplay === RegisterPositionDisplay.None
    ) {
        nextMessage = translate('s_inscrire_1_82684', props.event.name);
    } else if (props.event.slotDisplay === RegisterSlotDisplay.Hide) {
        nextMessage = translate('suivant_cr_ne_20377');
    }

    return (
        <>
            <Box ref={boxRef} css={{ display: 'none' }} />

            {props.usersInfos.length > 1 && (
                <div>
                    <OnChange name="userInfoId">
                        {(id: UsersInfoId) => {
                            const userInfo = props.usersInfos.find((u) => u.id === id);

                            if (userInfo) {
                                setIsFirstTime(true);

                                props.form.reset({
                                    userInfoId: userInfo.id,
                                    forOther: false,
                                    volunteerRegistration:
                                        volunteerRegistrationInput.volunteersRegistrationInputDefault(
                                            props.event,
                                            userInfo,
                                            props.customFields
                                        )
                                });
                            }
                        }}
                    </OnChange>

                    <Select
                        label={translate('s_lectionner_le_84819')}
                        name="userInfoId"
                        parseInt={true}
                    >
                        {props.usersInfos.map((userInfo) => (
                                <option value={userInfo.id} key={userInfo.id}>
                                    {userInfo.nameOrEmail}
                                </option>
                            ))}
                    </Select>
                </div>
            )}

            {showErrors && isNonEmptyArray(errors) && (
                <>
                    <Alert color="error" leftIcon="circle-exclamation">
                        <Box width={1} css={{ paddingLeft: '$6' }}>
                            {errors.map((error, index) => <li key={index}>{error}</li>)}
                        </Box>
                    </Alert>

                    <Spacer height={32} />
                </>
            )}

            <OnChange name="forOther">
                {(forOther: boolean) => {
                    if (isFirstTime && forOther) {
                        setIsFirstTime(false);

                        const volunteerRegistration =
                            volunteerRegistrationInput.volunteersRegistrationInputDefault(
                                props.event,
                                null,
                                props.customFields
                            );

                        props.form.reset({
                            forOther: true,
                            volunteerRegistration
                        });
                    }
                }}
            </OnChange>

            <Box
                css={{
                    background: '$oldGrayLight',
                    border: '1px solid $oldGrayBorder',
                    borderRadius: '3px',
                    display: 'flex',
                    padding: '$4',
                    '& i': {
                        fontSize: '16px'
                    },
                    '& > div:last-child': {
                        flex: '1',
                        wordBreak: 'break-word'
                    }
                }}
            >
                <div>
                    <i className="fal fa-info-circle" />
                </div>

                <Spacer width={12} />

                <div>
                    <div>{translate('inscrire_une_no_94934')}</div>

                    <Spacer height={8} />

                    <div>
                        <Checkbox
                            name="forOther"
                            text={translate('cocher_la_case_45725', props.email)}
                            noMargin={true}
                        />
                    </div>
                </div>
            </Box>

            <Spacer height={32} />

            <UserForm
                fieldsToDisplay={props.event.eventsFields}
                customFields={customFields}
                prefix="volunteerRegistration.userInfo.fields."
                values={props.values.volunteerRegistration.userInfo.fields}
                change={props.form.change}
            />

            <FlexedButton
                onClick={() => {
                    if (props.invalid) {
                        setShowErrors(true);
                        props.form.mutators.forceErrors();

                        setTimeout(() => {
                            boxRef.current?.parentElement?.scrollIntoView();
                        }, 100);
                    } else {
                        props.handleSubmit();
                    }
                }}
            >
                {nextMessage}
            </FlexedButton>
        </>
    );
};
