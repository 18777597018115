export const normalizeInteger = (value?: string) => {
    const intValue = parseInt(value!, 10);

    return isNaN(intValue) ? null : intValue;
};

export const normalizeFloat = (value?: string) => {
    const floatValue = parseFloat(value!);

    return isNaN(floatValue) ? null : floatValue;
};
