import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Button, IButtonColor } from './button';
import { Dropdown } from './dropdown/dropdown';
import { Menu } from './dropdown/menu';
import { Trigger } from './dropdown/trigger';

type BaseProps = {
    children: React.ReactNode;
    color: IButtonColor;
    isOpen?: boolean;

    onStateChange?(isOpen: boolean): void;
};

type NoButtonsProps = BaseProps & {
    showButtons: false;
};

type ButtonsProps = BaseProps & {
    showButtons: true;

    apply(): void;
    reinit(): void;
};

type IDropdownFiltersProps = NoButtonsProps | ButtonsProps;

export const DropdownFilters = (props: IDropdownFiltersProps) => {
    const translate = useTranslate();

    return (
        <Dropdown
            isOpen={props.isOpen}
            doNotCloseOnOtherOpen={true}
            onStateChange={props.onStateChange}
        >
            <Trigger>
                <Box>
                    <Button color={props.color} leftIcon="bars-filter">
                        {translate('filtres_64574')}
                    </Button>
                </Box>
            </Trigger>

            <Menu placement="bottom-end" width={385}>
                <Flex
                    direction="column"
                    onChange={(e) => {
                        e.nativeEvent.stopImmediatePropagation();
                        e.stopPropagation();
                    }}
                >
                    <Flex direction="column" gap="4" css={{ padding: '$2 $6 $4 $6' }}>
                        <Box font="gray800 textMd medium">{translate('liste_des_filtr_75605')}</Box>

                        {props.children}
                    </Flex>

                    {props.showButtons && (
                        <Flex
                            align="center"
                            justify="end"
                            gap="4"
                            css={{ borderTop: '1px solid $gray200', padding: '$4 $6 $2 $6' }}
                        >
                            <Box
                                font="gray700 textSm medium"
                                css={{ cursor: 'pointer' }}
                                onClick={props.reinit}
                            >
                                {translate('r_initialiser_77538')}
                            </Box>

                            <Button size="sm" onClick={props.apply}>
                                {translate('appliquer_05753')}
                            </Button>
                        </Flex>
                    )}
                </Flex>
            </Menu>
        </Dropdown>
    );
};
