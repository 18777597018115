import { compact, sortBy } from 'lodash-es';
import { Interval } from 'luxon';
import {
    DelegationAccreditationFragment,
    FormAccreditationsOptionsFragment,
    FormRegisterAccreditationFragment,
    RegisterAccreditationDisplay,
    VolunteerRegistrationFragment,
    VolunteersRegistrationsSlotInput
} from '../generated/types';
import { DateTimeService } from '../services/dateTimeService';
import { LocaleFormats } from '../util/luxon';
import { isNonEmptyString } from '../util/string';
import { canSelect as canSelectSlot } from './accreditationSlot';
import { UserInfoFields } from './field';
import { meetPositionCustomFieldsConditions } from './position';

export function canSelect(
    accreditation: FormRegisterAccreditationFragment,
    ranges: Array<Interval | VolunteersRegistrationsSlotInput>,
    userFields: UserInfoFields,
    options: FormAccreditationsOptionsFragment,
    delegationAccreditations: DelegationAccreditationFragment[]
): boolean {
    return (
        (options.accreditationDisplay !== RegisterAccreditationDisplay.None &&
            !options.hiddenAccreditationsIds.includes(accreditation.id) &&
            (options.displayedAccreditationsIds.length === 0 ||
                options.displayedAccreditationsIds.includes(accreditation.id)) &&
            meetPositionCustomFieldsConditions(accreditation, userFields) &&
            (!accreditation.hasSlots ||
                accreditation.slots.some((slot) => canSelectSlot(slot, ranges, options, delegationAccreditations)))) ||
        delegationAccreditations.some((da) => (
                da.accreditationId === accreditation.id &&
                (!accreditation.hasSlots ||
                    accreditation.slots.some((slot) => canSelectSlot(slot, ranges, options, delegationAccreditations)))
            ))
    );
}

export function getAccreditationsBadges(
    dateTimeService: DateTimeService,
    auis: VolunteerRegistrationFragment['accreditationsUsersInfos']
) {
    return sortBy(auis, (aui) => compact([
            aui.accreditationSlot.accreditationCategory.name,
            aui.accreditationSlot.accreditation.name,
            aui.accreditationSlot.date?.toMillis() ?? 1,
            aui.accreditationSlot.name
        ])).map(({ accreditationSlot, accreditationSlot: { accreditation } }) => {
        const name = isNonEmptyString(accreditation.acronym)
            ? accreditation.acronym
            : accreditation.name;
        const date = accreditationSlot.date
            ? ` | ${dateTimeService.toLocaleString(
                  accreditationSlot.date,
                  LocaleFormats.DateOnly.Numeric
              )}`
            : '';
        const text = `${name}${date}`.trim();

        return {
            id: accreditationSlot.id,
            color: accreditation.color,
            icon: accreditation.icon,
            text
        };
    });
}

export function getWishedAccreditationsBadges(
    dateTimeService: DateTimeService,
    accreditationsSlots: VolunteerRegistrationFragment['accreditationsSlots']
) {
    return sortBy(accreditationsSlots, (slot) => compact([
            slot.accreditationCategory.name,
            slot.accreditation.name,
            slot.date?.toMillis() ?? 1,
            slot.name
        ])).map((slot) => {
        const name = isNonEmptyString(slot.accreditation.acronym)
            ? slot.accreditation.acronym
            : slot.accreditation.name;
        const date = slot.date
            ? ` | ${dateTimeService.toLocaleString(slot.date, LocaleFormats.DateOnly.Numeric)}`
            : '';

        return {
            id: slot.id,
            color: slot.accreditation.color,
            icon: slot.accreditation.icon,
            text: `${name}${date}`.trim()
        };
    });
}
