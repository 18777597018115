import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import { Accept } from '../../../util/accept';
import { Acl, uploadFile } from '../../../util/file';
import { BaseInputProps } from '../input/baseInputProps';
import { Description } from '../input/description';
import { Hint } from '../input/hint';
import { Label } from '../input/label';
import { File } from './file';
import { FileProgress } from './fileProgress';
import { FileS3InputValue } from './fileS3InputValue';
import { FileUploader } from './fileUploader';

export enum FileS3InputState {
    File,
    Uploader,
    Uploading
}

interface IFileS3InputProps
    extends Pick<BaseInputProps, 'css' | 'label' | 'description' | 'hint' | 'state'> {
    accept?: Accept | Accept[];
    acl: Acl;
    value: FileS3InputValue | null;

    onChange(value: (FileS3InputValue & { file: File }) | null): void;
}

export const FileS3Input = (props: IFileS3InputProps) => {
    const [state, setState] = React.useState(
        props.value ? FileS3InputState.File : FileS3InputState.Uploader
    );
    const [name, setName] = React.useState('');
    const [progress, setProgress] = React.useState(0);
    const onChange = async (file: File) => {
        setState(FileS3InputState.Uploading);
        setName(file.name);
        setProgress(0);

        try {
            const doc = await uploadFile(file, props.acl, setProgress);

            props.onChange({ ...doc, file });
            setState(FileS3InputState.File);
        } catch {
            setState(FileS3InputState.Uploader);
        }
    };

    return (
        <Flex css={props.css} direction="column" width={1}>
            <Label>{props.label}</Label>

            <Description>{props.description}</Description>

            {(props.label || props.description) && <Spacer height="1" />}

            {state === FileS3InputState.File && props.value ? (
                <File
                    acl={props.acl}
                    document={props.value}
                    onDelete={() => {
                        props.onChange(null);
                        setState(FileS3InputState.Uploader);
                    }}
                />
            ) : state === FileS3InputState.Uploading ? (
                <FileProgress name={name} progress={progress} />
            ) : (
                <FileUploader accept={props.accept} onChange={onChange} />
            )}

            <Hint state={props.state}>{props.hint}</Hint>
        </Flex>
    );
};
