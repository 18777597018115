import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { UserCreateForm } from 'common-front/src/components/users/create/v2/userCreateForm';
import { Button } from 'common-front/src/designSystem/components/button';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { FormAccreditations } from 'common-front/src/forms/accreditations/formAccreditations';
import { FormMissions } from 'common-front/src/forms/missions/formMissions';
import { FormSlots } from 'common-front/src/forms/slots/formSlots';
import {
    useEmailUpdateMutation,
    useUserInfoAccreditationsUpdateMutation,
    useUserInfoPositionsUpdateMutation,
    useUserInfoSlotsUpdateMutation,
    useUserInfoUpdateMutation
} from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    DelegationsSpaceCreateMemberQuery,
    DelegationsSpaceUpdateMemberQuery,
    FormElementType,
    FormRegisterInput,
    RegisterAccreditationDisplay,
    RegisterPositionDisplay,
    RegisterSlotDisplay,
    UserEmail
} from 'common/src/generated/types';
import {
    FormRegisterInputService,
    IFormRegisterEmailValues
} from 'common/src/input/formRegisterInput';
import { FieldFormElement } from 'common/src/input/userInfoInput';
import { ValidateService } from 'common/src/services/validateService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { DelegationsPaths as DelegationsPathsV2 } from 'common/src/util/paths/delegationsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { DateTime } from 'luxon';
import * as React from 'react';
import {
    useDelegationsSpaceCreateMemberQuery,
    useDelegationsSpaceUpdateMemberQuery,
    useFormRegisterMutation
} from '../../../../generated/graphqlHooks';
import { DelegationMemberCreateUpdateBox } from './delegationMemberCreateUpdateBox';

type CreateUpdateProps = {
    delegation: DelegationsSpaceCreateMemberQuery['organization']['delegation'];
    initialValues: IFormRegisterEmailValues;

    mutate(email: UserEmail, formRegister: FormRegisterInput): Promise<any>;
    reload(): void;
};

type CreateMemberProps = CreateUpdateProps & {
    isEdit: false;
};

type UpdateMemberProps = CreateUpdateProps & {
    isEdit: true;
    userInfo: DelegationsSpaceUpdateMemberQuery['organization']['userInfo'];
};

type IDelegationCreateUpdateMemberProps = CreateMemberProps | UpdateMemberProps;

const DelegationCreateUpdateMember = (props: IDelegationCreateUpdateMemberProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, delegationId }
    } = useHeavent();
    const validateService = useService(ValidateService);
    const formRegisterInput = useService(FormRegisterInputService);
    const form = props.delegation.form;
    const fieldFormElements = React.useMemo(() => form.elements.filter(
            (e) => e.elementType === FormElementType.Field
        ) as FieldFormElement[], [form.elements]);
    const showSlots = React.useMemo(() => eventId && form.slotDisplay !== RegisterSlotDisplay.Hide, [form, eventId]);
    const showMissions = React.useMemo(() => eventId && form.positionDisplay !== RegisterPositionDisplay.None, [form, eventId]);
    const delegationAccreditations = React.useMemo(() => props.delegation.accreditations.filter((da) => da.isVisible), [props.delegation.accreditations]);
    const showAccreditations = React.useMemo(() => (
            eventId &&
            (form.accreditationDisplay !== RegisterAccreditationDisplay.None ||
                isNonEmptyArray(delegationAccreditations))
        ), [delegationAccreditations, form, eventId]);
    const validate = React.useCallback(
        (values: IFormRegisterEmailValues) => validateService.validateForForm(
                formRegisterInput.formRegisterSchema(
                    fieldFormElements,
                    form.slotDisplay,
                    props.delegation.event?.startAt ?? DateTime.now(),
                    props.delegation.event?.endAt ?? DateTime.now(),
                    form.positionRanking,
                    form.positionDisplay,
                    form.accreditationDisplay
                )
            )(values),
        [props.delegation, fieldFormElements, form]
    );
    const gotoDelegation = () =>
        history.push(DelegationsPathsV2.DELEGATION(organizationId, eventId, delegationId));

    return (
        <Form
            direction="column"
            width={1}
            hideErrors={true}
            initialValues={props.initialValues}
            validate={validate}
            onSubmit={async (values: IFormRegisterEmailValues) => {
                await props.mutate(values.email, values.formRegister);

                props.reload();

                gotoDelegation();
            }}
            render={({ form: { change }, submitting, values, handleSubmit }) => (
                    <>
                        <Button color="invisible" leftIcon="arrow-left" onClick={gotoDelegation}>
                            {translate('retour_92202')}
                        </Button>

                        <Spacer height="4" />

                        {props.isEdit ? (
                            <DelegationMemberCreateUpdateBox>
                                <Flex align="center" gap="3">
                                    <Avatar
                                        size={64}
                                        name={props.userInfo.name}
                                        email={props.userInfo.email}
                                        image={props.userInfo.picture?.url}
                                    />

                                    <Flex direction="column" gap="1">
                                        <Box font="gray500 textXs medium">
                                            {translate('mise_jour_des_00596')}
                                        </Box>

                                        <Box font="gray800 textXl bold">{props.userInfo.name}</Box>
                                    </Flex>
                                </Flex>
                            </DelegationMemberCreateUpdateBox>
                        ) : (
                            <Box font="gray800 textLg bold">
                                {translate('cr_ation_d_un_m_98624')}
                            </Box>
                        )}

                        <Spacer height="6" />

                        <FormErrors />

                        <DelegationMemberCreateUpdateBox>
                            {isNonEmptyString(form.description) && (
                                <>
                                    <RichText text={form.description} />

                                    <Spacer height="4" />
                                </>
                            )}

                            <TextInput label={translate('Email')} name="email" isEmail={true} />
                        </DelegationMemberCreateUpdateBox>

                        <Spacer height="6" />

                        <DelegationMemberCreateUpdateBox
                            title={
                                isNonEmptyString(form.informationTitle)
                                    ? form.informationTitle
                                    : translate('informations_pe_37892')
                            }
                        >
                            <UserCreateForm
                                autoComplete={true}
                                elements={form.elements}
                                prefix="formRegister.userInfo.fields."
                                values={values.formRegister.userInfo.fields}
                                change={change}
                            />
                        </DelegationMemberCreateUpdateBox>

                        <Spacer height="6" />

                        {showSlots && (
                            <>
                                <DelegationMemberCreateUpdateBox
                                    title={
                                        isNonEmptyString(form.slotTitle)
                                            ? form.slotTitle
                                            : translate('disponibilit_s_49923')
                                    }
                                >
                                    {isNonEmptyString(form.slotSubtitle) && (
                                        <>
                                            <RichText text={form.slotSubtitle} />

                                            <Spacer height="6" />
                                        </>
                                    )}

                                    <FormSlots
                                        currentSlots={values.formRegister.slots}
                                        customSlotsPeriods={form.customSlotsPeriods}
                                        daysDisplay={form.daysDisplay}
                                        event={props.delegation.event!}
                                        prefix="formRegister."
                                        slotDisplay={form.slotDisplay}
                                        change={change}
                                    />
                                </DelegationMemberCreateUpdateBox>

                                <Spacer height="6" />
                            </>
                        )}

                        {showMissions && (
                            <>
                                <DelegationMemberCreateUpdateBox
                                    title={
                                        isNonEmptyString(form.positionTitle)
                                            ? form.positionTitle
                                            : translate('missions_souhai_23225')
                                    }
                                >
                                    {isNonEmptyString(form.positionSubtitle) && (
                                        <>
                                            <RichText text={form.positionSubtitle} />

                                            <Spacer height="6" />
                                        </>
                                    )}

                                    <FormMissions
                                        event={props.delegation.event!}
                                        options={form}
                                        positionsCategoriesIds={
                                            values.formRegister.positionsCategoriesIds
                                        }
                                        positionsIds={values.formRegister.positionsIds}
                                        positionsSlotsIds={values.formRegister.positionsSlotsIds}
                                        prefix="formRegister."
                                        slots={values.formRegister.slots}
                                        userInfoFields={values.formRegister.userInfo.fields}
                                        change={change}
                                    />
                                </DelegationMemberCreateUpdateBox>

                                <Spacer height="6" />
                            </>
                        )}

                        {showAccreditations && (
                            <>
                                <DelegationMemberCreateUpdateBox
                                    title={
                                        isNonEmptyString(form.accreditationTitle)
                                            ? form.accreditationTitle
                                            : translate('accr_ditations_74375')
                                    }
                                >
                                    {isNonEmptyString(form.accreditationSubtitle) && (
                                        <>
                                            <RichText text={form.accreditationSubtitle} />

                                            <Spacer height="6" />
                                        </>
                                    )}

                                    <FormAccreditations
                                        delegationAccreditations={delegationAccreditations}
                                        event={props.delegation.event!}
                                        options={form}
                                        accreditationsSlotsIds={
                                            values.formRegister.accreditationsSlotsIds
                                        }
                                        prefix="formRegister."
                                        slots={values.formRegister.slots}
                                        userInfosFields={values.formRegister.userInfo.fields}
                                        change={change}
                                    />
                                </DelegationMemberCreateUpdateBox>

                                <Spacer height="6" />
                            </>
                        )}

                        <Flex gap="4">
                            <Button isLoading={submitting} onClick={handleSubmit}>
                                {props.isEdit
                                    ? translate('mettre_jour_l_47784')
                                    : translate('cr_er_le_membre_78743')}
                            </Button>

                            <Button color="white" onClick={gotoDelegation}>
                                {translate('annuler_48254')}
                            </Button>
                        </Flex>
                    </>
                )}
        />
    );
};

interface IDelegationCreateMemberProps {
    reload(): void;
}

export const DelegationCreateMember = (props: IDelegationCreateMemberProps) => {
    const {
        params: { organizationId, delegationId, formId }
    } = useHeavent();
    const formRegisterInput = useService(FormRegisterInputService);
    const { mutate: formRegister } = useFormRegisterMutation();
    const { data, loader } = useDelegationsSpaceCreateMemberQuery({
        organizationId,
        delegationId,
        formId
    });
    const customFields = React.useMemo(() => (data.organization?.delegation.form.elements ?? []).flatMap((element) => {
            if (element.elementType === FormElementType.Field) {
                return [element.customField!];
            } else {
                return [];
            }
        }), [data.organization]);
    const initialValues = React.useMemo(() => {
        if (data.organization) {
            return {
                email: '' as UserEmail,
                formRegister: formRegisterInput.formRegisterInputDefault(null, customFields, {
                    countryCode:
                        data.organization?.delegation?.event?.country ?? data.organization?.country,
                    eventStartDate: data.organization?.delegation?.event?.startAt,
                    forOther: true,
                    sendNotificationEmail: false,
                    slotDisplay: data.organization?.delegation.form?.slotDisplay
                })
            };
        } else {
            return {} as any;
        }
    }, [data.organization, customFields]);
    const mutate = React.useCallback(
        (email: UserEmail, formRegisterInput: FormRegisterInput) => formRegister({
                organizationId,
                eventId: data.organization?.delegation.eventId,
                delegationId,
                formId: data.organization?.delegation.form.id,
                email,
                formRegister: formRegisterInput
            }),
        [data.organization]
    );

    return (
        loader || (
            <DelegationCreateUpdateMember
                delegation={data.organization.delegation}
                initialValues={initialValues}
                isEdit={false}
                mutate={mutate}
                reload={props.reload}
            />
        )
    );
};

interface IDelegationUpdateMemberProps {
    reload(): void;
}

export const DelegationUpdateMember = (props: IDelegationUpdateMemberProps) => {
    const {
        params: { organizationId, eventId, delegationId, formId, userInfoId }
    } = useHeavent();
    const formRegisterInput = useService(FormRegisterInputService);
    const { mutate: emailUpdate } = useEmailUpdateMutation();
    const { mutate: userInfoUpdate } = useUserInfoUpdateMutation();
    const { mutate: userInfoSlotsUpdate } = useUserInfoSlotsUpdateMutation();
    const { mutate: userInfoPositionsUpdate } = useUserInfoPositionsUpdateMutation();
    const { mutate: userInfoAccreditationsUpdate } = useUserInfoAccreditationsUpdateMutation();
    const { data, loader } = useDelegationsSpaceUpdateMemberQuery({
        organizationId,
        delegationId,
        formId,
        userInfoId
    });
    const customFields = React.useMemo(() => (data.organization?.delegation.form.elements ?? []).flatMap((element) => {
            if (element.elementType === FormElementType.Field) {
                return [element.customField!];
            } else {
                return [];
            }
        }), [data.organization]);
    const initialValues = React.useMemo(() => {
        if (data.organization) {
            const ui = data.organization.userInfo;

            return {
                email: ui.email,
                formRegister: {
                    ...formRegisterInput.formRegisterInputDefault(ui, customFields, {
                        countryCode:
                            data.organization?.delegation?.event?.country ??
                            data.organization?.country,
                        eventStartDate: data.organization?.delegation?.event?.startAt,
                        forOther: true,
                        sendNotificationEmail: false,
                        slotDisplay: data.organization?.delegation.form?.slotDisplay
                    }),
                    positionsSlotsIds: ui.formUserInfo.positionsSlotsIds,
                    positionsIds: ui.formUserInfo.positionsIds,
                    positionsCategoriesIds: ui.formUserInfo.positionsCategoriesIds,
                    accreditationsSlotsIds: ui.formUserInfo.accreditationsSlotsIds,
                    slots: ui.formUserInfo.slots.map((slot) => ({
                        startDate: slot.startDate,
                        startTime: slot.startTime,
                        endDate: slot.endDate,
                        endTime: slot.endTime
                    }))
                }
            };
        } else {
            return {} as any;
        }
    }, [data.organization, customFields]);
    const mutate = React.useCallback(
        async (email: UserEmail, formRegister: FormRegisterInput) => {
            if (data.organization) {
                if (email !== data.organization.userInfo.email) {
                    await emailUpdate({
                        organizationId,
                        eventId,
                        delegationId,
                        userInfoId,
                        email
                    });
                }

                if (
                    eventId &&
                    data.organization.delegation.form.slotDisplay !== RegisterSlotDisplay.Hide
                ) {
                    await userInfoSlotsUpdate({
                        eventId,
                        delegationId,
                        userId: data.organization.userInfo.userId,
                        userInfoId,
                        userInfoSlots: [
                            {
                                formId,
                                slots: formRegister.slots
                            }
                        ]
                    });
                }

                if (
                    eventId &&
                    data.organization.delegation.form.positionDisplay !==
                        RegisterPositionDisplay.None
                ) {
                    await userInfoPositionsUpdate({
                        eventId,
                        delegationId,
                        userId: data.organization.userInfo.userId,
                        userInfoId,
                        userInfoPositions: [
                            {
                                formId,
                                positionsCategoriesIds: formRegister.positionsCategoriesIds,
                                positionsIds: formRegister.positionsIds,
                                positionsSlotsIds: formRegister.positionsSlotsIds
                            }
                        ]
                    });
                }

                if (
                    eventId &&
                    (data.organization.delegation.form.accreditationDisplay !==
                        RegisterAccreditationDisplay.None ||
                        isNonEmptyArray(data.organization.delegation.accreditations))
                ) {
                    await userInfoAccreditationsUpdate({
                        eventId,
                        delegationId,
                        userId: data.organization.userInfo.userId,
                        userInfoId,
                        userInfoAccreditations: [
                            {
                                formId,
                                accreditationsSlotsIds: formRegister.accreditationsSlotsIds
                            }
                        ]
                    });
                }

                return userInfoUpdate({
                    organizationId,
                    eventId,
                    delegationId,
                    userId: data.organization.userInfo.userId,
                    userInfo: formRegister.userInfo
                });
            } else {
                return true;
            }
        },
        [data.organization]
    );

    return (
        loader || (
            <DelegationCreateUpdateMember
                delegation={data.organization.delegation}
                initialValues={initialValues}
                isEdit={true}
                userInfo={data.organization.userInfo}
                mutate={mutate}
                reload={props.reload}
            />
        )
    );
};
