// see: https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
export function getColorByBackgroundColor(backgroundColor: string): 'black' | 'white' {
    try {
        if (backgroundColor[0] !== '#' || backgroundColor.length !== 7) {
            return 'black';
        } else {
            const red = parseInt(backgroundColor.substring(1, 3), 16);
            const green = parseInt(backgroundColor.substring(3, 5), 16);
            const blue = parseInt(backgroundColor.substring(5), 16);

            if (red * 0.299 + green * 0.587 + blue * 0.114 > 150) {
                return 'black';
            } else {
                return 'white';
            }
        }
    } catch {
        return 'black';
    }
}

export function hexToRgba(hex: string, opacity: number): string {
    const offset = hex[0] === '#' ? 1 : 0;
    const red = parseInt(hex.substring(0 + offset, 2 + offset), 16);
    const green = parseInt(hex.substring(2 + offset, 4 + offset), 16);
    const blue = parseInt(hex.substring(4 + offset), 16);
    const finalOpacity = opacity > 1 ? opacity / 100 : opacity;

    return `rgba(${red}, ${green}, ${blue}, ${finalOpacity})`;
}
