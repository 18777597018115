import {
    AccreditationState,
    CustomBadge,
    DelegationMembersQuery,
    FormId
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { getDocumentFullPath } from 'common/src/util/documentsPaths';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { getDisplayName } from 'common/src/vo/customBadge';
import * as React from 'react';
import { DeleteModal } from '../../../designSystem/components/deleteModal';
import { DropdownEllipsis } from '../../../designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from '../../../designSystem/components/dropdown/item';
import { Separator } from '../../../designSystem/components/separator';
import {
    useUserInfoDelegationDeleteMutation,
    useVolunteerRegistrationDeleteMutation,
    useVolunteersRegistrationsAccreditationsValidateMutation
} from '../../../generated/graphqlHooks';
import { useHeavent } from '../../../hooks/useHeavent';

interface IDelegationMemberRowDropdownProps {
    accreditationState: Emptyable<AccreditationState>;
    canEditMembers: boolean;
    customBadges: CustomBadge[];
    deleteFromEvent?: boolean;
    formId: FormId;
    member: DelegationMembersQuery['organization']['usersInfos']['nodes'][number];
    showAccreditActions: boolean;
    showDeleteFromEvent: boolean;
    showDocumentsDownload: boolean;

    reload(): void;
}

export const DelegationMemberRowDropdown = ({
    accreditationState,
    canEditMembers,
    customBadges,
    deleteFromEvent,
    formId,
    member,
    reload,
    showAccreditActions,
    showDeleteFromEvent,
    showDocumentsDownload
}: IDelegationMemberRowDropdownProps) => {
    const {
        translate,
        params: { organizationId, eventId, delegationId }
    } = useHeavent();
    const { mutate: accreditationsValidate } =
        useVolunteersRegistrationsAccreditationsValidateMutation();
    const { mutate: userInfoDelegationDelete } = useUserInfoDelegationDeleteMutation();
    const { mutate: volunteerRegistrationDelete } = useVolunteerRegistrationDeleteMutation();
    const [isDeleteFromDelegationOpen, setIsDeleteFromDelegationOpen] = React.useState(false);
    const [isDeleteFromEventOpen, setIsDeleteFromEventOpen] = React.useState(false);
    const showDocuments =
        showDocumentsDownload &&
        member.links !== undefined &&
        isNonEmptyArray(customBadges) &&
        eventId;

    return (
        <>
            <DropdownEllipsis>
                {showAccreditActions &&
                    eventId &&
                    accreditationState === AccreditationState.WaitingAccreditation && (
                        <>
                            <ItemIcon
                                icon="check"
                                onClick={async () => {
                                    await accreditationsValidate({
                                        eventId,
                                        formId: formId,
                                        volunteerRegistrationsIds: [
                                            member.volunteerRegistration!.id
                                        ]
                                    });

                                    reload();
                                }}
                            >
                                {translate('valider_les_acc_80801')}
                            </ItemIcon>

                            <ItemIcon
                                icon="badge-check"
                                to={HeaventPaths.ACCREDIT(organizationId, eventId, member.id)}
                            >
                                {translate('accr_diter_70543')}
                            </ItemIcon>

                            <Separator direction="horizontal" color="gray100" />
                        </>
                    )}

                {showDocuments && (
                    <>
                        {isNonEmptyArray(customBadges) && (
                            <>
                                {customBadges.map((customBadge, index) => (
                                        <ItemIcon
                                            key={index}
                                            icon="arrow-down-to-bracket"
                                            download={`${member.nameOrEmail}-custom-badge`}
                                            to={{
                                                pathname: getDocumentFullPath(
                                                    member.links?.userCustomBadgeLinks![
                                                        customBadge
                                                    ],
                                                    'pdf'
                                                )
                                            }}
                                            target={'_blank'}
                                        >
                                            {translate(
                                                't_l_charger_1_80752',
                                                getDisplayName(customBadge)
                                            )}
                                        </ItemIcon>
                                    ))}
                            </>
                        )}
                    </>
                )}

                {showDocuments && canEditMembers && (
                    <Separator direction="horizontal" color="gray100" />
                )}

                {canEditMembers && (
                    <ItemIcon
                        color="red"
                        icon="user-xmark"
                        onClick={() => setIsDeleteFromDelegationOpen(true)}
                    >
                        {translate('retirer_de_la_d_64963')}
                    </ItemIcon>
                )}

                {showDeleteFromEvent && (
                    <ItemIcon
                        color="red"
                        icon="trash-can"
                        onClick={() => setIsDeleteFromEventOpen(true)}
                    >
                        {translate('delete_from_eve_69290')}
                    </ItemIcon>
                )}
            </DropdownEllipsis>

            {isDeleteFromDelegationOpen && (
                <DeleteModal
                    text={translate('suppression_de_73669', member.name)}
                    subtext={[translate('_tes_vous_sur_d_88752')]}
                    buttonText={translate('supprimer_43083')}
                    onDelete={async () => {
                        await userInfoDelegationDelete({
                            organizationId,
                            eventId,
                            userInfoId: member.id,
                            delegationId,
                            deleteFromEvent
                        });

                        reload();
                    }}
                    onClose={() => setIsDeleteFromDelegationOpen(false)}
                />
            )}

            {isDeleteFromEventOpen && member.volunteerRegistration && (
                <DeleteModal
                    text={translate('suppression_de_73669', member.name)}
                    subtext={[translate('are_you_sure_yo_46668')]}
                    buttonText={translate('supprimer_43083')}
                    onDelete={async () => {
                        await volunteerRegistrationDelete({
                            eventId,
                            volunteerRegistrationId: member.volunteerRegistration!.id
                        });

                        reload();
                    }}
                    onClose={() => setIsDeleteFromDelegationOpen(false)}
                />
            )}
        </>
    );
};
