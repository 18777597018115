import { Empty } from 'common-front/src/components/empty/empty';
import { VolunteerAssignmentsQuery } from 'common/src/generated/types';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { getEmbedPath, VolunteersPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useEventPageContext } from '../common/eventPageContext';
import { Registration } from './registration';

interface IWithRegistrationProps {
    event: VolunteerAssignmentsQuery['event'];
    user: VolunteerAssignmentsQuery['user'];

    render(volunteerRegistration: Registration): React.ReactNode;
}

export const WithRegistration = (props: IWithRegistrationProps) => {
    const { organizationId, eventId, volunteerRegistrationId } = useParams();
    const { isEmbed } = useEventPageContext();
    const volunteerRegistration = props.user.volunteersRegistrations.find(
        ({ id }) => id === volunteerRegistrationId
    );

    if (volunteerRegistration) {
        return <>{props.render(volunteerRegistration)}</>;
    } else {
        return (
            <Empty
                path={getEmbedPath(isEmbed, VolunteersPaths.ASSIGNMENTS(organizationId, eventId))}
            />
        );
    }
};
