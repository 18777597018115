import { injectable } from 'inversify';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { TranslationService } from '../services/translationService';
import { DateTimeSchemaType } from '../util/yup/dateTimeSchemaType';

export interface IEventDuplicateValues {
    startAt: DateTime;
    endAt: DateTime;
}

@injectable()
export class EventDuplicateInputService {
    constructor(private translationService: TranslationService) {}

    eventDuplicateSchema() {
        return yup.object().shape({
            startAt: DateTimeSchemaType.required(
                this.translationService.translate('la_date_d_but_d_30022')
            ).typeError(this.translationService.translate('la_date_d_but_d_30022')),
            endAt: DateTimeSchemaType.required(
                this.translationService.translate('la_date_de_fin_45068')
            )
                .typeError(this.translationService.translate('la_date_de_fin_45068'))
                .test(
                    'after-start-at',
                    this.translationService.translate('la_date_de_fin_00429'),
                    function () {
                        return (
                            DateTime.isDateTime(this.parent.startAt) &&
                            this.parent.startAt.isValid &&
                            DateTime.isDateTime(this.parent.endAt) &&
                            this.parent.endAt.isValid &&
                            this.parent.endAt >= this.parent.startAt
                        );
                    }
                )
        });
    }
}
