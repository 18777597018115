import { injectable } from 'inversify';
import { sumBy } from 'lodash-es';
import * as yup from 'yup';
import {
    ALL_CAMPAIGNTYPE,
    CampaignEditFragment,
    CampaignInput,
    CampaignState,
    CampaignType,
    EmailsSenderId
} from '../generated/types';
import { TranslationService } from '../services/translationService';
import { isNonEmptyArray } from '../util/array';
import { isClean } from '../util/email';
import { Emptyable } from '../util/emptyable';
import { isNonEmptyString } from '../util/string';
import { DateTimeSchemaType } from '../util/yup/dateTimeSchemaType';
import { invalidCharacters } from '../vo/sms';
import { InputService } from './inputService';

export const TEXT_MESSAGE_SENDER_REGEX = /^[a-z][a-z0-9]{2,10}$/i;
const SMS_TEXT_MAX_LENGTH = 1000;
const ATTACHMENTS_MAX_SIZE = 8_000_000; // 8Mo

export enum CampaignRecipients {
    Accreditations = 'accreditations',
    Everyone = 'everyone',
    Positions = 'positions',
    Segments = 'segments',
    States = 'states',
    Users = 'users'
}

export interface ICreateUpdateCampaignValues {
    campaign: CampaignInput;
    recipients: CampaignRecipients;
    customSender: boolean;
    textOrHtml: 'text' | 'html';
    delivery: 'now' | 'later';
}

@injectable()
export class CampaignInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    createUpdateCampaignValuesDefault(
        isAdmin: boolean,
        emailSenderId: Emptyable<EmailsSenderId>,
        campaign?: CampaignEditFragment
    ): ICreateUpdateCampaignValues {
        const campaignInput = this.campaignInputDefault(isAdmin, emailSenderId, campaign);

        return {
            campaign: campaignInput,
            recipients: campaignInput.sendToEveryone
                ? CampaignRecipients.Everyone
                : isNonEmptyArray(campaignInput.states)
                ? CampaignRecipients.States
                : isNonEmptyArray(campaignInput.usersInfosIds)
                ? CampaignRecipients.Users
                : isNonEmptyArray(campaignInput.segmentsIds)
                ? CampaignRecipients.Segments
                : isNonEmptyArray(campaignInput.accreditationsCategoriesIds) ||
                  isNonEmptyArray(campaignInput.accreditationsIds) ||
                  isNonEmptyArray(campaignInput.accreditationsSlotsIds)
                ? CampaignRecipients.Accreditations
                : CampaignRecipients.Positions,
            customSender: isNonEmptyString(campaign?.sender),
            textOrHtml: isNonEmptyString(campaign?.html) ? 'html' : 'text',
            delivery:
                campaignInput.state === CampaignState.Scheduled && campaign?.scheduledAt?.isValid
                    ? 'later'
                    : 'now'
        };
    }

    campaignStep1Fields() {
        return {
            name: yup.string().required(this.t('le_nom_de_la_ca_63405')),
            campaignType: yup
                .string()
                .required(this.t('le_type_de_camp_96559'))
                .oneOf(ALL_CAMPAIGNTYPE, this.t('le_type_de_camp_56467'))
        };
    }

    campaignStep1Schema() {
        return yup.object().shape({
            campaign: yup.object().shape({
                ...this.campaignStep1Fields()
            })
        });
    }

    campaignStep2Fields() {
        const self = this;

        return {
            sender: yup.string().test('is-valid', this.t('l_metteur_ne_r_16421'), function () {
                if (isNonEmptyString(this.parent.sender)) {
                    return TEXT_MESSAGE_SENDER_REGEX.test(this.parent.sender);
                } else {
                    return true;
                }
            }),
            text: yup
                .string()
                .test('non-empty-text', this.t('le_contenu_est_45048'), function () {
                    return isNonEmptyString(this.parent.text) || isNonEmptyString(this.parent.html);
                })
                .test('only-valid-characters', '', function () {
                    if (this.parent.campaignType === CampaignType.Email) {
                        return true;
                    } else {
                        const characters = invalidCharacters(this.parent.text);

                        if (characters.size > 0) {
                            return this.createError({
                                message: self.translationService.translate(
                                    'les_caract_res_84839',
                                    Array.from(characters).join(', ')
                                )
                            });
                        } else {
                            return true;
                        }
                    }
                })
                .test('max-length', this.t('le_contenu_ne_p_25416'), function () {
                    if (this.parent.campaignType === CampaignType.Email) {
                        return true;
                    } else {
                        return this.parent.text.length < SMS_TEXT_MAX_LENGTH;
                    }
                })
                .test('no-percent-encoded-variables', this.t('le_texte_de_l_e_71717'), function () {
                    return !(this.parent.text.includes('%7B') || this.parent.text.includes('%7D'));
                }),
            subject: yup.string().when('campaignType', {
                is: CampaignType.Email,
                then: (s) => s.required(this.t('le_sujet_est_re_18923'))
            }),
            html: yup
                .string()
                .test('non-empty-text', this.t(this.t('le_code_html_es_52280')), function () {
                    return isNonEmptyString(this.parent.text) || isNonEmptyString(this.parent.html);
                }),
            replyTo: yup
                .string()
                .email(this.t('l_adresse_de_r_53879'))
                .test('clean-reply-to', this.t('l_adresse_de_r_53879'), function () {
                    return isClean(this.parent.replyTo);
                }),
            documents: yup.array().test('max-size', this.t('l_ensemble_des_32797'), function () {
                if (
                    this.parent.campaignType === CampaignType.Email &&
                    isNonEmptyArray(this.parent.documents)
                ) {
                    const totalSize = sumBy(this.parent.documents, (d: any) => d.size);

                    return totalSize < ATTACHMENTS_MAX_SIZE;
                } else {
                    return true;
                }
            }),
            emailSenderId: yup.number().nullable(),
            attachments: yup.array().of(yup.string())
        };
    }

    campaignStep2Schema() {
        return yup.object().shape({
            campaign: yup.object().shape({
                ...this.campaignStep2Fields()
            })
        });
    }

    campaignStep3Fields() {
        const self = this;
        const testFn = function (this: yup.TestContext) {
            if (
                this.parent.sendToEveryone === false &&
                this.parent.states.length === 0 &&
                this.parent.positionsCategoriesIds.length === 0 &&
                this.parent.positionsIds.length === 0 &&
                this.parent.positionsSlotsIds.length === 0 &&
                this.parent.tagsIds.length === 0 &&
                this.parent.accreditationsCategoriesIds.length === 0 &&
                this.parent.accreditationsIds.length === 0 &&
                this.parent.accreditationsSlotsIds.length === 0 &&
                this.parent.usersInfosIds.length === 0 &&
                this.parent.segmentsIds.length === 0
            ) {
                return this.createError({
                    message: self.translationService.translate('vous_devez_s_le_80552')
                });
            } else {
                return true;
            }
        };

        return {
            sendToEveryone: yup.boolean().required(this.t('requis_75853')),
            states: yup.array().of(yup.string()).test('non-empty', '', testFn),
            positionsCategoriesIds: yup.array().of(yup.number()).test('non-empty', '', testFn),
            positionsIds: yup.array().of(yup.number()).test('non-empty', '', testFn),
            positionsSlotsIds: yup.array().of(yup.number()).test('non-empty', '', testFn),
            tagsIds: yup.array().of(yup.number()).test('non-empty', '', testFn),
            accreditationsCategoriesIds: yup.array().of(yup.number()).test('non-empty', '', testFn),
            accreditationsIds: yup.array().of(yup.number()).test('non-empty', '', testFn),
            accreditationsSlotsIds: yup.array().of(yup.number()).test('non-empty', '', testFn),
            usersInfosIds: yup.array().of(yup.number()).test('non-empty', '', testFn),
            segmentsIds: yup.array().of(yup.number()).test('non-empty', '', testFn)
        };
    }

    campaignStep3Schema() {
        return yup.object().shape({
            campaign: yup.object().shape({
                ...this.campaignStep3Fields()
            })
        });
    }

    campaignStep4Fields() {
        return {
            scheduledAtDate: DateTimeSchemaType.when('state', {
                is: CampaignState.Scheduled,
                then: (s) =>
                    s
                        .required(this.t('la_date_d_envoi_17792'))
                        .typeError(this.t('la_date_d_envoi_17792'))
            }),
            scheduledAtTime: yup.string().when('state', {
                is: CampaignState.Scheduled,
                then: (s) =>
                    s
                        .required(this.t('l_heure_d_envoi_53643'))
                        .matches(/\d{2}:\d{2}/, this.t('l_heure_d_envoi_53643'))
            })
        };
    }

    campaignStep4Schema() {
        return yup.object().shape({
            campaign: yup.object().shape({
                ...this.campaignStep4Fields()
            })
        });
    }

    campaignInputSchema() {
        return yup.object().shape({
            ...this.campaignStep1Fields(),
            ...this.campaignStep2Fields(),
            ...this.campaignStep3Fields(),
            ...this.campaignStep4Fields()
        });
    }

    createUpdateCampaignSchema() {
        return yup.object().shape({
            campaign: this.campaignInputSchema()
        });
    }

    private campaignInputDefault(
        isAdmin: boolean,
        emailSenderId: Emptyable<EmailsSenderId>,
        campaign?: CampaignEditFragment
    ): CampaignInput {
        return {
            name: campaign?.name ?? '',
            state: campaign?.state ?? CampaignState.Draft,
            campaignType: campaign?.campaignType ?? CampaignType.Email,
            sender: campaign?.sender ?? '',
            text: campaign?.text ?? '',
            subject: campaign?.subject ?? '',
            html: campaign?.html ?? '',
            replyTo: campaign?.replyTo ?? '',
            sendToEveryone: campaign?.sendToEveryone ?? isAdmin,
            states: campaign?.states ?? [],
            positionsCategoriesIds: campaign?.positionsCategoriesIds ?? [],
            positionsIds: campaign?.positionsIds ?? [],
            positionsSlotsIds: campaign?.positionsSlotsIds ?? [],
            tagsIds: campaign?.tagsIds ?? [],
            accreditationsCategoriesIds: campaign?.accreditationsCategoriesIds ?? [],
            accreditationsIds: campaign?.accreditationsIds ?? [],
            accreditationsSlotsIds: campaign?.accreditationsSlotsIds ?? [],
            usersInfosIds: campaign?.usersInfosIds ?? [],
            segmentsIds: campaign?.segmentsIds ?? [],
            scheduledAtDate: campaign?.scheduledAt ?? undefined,
            scheduledAtTime: campaign?.scheduledAt?.toFormat('HH:mm') ?? undefined,
            documents: campaign?.documents ?? [],
            emailSenderId: campaign?.emailSenderId ?? emailSenderId,
            attachments: campaign?.attachments ?? []
        };
    }
}
