import { Interval } from 'luxon';
import {
    DelegationAccreditationFragment,
    FormAccreditationsOptionsFragment,
    FormRegisterAccreditationCategoryFragment,
    RegisterAccreditationDisplay,
    VolunteersRegistrationsSlotInput
} from '../generated/types';
import { canSelect as canSelectAccreditation } from './accreditation';
import { UserInfoFields } from './field';

export function canSelect(
    category: FormRegisterAccreditationCategoryFragment,
    ranges: Array<Interval | VolunteersRegistrationsSlotInput>,
    userFields: UserInfoFields,
    options: FormAccreditationsOptionsFragment,
    delegationAccreditations: DelegationAccreditationFragment[]
): boolean {
    return (
        (options.accreditationDisplay !== RegisterAccreditationDisplay.None &&
            !options.hiddenAccreditationsCategoriesIds.includes(category.id) &&
            (options.displayedAccreditationsCategoriesIds.length === 0 ||
                options.displayedAccreditationsCategoriesIds.includes(category.id)) &&
            category.accreditations.some((accreditation) => canSelectAccreditation(
                    accreditation,
                    ranges,
                    userFields,
                    options,
                    delegationAccreditations
                ))) ||
        delegationAccreditations.some((da) => (
                da.accreditationCategoryId === category.id &&
                category.accreditations.some((accreditation) => canSelectAccreditation(
                        accreditation,
                        ranges,
                        userFields,
                        options,
                        delegationAccreditations
                    ))
            ))
    );
}
