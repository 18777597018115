import { injectable } from 'inversify';
import { EventLinksInput, EventLinksQuery } from '../generated/types';

export interface IEventLinksValues {
    event: EventLinksInput;
}

@injectable()
export class EventLinksInputService {
    eventLinksInputDefault(event: EventLinksQuery['event']): EventLinksInput {
        return {
            volunteerSpaceLink: event.volunteerSpaceLink ?? ''
        };
    }
}
