import { Spacer } from 'common/src/designSystem/components/spacer';
import { VolunteerRegistrationSlotInputService } from 'common/src/input/volunteerRegistrationSlotInput';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { DateTime } from 'luxon';
import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Button } from '../../../designSystem/components/button';
import { FormSlotsSlot } from './formSlotsSlot';

interface IFormSlotsSlotsProps {
    min: DateTime;
    max: DateTime;
    prefix: string;
}

export const FormSlotsSlots = (props: IFormSlotsSlotsProps) => {
    const translate = useTranslate();
    const volunteerRegistrationSlotInput = useService(VolunteerRegistrationSlotInputService);
    const max = React.useMemo(() => props.max.endOf('day'), [props.max]);

    return (
        <FieldArray name={`${props.prefix}slots`}>
            {({ fields }) => (
                    <>
                        {fields.map((field, index) => (
                                <React.Fragment key={index}>
                                    {index !== 0 && <Spacer height="4" />}

                                    <FormSlotsSlot
                                        index={index}
                                        min={props.min}
                                        max={max}
                                        prefix={field}
                                        remove={() => {
                                            fields.remove(index);
                                        }}
                                    />
                                </React.Fragment>
                            ))}

                        <Spacer height="4" />

                        <Button
                            color="invisible"
                            leftIcon="plus"
                            onClick={() => {
                                fields.push(
                                    volunteerRegistrationSlotInput.volunteersRegistrationsSlotInputDefault(
                                        props.min
                                    )
                                );
                            }}
                        >
                            {translate('ajouter_une_dis_96540')}
                        </Button>
                    </>
                )}
        </FieldArray>
    );
};
