import { injectable } from 'inversify';
import { TranslationFragment, TranslationInput, TranslationType } from '../generated/types';
import { assertUnreachable } from '../util/assertUnreachable';
import { isNonEmptyString } from '../util/string';

export interface IUpdateTranslationValues {
    translation: TranslationInput;
}

@injectable()
export class TranslationInputService {
    translationInputDefault(translation: TranslationFragment): TranslationInput {
        return {
            fr: this.default(translation.translationType, translation.fr),
            en: this.default(translation.translationType, translation.en),
            es: this.default(translation.translationType, translation.es),
            de: this.default(translation.translationType, translation.de),
            nl: this.default(translation.translationType, translation.nl)
        };
    }

    private default(translationType: TranslationType, translation: any) {
        switch (translationType) {
            case TranslationType.String:
                return isNonEmptyString(translation) ? translation : '';
            case TranslationType.StringArray:
                return Array.isArray(translation) ? translation : [];
            case TranslationType.RichText:
                return isNonEmptyString(translation) ? translation : '';
            default:
                return assertUnreachable(translationType);
        }
    }
}
