import { Separator } from 'common-front/src/designSystem/components/separator';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { MemberQuery } from 'common/src/generated/types';
import { NumberService } from 'common/src/services/numberService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IMemberDocumentsProps {
    documents: MemberQuery['userInfo']['documents']['nodes'];
}

export const MemberDocuments = (props: IMemberDocumentsProps) => {
    const translate = useTranslate();
    const numberService = useService(NumberService);
    const sizeFormatter = React.useMemo(() => {
        try {
            return numberService.getFormatter();
        } catch {
            return null;
        }
    }, []);

    return (
        <Flex direction="column" gap="4" width={1} css={{ bd: true, padding: '$6' }}>
            {props.documents.length === 0 && (
                <Box color="gray500" textAlign="center">
                    {translate('aucun_document_09772')}
                </Box>
            )}

            {props.documents.map((document, index) => (
                    <React.Fragment key={document.id}>
                        {index !== 0 && <Separator direction="horizontal" />}

                        <Flex align="center" gap="3">
                            <Flex
                                align="center"
                                justify="center"
                                height={40}
                                width={40}
                                css={{
                                    background: '$gray100',
                                    borderRadius: '$2',
                                    color: '$gray700'
                                }}
                            >
                                <I icon="file-lines" />
                            </Flex>

                            <Flex direction="column" gap="1">
                                <Box font="gray800 textSm medium">{document.name}</Box>

                                {sizeFormatter && typeof document.size === 'number' && (
                                    <Box color="gray500">{sizeFormatter.format(document.size)}</Box>
                                )}
                            </Flex>
                        </Flex>
                    </React.Fragment>
                ))}
        </Flex>
    );
};

export const MemberDocumentsSkeleton = () => (
        <Flex
            direction="column"
            gap="4"
            width={1}
            css={{
                bd: true,
                padding: '$6'
            }}
        >
            <Skeleton height={40} width={1} borderRadius="$2" />
            <Separator direction="horizontal" />
            <Skeleton height={40} width={1} borderRadius="$2" />
        </Flex>
    );
