import { injectable } from 'inversify';
import * as yup from 'yup';
import {
    ALL_FORMSTATE,
    ALL_REGISTERDAYSDISPLAY,
    ALL_REGISTERPOSITIONDISPLAY,
    ALL_REGISTERPOSITIONFILTER,
    ALL_REGISTERSLOTDISPLAY,
    EventParametersInput,
    RegisterSlotDisplay
} from '../generated/types';
import { TranslationService } from '../services/translationService';
import { CustomSlotInputService } from './customSlotInput';
import { InputService } from './inputService';

export interface IEventParametersValues {
    event: EventParametersInput;
}

@injectable()
export class EventParametersInputService extends InputService {
    constructor(
        private customSlotInput: CustomSlotInputService,
        protected translationService: TranslationService
    ) {
        super(translationService);
    }

    eventParametersInputSchema() {
        return yup.object().shape({
            positionDisplay: yup
                .string()
                .required(this.t('la_strat_gie_d_44170'))
                .oneOf(ALL_REGISTERPOSITIONDISPLAY, this.t('la_strat_gie_d_44170')),
            slotDisplay: yup
                .string()
                .required(this.t('la_strat_gie_d_34961'))
                .oneOf(ALL_REGISTERSLOTDISPLAY, this.t('la_strat_gie_d_34961')),
            positionFilter: yup
                .string()
                .required(this.t('la_strat_gie_de_13294'))
                .oneOf(ALL_REGISTERPOSITIONFILTER, this.t('la_strat_gie_de_13294')),
            daysDisplay: yup
                .string()
                .required(this.t('la_strat_gie_de_56435'))
                .oneOf(ALL_REGISTERDAYSDISPLAY, this.t('la_strat_gie_de_56435')),
            customSlots: yup
                .array()
                .of(this.customSlotInput.customSlotInputSchema())
                .when('slotDisplay', {
                    is: RegisterSlotDisplay.Custom,
                    then: (s) =>
                        s
                            .required(this.t('au_moins_un_cr_56018'))
                            .min(1, this.t('au_moins_un_cr_56018'))
                }),
            formState: yup
                .string()
                .required(this.t('la_strat_gie_de_36288'))
                .oneOf(ALL_FORMSTATE, this.t('la_strat_gie_de_36288'))
        });
    }

    updateEventParametersSchema() {
        return yup.object().shape({
            event: this.eventParametersInputSchema()
        });
    }
}
