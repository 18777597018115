import { Box } from 'common/src/designSystem/components/box';
import * as React from 'react';

export enum Color {
    BlueBayoux
}

interface IUppercaseTitleProps {
    title: string;
    color?: Color;
}

export const UppercaseTitle = (props: IUppercaseTitleProps) => (
        <Box
            css={{
                color: props.color === Color.BlueBayoux ? '$oldBlueBayoux' : 'inherit',
                fontSize: '11px',
                fontWeight: '$oldBold',
                letterSpacing: '1px',
                marginBottom: '$2',
                textTransform: 'uppercase'
            }}
        >
            {props.title}
        </Box>
    );
