import { TranslateFn } from 'common/src/services/translationService';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import { DateTime } from 'luxon';
import * as React from 'react';

export enum SelectMode {
    Start,
    End
}

export function displayDate(translate: TranslateFn, date: DateTime | null) {
    return date && date.isValid
        ? date.toLocaleString(LocaleFormats.DateOnly.Numeric)
        : translate('Date');
}

export function displayTime(translate: TranslateFn, time: string | null) {
    if (isNonEmptyString(time) && time!.length >= 5) {
        return time!.substring(0, 5);
    } else {
        return translate('heure_28539');
    }
}

const CALENDARS_HEIGHT = 256;

export function getPortalStyles(boudingClientRect?: DOMRect): React.CSSProperties {
    let top: number | undefined = (boudingClientRect?.y ?? 0) + 70;
    let bottom: number | undefined;

    if (top + CALENDARS_HEIGHT > window.innerHeight) {
        top = undefined;
        bottom = window.innerHeight - (boudingClientRect?.y ?? 0) + 8;
    }

    return {
        bottom: bottom ? `${bottom}px` : undefined,
        left: `${boudingClientRect?.x ?? 0}px`,
        position: 'absolute',
        top: top ? `${top}px` : undefined,
        zIndex: 100
    };
}
