import { Box } from 'common/src/designSystem/components/box';
import { Document } from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Accept } from '../../../util/accept';
import { Acl } from '../../../util/file';
import { Fields } from '../../form/fields';
import { Label } from '../label/label';
import { Uploader, UploaderMode } from './file/uploader';
import { Subtitle } from './subtitle';

interface IFileInputComponentProps {
    acl: Acl;
    accept: Accept | Accept[];
    mode: UploaderMode;
    label: string;
    size?: number;
    subtext?: string;
    isOptional?: boolean;
    keyProps: FieldRenderProps<string, HTMLInputElement>;
    nameProps: FieldRenderProps<string, HTMLInputElement>;
    aclProps: FieldRenderProps<string, HTMLInputElement>;
}

const FileInputComponent = (props: IFileInputComponentProps) => {
    const document: Pick<Document, 'acl' | 'key' | 'name'> | null = React.useMemo(() => {
        if (
            isNonEmptyString(props.keyProps.input.value) &&
            isNonEmptyString(props.nameProps.input.value) &&
            isNonEmptyString(props.aclProps.input.value)
        ) {
            return {
                acl: props.aclProps.input.value,
                key: props.keyProps.input.value,
                name: props.nameProps.input.value
            };
        } else {
            return null;
        }
    }, [props.keyProps.input.value, props.nameProps.input.value, props.aclProps.input.value]);

    return (
        <Box css={{ marginBottom: '$6' }}>
            {props.mode === UploaderMode.Simple && (
                <Label htmlFor={props.nameProps.input.name} isOptional={props.isOptional}>
                    {props.label}
                </Label>
            )}

            {props.mode === UploaderMode.Simple && isNonEmptyString(props.subtext) && (
                <Subtitle text={props.subtext} />
            )}

            <Uploader
                acl={props.acl}
                accept={props.accept}
                document={document}
                mode={props.mode}
                label={props.label}
                size={props.size}
                subtext={props.subtext}
                isOptional={props.isOptional}
                onChangeEnd={({ acl, key, name }) => {
                    props.keyProps.input.onChange(key);
                    props.nameProps.input.onChange(name);
                    props.aclProps.input.onChange(acl);
                }}
                onDelete={() => {
                    props.keyProps.input.onChange('');
                    props.nameProps.input.onChange('');
                    props.aclProps.input.onChange('');
                }}
            />

            <input {...props.keyProps.input} type="hidden" />
            <input {...props.nameProps.input} type="hidden" />
            <input {...props.aclProps.input} type="hidden" />
        </Box>
    );
};

interface IFileProps {
    acl: Acl;
    accept: Accept | Accept[];
    mode: UploaderMode;
    label: string;
    size?: number;
    subtext?: string;
    prefix: string;
    isOptional?: boolean;
    subtitle?: string;
}

export const FileInput = (props: IFileProps) => (
        <Fields
            names={[`${props.prefix}key`, `${props.prefix}name`, `${props.prefix}acl`]}
            render={(fields) => (
                    <FileInputComponent
                        acl={props.acl}
                        accept={props.accept}
                        mode={props.mode}
                        label={props.label}
                        size={props.size}
                        subtext={props.subtext}
                        isOptional={props.isOptional}
                        keyProps={fields[`${props.prefix}key`]}
                        nameProps={fields[`${props.prefix}name`]}
                        aclProps={fields[`${props.prefix}acl`]}
                    />
                )}
        />
    );
