import { useMediaQueryContext } from 'common-front/src/components/mediaQuery/mediaQueryContext';
import { Box } from 'common/src/designSystem/components/box';
import { createTheme, theme } from 'common/src/designSystem/components/stitches';
import { EventPageFragment } from 'common/src/generated/types';
import { color } from 'd3-color';
import * as React from 'react';
import { EventPageContextProvider } from './eventPageContext';

interface IEventPageProps {
    children: React.ReactNode;
    event: EventPageFragment;
    isEmbed: boolean;
}

export const EventPage = (props: IEventPageProps) => {
    const { isMobile } = useMediaQueryContext();
    const [height, setHeight] = React.useState(Math.round((window.innerWidth * 360) / 1170));
    const handleResize = React.useCallback(() => {
        setHeight(Math.round((window.innerWidth * 360) / 1170));
    }, [setHeight]);

    React.useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    React.useEffect(() => {
        if (props.event) {
            const colors: any = {
                oldEventBackground:
                    props.event.parameters.colors?.background ??
                    theme.colors.oldEventBackground.value,
                oldEventStepBackground:
                    props.event.parameters.colors?.stepBackground ??
                    theme.colors.oldEventStepBackground.value,
                oldEventFont:
                    props.event.parameters.colors?.font ?? theme.colors.oldEventFont.value,
                oldBlue: props.event.parameters.colors?.button ?? theme.colors.oldBlue.value
            };

            if (props.event.parameters.colors?.button) {
                colors.oldblueDark = color(props.event.parameters.colors.button)!
                    .darker()
                    .toString();
                colors.oldBlueDarkDark = color(props.event.parameters.colors.button)!
                    .darker()
                    .darker()
                    .toString();
            }

            const customTheme = createTheme({
                colors
            });

            document.getElementById('heavent-react-hook')?.classList.add(customTheme.toString());
        }
    }, [props.event]);

    return (
        <EventPageContextProvider isEmbed={props.isEmbed}>
            <Box
                css={{
                    height: '100%',
                    overflowY: 'auto',
                    width: '100%'
                }}
            >
                {!props.isEmbed && props.event.background && (
                    <Box
                        css={{
                            background: '$oldEventBackground',
                            paddingTop: '$10',
                            width: '100%',
                            '@oldBreakpoint': {
                                paddingTop: '0'
                            }
                        }}
                        style={{
                            height: isMobile ? `${height}px` : '424px'
                        }}
                    >
                        <Box
                            css={{
                                background: `url('${props.event.background.url}')`,
                                backgroundSize: 'cover',
                                borderRadius: '$1',
                                height: '100%',
                                margin: 'auto',
                                width: '$container',
                                '@oldBreakpoint': {
                                    borderRadius: '0',
                                    width: '100%'
                                }
                            }}
                            style={{}}
                        />
                    </Box>
                )}

                <Box
                    css={{
                        background: '$oldEventBackground',
                        minHeight: props.isEmbed ? '100%' : 'calc(100% - 400px)',
                        paddingBottom: '$10',
                        paddingTop: '$10',
                        width: '100%',
                        '@oldBreakpoint': {
                            padding: '$4'
                        }
                    }}
                >
                    <Box
                        css={{
                            margin: 'auto',
                            width: '$container',
                            '@oldBreakpoint': {
                                width: '100%'
                            }
                        }}
                    >
                        {props.children}
                    </Box>
                </Box>
            </Box>
        </EventPageContextProvider>
    );
};
