import { Button } from 'common-front/src/components/button/button';
import { H1 } from 'common-front/src/components/titles/h1';
import { Svgs } from 'common-front/src/util/assets';
import { Flex } from 'common/src/designSystem/components/flex';
import { useHistory, useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { getEmbedPath, VolunteersPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';

interface INotRegisteredProps {
    isEmbed: boolean;
}

export const NotRegistered = (props: INotRegisteredProps) => {
    const translate = useTranslate();
    const { organizationId, eventId } = useParams();
    const history = useHistory();

    return (
        <Flex
            direction="column"
            align="center"
            justify="center"
            css={{
                background: '$oldEventStepBackground',
                borderRadius: '3px',
                boxShadow: '$old',
                padding: '80px'
            }}
        >
            <img src={Svgs.AssignmentsBlank} width="450px" height="300px" alt="Volunteers blank" />

            <H1 margin={true}>{translate('vous_n_tes_pas_99875')}</H1>

            <div>
                <Button
                    onClick={() => {
                        history.push(
                            getEmbedPath(
                                props.isEmbed,
                                VolunteersPaths.REGISTER(organizationId, eventId)
                            )
                        );
                    }}
                >
                    {translate('s_inscrire_20304')}
                </Button>
            </div>
        </Flex>
    );
};
