import * as React from 'react';

interface ITabPanelProps {
    children: React.ReactNode;
    path: string | string[];
}

export const TabPanel = (props: ITabPanelProps) => <>{props.children}</>;

TabPanel.displayName = 'TabPanel';
