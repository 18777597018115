import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { useEsc } from '../../hooks/useEsc';
import { useMobileQuery } from '../../hooks/useMobileQuery';

const ModalInner = styled('div', {
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    variants: {
        mobile: {
            true: {
                height: '100%',
                paddingBottom: '$6',
                paddingTop: '$8',
                width: '100%'
            },
            false: {
                borderRadius: '12px',
                maxHeight: 'calc(100% - 80px)',
                py: '$7'
            }
        },
        size: {
            sm: {},
            md: {}
        }
    },
    compoundVariants: [
        {
            mobile: false,
            size: 'sm',
            css: { width: '480px' }
        },
        {
            mobile: false,
            size: 'md',
            css: { width: '640px' }
        }
    ],
    defaultVariants: {
        mobile: false
    }
});

const ModalChildren = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
    variants: {
        mobile: {
            true: {
                flex: '1',
                px: '$4'
            },
            false: {
                px: '$8'
            }
        }
    }
});

const ModalButtons = styled('div', {
    display: 'flex',
    gap: '$3',
    variants: {
        mobile: {
            true: {
                boxShadow:
                    '0px -1px 3px rgba(16, 24, 40, 0.1), 0px -1px 2px rgba(16, 24, 40, 0.06)',
                flexDirection: 'column',
                padding: '$6 $4 0 $4',
                '& .h-button-content': {
                    textAlign: 'center'
                }
            },
            false: {
                flexDirection: 'row',
                justifyContent: 'flex-end',
                px: '$8'
            }
        }
    }
});

export type IModalSize = 'sm' | 'md';

interface IModalProps {
    buttons?: React.ReactNode;
    size: IModalSize;
    children: React.ReactNode;

    onClose(): void;
}

export const Modal = (props: IModalProps) => {
    const isMobile = useMobileQuery();

    useEsc(props.onClose);

    return createPortal(
        <Flex
            align="center"
            justify="center"
            css={{
                background: 'rgba(52, 64, 84, 0.7)',
                height: '100%',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '100%',
                zIndex: '300'
            }}
            onClick={props.onClose}
        >
            <ModalInner
                mobile={isMobile}
                size={props.size}
                onClick={(e) => {
                    e.nativeEvent.stopImmediatePropagation();
                    e.stopPropagation();
                }}
            >
                <ModalChildren mobile={isMobile}>{props.children}</ModalChildren>

                {props.buttons && <ModalButtons mobile={isMobile}>{props.buttons}</ModalButtons>}
            </ModalInner>
        </Flex>,
        document.body
    );
};
