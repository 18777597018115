import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Span } from 'common/src/designSystem/components/span';
import { styled } from 'common/src/designSystem/components/stitches';
import { addOrRemove } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { randomCode } from 'common/src/util/random';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Field, FieldRenderProps, useForm, useFormState } from 'react-final-form';
import { Portal } from 'react-portal';
import { useBodyClick } from '../../../../hooks/useBodyClick';
import { useEsc } from '../../../../hooks/useEsc';
import { Label } from '../../label/label';
import { CheckboxOnly } from '../checkbox';
import { Radio } from '../radio';
import { SearchInput } from '../search';
import { Subtitle } from '../subtitle';

const _PossibleValue = styled('div', {
    display: 'flex',
    marginBottom: '$3',
    '& > :first-child': {
        width: '100%'
    },
    '&:last-child': {
        marginBottom: '0'
    },
    variants: {
        create: {
            true: {
                cursor: 'pointer'
            }
        }
    }
});
const _Dropdown = styled('div', {
    background: 'white',
    border: '1px solid $oldGrayBorder',
    borderRadius: '$1',
    maxHeight: '230px',
    overflowY: 'auto',
    padding: '$4',
    position: 'absolute',
    width: '100%',
    zIndex: '1000',
    variants: {
        bottom: {
            true: {
                borderTop: 'none'
            }
        },
        top: {
            true: {
                borderBottom: 'none'
            }
        }
    }
});

type ValueId = number | string;
type Value = { id: ValueId; name: string };

interface ISelectSearchComponentProps<T extends Value>
    extends FieldRenderProps<ValueId[] | ValueId, HTMLElement> {
    canCreateValue: boolean;
    isFilter: boolean;
    options: T[];
    placeholder: string;
    searchPlaceholder?: string;
    hideSearch?: boolean;

    createValue?(value: string): Promise<any>;

    onClose(): void;

    renderOption?(option: T): React.ReactNode;
}

const SelectSearchComponent = <T extends Value>(props: ISelectSearchComponentProps<T>) => {
    const translate = useTranslate();
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const valuesRef = React.useRef<HTMLDivElement | null>(null);
    const dropdownRef = React.useRef<HTMLDivElement | null>(null);
    const dropdownHeight = React.useMemo(() => {
        if (dropdownRef.current) {
            return dropdownRef.current.getBoundingClientRect().height;
        } else {
            return 0;
        }
    }, [isDropdownOpen, dropdownRef.current]);
    const [dropdownStyle, isDropdownBottom] = React.useMemo(() => {
        if (valuesRef.current) {
            const { x, y, height, width } = valuesRef.current.getBoundingClientRect();
            let top = y + height;
            let isBottom = true;

            if (top + dropdownHeight > window.innerHeight) {
                top = y - dropdownHeight;
                isBottom = false;
            }

            return [
                {
                    left: `${x}px`,
                    top: `${top}px`,
                    width: `${width}px`
                },
                isBottom
            ];
        } else {
            return [
                {
                    left: '0',
                    top: '0',
                    width: '0'
                },
                true
            ];
        }
    }, [isDropdownOpen, dropdownHeight]);
    const prefix = React.useMemo(() => randomCode(), []);
    const { change } = useForm();
    const { values } = useFormState();
    const canSelectMultiple = Array.isArray(props.input.value);
    const selectedValuesString = React.useMemo(() => {
        if (Array.isArray(props.input.value)) {
            return props.input.value
                .map((selectedValue) => props.options.find(({ id }) => id === selectedValue)?.name ?? '')
                .join(', ');
        } else {
            return props.options.find(({ id }) => id === props.input.value)?.name ?? '';
        }
    }, [props.input.value]);
    const selectSearchOpenListener = React.useCallback((e) => {
        if (e.detail.prefix !== prefix) {
            setIsDropdownOpen(false);
        }
    }, []);
    React.useEffect(() => {
        document.addEventListener('h-select-search-open', selectSearchOpenListener, false);

        return () => {
            document.removeEventListener('h-select-search-open', selectSearchOpenListener, false);
        };
    }, []);
    const isOptionSelected = React.useCallback(
        (id: ValueId) => (props.input.value as ValueId[]).includes(id),
        [props.input.value]
    );
    const changeOptionSelected = React.useCallback(
        (id: ValueId, isSelected: boolean) => {
            props.input.onChange(addOrRemove(props.input.value as ValueId[], id, isSelected));
        },
        [props.input.value]
    );

    useBodyClick(() => {
        if (isDropdownOpen) {
            setIsDropdownOpen(false);
            props.onClose();
        }
    }, [isDropdownOpen]);
    useEsc(() => {
        if (isDropdownOpen) {
            setIsDropdownOpen(false);
            props.onClose();
        }
    }, [isDropdownOpen]);

    const searchValue = (values[prefix]?.search ?? '').trim();
    const searchValueLower = searchValue.toLowerCase();
    const isCreateVisible =
        props.canCreateValue &&
        isNonEmptyString(searchValue) &&
        !props.options.some(({ name }) => name.trim() === searchValue);
    const createValue = async () => {
        if (isCreateVisible) {
            await props.createValue!(searchValue);

            change(`${prefix}.search`, '');
        }
    };

    return (
        <div>
            <Flex
                align="center"
                css={{
                    background: props.isFilter ? '$oldGrayLight' : 'white',
                    border: props.isFilter ? 'none' : '1px solid $oldGrayBorder',
                    borderRadius: '$1',
                    color: props.isFilter ? '$oldBlackLight' : 'inherit',
                    cursor: 'pointer',
                    ellipsis: true,
                    height: '40px',
                    padding: '$2 $3',
                    width: '100%'
                }}
                onClick={(e) => {
                    e.nativeEvent.stopImmediatePropagation();

                    if (!isDropdownOpen) {
                        e.target.dispatchEvent(
                            new CustomEvent('h-select-search-open', {
                                detail: { prefix },
                                bubbles: true
                            })
                        );
                    }

                    setIsDropdownOpen(!isDropdownOpen);
                }}
                ref={valuesRef}
                title={selectedValuesString}
            >
                {isNonEmptyString(selectedValuesString) ? (
                    selectedValuesString
                ) : (
                    <Span
                        css={{
                            color: props.isFilter ? '$oldBlackLight' : '$oldBlueGray',
                            opacity: 0.6
                        }}
                    >
                        {props.placeholder}
                    </Span>
                )}
            </Flex>

            {isDropdownOpen && (
                <Portal>
                    <_Dropdown
                        bottom={isDropdownBottom}
                        top={!isDropdownBottom}
                        style={dropdownStyle}
                        onClick={(e) => {
                            e.nativeEvent.stopImmediatePropagation();
                        }}
                        ref={dropdownRef}
                    >
                        {props.hideSearch !== true && (
                            <SearchInput
                                name={`${prefix}.search`}
                                placeholder={
                                    isNonEmptyString(props.searchPlaceholder)
                                        ? props.searchPlaceholder
                                        : translate('rechercher_50038')
                                }
                                onEnter={createValue}
                            />
                        )}

                        <div>
                            {isCreateVisible && (
                                <_PossibleValue create={true} onClick={createValue}>
                                    {translate('cr_er_1_47631', searchValue)}
                                </_PossibleValue>
                            )}

                            {props.options
                                .filter(({ name }) => {
                                    if (isNonEmptyString(searchValueLower)) {
                                        return name.toLowerCase().includes(searchValueLower);
                                    } else {
                                        return true;
                                    }
                                })
                                .map((option: T) => (
                                        <_PossibleValue key={option.id}>
                                            {canSelectMultiple ? (
                                                <>
                                                    <Flex align="center">
                                                        <div>
                                                            <CheckboxOnly
                                                                value={isOptionSelected(option.id)}
                                                                onClick={(isSelected: boolean) => {
                                                                    changeOptionSelected(
                                                                        option.id,
                                                                        isSelected
                                                                    );
                                                                }}
                                                            />
                                                        </div>

                                                        <Box
                                                            css={{
                                                                cursor: 'pointer',
                                                                marginLeft: '10px',
                                                                userSelect: 'none'
                                                            }}
                                                            onClick={() => {
                                                                changeOptionSelected(
                                                                    option.id,
                                                                    !isOptionSelected(option.id)
                                                                );
                                                            }}
                                                        >
                                                            {option.name}
                                                        </Box>
                                                    </Flex>

                                                    {props.renderOption &&
                                                        props.renderOption(option)}
                                                </>
                                            ) : (
                                                <>
                                                    <Radio
                                                        css={{
                                                            marginBottom: '$3'
                                                        }}
                                                        name={props.input.name}
                                                        value={option.id}
                                                        text={option.name}
                                                    />

                                                    {props.renderOption &&
                                                        props.renderOption(option)}
                                                </>
                                            )}
                                        </_PossibleValue>
                                    ))}
                        </div>
                    </_Dropdown>
                </Portal>
            )}
        </div>
    );
};

interface ISelectSearchLabelProps<T extends Value>
    extends FieldRenderProps<ValueId[] | ValueId, HTMLElement> {
    label?: string;
    isOptional?: boolean;
    subtitle?: string;
    canCreateValue: boolean;
    isFilter: boolean;
    options: T[];
    placeholder: string;
    searchPlaceholder?: string;
    hideSearch?: boolean;

    createValue?(value: string): Promise<any>;

    renderOption?(option: T): React.ReactNode;
}

const SelectSearchLabel = <T extends Value>(props: ISelectSearchLabelProps<T>) => {
    const [touched, setTouched] = React.useState(false);
    const invalid = touched && props.meta.invalid;

    return (
        <div>
            {props.label && (
                <Label htmlFor={props.input.name} isOptional={props.isOptional}>
                    {props.label}
                </Label>
            )}

            {isNonEmptyString(props.subtitle) && <Subtitle text={props.subtitle} />}

            <Box
                css={{
                    marginBottom: invalid ? '$2' : '$6'
                }}
            >
                <SelectSearchComponent
                    canCreateValue={props.canCreateValue}
                    isFilter={props.isFilter}
                    options={props.options}
                    placeholder={props.placeholder}
                    searchPlaceholder={props.searchPlaceholder}
                    hideSearch={props.hideSearch}
                    input={props.input}
                    meta={props.meta}
                    createValue={props.createValue}
                    onClose={() => {
                        setTouched(true);
                    }}
                    renderOption={props.renderOption}
                />
            </Box>

            {invalid && (
                <Box
                    css={{
                        color: '$oldRed',
                        marginBottom: '$6'
                    }}
                >
                    {props.meta.error}
                </Box>
            )}
        </div>
    );
};

interface ISelectSearchProps<T extends Value> {
    label?: string;
    name: string;
    isOptional?: boolean;
    subtitle?: string;
    canCreateValue: boolean;
    options: T[];
    placeholder?: string;
    searchPlaceholder?: string;
    hideSearch?: boolean;

    createValue?(value: string): Promise<any>;

    renderOption?(option: T): React.ReactNode;
}

export const SelectSearch = <T extends Value>(props: ISelectSearchProps<T>) => (
        <Field
            {...props}
            render={(renderProps) => (
                    <SelectSearchLabel
                        {...props}
                        {...renderProps}
                        isFilter={false}
                        placeholder={props.placeholder || props.label || ''}
                    />
                )}
        />
    );

interface ISelectSearchFilterProps<T> {
    name: string;
    isSearchVisible: boolean;
    options: T[];
    placeholder: string;
    searchPlaceholder?: string;
}

export const SelectSearchFilter = <T extends Value>(props: ISelectSearchFilterProps<T>) => (
        <Field
            {...props}
            render={(renderProps) => (
                    <SelectSearchLabel
                        {...props}
                        {...renderProps}
                        canCreateValue={false}
                        isFilter={true}
                    />
                )}
        />
    );
