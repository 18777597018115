import * as React from 'react';
import { Box } from './box';
import { CSS, keyframes } from './stitches';

const pulse = keyframes({
    '0%': { opacity: 1 },
    '50%': { opacity: 0.4 },
    '100%': { opacity: 1 }
});

type CircularSkeletonProps = {
    variant: 'circular';
    css?: CSS;
    size?: number | string;
};

type RectangularSkeletonProps = {
    variant?: 'rectangular';
    borderRadius?: '$1' | '$2' | string;
    css?: CSS;
    height?: number | string;
    width?: number | string;
};

type SkeletonProps = CircularSkeletonProps | RectangularSkeletonProps;

export const Skeleton = (props: SkeletonProps) => {
    const height = props.variant === 'circular' ? props.size : props.height;
    const width = props.variant === 'circular' ? props.size : props.width;

    return (
        <Box
            height={height}
            width={width}
            css={{
                animation: `${pulse} 1.5s ease-in-out 0.5s infinite`,
                background: '$gray200',
                borderRadius: props.variant === 'circular' ? '50%' : props.borderRadius,
                ...(props.css as any)
            }}
        />
    );
};
