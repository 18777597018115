import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Table } from 'common/src/designSystem/components/table/table';
import * as React from 'react';
import { PaginationCell } from './pagination/paginationCell';
import { PaginationRow } from './pagination/paginationRow';

interface ITableFiltersProps {
    filters: React.ReactNode;
    headerCells: React.ReactNode;
    limit?: number;
    numberOfPages: number;
    offset: number;
    rows: React.ReactNode;
    showPagination?: boolean;
    title?: React.ReactNode;
    totalCount: number;

    setOffset(offset: number): void;
}

export const TableFilters = ({
    filters,
    headerCells,
    limit,
    numberOfPages,
    offset,
    rows,
    setOffset,
    showPagination = true,
    title,
    totalCount
}: ITableFiltersProps) => (
        <Box>
            <Flex
                gap="3"
                css={{
                    background: 'white',
                    border: title ? '1px solid $gray200' : 'none',
                    borderBottom: 'none',
                    borderTopLeftRadius: '$2',
                    borderTopRightRadius: '$2',
                    padding: title ? '$5 $6' : 'unset'
                }}
                align="center"
            >
                {title && (
                    <Box color="gray900" fontSize="textLg" fontWeight="medium" css={{ flex: '1' }}>
                        {title}
                    </Box>
                )}

                {filters}
            </Flex>

            <Table>
                <HeaderRow
                    css={{
                        borderRadius: title ? '0 !important' : '$2 $2 0 0 !important',
                        borderTop: title ? 'none' : '1px solid $gray200'
                    }}
                >
                    {headerCells}
                </HeaderRow>

                {rows}

                {showPagination && totalCount > 0 && (
                    <PaginationRow>
                        <PaginationCell
                            limit={limit}
                            numberOfPages={numberOfPages}
                            offset={offset}
                            setOffset={setOffset}
                            showLimits={false}
                            totalCount={totalCount}
                        />
                    </PaginationRow>
                )}
            </Table>
        </Box>
    );
