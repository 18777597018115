import { UsersInfo } from '../generated/types';
import { capitalizeName, isNonEmptyString } from '../util/string';

export function getFirstName(userInfo: Pick<UsersInfo, 'fields'>): string {
    return capitalizeName(
        isNonEmptyString(userInfo.fields?.firstName) ? userInfo.fields.firstName : ''
    );
}

export function getLastName(userInfo: Pick<UsersInfo, 'fields'>): string {
    return capitalizeName(
        isNonEmptyString(userInfo.fields?.lastName) ? userInfo.fields.lastName : ''
    );
}

interface IGetNameOptions {
    firstName?: string;
    lastName?: string;
    upperLastName?: boolean | null;
    lastNameFirst?: boolean | null;
}

export function getName(options: IGetNameOptions = {}): string {
    const firstName = capitalizeName(options.firstName || '').trim();
    const lastName = capitalizeName(options.lastName || '').trim();
    const finalLastName = options.upperLastName ? lastName.toUpperCase() : lastName;

    if (options.lastNameFirst) {
        return `${finalLastName} ${firstName}`.trim();
    } else {
        return `${firstName} ${finalLastName}`.trim();
    }
}
