import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { RichText } from '../../../designSystem/components/richEditor/richText';
import {
    DocumentUserCustomBadgeFragment,
    T24assignmentDocumentOptions
} from '../../../generated/types';
import { DateTimeService } from '../../../services/dateTimeService';
import { useService } from '../../../util/dependencies/dependencies';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

const RED = '#e30613';
const BLUE = '#26306b';
const POSITION_FIELD_SLUG_TO_NAME: Record<string, string> = {
    cf25078: 'Parcours',
    cf25079: 'Plan village',
    cf25080: 'Poste vélo',
    cf25511: 'Poste course à pied',
    cf25512: `Plan d'accès`
};
const POSITION_FIELDS_SLUGS = Object.keys(POSITION_FIELD_SLUG_TO_NAME);

interface IT24AssignmentPageProps {
    options: T24assignmentDocumentOptions;
    positionSlotUserInfo: DocumentUserCustomBadgeFragment['volunteerRegistration']['positionsSlotsUsersInfos'][0];
    userInfo: DocumentUserCustomBadgeFragment['volunteerRegistration']['userInfo'];
}

export const T24AssignmentPage = (props: IT24AssignmentPageProps) => {
    const dateTimeService = useService(DateTimeService);
    const category = props.positionSlotUserInfo.positionCategory;
    const position = props.positionSlotUserInfo.position;
    const slot = props.positionSlotUserInfo.positionSlot;
    const leader = category.leaders[0];
    const hasPositionFields = POSITION_FIELDS_SLUGS.some((slug) => isNonEmptyString(position.fields[slug]?.url));
    const showHours =
        hasPositionFields ||
        category.id === 16418 ||
        position.id === 79192 ||
        position.id === 98285 ||
        position.id === 105883 ||
        position.id === 98599 ||
        position.id === 98606;

    return (
        <Flex
            direction="column"
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: 'white',
                fontFamily: '$roboto'
            }}
        >
            <Flex height={185} width={1}>
                <Flex height={1} width={185}>
                    {isNonEmptyString(props.options.logo?.content) && (
                        <img src={props.options.logo.content} height="100%" width="100%" />
                    )}
                </Flex>

                <Flex width={609} css={{ padding: '$4 $7' }}>
                    <Flex direction="column" justify="between" width={1}>
                        <Flex justify="between" width={1}>
                            <Flex direction="column" width={1}>
                                <Box
                                    css={{
                                        color: RED,
                                        fontSize: '34px',
                                        fontWeight: '700',
                                        lineHeight: '40px'
                                    }}
                                >
                                    {isNonEmptyString(position.acronym)
                                        ? position.acronym
                                        : position.name}
                                </Box>

                                <Box
                                    css={{
                                        color: RED,
                                        ellipsis: true,
                                        fontSize: '18px',
                                        fontWeight: '700'
                                    }}
                                >
                                    POSTE / {category.name}
                                </Box>
                            </Flex>

                            {showHours && (
                                <Flex direction="column" width={1}>
                                    <Box
                                        css={{
                                            color: RED,
                                            fontSize: '18px',
                                            fontWeight: '700',
                                            textAlign: 'right'
                                        }}
                                    >
                                        Adresse
                                    </Box>

                                    <Box
                                        css={{
                                            color: BLUE,
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            textAlign: 'right'
                                        }}
                                    >
                                        {position.address}
                                    </Box>
                                </Flex>
                            )}
                        </Flex>

                        {showHours && (
                            <Flex justify="between" width={1}>
                                <Flex direction="column">
                                    <Box
                                        css={{
                                            color: BLUE,
                                            fontSize: '18px',
                                            fontWeight: '700'
                                        }}
                                    >
                                        Point GPS
                                    </Box>

                                    <Box
                                        css={{
                                            color: RED,
                                            fontSize: '16px',
                                            fontWeight: '500'
                                        }}
                                    >
                                        {position.latitude}, {position.longitude}
                                    </Box>
                                </Flex>

                                <Flex direction="column">
                                    <Box
                                        css={{
                                            color: BLUE,
                                            fontSize: '18px',
                                            fontWeight: '700',
                                            textAlign: 'right'
                                        }}
                                    >
                                        Horaires de mission
                                    </Box>

                                    <Box
                                        css={{
                                            color: RED,
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            textAlign: 'right',
                                            textTransform: 'capitalize'
                                        }}
                                    >
                                        {dateTimeService.toLocaleString(slot.range.start!, {
                                            month: 'long',
                                            day: 'numeric',
                                            weekday: 'short'
                                        })}{' '}
                                        | {slot.range.start!.toFormat(`HH'h'mm`)} -{' '}
                                        {slot.range.end!.toFormat(`HH'h'mm`)}
                                    </Box>
                                </Flex>
                            </Flex>
                        )}

                        {!showHours && (
                            <Flex width={1}>
                                <Flex direction="column">
                                    <Box
                                        css={{
                                            color: RED,
                                            fontSize: '18px',
                                            fontWeight: '700'
                                        }}
                                    >
                                        Adresse
                                    </Box>

                                    <Box
                                        css={{
                                            color: BLUE,
                                            fontSize: '16px',
                                            fontWeight: '500'
                                        }}
                                    >
                                        {position.address}
                                    </Box>
                                </Flex>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            </Flex>

            <Box height={10} width={1} css={{ background: RED }} />

            <Flex direction="column" gap="1" width={1} css={{ flex: '1', padding: '$6 $7' }}>
                <Box
                    css={{
                        color: RED,
                        fontSize: '20px',
                        fontWeight: '700'
                    }}
                >
                    VOTRE MISSION
                </Box>

                <Box
                    css={{
                        color: BLUE,
                        fontSize: '16px',
                        fontWeight: '500',
                        '& .h-rich-text > p': {
                            margin: 0
                        }
                    }}
                >
                    <RichText text={position.description} />
                </Box>
            </Flex>

            {hasPositionFields && (
                <>
                    <Box height={10} width={1} css={{ background: RED }} />

                    <Flex gap="5" height={335} width={1} css={{ padding: '$5 $7' }}>
                        {POSITION_FIELDS_SLUGS.map((slug) => {
                            if (isNonEmptyString(position.fields[slug]?.url)) {
                                const name = POSITION_FIELD_SLUG_TO_NAME[slug];

                                return (
                                    <Flex key={slug} direction="column" gap="2" width={1}>
                                        <Box
                                            css={{
                                                color: RED,
                                                fontSize: '20px',
                                                fontWeight: '700'
                                            }}
                                        >
                                            {name}
                                        </Box>

                                        <Box height={255} width={1}>
                                            <img
                                                src={position.fields[slug].url}
                                                height="100%"
                                                width="100%"
                                            />
                                        </Box>
                                    </Flex>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </Flex>
                </>
            )}

            <Flex
                align="center"
                justify="center"
                height={40}
                width={1}
                css={{
                    background: RED,
                    color: 'white',
                    fontSize: '24px',
                    fontWeight: '700',
                    textTransform: 'uppercase'
                }}
            >
                Vous rencontrez un problème ?
            </Flex>

            <Flex direction="column" height={88} width={1} css={{ padding: '$4' }}>
                <Box
                    width={1}
                    css={{
                        color: BLUE,
                        fontSize: '20px',
                        fontWeight: '500',
                        textAlign: 'center'
                    }}
                >
                    Contactez votre responsable de secteur
                </Box>

                {leader && (
                    <Box
                        width={1}
                        css={{
                            color: BLUE,
                            fontSize: '20px',
                            fontWeight: '700',
                            textAlign: 'center'
                        }}
                    >
                        {leader.userInfo.name} : {leader.userInfo.phone?.internationalFormat}
                    </Box>
                )}
            </Flex>

            <Flex
                align="center"
                justify="center"
                height={40}
                width={1}
                css={{
                    background: BLUE,
                    color: 'white',
                    fontSize: '24px',
                    fontWeight: '700',
                    textTransform: 'uppercase'
                }}
            >
                Merci
            </Flex>
        </Flex>
    );
};
