import { Box } from 'common/src/designSystem/components/box';
import {
    AccreditationsSlotId,
    FormRegisterAccreditationFragment,
    FormRegisterAccreditationSlotFragment
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { addOrRemove } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { fullName } from 'common/src/vo/accreditationSlot';
import * as React from 'react';
import { CheckboxText } from '../../../designSystem/components/checkbox';

interface IFormAccreditationsSlotsListProps {
    accreditation: FormRegisterAccreditationFragment;
    accreditationsSlotsIds: AccreditationsSlotId[];
    prefix: string;
    slots: FormRegisterAccreditationSlotFragment[];

    change(name: string, value: any): void;
}

export const FormAccreditationsSlotsList = (props: IFormAccreditationsSlotsListProps) => {
    const dateTimeService = useService(DateTimeService);

    return (
        <>
            {props.slots.map((slot) => (
                    <CheckboxText
                        key={slot.id}
                        state={
                            props.accreditationsSlotsIds.includes(slot.id) ? 'checked' : 'unchecked'
                        }
                        onClick={(state) => {
                            props.change(
                                `${props.prefix}accreditationsSlotsIds`,
                                addOrRemove(
                                    props.accreditationsSlotsIds,
                                    slot.id,
                                    state === 'checked'
                                )
                            );
                        }}
                    >
                        <Box css={{ textTransform: 'capitalize' }}>
                            {fullName(dateTimeService, slot, props.accreditation.name, {
                                format: LocaleFormats.DateOnly.WeekdayLongMonthLong
                            })}
                        </Box>
                    </CheckboxText>
                ))}
        </>
    );
};
