import { Map } from 'common-front/src/components/map/map';
import { Box } from 'common/src/designSystem/components/box';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { VolunteerAssignmentsQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { extractCoordinates } from 'common/src/util/map';
import { isNonEmptyString } from 'common/src/util/string';
import { hasAddress, hasAddressOrCoordinates, hasCoordinates } from 'common/src/vo/position';
import * as React from 'react';
import { VolunteersBox } from '../../common/volunteersBox';

type PositionsSlotsUsersInfos =
    VolunteerAssignmentsQuery['user']['volunteersRegistrations'][0]['positionsSlotsUsersInfos'][0];
type AssignmentPosition = PositionsSlotsUsersInfos['position'];
type AssignmentPositionSlot = PositionsSlotsUsersInfos['positionSlot'];

interface IAssignmentProps {
    position: AssignmentPosition;
    positionSlot: AssignmentPositionSlot;
}

export const Assignment = (props: IAssignmentProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const [isOpen, setIsOpen] = React.useState(true);

    return (
        <VolunteersBox
            header={
                <>
                    <Box
                        css={{
                            flex: '1',
                            fontSize: '18px',
                            fontWeight: '$oldBold',
                            '& > div': {
                                ellipsis: true,
                                width: '100%'
                            }
                        }}
                    >
                        <div>{props.position.name}</div>
                    </Box>

                    <Box
                        css={{
                            marginLeft: '$4',
                            width: '16px',
                            '& i': {
                                fontSize: '16px'
                            }
                        }}
                    >
                        {isOpen ? (
                            <i className="far fa-angle-up" />
                        ) : (
                            <i className="far fa-angle-down" />
                        )}
                    </Box>
                </>
            }
            headerCss={{
                cursor: 'pointer'
            }}
            headerClick={() => {
                setIsOpen(!isOpen);
            }}
        >
            {isOpen && (
                <>
                    <Box
                        css={{
                            display: 'flex',
                            marginBottom: '40px',
                            width: '100%',
                            '&:last-child': {
                                marginBottom: '0'
                            },
                            '& > :nth-child(1)': {
                                fontWeight: '$oldBold',
                                marginRight: '40px',
                                width: '150px'
                            },
                            '& > :nth-child(2)': {
                                flex: '1'
                            }
                        }}
                    >
                        <div>{translate('date_et_heure_d_79687')}</div>
                        <div>
                            {dateTimeService.toLocaleString(
                                props.positionSlot.range.start!,
                                LocaleFormats.DateTime
                            )}
                        </div>
                    </Box>

                    <Box
                        css={{
                            display: 'flex',
                            marginBottom: '40px',
                            width: '100%',
                            '&:last-child': {
                                marginBottom: '0'
                            },
                            '& > :nth-child(1)': {
                                fontWeight: '$oldBold',
                                marginRight: '40px',
                                width: '150px'
                            },
                            '& > :nth-child(2)': {
                                flex: '1'
                            }
                        }}
                    >
                        <div>{translate('date_et_heure_d_10450')}</div>
                        <div>
                            {dateTimeService.toLocaleString(
                                props.positionSlot.range.end!,
                                LocaleFormats.DateTime
                            )}
                        </div>
                    </Box>

                    {props.position.category.leaders
                        .concat(props.position.leaders)
                        .map(({ userInfo }, index) => (
                                <Box
                                    css={{
                                        display: 'flex',
                                        marginBottom: '40px',
                                        width: '100%',
                                        '&:last-child': {
                                            marginBottom: '0'
                                        },
                                        '& > :nth-child(1)': {
                                            fontWeight: '$oldBold',
                                            marginRight: '40px',
                                            width: '150px'
                                        },
                                        '& > :nth-child(2)': {
                                            flex: '1'
                                        }
                                    }}
                                    key={index}
                                >
                                    <div>{translate('reponsable_82458')}</div>
                                    <div>
                                        {isNonEmptyString(userInfo.name) && (
                                            <>
                                                {userInfo.name}
                                                <br />
                                            </>
                                        )}

                                        {userInfo.email}

                                        {userInfo.phone && (
                                            <>
                                                <br />
                                                {userInfo.phone.internationalFormat}
                                            </>
                                        )}
                                    </div>
                                </Box>
                            ))}

                    {isNonEmptyString(props.position.description) && (
                        <Box
                            css={{
                                display: 'flex',
                                marginBottom: '40px',
                                width: '100%',
                                '&:last-child': {
                                    marginBottom: '0'
                                },
                                '& > :nth-child(1)': {
                                    fontWeight: '$oldBold',
                                    marginRight: '40px',
                                    width: '150px'
                                },
                                '& > :nth-child(2)': {
                                    flex: '1'
                                }
                            }}
                        >
                            <div>{translate('description_58935')}</div>
                            <div>
                                <RichText text={props.position.description} />
                            </div>
                        </Box>
                    )}

                    {hasAddressOrCoordinates(props.position) && (
                        <Box
                            css={{
                                display: 'flex',
                                marginBottom: '40px',
                                width: '100%',
                                '&:last-child': {
                                    marginBottom: '0'
                                },
                                '& > :nth-child(1)': {
                                    fontWeight: '$oldBold',
                                    marginRight: '40px',
                                    width: '150px'
                                },
                                '& > :nth-child(2)': {
                                    flex: '1'
                                }
                            }}
                        >
                            <div>{translate('localisation_38416')}</div>
                            <div>
                                {hasCoordinates(props.position) && (
                                    <Box
                                        css={{
                                            height: '160px',
                                            marginBottom: '$2',
                                            width: '100%'
                                        }}
                                    >
                                        <Map
                                            point={extractCoordinates(props.position)}
                                            layer={props.position.layer}
                                            initialZoom={props.position.zoom}
                                            traces={props.position.traces}
                                        />
                                    </Box>
                                )}

                                {hasAddress(props.position) && <div>{props.position.address}</div>}
                            </div>
                        </Box>
                    )}
                </>
            )}
        </VolunteersBox>
    );
};
