import { Empty } from 'common-front/src/components/empty/empty';
import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { TabViewResponsive } from 'common-front/src/spaces/tabViewReponsive';
import { UserAccreditationsWished } from 'common-front/src/users/information/userAccreditationsWished';
import { UserPositionsWished } from 'common-front/src/users/information/userPositionsWished';
import { UserPositionsWishedSlots } from 'common-front/src/users/information/userPositionsWishedSlots';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { AccreditationState, VolunteerRegistrationState } from 'common/src/generated/types';
import { MembersPaths } from 'common/src/util/membersPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useMemberRegistrationQuery } from '../../../generated/graphqlHooks';
import { SpaceContainer } from '../../common/spaceContainer';
import { MemberRegistrationAccreditations } from './accreditations/show/memberRegistrationAccreditations';
import { MemberRegistrationAssignments } from './assignments/show/memberRegistrationAssignments';
import { MemberRegistrationUpdateAvailability } from './availability/update/memberRegistrationUpdateAvailability';
import { MemberRegistrationDocuments } from './documents/show/memberRegistrationDocuments';
import { MemberRegistrationInformation } from './information/show/memberRegistrationInformation';
import { MemberRegistrationUpdateInformation } from './information/update/memberRegistrationUpdateInformation';
import { MemberRegistrationUpdateWishedAccreditations } from './wishedAccreditations/update/memberRegistrationUpdateWishedAccreditations';
import { MemberRegistrationUpdateWishedPositions } from './wishedPositions/update/memberRegistrationUpdateWishedPositions';

export const MemberSpace = () => {
    const {
        history,
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const { data, loader, reload } = useMemberRegistrationQuery({
        organizationId,
        eventId,
        userInfoId,
        isEvent: !!eventId
    });
    const isEditAccreditationsDisabled = loader
        ? undefined
        : data.organization?.userInfo.volunteerRegistration?.accreditationState ===
          AccreditationState.Accredited;

    const isEditPositionsDisabled = loader
        ? undefined
        : data.organization?.userInfo.volunteerRegistration?.state ===
          VolunteerRegistrationState.Assigned;

    const userInfo = React.useMemo(() => {
        if (loader) {
            return null;
        } else {
            const userInfo = data.organization?.userInfo;
            const vr = userInfo?.volunteerRegistration;

            return eventId
                ? {
                      ...userInfo,
                      volunteerRegistration: {
                          ...vr!,
                          accreditationsUsersInfos: data.organization
                              .hideAccreditationsUntilConfirmationEmailSent
                              ? vr!.accreditationsUsersInfos.filter(
                                    (aui) => aui.isConfirmationEmailSent
                                )
                              : vr!.accreditationsUsersInfos,
                          positionsSlotsUsersInfos: data.organization
                              .hideAssignmentsUntilConfirmationEmailSent
                              ? vr!.positionsSlotsUsersInfos.filter(
                                    (psui) => psui.isConfirmationEmailSent
                                )
                              : vr!.positionsSlotsUsersInfos
                      }
                  }
                : data.organization.userInfo;
        }
    }, [data, loader]);

    return (
        loader ||
        (userInfo ? (
            <SpaceContainer
                isLoading={false}
                organization={data.organization}
                spaceDesign={{
                    themeColors: data.organization?.membersSpaceDesign?.colors,
                    bannerSrc: data.organization?.membersSpaceDesign?.banner?.url,
                    logoSrc: data.organization?.membersSpaceDesign?.logo?.url
                }}
                userInfo={userInfo}
            >
                <Switch>
                    <Route
                        exact
                        path={MembersPaths.MEMBER_REGISTRATION({
                            organizationId: ':organizationId',
                            userInfoId: ':userInfoId',
                            eventId: eventId ? ':eventId' : null
                        })}
                        children={
                            <Empty
                                path={MembersPaths.MEMBER_REGISTRATION_INFORMATION(
                                    organizationId,
                                    userInfoId,
                                    eventId
                                )}
                                replace={true}
                            />
                        }
                    />

                    <Route
                        path={MembersPaths.MEMBER_REGISTRATION_INFORMATION_EDIT(
                            ':organizationId',
                            ':userInfoId',
                            eventId ? ':eventId' : null
                        )}
                        children={
                            <MemberRegistrationUpdateInformation
                                customFields={data.organization.customFields.nodes}
                                country={
                                    data.organization?.event?.country ||
                                    data.organization?.country ||
                                    undefined
                                }
                                reload={reload}
                                userInfo={userInfo}
                            />
                        }
                    />

                    <Route
                        path={MembersPaths.MEMBER_REGISTRATION_AVAILABILITY_EDIT(
                            ':organizationId',
                            ':userInfoId',
                            ':eventId'
                        )}
                        children={
                            !isEditPositionsDisabled && (
                                <MemberRegistrationUpdateAvailability reload={reload} />
                            )
                        }
                    />

                    <Route
                        path={MembersPaths.MEMBER_REGISTRATION_POSITIONS_EDIT(
                            ':organizationId',
                            ':userInfoId',
                            ':eventId'
                        )}
                        children={
                            !isEditPositionsDisabled && (
                                <MemberRegistrationUpdateWishedPositions reload={reload} />
                            )
                        }
                    />

                    <Route
                        path={MembersPaths.MEMBER_REGISTRATION_WISHED_ACCREDITATIONS_EDIT(
                            ':organizationId',
                            ':userInfoId',
                            ':eventId'
                        )}
                        children={
                            !isEditAccreditationsDisabled && (
                                <MemberRegistrationUpdateWishedAccreditations reload={reload} />
                            )
                        }
                    />

                    <Route
                        children={
                            <Flex direction="column">
                                <Flex direction="column" gap="2">
                                    <Button
                                        leftIcon="arrow-left"
                                        color="invisible"
                                        onClick={() => {
                                            history.push(
                                                MembersPaths.MEMBER(organizationId, userInfoId)
                                            );
                                        }}
                                    >
                                        {translate('retour_l_accu_00810')}
                                    </Button>

                                    <Box font="gray900 displayXs semiBold">
                                        {eventId
                                            ? data.organization.event?.name
                                            : data.organization.name}
                                    </Box>
                                </Flex>

                                <TabViewResponsive
                                    sections={[
                                        {
                                            id: 1,
                                            title: translate('informations_pe_37892'),
                                            getPath: (isRouteComponent: boolean = false) =>
                                                MembersPaths.MEMBER_REGISTRATION_INFORMATION(
                                                    isRouteComponent
                                                        ? ':organizationId'
                                                        : organizationId,
                                                    isRouteComponent ? ':userInfoId' : userInfoId,
                                                    isRouteComponent
                                                        ? eventId
                                                            ? ':eventId'
                                                            : undefined
                                                        : eventId
                                                ),
                                            elem: (
                                                <MemberRegistrationInformation
                                                    userInfo={userInfo}
                                                />
                                            )
                                        },
                                        ...(eventId
                                            ? [
                                                  {
                                                      id: 2,
                                                      title: translate('disponibilit_s_49923'),
                                                      getPath: (
                                                          isRouteComponent: boolean = false
                                                      ) =>
                                                          MembersPaths.MEMBER_REGISTRATION_AVAILABILITY(
                                                              isRouteComponent
                                                                  ? ':organizationId'
                                                                  : organizationId,
                                                              isRouteComponent
                                                                  ? ':userInfoId'
                                                                  : userInfoId,
                                                              isRouteComponent
                                                                  ? ':eventId'
                                                                  : eventId
                                                          ),
                                                      elem: (
                                                          <UserPositionsWishedSlots
                                                              editFormPath={
                                                                  MembersPaths.MEMBER_REGISTRATION_AVAILABILITY_EDIT_FORM
                                                              }
                                                              editPath={MembersPaths.MEMBER_REGISTRATION_AVAILABILITY_EDIT(
                                                                  organizationId,
                                                                  userInfoId,
                                                                  eventId
                                                              )}
                                                              formsUsersInfos={
                                                                  userInfo.formsUsersInfos
                                                              }
                                                              isEditDisabled={
                                                                  isEditPositionsDisabled
                                                              }
                                                          />
                                                      )
                                                  },
                                                  {
                                                      id: 3,
                                                      title: translate('missions_souhai_23225'),
                                                      getPath: (
                                                          isRouteComponent: boolean = false
                                                      ) =>
                                                          MembersPaths.MEMBER_REGISTRATION_POSITIONS(
                                                              isRouteComponent
                                                                  ? ':organizationId'
                                                                  : organizationId,
                                                              isRouteComponent
                                                                  ? ':userInfoId'
                                                                  : userInfoId,
                                                              isRouteComponent
                                                                  ? ':eventId'
                                                                  : eventId
                                                          ),
                                                      elem: (
                                                          <UserPositionsWished
                                                              editFormPath={
                                                                  MembersPaths.MEMBER_REGISTRATION_POSITIONS_EDIT_FORM
                                                              }
                                                              editPath={MembersPaths.MEMBER_REGISTRATION_POSITIONS_EDIT(
                                                                  organizationId,
                                                                  userInfoId,
                                                                  eventId
                                                              )}
                                                              formsUsersInfos={
                                                                  userInfo.formsUsersInfos
                                                              }
                                                              isEditDisabled={
                                                                  isEditPositionsDisabled
                                                              }
                                                          />
                                                      )
                                                  },
                                                  {
                                                      id: 4,
                                                      title: translate('affectation_plural'),
                                                      getPath: (
                                                          isRouteComponent: boolean = false
                                                      ) =>
                                                          MembersPaths.MEMBER_REGISTRATION_ASSIGNMENTS(
                                                              isRouteComponent
                                                                  ? ':organizationId'
                                                                  : organizationId,
                                                              isRouteComponent
                                                                  ? ':userInfoId'
                                                                  : userInfoId,
                                                              isRouteComponent
                                                                  ? ':eventId'
                                                                  : eventId
                                                          ),
                                                      elem: (
                                                          <MemberRegistrationAssignments
                                                              userInfo={userInfo}
                                                          />
                                                      )
                                                  },
                                                  {
                                                      id: 5,
                                                      title: translate('desired_accreditations'),
                                                      getPath: (
                                                          isRouteComponent: boolean = false
                                                      ) =>
                                                          MembersPaths.MEMBER_REGISTRATION_WISHED_ACCREDITATIONS(
                                                              isRouteComponent
                                                                  ? ':organizationId'
                                                                  : organizationId,
                                                              isRouteComponent
                                                                  ? ':userInfoId'
                                                                  : userInfoId,
                                                              isRouteComponent
                                                                  ? ':eventId'
                                                                  : eventId
                                                          ),
                                                      elem: (
                                                          <UserAccreditationsWished
                                                              editFormPath={
                                                                  MembersPaths.MEMBER_REGISTRATION_WISHED_ACCREDITATIONS_EDIT_FORM
                                                              }
                                                              editPath={MembersPaths.MEMBER_REGISTRATION_WISHED_ACCREDITATIONS_EDIT(
                                                                  organizationId,
                                                                  userInfoId,
                                                                  eventId
                                                              )}
                                                              formsUsersInfos={
                                                                  userInfo.formsUsersInfos
                                                              }
                                                              isEditDisabled={
                                                                  isEditAccreditationsDisabled
                                                              }
                                                          />
                                                      )
                                                  },
                                                  {
                                                      id: 6,
                                                      title: translate('accr_ditations_39450'),
                                                      getPath: (
                                                          isRouteComponent: boolean = false
                                                      ) =>
                                                          MembersPaths.MEMBER_REGISTRATION_ACCREDITATIONS(
                                                              isRouteComponent
                                                                  ? ':organizationId'
                                                                  : organizationId,
                                                              isRouteComponent
                                                                  ? ':userInfoId'
                                                                  : userInfoId,
                                                              isRouteComponent
                                                                  ? ':eventId'
                                                                  : eventId
                                                          ),
                                                      elem: (
                                                          <MemberRegistrationAccreditations
                                                              userInfo={userInfo}
                                                          />
                                                      )
                                                  },
                                                  {
                                                      id: 7,
                                                      title: translate('documents_87028'),
                                                      getPath: (
                                                          isRouteComponent: boolean = false
                                                      ) =>
                                                          MembersPaths.MEMBER_REGISTRATION_DOCUMENTS(
                                                              isRouteComponent
                                                                  ? ':organizationId'
                                                                  : organizationId,
                                                              isRouteComponent
                                                                  ? ':userInfoId'
                                                                  : userInfoId,
                                                              isRouteComponent
                                                                  ? ':eventId'
                                                                  : eventId
                                                          ),
                                                      elem: (
                                                          <MemberRegistrationDocuments
                                                              campaigns={userInfo.campaigns.nodes}
                                                          />
                                                      )
                                                  }
                                              ]
                                            : [])
                                    ]}
                                />
                            </Flex>
                        }
                    />
                </Switch>
            </SpaceContainer>
        ) : (
            // This path should be unreachable
            <Box>ERROR</Box>
        ))
    );
};
