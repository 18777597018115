import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { VolunteerAssignmentsQuery } from 'common/src/generated/types';
import * as React from 'react';
import { NamesSelector } from '../namesSelector';
import { WithRegistration } from '../withRegistration';
import { Assignment } from './assignment';
import { NoAssignments } from './noAssignments';
import { VolunteerInfos } from './volunteerInfos';

interface IAssignmentPageProps {
    event: VolunteerAssignmentsQuery['event'];
    user: VolunteerAssignmentsQuery['user'];
}

export const AssignmentPage = (props: IAssignmentPageProps) => (
        <WithRegistration
            event={props.event}
            user={props.user}
            render={(volunteerRegistration) => (
                    <>
                        <NamesSelector registrations={props.user.volunteersRegistrations} />

                        <Flex width={1}>
                            <Box
                                css={{
                                    marginRight: '$7',
                                    width: '360px'
                                }}
                            >
                                <VolunteerInfos
                                    event={props.event}
                                    registration={volunteerRegistration}
                                />
                            </Box>

                            <Box css={{ flex: '1' }}>
                                {volunteerRegistration.positionsSlotsUsersInfos.length === 0 ? (
                                    <Box
                                        css={{
                                            marginBottom: '$7',
                                            width: '100%',
                                            '&:last-child': {
                                                marginBottom: '0'
                                            }
                                        }}
                                    >
                                        <NoAssignments />
                                    </Box>
                                ) : (
                                    <>
                                        {volunteerRegistration.positionsSlotsUsersInfos.map(
                                            ({ position, positionSlot }, index) => (
                                                    <Box
                                                        key={index}
                                                        css={{
                                                            marginBottom: '$7',
                                                            width: '100%',
                                                            '&:last-child': {
                                                                marginBottom: '0'
                                                            }
                                                        }}
                                                    >
                                                        <Assignment
                                                            position={position}
                                                            positionSlot={positionSlot}
                                                        />
                                                    </Box>
                                                )
                                        )}
                                    </>
                                )}
                            </Box>
                        </Flex>
                    </>
                )}
        />
    );
