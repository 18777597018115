import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import { CountriesService, ICountry } from 'common/src/services/countriesService';
import { toArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString, normalize } from 'common/src/util/string';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Fields } from '../../form/fields';
import { isInvalid } from '../isInvalid';
import { Label } from '../label/label';
import { Subtitle } from './subtitle';

const _PhoneNumber = styled('input', {
    background: 'white',
    borderBottom: '1px solid $oldGrayBorder',
    borderRadius: '$1',
    borderLeft: 'none',
    borderRight: '1px solid $oldGrayBorder',
    borderTop: '1px solid $oldGrayBorder',
    borderBottomLeftRadius: '0',
    borderTopLeftRadius: '0',
    display: 'block',
    height: '40px',
    padding: '$2 $3',
    width: '100%',
    variants: {
        dropdownOpen: {
            true: {
                borderBottomRightRadius: '0'
            }
        },
        invalid: {
            true: {
                border: '1px solid $oldRed',
                borderLeft: 'none'
            }
        }
    }
});

interface IDropdownProps {
    countries: ICountry[];

    onSelect(code: ICountry): void;
}

const Dropdown = (props: IDropdownProps) => {
    const translate = useTranslate();
    const [searchValue, setSearchValue] = React.useState('');
    const countries = React.useMemo(() => {
        if (searchValue.trim().length > 0) {
            return props.countries.filter((country) => (
                    country.name.toLowerCase().includes(searchValue.trim()) ||
                    country.phoneCode.includes(searchValue.trim())
                ));
        } else {
            return props.countries;
        }
    }, [searchValue]);

    return (
        <Box
            css={{
                background: 'white',
                border: '1px solid $oldGrayBorder',
                borderBottomLeftRadius: '$1',
                borderBottomRightRadius: '$1',
                borderTop: 'none',
                height: '200px',
                position: 'absolute',
                top: '40px',
                userSelect: 'none',
                width: '100%',
                zIndex: '10'
            }}
        >
            <Box
                css={{
                    borderBottom: '1px solid $oldGrayBorder',
                    height: '30px',
                    padding: '0 $2',
                    width: '100%',
                    '& > input': {
                        border: 'none',
                        height: '100%',
                        width: '100%'
                    }
                }}
            >
                <input
                    type="text"
                    value={searchValue}
                    onChange={(e) => {
                        setSearchValue(e.target.value);
                    }}
                    placeholder={translate('rechercher_un_p_04678')}
                />
            </Box>

            <Box
                css={{
                    height: 'calc(100% - 30px)',
                    overflowY: 'auto',
                    width: '100%'
                }}
            >
                {countries.map((country, index) => (
                        <Flex
                            align="center"
                            gap="2"
                            css={{
                                cursor: 'pointer',
                                height: '40px',
                                padding: '$1 $2',
                                '&:hover': {
                                    background: '$oldGray'
                                }
                            }}
                            key={index}
                            onClick={() => {
                                props.onSelect(country);
                            }}
                        >
                            <div>{country.emoji}</div>

                            <div>{country.name}</div>

                            <Box css={{ color: '$oldBlueBayoux' }}>{country.phoneCode}</Box>
                        </Flex>
                    ))}
            </Box>
        </Box>
    );
};

interface IPhoneInputComponentProps {
    label: string;
    isOptional?: boolean;
    subtitle?: string;
    countryProps: FieldRenderProps<string, HTMLInputElement>;
    codeProps: FieldRenderProps<string, HTMLInputElement>;
    numberProps: FieldRenderProps<string, HTMLInputElement>;
}

const PhoneInputComponent = (props: IPhoneInputComponentProps) => {
    const countriesService = useService(CountriesService);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const countries = React.useMemo(() => sortBy(
            countriesService.countries.flatMap((country) => toArray(country.phoneCode).map((phoneCode) => ({
                        ...country,
                        phoneCode
                    }))),
            ({ name }) => normalize(name)
        ), []);
    const invalid = isInvalid(props.codeProps.meta) || isInvalid(props.numberProps.meta);
    const currentCountry = countries.find(({ code, phoneCode }) => code === props.countryProps.input.value && phoneCode === props.codeProps.input.value);

    return (
        <div>
            <Label htmlFor={props.numberProps.input.name} isOptional={props.isOptional}>
                {props.label}
            </Label>

            {isNonEmptyString(props.subtitle) && <Subtitle text={props.subtitle} />}

            <Flex
                css={{
                    height: '40px',
                    marginBottom: invalid ? '$2' : '$6',
                    position: 'relative',
                    width: '100%'
                }}
            >
                <Flex
                    align="center"
                    css={{
                        border: invalid ? '1px solid $oldRed' : '1px solid $oldGrayBorder',
                        borderBottomLeftRadius: isDropdownOpen ? '0' : '$1',
                        borderTopLeftRadius: '$1',
                        cursor: 'pointer',
                        height: '100%',
                        justifyContent: 'space-around',
                        width: '85px',
                        '& > img': {
                            borderRadius: '3px',
                            maxHeight: '35px',
                            maxWidth: '35px'
                        }
                    }}
                    onClick={() => {
                        setIsDropdownOpen(!isDropdownOpen);
                    }}
                >
                    {currentCountry && `${currentCountry.emoji} ${currentCountry.phoneCode}`}
                </Flex>

                <_PhoneNumber
                    dropdownOpen={isDropdownOpen}
                    invalid={invalid}
                    {...props.numberProps.input}
                    type="text"
                />

                {isDropdownOpen && (
                    <Dropdown
                        countries={countries}
                        onSelect={(country: ICountry) => {
                            props.countryProps.input.onChange(country.code);
                            props.codeProps.input.onChange(country.phoneCode);

                            setIsDropdownOpen(false);
                        }}
                    />
                )}
            </Flex>

            {invalid && (
                <Box
                    css={{
                        color: '$oldRed',
                        marginBottom: '$6'
                    }}
                >
                    {props.codeProps.meta.error || props.numberProps.meta.error}
                </Box>
            )}
        </div>
    );
};

interface IPhoneInputProps {
    label: string;
    prefix: string;
    isOptional?: boolean;
    subtitle?: string;
}

export const PhoneInput = (props: IPhoneInputProps) => (
        <Fields
            names={[`${props.prefix}country`, `${props.prefix}code`, `${props.prefix}number`]}
            render={(fields) => (
                    <PhoneInputComponent
                        label={props.label}
                        subtitle={props.subtitle}
                        isOptional={props.isOptional}
                        countryProps={fields[`${props.prefix}country`]}
                        codeProps={fields[`${props.prefix}code`]}
                        numberProps={fields[`${props.prefix}number`]}
                    />
                )}
        />
    );
