import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { CSS, styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';

const _RadioContainer = styled('div', {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    marginBottom: '$6',
    width: '100%',
    variants: {
        enabled: {
            true: {
                '&:hover :first-child': {
                    border: '1px solid $oldBlue'
                }
            }
        }
    }
});

const _Radio = styled('div', {
    alignItems: 'center',
    border: '1px solid $oldGrayBorder',
    borderRadius: '20px',
    display: 'flex',
    height: '20px',
    justifyContent: 'center',
    width: '20px',
    variants: {
        checked: {
            true: {
                border: '1px solid $oldBlue'
            }
        },
        disabled: {
            true: {
                background: '$oldGrayLight'
            }
        }
    }
});

interface IRadioComponentProps extends FieldRenderProps<any, HTMLElement> {
    css?: CSS;
    myValue: any;
    text: string | React.ReactNode;
    subtext?: string;
    disabled?: boolean;
}

const RadioComponent = (props: IRadioComponentProps) => {
    const isChecked = props.myValue === props.input.value;
    const hasSubtext = typeof props.subtext === 'string' && props.subtext.trim().length > 0;
    const disabled = props.disabled === true;

    return (
        <_RadioContainer
            enabled={!disabled}
            css={props.css}
            onClick={() => {
                if (!disabled) {
                    props.input!.onChange(props.myValue as any);
                }
            }}
        >
            <_Radio checked={isChecked} disabled={disabled}>
                {isChecked && (
                    <Box
                        css={{
                            background: '$oldBlue',
                            border: '1px solid $oldBlue',
                            borderRadius: '8px',
                            height: '8px',
                            width: '8px'
                        }}
                    />
                )}
            </_Radio>

            <Flex
                direction="column"
                css={{
                    marginLeft: '10px',
                    width: 'calc(100% - 30px)'
                }}
            >
                <Box
                    css={{
                        fontWeight: hasSubtext ? '$oldBold' : ''
                    }}
                >
                    {props.text}
                </Box>

                {hasSubtext && (
                    <Box
                        css={{
                            color: '$oldBlackLight',
                            marginTop: '$1'
                        }}
                    >
                        {props.subtext}
                    </Box>
                )}
            </Flex>
        </_RadioContainer>
    );
};

interface IRadioFieldProps {
    css?: CSS;
    name: string;
    value: any;
    text: string | React.ReactNode;
    subtext?: string;
    disabled?: boolean;
}

export const Radio = (props: IRadioFieldProps) => (
        <Field
            css={props.css}
            name={props.name}
            myValue={props.value}
            component={RadioComponent as any}
            text={props.text}
            subtext={props.subtext}
            disabled={props.disabled}
        />
    );
