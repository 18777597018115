import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AccreditationsSlotId,
    DelegationAccreditationFragment,
    FormAccreditationsOptionsFragment,
    FormAccreditationsQuery,
    VolunteersRegistrationsSlotInput
} from 'common/src/generated/types';
import { canSelect } from 'common/src/vo/accreditation';
import { UserInfoFields } from 'common/src/vo/field';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { FormAccreditationsSlotsAccreditation } from './formAccreditationsSlotsAccreditation';

interface IFormAccreditationsSlotsCategoryProps {
    accreditationsSlotsIds: AccreditationsSlotId[];
    category: FormAccreditationsQuery['event']['accreditationsCategories'][0];
    delegationAccreditations: DelegationAccreditationFragment[];
    options: FormAccreditationsOptionsFragment;
    prefix: string;
    slots: VolunteersRegistrationsSlotInput[];
    userInfoFields: UserInfoFields;

    change(name: string, value: any): void;
}

export const FormAccreditationsSlotsCategory = (props: IFormAccreditationsSlotsCategoryProps) => {
    const accreditations = React.useMemo(() => sortBy(
            props.category.accreditations.filter((accreditation) => canSelect(
                    accreditation,
                    props.slots,
                    props.userInfoFields,
                    props.options,
                    props.delegationAccreditations
                )),
            (a) => a.name.toLowerCase()
        ), [
        props.category,
        props.delegationAccreditations,
        props.slots,
        props.userInfoFields,
        props.options
    ]);

    return (
        <>
            <Box font="gray900 textSm semiBold">{props.category.name}</Box>

            <Spacer height="2" />

            {accreditations.map((accreditation, index) => (
                    <React.Fragment key={accreditation.id}>
                        {index !== 0 && <Spacer height="5" />}

                        <FormAccreditationsSlotsAccreditation
                            accreditation={accreditation}
                            accreditationsSlotsIds={props.accreditationsSlotsIds}
                            delegationAccreditations={props.delegationAccreditations}
                            options={props.options}
                            prefix={props.prefix}
                            slots={props.slots}
                            change={props.change}
                        />
                    </React.Fragment>
                ))}
        </>
    );
};
