import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

interface IIconBackgroundProps {
    color: 'error' | 'primary' | 'purple' | 'success' | 'warning';
    css?: CSS;
    icon: IIcon;
}

export const IconBackground = (props: IIconBackgroundProps) => (
        <Flex
            align="center"
            justify="center"
            height={48}
            width={48}
            css={{
                background: `$${props.color}50`,
                borderRadius: '48px',
                color: `$${props.color}700`,
                ...((props.css as any) || {})
            }}
        >
            <Flex
                align="center"
                justify="center"
                height={32}
                width={32}
                css={{
                    background: `$${props.color}100`,
                    borderRadius: '32px'
                }}
            >
                <I icon={props.icon} />
            </Flex>
        </Flex>
    );
