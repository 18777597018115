import { CSS, styled } from 'common/src/designSystem/components/stitches';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

const _Label = styled('label', {
    fontWeight: '600',
    marginBottom: '$1'
});

interface ILabelProps {
    htmlFor: string;
    children: React.ReactNode;
    isOptional?: boolean;
    css?: CSS;
}

export const Label = (props: ILabelProps) => {
    const translate = useTranslate();

    return (
        <_Label htmlFor={props.htmlFor} css={props.css}>
            {props.children}
            {props.isOptional && <span> ({translate('facultatif_76747')})</span>}
        </_Label>
    );
};
