import { Box } from 'common/src/designSystem/components/box';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import {
    DATE_TIME_FORM_FORMAT,
    formatForDateTimeForm,
    parseFromDateTimeForm,
    TIME_FORMAT
} from 'common/src/util/luxon';
import * as luxon from 'luxon';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { isInvalid } from '../../isInvalid';
import { Label } from '../../label/label';
import { _OldInputStyled } from '../text';

interface ISlotPickerMobileProps {
    startDateProps: FieldRenderProps<luxon.DateTime, HTMLInputElement>;
    startTimeProps: FieldRenderProps<string, HTMLInputElement>;
    endDateProps: FieldRenderProps<luxon.DateTime, HTMLInputElement>;
    endTimeProps: FieldRenderProps<string, HTMLInputElement>;
    prefix: string;
    min?: luxon.DateTime;
    max?: luxon.DateTime;
}

export const SlotPickerMobile = (props: ISlotPickerMobileProps) => {
    const translate = useTranslate();
    const startDateInvalid = isInvalid(props.startDateProps.meta);
    const startTimeInvalid = isInvalid(props.startTimeProps.meta);
    const startInvalid = startDateInvalid || startTimeInvalid;
    const endDateInvalid = isInvalid(props.endDateProps.meta);
    const endTimeInvalid = isInvalid(props.endTimeProps.meta);
    const endInvalid = endDateInvalid || endTimeInvalid;
    const startError = startDateInvalid
        ? props.startDateProps.meta.error
        : startTimeInvalid
        ? props.startTimeProps.meta.error
        : '';
    const endError = endDateInvalid
        ? props.endDateProps.meta.error
        : endTimeInvalid
        ? props.endTimeProps.meta.error
        : '';

    return (
        <div>
            <div>
                <Label htmlFor="start">{translate('d_but_41840')}</Label>

                <_OldInputStyled
                    type="datetime-local"
                    invalid={startInvalid}
                    min={props.min?.toFormat(DATE_TIME_FORM_FORMAT)}
                    max={props.max?.toFormat(DATE_TIME_FORM_FORMAT)}
                    onChange={(e) => {
                        const parsedValue = parseFromDateTimeForm(e.target.value);

                        if (parsedValue.isValid) {
                            props.startDateProps.input.onChange(
                                parsedValue.set({
                                    hour: 0,
                                    minute: 0
                                })
                            );
                            props.startTimeProps.input.onChange(parsedValue.toFormat(TIME_FORMAT));
                        } else {
                            props.startDateProps.input.onChange(parsedValue);
                            props.startTimeProps.input.onChange('');
                        }
                    }}
                    value={formatForDateTimeForm(
                        props.startDateProps.input.value,
                        props.startTimeProps.input.value
                    )}
                />

                {startInvalid && (
                    <Box
                        css={{
                            color: '$oldRed',
                            marginBottom: '$6'
                        }}
                    >
                        {startError}
                    </Box>
                )}
            </div>

            <div>
                <Label htmlFor="end">{translate('fin_60104')}</Label>

                <_OldInputStyled
                    type="datetime-local"
                    invalid={endInvalid}
                    min={props.min?.toFormat(DATE_TIME_FORM_FORMAT)}
                    max={props.max?.toFormat(DATE_TIME_FORM_FORMAT)}
                    onChange={(e) => {
                        const parsedValue = parseFromDateTimeForm(e.target.value);

                        if (parsedValue.isValid) {
                            props.endDateProps.input.onChange(
                                parsedValue.set({
                                    hour: 0,
                                    minute: 0
                                })
                            );
                            props.endTimeProps.input.onChange(parsedValue.toFormat(TIME_FORMAT));
                        } else {
                            props.endDateProps.input.onChange(parsedValue);
                            props.endTimeProps.input.onChange('');
                        }
                    }}
                    value={formatForDateTimeForm(
                        props.endDateProps.input.value,
                        props.endTimeProps.input.value
                    )}
                />

                {endInvalid && (
                    <Box
                        css={{
                            color: '$oldRed',
                            marginBottom: '$6'
                        }}
                    >
                        {endError}
                    </Box>
                )}
            </div>
        </div>
    );
};
