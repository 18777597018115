import { Separator } from 'common-front/src/designSystem/components/separator';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { CampaignType, MemberQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import * as React from 'react';

interface IMemberCampaignsProps {
    campaigns: MemberQuery['userInfo']['campaigns']['nodes'];
}

export const MemberCampaigns = (props: IMemberCampaignsProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);

    return (
        <Flex
            direction="column"
            gap="4"
            width={1}
            css={{
                bd: true,
                padding: '$6'
            }}
        >
            {props.campaigns.length === 0 && (
                <Box color="gray500" textAlign="center">
                    {translate('aucun_message_r_16606')}
                </Box>
            )}

            {props.campaigns.map((campaign, index) => (
                    <React.Fragment key={campaign.id}>
                        {index !== 0 && <Separator direction="horizontal" />}

                        <Flex align="center" gap="3">
                            <Flex
                                align="center"
                                justify="center"
                                height={40}
                                width={40}
                                css={{
                                    background: '$gray100',
                                    borderRadius: '$2',
                                    color: '$gray700'
                                }}
                            >
                                <I
                                    icon={
                                        campaign.campaignType === CampaignType.Sms
                                            ? 'message'
                                            : 'at'
                                    }
                                />
                            </Flex>

                            <Flex direction="column" gap="1">
                                <Box font="gray800 textSm medium">{campaign.name}</Box>

                                {campaign.sentAt && (
                                    <Box color="gray500">
                                        {translate(
                                            're_u_le_1_41629',
                                            dateTimeService.toLocaleString(
                                                campaign.sentAt,
                                                LocaleFormats.DateOnly.MonthLong
                                            )
                                        )}
                                    </Box>
                                )}
                            </Flex>
                        </Flex>
                    </React.Fragment>
                ))}
        </Flex>
    );
};

export const MemberCampaignsSkeleton = () => (
        <Flex
            direction="column"
            gap="4"
            width={1}
            css={{
                bd: true,
                padding: '$6'
            }}
        >
            <Skeleton height={40} width={1} borderRadius="$2" />
            <Separator direction="horizontal" />
            <Skeleton height={40} width={1} borderRadius="$2" />
        </Flex>
    );
