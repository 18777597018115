import { FormErrors } from 'common-front/src/components/form/formErrors';
import { Button } from 'common-front/src/designSystem/components/button';
import { FormAccreditations } from 'common-front/src/forms/accreditations/formAccreditations';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AccreditationsSlotId,
    EventId,
    RegisterFormQuery,
    VolunteersRegistrationsSlotInput
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { UserInfoFields } from 'common/src/vo/field';
import * as React from 'react';
import { useFormAccreditationsQuery } from '../../../../generated/graphqlHooks';

interface IFormAccreditationsLoaderProps {
    accreditationsSlotsIds: AccreditationsSlotId[];
    eventId: EventId;
    form: RegisterFormQuery['organization']['form'];
    registerButtonText: string;
    slots: VolunteersRegistrationsSlotInput[];
    userInfoFields: UserInfoFields;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

export const FormAccreditationsLoader = (props: IFormAccreditationsLoaderProps) => {
    const translate = useTranslate();
    const { data, loader } = useFormAccreditationsQuery({ eventId: props.eventId });

    return (
        loader || (
            <>
                {isNonEmptyString(props.form.accreditationSubtitle) ? (
                    <RichText text={props.form.accreditationSubtitle} />
                ) : (
                    <>
                        <Box font="gray800 textMd semiBold">
                            {translate('s_lectionnez_vo_91873')}
                        </Box>

                        <Spacer height="1" />

                        <Box color="gray500">{translate('s_lectionnez_le_63924')}</Box>
                    </>
                )}

                <Spacer height="6" />

                <FormErrors />

                <FormAccreditations
                    delegationAccreditations={[]}
                    event={data.event}
                    options={props.form}
                    accreditationsSlotsIds={props.accreditationsSlotsIds}
                    prefix="formRegister."
                    slots={props.slots}
                    userInfosFields={props.userInfoFields}
                    change={props.change}
                />

                <Spacer height="7" />

                <Flex>
                    <Button onClick={props.handleSubmit}>{props.registerButtonText}</Button>
                </Flex>
            </>
        )
    );
};
