import { Select } from 'common-front/src/designSystem/components/select/select';
import { useUserContext } from 'common-front/src/userContext';
import { getLanguage } from 'common-front/src/util/language';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { SupportedLanguage } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IFormLanguagesProps {
    languages: SupportedLanguage[];
}

export const FormLanguages = (props: IFormLanguagesProps) => {
    const translate = useTranslate();
    const { user } = useUserContext();
    const url = new URL(location.href);

    return (
        <Flex
            direction={{ '@initial': 'column', '@tablet': 'row' }}
            align={{ '@initial': 'start', '@tablet': 'center' }}
            gap="4"
            css={{
                background: 'white',
                bd: 'white 1 sm',
                padding: '$4 $6',
                width: '100%',
                '@tablet': {
                    padding: '$6',
                    width: '$containerTablet'
                },
                '@desktop': {
                    width: '$container'
                }
            }}
        >
            <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                {translate('langue_du_formu_66193')}
            </Box>

            <Box css={{ '@mobile': { width: '100%' }, '@tablet': { flex: '1' } }}>
                <Select
                    value={getLanguage(user, url.searchParams)}
                    onChange={(newLanguage: string) => {
                        url.searchParams.set('lang', newLanguage.toLowerCase());

                        location.href = url.toString();
                    }}
                >
                    {props.languages.map((language) => (
                            <option key={language} value={language}>
                                {translate(language)}
                            </option>
                        ))}
                </Select>
            </Box>
        </Flex>
    );
};
