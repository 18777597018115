import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { fileInfos } from 'common/src/util/file';
import * as React from 'react';
import { Accept, acceptExtensions, acceptString } from '../../../util/accept';
import { Button } from '../button';
import { AutoComplete } from '../textInput';

interface IImageUploaderProps {
    autoComplete?: AutoComplete;
    label: React.ReactNode;
    hint?: string;

    onChange(file: File): void;
}

export const ImageUploader = (props: IImageUploaderProps) => {
    const translate = useTranslate();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [isDraggingOver, setIsDraggingOver] = React.useState(false);
    const extensions = acceptExtensions(Accept.Images);

    return (
        <Flex gap="4" width={1}>
            <Flex
                align="center"
                justify="center"
                height={126}
                width={126}
                css={{
                    background: isDraggingOver ? '$primary50' : '$gray50',
                    border: isDraggingOver ? '1px solid $primary300' : '1px solid $gray200',
                    borderRadius: '$1',
                    cursor: 'pointer'
                }}
                onDragEnter={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setIsDraggingOver(true);
                }}
                onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (!isDraggingOver) {
                        setIsDraggingOver(true);
                    }
                }}
                onDragLeave={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setIsDraggingOver(false);
                }}
                onDrop={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setIsDraggingOver(false);

                    const file = e.dataTransfer.files.item(0);

                    if (file && extensions.includes(fileInfos(file.name).extension)) {
                        props.onChange(file);
                    }
                }}
                onClick={() => {
                    inputRef.current?.click();
                }}
            >
                <Flex
                    align="center"
                    justify="center"
                    height={32}
                    width={32}
                    css={{
                        background: '$primary700',
                        borderRadius: '32px',
                        color: 'white'
                    }}
                >
                    <I icon="plus" />
                </Flex>
            </Flex>

            <Flex direction="column">
                <Box color="gray700" fontWeight="medium">
                    {props.label}
                </Box>

                {props.hint && (
                    <>
                        <Spacer height="1" />

                        <Box color="gray400">{props.hint}</Box>
                    </>
                )}

                <Spacer height="3" />

                <Flex>
                    <Button
                        onClick={() => {
                            inputRef.current?.click();
                        }}
                    >
                        {translate('s_lectionner_un_50712')}
                    </Button>
                </Flex>
            </Flex>

            <Box css={{ display: 'none' }}>
                <input
                    autoComplete={props.autoComplete}
                    type="file"
                    accept={acceptString(Accept.Images)}
                    ref={inputRef}
                    onChange={(e) => {
                        if (e.target.files?.[0]) {
                            props.onChange(e.target.files[0]);
                        }
                    }}
                />
            </Box>
        </Flex>
    );
};
