import { Box } from 'common/src/designSystem/components/box';
import { styled } from 'common/src/designSystem/components/stitches';
import { CustomFieldWithConditionFragment, Document, FieldType } from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { FieldService, UserInfo } from 'common/src/vo/field';
import * as React from 'react';
import { File } from '../../../designSystem/components/file/file';

interface ICustomFieldProps {
    field: Pick<CustomFieldWithConditionFragment, 'fieldType' | 'slug' | 'values'>;
    userInfo: UserInfo;
}

const _Box = styled(Box, {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
});

export const Field = (props: ICustomFieldProps) => {
    const fieldService = useService(FieldService);

    switch (props.field.fieldType) {
        case FieldType.File: {
            const document = fieldService.getValue<Document>(props.field, props.userInfo);

            if (document) {
                return <File document={document} acl="public-read" />;
            } else {
                return (
                    <_Box
                        color="gray500"
                        css={{ fontSize: '$textMd', '@tablet': { fontSize: '$textSm' } }}
                    >
                        -
                    </_Box>
                );
            }
        }
        case FieldType.Phone: {
            const phone = fieldService.getValueString(props.field, props.userInfo);

            return (
                <_Box
                    color="gray500"
                    css={{ fontSize: '$textMd', '@tablet': { fontSize: '$textSm' } }}
                >
                    {isNonEmptyString(phone) ? <a href={`tel:${phone}`}>{phone}</a> : '-'}
                </_Box>
            );
        }
        default: {
            const value = fieldService.getValueString(props.field, props.userInfo);

            return (
                <_Box
                    color="gray500"
                    css={{ fontSize: '$textMd', '@tablet': { fontSize: '$textSm' } }}
                >
                    {isNonEmptyString(value) ? value : '-'}
                </_Box>
            );
        }
    }
};
