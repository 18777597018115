import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';
import { VolunteersBox } from '../common/volunteersBox';

interface IStepProps {
    children: React.ReactNode;
    stepNumber: number;
    title: string;
    canSelect: boolean;
    isOpen: boolean;
    isCompleted: boolean;

    onSelect(): void;
}

export const Step = (props: IStepProps) => (
        <VolunteersBox
            header={
                <>
                    <Flex
                        align="center"
                        justify="center"
                        css={{
                            border: '1px solid $oldSolitude',
                            borderRadius: '30px',
                            fontWeight: '$oldBold',
                            height: '30px',
                            width: '30px',
                            '& > i': {
                                color: '$oldGreen'
                            }
                        }}
                    >
                        {props.isCompleted ? <i className="far fa-check" /> : props.stepNumber}
                    </Flex>

                    <Box
                        css={{
                            fontSize: '16px',
                            fontWeight: '$oldBold',
                            marginLeft: '$4'
                        }}
                    >
                        {props.title}
                    </Box>
                </>
            }
            headerCss={{
                cursor: props.canSelect ? 'pointer' : 'default'
            }}
            headerClick={() => {
                if (props.canSelect) {
                    props.onSelect();
                }
            }}
            contentCss={{
                padding: '40px 70px',
                '@oldBreakpoint': {
                    padding: '$7'
                }
            }}
            showPoweredBy={true}
        >
            {props.isOpen ? props.children : undefined}
        </VolunteersBox>
    );
