import * as React from 'react';

interface IEventPageContext {
    isEmbed: boolean;
}

const EventPageContext = React.createContext<IEventPageContext>({} as any);

interface IEventPageContextProviderProps {
    children: React.ReactNode;
    isEmbed: boolean;
}

export const EventPageContextProvider = (props: IEventPageContextProviderProps) => (
        <EventPageContext.Provider
            value={{
                isEmbed: props.isEmbed
            }}
        >
            {props.children}
        </EventPageContext.Provider>
    );

export function useEventPageContext(): IEventPageContext {
    return React.useContext(EventPageContext);
}
