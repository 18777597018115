import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CSS } from 'common/src/designSystem/components/stitches';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IBlankProps {
    button?: React.ReactNode;
    imageSrc?: string;
    title: string;
    subtitle?: string;
    css?: CSS;
}

export const Blank = (props: IBlankProps) => (
        <Flex
            direction="column"
            align="center"
            justify="center"
            height={1}
            width={1}
            css={props.css}
        >
            {props.imageSrc && (
                <Box css={{ width: '70%', '@tablet': { height: '50%', width: '50%' } }}>
                    <img
                        src={props.imageSrc}
                        className="blank-image"
                        alt="Blank image"
                        height="100%"
                        width="100%"
                    />
                </Box>
            )}

            <Spacer height="7" />

            <Box
                textAlign="center"
                css={{
                    color: '$gray800',
                    fontSize: '$textMd',
                    fontWeight: '$semiBold',
                    '@tablet': { color: '$gray900', fontSize: '$textMd', fontWeight: '$medium' }
                }}
            >
                {props.title}
            </Box>

            {isNonEmptyString(props.subtitle) && (
                <>
                    <Spacer height="2" />

                    <Box color="gray500" fontSize="textSm" textAlign="center">
                        {props.subtitle}
                    </Box>
                </>
            )}

            {props.button && (
                <>
                    <Spacer height="4" />

                    <Flex justify="center">{props.button}</Flex>
                </>
            )}
        </Flex>
    );
