import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useUniqueIds } from '../../hooks/useUniqueIds';
import { BaseInputProps } from './input/baseInputProps';
import { Description } from './input/description';
import { Hint } from './input/hint';
import { Label } from './input/label';
import { StyledInputContainer } from './input/styledInputContainer';

type StringProps = {
    value: string;
    parseInt?: false;
    isEmail?: boolean;
    type?: string;

    onChange(value: string): void;
};

type IntProps = {
    value: number;
    parseInt: true;
    isEmail?: false;
    type?: 'number';

    onChange(value: number): void;
};

type ValueProps = StringProps | IntProps;

export type AutoComplete =
    | 'on'
    | 'off'
    | 'name'
    | 'honorific-prefix'
    | 'given-name'
    | 'additional-name'
    | 'family-name'
    | 'honorific-suffix'
    | 'nickname'
    | 'email'
    | 'username'
    | 'new-password'
    | 'current-password'
    | 'one-time-code'
    | 'organization-title'
    | 'organization'
    | 'street-address'
    | 'shipping'
    | 'billing'
    | 'address-line1'
    | 'address-line2'
    | 'address-line3'
    | 'address-level4'
    | 'address-level3'
    | 'address-level2'
    | 'address-level1'
    | 'country'
    | 'country-name'
    | 'postal-code'
    | 'language'
    | 'bday'
    | 'bday-day'
    | 'bday-month'
    | 'bday-year'
    | 'sex'
    | 'tel'
    | 'tel-country-code'
    | 'tel-national'
    | 'tel-area-code'
    | 'tel-local'
    | 'tel-extension'
    | 'photo';

type IInputProps = BaseInputProps &
    ValueProps & {
        autoComplete?: AutoComplete;
        onRightIconClick?(e: React.MouseEvent<HTMLDivElement>): void;
        min?: number;
    };

export const TextInput = React.forwardRef(
    (props: IInputProps, ref: React.Ref<HTMLInputElement>) => {
        const rootRef = React.useRef<any>();

        const type = (): HTMLInputElement['type'] => {
            if (props.type) {
                return props.type;
            } else if (props.isEmail) {
                return 'email';
            } else if (props.parseInt) {
                return 'number';
            } else {
                return 'text';
            }
        };
        const { inputId, descId, errorId } = useUniqueIds();

        return (
            <Flex
                ref={rootRef}
                css={props.css}
                direction="column"
                width={1}
                onClick={(e) => {
                    e.preventDefault();

                    rootRef.current?.querySelector('input')?.focus();
                }}
            >
                <Label htmlFor={inputId}>{props.label}</Label>

                <Description id={descId}>{props.description}</Description>

                {(props.label || props.description) && <Spacer height="1" />}

                <StyledInputContainer
                    icon={props.icon}
                    rightIcon={props.rightIcon}
                    state={props.state}
                    cursor="text"
                    onRightIconClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();

                        props.onRightIconClick?.(e);
                    }}
                >
                    <Box
                        color="inherit"
                        css={{
                            flex: '1',
                            height: '100%',
                            '& input': {
                                background: 'transparent',
                                border: 'none',
                                color: '$gray800',
                                height: '100%',
                                outline: 'none',
                                width: '100%'
                            },
                            '& input::placeholder': {
                                color: props.state === 'search' ? '$gray400' : '$gray500',
                                opacity: 1
                            },
                            '& input::-ms-input-placeholder ': {
                                color: props.state === 'search' ? '$gray400' : '$gray500',
                                opacity: 1
                            }
                        }}
                    >
                        <input
                            ref={ref}
                            id={inputId}
                            aria-describedby={props.description ? descId : undefined}
                            aria-invalid={props.state === 'error'}
                            aria-errormessage={props.state === 'error' ? errorId : undefined}
                            type={type()}
                            placeholder={props.placeholder}
                            disabled={props.state === 'disabled'}
                            autoComplete={props.autoComplete}
                            value={props.value}
                            min={props.min}
                            onChange={(e) => {
                                if (props.isEmail && isNonEmptyString(e.target.value)) {
                                    props.onChange(e.target.value.toLowerCase());
                                } else if (props.parseInt === true) {
                                    const intValue = parseInt(e.target.value, 10);

                                    if (isNaN(intValue)) {
                                        props.onChange(0);
                                    } else {
                                        props.onChange(intValue);
                                    }
                                } else {
                                    props.onChange(e.target.value);
                                }
                            }}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            onKeyDown={props.onKeyDown}
                        />
                    </Box>
                </StyledInputContainer>

                <Hint id={errorId} state={props.state}>
                    {props.hint}
                </Hint>
            </Flex>
        );
    }
);
