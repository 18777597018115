import { range as lodashRange } from 'lodash-es';
import { DateTime, Interval } from 'luxon';
import {
    CheckInState,
    FormRegisterCustomSlotFragment,
    RegisterDaysDisplay,
    VolunteersRegistrationsSlotInput
} from '../generated/types';
import { Emptyable } from '../util/emptyable';
import { fromInterval, toInterval } from '../util/luxon';
import { isNonEmptyString } from '../util/string';

export function getDays(
    startAt: DateTime,
    endAt: DateTime,
    ranges: Interval[],
    daysDisplay: RegisterDaysDisplay
): DateTime[] {
    const numberOfDays = endAt.diff(startAt, 'days').days;

    return lodashRange(0, numberOfDays + 1)
        .map((i) => startAt.plus({ days: i }))
        .filter((day) => {
            if (daysDisplay === RegisterDaysDisplay.AllDays) {
                return true;
            } else {
                return ranges.some((range) => (
                        range.contains(day) ||
                        range.start!.startOf('day').equals(day) ||
                        range.end!.startOf('day').equals(day)
                    ));
            }
        });
}

export function getIntervals(
    startAt: DateTime,
    endAt: DateTime,
    customSlots: FormRegisterCustomSlotFragment[],
    ranges: Interval[],
    daysDisplay: RegisterDaysDisplay
) {
    const numberOfDays = endAt.diff(startAt, 'days').days;

    return lodashRange(0, numberOfDays + 1)
        .flatMap((i) => {
            const day = startAt.plus({ days: i });

            return customSlots.map((customSlot) => {
                const [startHour, startMinute] = customSlot.startTime
                    .split(':')
                    .map((p: string) => parseInt(p, 10));
                const [endHour, endMinute] = customSlot.endTime
                    .split(':')
                    .map((p: string) => parseInt(p, 10));
                const startDate = day.set({
                    hour: startHour,
                    minute: startMinute
                });
                let endDate = day.set({
                    hour: endHour,
                    minute: endMinute
                });

                if (endHour < startHour || (endHour === startHour && endMinute <= startMinute)) {
                    endDate = endDate.plus({ day: 1 });
                }

                return {
                    name: customSlot.name,
                    interval: Interval.fromDateTimes(startDate, endDate)
                };
            });
        })
        .filter((slot) => {
            if (daysDisplay === RegisterDaysDisplay.AllDays) {
                return true;
            } else {
                return ranges.some((range) => range.overlaps(slot.interval));
            }
        });
}

export function intervalFinalName(name: Emptyable<string>, interval: Interval): string {
    return isNonEmptyString(name)
        ? `${name} (${interval.toFormat('HH:mm')})`
        : interval.toFormat('HH:mm');
}

export function addRemoveSlot(
    currentSlots: VolunteersRegistrationsSlotInput[],
    interval: Interval,
    shouldAdd: boolean
): VolunteersRegistrationsSlotInput[] {
    const currentSlotsWithout = currentSlots.filter((slot) => !interval.equals(toInterval(slot)));

    if (currentSlotsWithout.length === currentSlots.length && shouldAdd) {
        return [...currentSlots, fromInterval(interval)];
    } else if (currentSlotsWithout.length !== currentSlots.length && !shouldAdd) {
        return currentSlotsWithout;
    } else {
        return currentSlots;
    }
}

export function getCheckInStateColor(state: CheckInState): 'success' | 'warning' {
    switch (state) {
        case CheckInState.CheckedIn:
        case CheckInState.CheckedOut:
            return 'success';
        case CheckInState.Waiting:
            return 'warning';
    }
}
