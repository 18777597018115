import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useHistory, useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { getEmbedPath, VolunteersPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useEventPageContext } from '../common/eventPageContext';
import { Registration, Registrations } from './registration';

interface INamesSelectorProps {
    registrations: Registrations;
}

export const NamesSelector = (props: INamesSelectorProps) => {
    const translate = useTranslate();
    const history = useHistory();
    const { organizationId, eventId, volunteerRegistrationId } = useParams();
    const { isEmbed } = useEventPageContext();

    return props.registrations.length > 1 ? (
        <Flex
            align="center"
            css={{
                background: '$oldEventStepBackground',
                borderRadius: '3px',
                boxShadow: '$old',
                height: '72px',
                marginBottom: '$7',
                paddingLeft: '$6',
                paddingRight: '$6',
                width: '100%'
            }}
        >
            {props.registrations.map((registration: Registration, index: number) => {
                const isSelected = volunteerRegistrationId === registration.id;

                return (
                    <Box
                        key={registration.id}
                        css={{
                            color: isSelected ? '$oldBlue' : 'inherit',
                            cursor: 'pointer',
                            fontWeight: '$oldBold',
                            marginRight: '$7',
                            '&:last-child': {
                                marginRight: '0'
                            }
                        }}
                        onClick={() => {
                            history.push(
                                getEmbedPath(
                                    isEmbed,
                                    VolunteersPaths.ASSIGNMENTS_REGISTRATION(
                                        organizationId,
                                        eventId,
                                        registration.id
                                    )
                                )
                            );
                        }}
                    >
                        {registration.userInfo.name || translate('inscription_n_57551', index + 1)}
                    </Box>
                );
            })}
        </Flex>
    ) : null;
};
