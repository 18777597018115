import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

interface IVolunteersBoxProps {
    className?: string;
    header?: React.ReactNode;
    headerCss?: CSS;
    children?: React.ReactNode;
    contentCss?: CSS;
    showPoweredBy?: boolean;

    headerClick?(): void;
}

export const VolunteersBox = (props: IVolunteersBoxProps) => {
    const hasContent = !!props.children;

    return (
        <Box
            css={{
                background: '$oldEventStepBackground',
                borderRadius: '3px',
                boxShadow: '$old',
                position: 'relative',
                width: '100%'
            }}
        >
            {props.header && (
                <Flex
                    align="center"
                    height={70}
                    width={1}
                    css={{
                        borderBottom: hasContent ? '1px solid $oldSolitude' : '',
                        padding: '$6',
                        ...(props.headerCss as any)
                    }}
                    onClick={() => {
                        if (props.headerClick) {
                            props.headerClick();
                        }
                    }}
                >
                    {props.header}
                </Flex>
            )}

            {hasContent && (
                <Box
                    css={{
                        padding: '$6',
                        ...(props.contentCss as any)
                    }}
                >
                    {props.children}
                </Box>
            )}
        </Box>
    );
};
