import { Box } from 'common/src/designSystem/components/box';
import { useLocation } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Link } from '../../../util/link';

interface ITabProps {
    basePath?: string;
    children: React.ReactNode;
    path: string;
}

export const Tab = (props: ITabProps) => {
    const location = useLocation();
    const isSelected = location.pathname.includes(props.basePath || props.path);

    return (
        <>
            <Link to={props.path}>
                <Box
                    color={isSelected ? 'primary700' : 'gray500'}
                    css={{
                        borderBottom: isSelected ? '2px solid $primary700' : 'unset',
                        paddingBlock: '$3',
                        '&:hover': {
                            color: isSelected ? '$primary800' : '$gray800'
                        }
                    }}
                >
                    {props.children}
                </Box>
            </Link>

            <Box width={24} />
        </>
    );
};
