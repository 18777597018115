import {
    EventRegisterSlotsPositionsFragment,
    RegisterDaysDisplay,
    VolunteersRegistrationInput
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { fromInterval, LocaleFormats, toInterval } from 'common/src/util/luxon';
import { range as lodashRange } from 'lodash-es';
import { Interval } from 'luxon';
import * as React from 'react';
import { OnChange } from 'react-final-form-listeners';
import { Checkbox } from '../reactFinalForm/input/checkbox';

interface IVolunteerRegistrationCustomSlotsProps {
    event: EventRegisterSlotsPositionsFragment;
    values: VolunteersRegistrationInput;

    change(name: string, value: any): void;
}

export const VolunteerRegistrationCustomSlots = (props: IVolunteerRegistrationCustomSlotsProps) => {
    const dateTimeService = useService(DateTimeService);
    const intervals = React.useMemo(() => {
        const numberOfDays = props.event.endAt.diff(props.event.startAt, 'days').days;

        return lodashRange(0, numberOfDays + 1)
            .flatMap((i) => {
                const day = props.event.startAt.plus({ days: i });

                return props.event.customSlots.map((customSlot) => {
                    const [startHour, startMinute] = customSlot.startTime
                        .split(':')
                        .map((p: string) => parseInt(p, 10));
                    const [endHour, endMinute] = customSlot.endTime
                        .split(':')
                        .map((p: string) => parseInt(p, 10));
                    const startDate = day.set({
                        hour: startHour,
                        minute: startMinute
                    });
                    let endDate = day.set({
                        hour: endHour,
                        minute: endMinute
                    });

                    if (endHour < startHour || (endHour === startHour && endMinute < startMinute)) {
                        endDate = endDate.plus({ day: 1 });
                    }

                    return {
                        name: customSlot.name,
                        interval: Interval.fromDateTimes(startDate, endDate)
                    };
                });
            })
            .filter((slot) => {
                if (props.event.daysDisplay === RegisterDaysDisplay.AllDays) {
                    return true;
                } else {
                    return props.event.ranges.some((range) => range.overlaps(slot.interval));
                }
            });
    }, [props.event.daysDisplay]);
    const addRemoveSlot = (interval: Interval) => (value: boolean) => {
            const currentSlotsWithout = props.values.slots.filter((slot) => !interval.equals(toInterval(slot)));

            if (currentSlotsWithout.length === props.values.slots.length && value) {
                props.change('volunteerRegistration.slots', [
                    ...props.values.slots,
                    fromInterval(interval)
                ]);
            } else if (currentSlotsWithout.length !== props.values.slots.length && !value) {
                props.change('volunteerRegistration.slots', currentSlotsWithout);
            }
        };

    return (
        <>
            {intervals.map(({ name, interval }, index) => {
                const displayName = `${dateTimeService.toLocaleString(
                    interval.start!,
                    LocaleFormats.DateOnly.WeekdayLongMonthLong
                )} ${name} (${interval.toFormat('HH:mm')})`;

                const initialValue = props.values.slots.some((slot) =>
                    interval.equals(toInterval(slot))
                );

                return (
                    <React.Fragment key={index}>
                        <OnChange name={`interval${index}`}>{addRemoveSlot(interval)}</OnChange>

                        <Checkbox
                            initialValue={initialValue}
                            name={`interval${index}`}
                            text={displayName}
                        />
                    </React.Fragment>
                );
            })}
        </>
    );
};
