import { pick } from 'lodash-es';
import { MapLayer } from '../generated/types';
import { Emptyable } from './emptyable';
import { isValidNumber } from './number';
import { isNonEmptyString } from './string';

export interface ICoordinates {
    latitude: number;
    longitude: number;
}

export interface IGoogleCoordinates {
    lat: number;
    lng: number;
}

export function toGoogleCoordinates(coordinates?: ICoordinates): IGoogleCoordinates | null {
    if (coordinates) {
        return {
            lat: coordinates.latitude,
            lng: coordinates.longitude
        };
    } else {
        return null;
    }
}

export const DEFAULT_CENTER = toGoogleCoordinates({
    latitude: 43.6087733,
    longitude: 3.87963579999996
})!;

export const DEFAULT_ZOOM = 13;

export function extractCoordinates<
    T extends {
        latitude?: number | null;
        longitude?: number | null;
    }
>(obj: T): ICoordinates | undefined {
    if (isValidNumber(obj.latitude) && isValidNumber(obj.longitude)) {
        return pick(obj, ['latitude', 'longitude']) as ICoordinates;
    } else {
        return undefined;
    }
}

export function getMapTypeId(layer?: MapLayer | null) {
    return layer === MapLayer.GoogleSatellite
        ? 'satellite'
        : layer === MapLayer.Google
        ? 'roadmap'
        : layer ?? MapLayer.Google;
}

export function googleCoordinatesFromString(str: Emptyable<string>): IGoogleCoordinates | null {
    if (isNonEmptyString(str)) {
        const [latStr, lngStr] = str.split(',');

        if (isNonEmptyString(latStr) && isNonEmptyString(lngStr)) {
            const lat = parseFloat(latStr);
            const lng = parseFloat(lngStr);

            if (isNaN(lat) || isNaN(lng)) {
                return null;
            } else {
                return { lat, lng };
            }
        } else {
            return null;
        }
    } else {
        return null;
    }
}
