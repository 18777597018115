import { Box } from 'common/src/designSystem/components/box';
import { CSS } from 'common/src/designSystem/components/stitches';
import { useHistory } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useBodyClick } from '../../hooks/useBodyClick';

export interface IItem {
    borderBottom?: boolean;
    borderTop?: boolean;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    isRed?: boolean;
    link?: string;
    title?: string;
    content?: React.ReactNode;
}

interface IDropdownMenuProps {
    css?: CSS;
    isOpen: boolean;
    items: IItem[];

    close(): void;
}

export const DropdownMenu = (props: IDropdownMenuProps) => {
    const history = useHistory();
    useBodyClick(() => {
        props.close();
    });
    const items = props.items.map((item: IItem, index: number) => (
            <React.Fragment key={index}>
                {item.borderTop && (
                    <Box
                        css={{
                            borderTop: '1px solid $oldGrayBorder',
                            marginBottom: '$2',
                            marginTop: '$2',
                            width: '100%'
                        }}
                    />
                )}

                <Box
                    css={{
                        color: item.isRed ? '$oldRed' : 'inherit',
                        cursor: 'pointer',
                        ellipsis: true,
                        padding: '$2 $7',
                        userSelect: 'none',
                        width: '100%'
                    }}
                    onClick={(e) => {
                        if (isNonEmptyString(item.link)) {
                            history.push(item.link);
                        } else if (typeof item.onClick === 'function') {
                            item.onClick(e);
                        }

                        props.close();
                    }}
                >
                    {item.content ? item.content : item.title}
                </Box>

                {item.borderBottom && (
                    <Box
                        css={{
                            borderTop: '1px solid $oldGrayBorder',
                            marginBottom: '$2',
                            marginTop: '$2',
                            width: '100%'
                        }}
                    />
                )}
            </React.Fragment>
        ));

    return (
        <Box
            css={{
                background: 'white',
                borderRadius: '4px',
                boxShadow: '0 2px 4px 0 rgba(48, 48, 77, 0.25), 0 4px 6px 0 rgba(48, 48, 77, 0.15)',
                display: props.isOpen ? 'block' : 'none',
                padding: '$4 0',
                position: 'absolute',
                width: '250px',
                zIndex: '1000',
                ...(props.css as any)
            }}
            onClick={(e) => {
                e.nativeEvent.stopImmediatePropagation();
            }}
        >
            {items}
        </Box>
    );
};
