import { isNonEmptyArray } from 'common/src/util/array';
import { IUseMutationOptions, IUseMutationReturns } from 'common/src/util/graphql/types';
import * as React from 'react';
import { getToken } from '../../util/aws/cognito';
import { ErrorBoundaryContext } from '../errorBoundary/errorBoundaryContext';
import { Loader } from '../loader/loader';
import { executeQuery } from './graphql';

const loader = <Loader />;

const executeMutation = <TParams extends Record<string, any>, TResult>(
    query: string,
    variables: TParams,
    token?: string
): Promise<TResult> => executeQuery(query, {
        variables,
        token: token || undefined
    });

export function useMutationImpl<TResult, TParams extends Record<string, any>>(
    query: string,
    { redirectOnSuccess }: IUseMutationOptions = {}
): IUseMutationReturns<TResult, TParams> {
    const [isLoading, setIsLoading] = React.useState(false);
    const { setIsError } = React.useContext(ErrorBoundaryContext);
    const mutate = async (variables: TParams, token?: string): Promise<TResult> => {
        setIsLoading(true);

        try {
            const currentToken = await getToken();
            const data = await executeMutation<TParams, TResult>(
                query,
                variables,
                token || currentToken
            );

            if (redirectOnSuccess !== true) {
                setIsLoading(false);
                setIsError(false);
            }

            return data;
        } catch (errors) {
            setIsError(true, isNonEmptyArray(errors) ? errors : []);

            throw errors;
        }
    };

    let _loader: any = null;

    if (isLoading) {
        _loader = loader;
    }

    return { isLoading, loader: _loader, mutate };
}
