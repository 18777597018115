import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { isInvalid } from '../isInvalid';
import { fieldSubscriptions } from '../subscriptions';

interface ISearchComponentProps extends FieldRenderProps<string, HTMLInputElement> {
    placeholder: string;

    onEnter?(): void;
}

const SearchComponent = (props: ISearchComponentProps) => {
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const invalid = isInvalid(props.meta);

    return (
        <Box
            width={1}
            css={{
                '& input': {
                    background: 'transparent',
                    border: 'none',
                    height: '100%',
                    outline: 'none',
                    width: '100%'
                }
            }}
        >
            <Flex
                css={{
                    background: '$oldGrayLight',
                    border: invalid ? '1px solid $oldRed' : 'none',
                    borderRadius: '$1',
                    color: '$oldBlackLight',
                    height: '40px',
                    marginBottom: invalid ? '$2' : '$6',
                    width: '100%'
                }}
            >
                <Flex
                    align="center"
                    justify="center"
                    css={{
                        color: '$oldGrayIcon',
                        cursor: 'text',
                        height: '100%',
                        width: '40px'
                    }}
                    onClick={() => {
                        inputRef.current?.focus();
                    }}
                >
                    <i className="far fa-search" />
                </Flex>

                <input
                    {...props.input}
                    placeholder={props.placeholder}
                    ref={inputRef}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && props.onEnter) {
                            props.onEnter();
                        }
                    }}
                />
            </Flex>

            {invalid && (
                <Box
                    css={{
                        color: '$oldRed',
                        marginBottom: '$6'
                    }}
                >
                    {props.meta.error}
                </Box>
            )}
        </Box>
    );
};

interface ISearchInputProps {
    name: string;
    placeholder: string;

    onEnter?(): void;
}

export const SearchInput = (props: ISearchInputProps) => (
        <Field {...props} component={SearchComponent as any} subscription={fieldSubscriptions} />
    );
