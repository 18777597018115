import { Box } from 'common/src/designSystem/components/box';
import * as React from 'react';

interface IDescriptionProps {
    children?: React.ReactNode;
    id?: string;
}

export const Description = ({ children, id }: IDescriptionProps) => {
    if (children) {
        return (
            <Box id={id} color="gray500" fontSize="textXs">
                {children}
            </Box>
        );
    } else {
        return null;
    }
};
