import {
    CustomFieldWithConditionFragment,
    EventsField,
    FieldProperty
} from 'common/src/generated/types';
import { shouldDisplay, UserInfoFields } from 'common/src/vo/field';
import * as React from 'react';
import { AddressPlaceChange } from '../../reactFinalForm/addressPlaceChange';
import { Field } from './field';

interface IUserFormProps {
    fieldsToDisplay: Array<
        Pick<EventsField, 'isMandatory'> & { field: CustomFieldWithConditionFragment }
    >;
    customFields: CustomFieldWithConditionFragment[];
    prefix: string;
    values: UserInfoFields;

    change(name: string, value: any): void;
}

export const UserForm = (props: IUserFormProps) => (
        <>
            {props.fieldsToDisplay.map((eventField) => {
                const field = eventField.field;

                return (
                    <React.Fragment key={field.id}>
                        {field.fieldProperty === FieldProperty.Street && (
                            <AddressPlaceChange
                                name="place"
                                prefix={props.prefix}
                                changeCity={true}
                                changePostalCode={true}
                                changeCountry={true}
                                change={props.change}
                            />
                        )}

                        {shouldDisplay(field, props.values, props.customFields) && (
                            <Field eventField={eventField} prefix={props.prefix} />
                        )}
                    </React.Fragment>
                );
            })}
        </>
    );
