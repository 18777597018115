import { isNonEmptyArray } from 'common/src/util/array';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Field } from 'react-final-form';
import { isInvalid } from '../../components/reactFinalForm/isInvalid';
import { BaseInputProps } from '../components/input/baseInputProps';
import {
    BaseRichSelectProps,
    RichSelect as RichSelectComponent
} from '../components/richSelect/richSelect';

type IRichSelectProps = {
    name: string;
} & BaseRichSelectProps &
    BaseInputProps;

export const RichSelect = (props: IRichSelectProps) => (
        <Field
            name={props.name}
            render={(fieldProps) => {
                const invalid = isInvalid(fieldProps.meta);

                return (
                    <RichSelectComponent
                        {...props}
                        hint={invalid ? fieldProps.meta.error : props.hint}
                        state={invalid ? 'error' : props.state}
                        values={
                            props.multiple
                                ? Array.isArray(fieldProps.input.value)
                                    ? fieldProps.input.value
                                    : []
                                : typeof fieldProps.input.value === 'number' ||
                                  isNonEmptyString(fieldProps.input.value)
                                ? [fieldProps.input.value]
                                : []
                        }
                        onChange={(values) => {
                            if (props.multiple) {
                                fieldProps.input.onChange(values);
                            } else if (isNonEmptyArray(values)) {
                                fieldProps.input.onChange(values[0]);
                            } else {
                                fieldProps.input.onChange(null);
                            }
                        }}
                    />
                );
            }}
        />
    );
