import { CSS } from 'common/src/designSystem/components/stitches';
import { Row } from 'common/src/designSystem/components/table/row';
import * as React from 'react';

interface IPaginationRowProps {
    children: React.ReactNode;
    css?: CSS;
}

export const PaginationRow = (props: IPaginationRowProps) => <Row css={{ height: '56px', ...(props.css as any) }}>{props.children}</Row>;
