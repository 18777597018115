import { Flex } from 'common/src/designSystem/components/flex';
import { CustomFieldWithConditionFragment } from 'common/src/generated/types';
import { UserInfo } from 'common/src/vo/field';
import * as React from 'react';
import { Field } from './field';
import { FieldName } from './fieldName';

interface IUserFieldProps {
    field: Pick<CustomFieldWithConditionFragment, 'name' | 'fieldType' | 'slug' | 'values'>;
    userInfo: UserInfo;
}

export const UserField = (props: IUserFieldProps) => (
        <Flex direction="column" gap="1">
            <FieldName>{props.field.name}</FieldName>
            <Field field={props.field} userInfo={props.userInfo} />
        </Flex>
    );
