import { Flex } from 'common/src/designSystem/components/flex';
import { Interval } from 'luxon';
import * as React from 'react';
import { CheckboxText } from '../../../designSystem/components/checkbox';

interface IFormSlotsCustomDaySingleProps {
    hideNameDates: boolean;
    intervalsGrouped: Array<[string, Array<{ name: string; interval: Interval }>]>;

    isSelected(inteval: Interval): boolean;
    setSelected(interval: Interval, isSelected: boolean): void;
}

export const FormSlotsCustomDaySingle = (props: IFormSlotsCustomDaySingleProps) => (
        <Flex
            direction="column"
            gap="3"
            css={{
                border: '1px solid $gray200',
                borderRadius: '$1',
                boxShadow: '$xs',
                padding: '$4 $5'
            }}
        >
            {props.intervalsGrouped.map(([day, [{ name, interval }]], index) => {
                const nameDates = `${name} ${interval.toFormat('HH:mm')}`.trim();
                const text = `${day} ${props.hideNameDates ? '' : `(${nameDates})`}`.trim();

                return (
                    <CheckboxText
                        key={index}
                        state={props.isSelected(interval) ? 'checked' : 'unchecked'}
                        onClick={(newState) => {
                            props.setSelected(interval, newState === 'checked');
                        }}
                    >
                        {text}
                    </CheckboxText>
                );
            })}
        </Flex>
    );
