import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { CSS, styled, theme } from 'common/src/designSystem/components/stitches';
import { isNonEmptyArray } from 'common/src/util/array';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { DropdownMenu, IItem } from '../dropdownMenu/dropdownMenu';
import { LoaderSvg } from '../loader/loader';

const _Button = styled('div', {
    alignItems: 'center',
    borderRadius: '3px',
    color: 'white',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '13px',
    fontWeight: '$oldBold',
    height: '40px',
    justifyContent: 'center',
    padding: '0 $4',
    variants: {
        color: {
            blue: {
                background: '$oldBlue',
                '&:hover': {
                    background: '$oldBlueDark'
                },
                '& .h-chevron': {
                    borderLeft: '1px solid $oldBlueDark'
                },
                '&:hover .h-chevron': {
                    borderLeft: '1px solid $oldBlueDarkDark'
                }
            },
            gray: {
                background: '$oldGrayLight',
                color: '$oldBlackLight',
                fontWeight: '$oldBold',
                '&:hover': {
                    background: '$oldSolitude'
                }
            },
            transparent: {
                background: 'white',
                border: '1px solid $oldGrayBorder',
                color: '$oldGrayDark',
                fontWeight: '$oldBold',
                '&:hover': {
                    border: '1px solid $oldBlue',
                    color: '$oldBlue'
                },
                '& .h-chevron': {
                    borderLeft: '1px solid $oldGrayBorder'
                },
                '&:hover .h-chevron': {
                    borderLeft: '1px solid $oldBlue'
                }
            },
            inactive: {
                background: '$oldGrayLight',
                color: '#a5adba',
                cursor: 'default'
            }
        }
    },
    defaultVariants: {
        color: 'blue'
    }
});

export enum Color {
    Blue,
    Gray,
    Transparent
}

interface IButtonProps {
    children: React.ReactNode;
    css?: CSS;
    color?: Color;
    isInactive?: boolean;
    isLoading?: boolean;
    logoClassName?: string;
    items?: IItem[];

    onClick?(event?: any): void;
}

enum LoadingState {
    NotLoading,
    Loading,
    Check
}

interface IButtonState {
    loading: LoadingState;
    isOpen: boolean;
}

export class Button extends React.Component<IButtonProps, IButtonState> {
    private timer: any | null = null;

    constructor(props: IButtonProps) {
        super(props);

        this.state = {
            loading: props.isLoading === true ? LoadingState.Loading : LoadingState.NotLoading,
            isOpen: false
        };
    }

    componentDidUpdate(previousProps: IButtonProps) {
        if (this.props.isLoading === false && previousProps.isLoading === true) {
            this.setState({
                loading: LoadingState.Check
            });

            this.timer = setTimeout(() => {
                this.setState({
                    loading: LoadingState.NotLoading
                });
            }, 1000);
        } else if (this.props.isLoading === true && previousProps.isLoading === false) {
            this.setState({
                loading: LoadingState.Loading
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        const color = this.props.color || Color.Blue;
        const hasDropdownMenu = isNonEmptyArray(this.props.items);

        let children: React.ReactNode | null = null;

        if (this.state.loading === LoadingState.NotLoading) {
            children = (
                <>
                    {isNonEmptyString(this.props.logoClassName) && (
                        <Box css={{ marginRight: '10px' }}>
                            <i className={this.props.logoClassName} />
                        </Box>
                    )}

                    <div>{this.props.children}</div>

                    {hasDropdownMenu && (
                        <Flex
                            className="h-chevron"
                            align="center"
                            justify="end"
                            height={1}
                            width={24}
                            css={{
                                marginLeft: '$4'
                            }}
                        >
                            <i className={`far fa-chevron-${this.state.isOpen ? 'up' : 'down'}`} />
                        </Flex>
                    )}
                </>
            );
        } else if (this.state.loading === LoadingState.Loading) {
            children = (
                <LoaderSvg
                    color={color === Color.Blue ? 'white' : theme.colors.oldGrayDark.value}
                    height="50px"
                    strokeWidth="3"
                    width="50px"
                />
            );
        } else if (this.state.loading === LoadingState.Check) {
            children = (
                <Box css={{ '& > i': { fontSize: '18px' } }}>
                    <i className="fas fa-check" />
                </Box>
            );
        }

        return (
            <Box css={{ userSelect: 'none' }}>
                <_Button
                    color={
                        this.props.isInactive
                            ? 'inactive'
                            : color === Color.Gray
                            ? 'gray'
                            : color === Color.Transparent
                            ? 'transparent'
                            : 'blue'
                    }
                    css={this.props.css}
                    onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        event.nativeEvent.stopImmediatePropagation();

                        if (this.state.loading === LoadingState.NotLoading) {
                            if (this.props.isInactive !== true) {
                                if (hasDropdownMenu) {
                                    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
                                } else if (this.props.onClick) {
                                    this.props.onClick(event);
                                }
                            }
                        }
                    }}
                >
                    {children}
                </_Button>

                {hasDropdownMenu && (
                    <DropdownMenu
                        css={{
                            right: '0',
                            top: '44px'
                        }}
                        isOpen={this.state.isOpen}
                        items={this.props.items!}
                        close={() => {
                            this.setState(() => ({ isOpen: false }));
                        }}
                    />
                )}
            </Box>
        );
    }
}

export const FlexedButton = (props: IButtonProps) => (
        <Flex>
            <Button {...props} />
        </Flex>
    );
