import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { UpdateUserForm } from 'common-front/src/users/update/updateUserForm';
import { useReferrerContext } from 'common-front/src/util/referrerContext';
import { MembersPaths } from 'common/src/util/membersPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useUpdateMemberInfosQuery } from '../../../generated/graphqlHooks';

export const UpdateMemberLoader = () => {
    const {
        history,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const { getReferrerPath } = useReferrerContext();
    const { data, loader } = useUpdateMemberInfosQuery({ organizationId, eventId, userInfoId });
    const forms = React.useMemo(() => (data.userInfo?.formsUsersInfos ?? []).map(({ form }) => form), [data.userInfo?.formsUsersInfos]);

    if (loader) {
        return loader;
    } else {
        return (
            <Switch>
                <Route
                    path={MembersPaths.MEMBER_REGISTRATION_EDIT_FORM(
                        ':organizationId',
                        ':userInfoId',
                        ':eventId',
                        ':formId'
                    )}
                    children={
                        <UpdateUserForm
                            closePath={MembersPaths.MEMBER_REGISTRATION_INFORMATION(
                                organizationId,
                                userInfoId,
                                eventId
                            )}
                            customFields={[]}
                            country={data.event.country}
                            eventId={eventId}
                            formIdToInsertedAt={{}}
                            formIdToTeamCode={{}}
                            forms={forms}
                            organizationId={organizationId}
                            organization={data.organization}
                            origin="members-space"
                            showAllAndPrivate={false}
                            showIsFilled={false}
                            userInfo={data.userInfo}
                            userInfoId={userInfoId}
                            getEditUserFormPath={(formId) => MembersPaths.MEMBER_REGISTRATION_EDIT_FORM(
                                    organizationId,
                                    userInfoId,
                                    eventId,
                                    formId
                                )}
                            onSuccess={() => {
                                history.push(
                                    getReferrerPath() ??
                                        MembersPaths.MEMBER_REGISTRATION_INFORMATION(
                                            organizationId,
                                            userInfoId,
                                            eventId
                                        )
                                );
                            }}
                        />
                    }
                />

                <Route
                    children={
                        <Empty
                            path={MembersPaths.MEMBER_REGISTRATION_EDIT_FORM(
                                organizationId,
                                userInfoId,
                                eventId,
                                data.userInfo.formsUsersInfos[0].form.id
                            )}
                            replace={true}
                        />
                    }
                />
            </Switch>
        );
    }
};
