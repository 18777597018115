import { pick } from 'lodash-es';
import * as React from 'react';
import { Flex, IFlexProps } from '../flex';
import { CSS } from '../stitches';

interface ICellProps extends Pick<IFlexProps, 'direction' | 'align' | 'justify' | 'wrap' | 'gap'> {
    css?: CSS;
    children?: React.ReactNode;
    width?: number;

    onClick?(e: React.MouseEvent<HTMLDivElement>): void;
}

export const Cell = React.forwardRef((props: ICellProps, ref: React.Ref<HTMLDivElement>) => (
        <Flex
            ref={ref}
            className="table-cell"
            align="center"
            height={1}
            css={{
                color: '$gray800',
                flex: props.width ? `0 ${props.width}px` : '1',
                overflow: 'hidden',
                px: '$6',
                ...(props.css as any)
            }}
            onClick={(e) => {
                if (props.onClick) {
                    props.onClick(e);
                }
            }}
            {...pick(props, ['direction', 'align', 'justify', 'wrap', 'gap'])}
        >
            {props.children}
        </Flex>
    ));
