import { Box } from 'common/src/designSystem/components/box';
import * as React from 'react';

export enum SeparatorColor {
    Gray,
    Solitude
}

interface ISeparatorProps {
    color?: SeparatorColor;
    margin?: number;
    marginBottom?: number;
    marginTop?: number;
}

export const Separator = (props: ISeparatorProps) => {
    const marginBottom = props.marginBottom ?? props.margin ?? 0;
    const marginTop = props.marginTop ?? props.margin ?? 0;

    return (
        <Box
            css={{
                borderBottom:
                    props.color === SeparatorColor.Gray
                        ? '1px solid $oldGrayBorder'
                        : '1px solid $oldSolitude',
                height: '1px',
                width: '100%'
            }}
            style={{
                marginBottom: `${marginBottom}px`,
                marginTop: `${marginTop}px`
            }}
        />
    );
};
