import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { fileInfos } from 'common/src/util/file';
import * as React from 'react';
import { Accept, acceptExtensions, acceptString } from '../../../util/accept';

interface IFileUploaderProps {
    accept?: Accept | Accept[];

    onChange(file: File): void;
}

export const FileUploader = (props: IFileUploaderProps) => {
    const translate = useTranslate();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [isDraggingOver, setIsDraggingOver] = React.useState(false);
    const extensions = acceptExtensions(props.accept || []);

    return (
        <Flex
            direction="column"
            align="center"
            width={1}
            css={{
                background: isDraggingOver ? '$primary50' : '$gray50',
                border: isDraggingOver ? '1px solid $primary300' : '1px solid $gray200',
                borderRadius: '$2',
                cursor: 'pointer',
                padding: '$4'
            }}
            onDragEnter={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setIsDraggingOver(true);
            }}
            onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (!isDraggingOver) {
                    setIsDraggingOver(true);
                }
            }}
            onDragLeave={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setIsDraggingOver(false);
            }}
            onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setIsDraggingOver(false);

                const file = e.dataTransfer.files.item(0);

                if (
                    file &&
                    (extensions.length === 0 || extensions.includes(fileInfos(file.name).extension))
                ) {
                    props.onChange(file);
                }
            }}
            onClick={() => {
                inputRef.current?.click();
            }}
        >
            <Flex
                align="center"
                justify="center"
                height={36}
                width={36}
                css={{
                    background: isDraggingOver ? '$primary100' : '$gray100',
                    borderRadius: '36px',
                    color: isDraggingOver ? '$primary600' : '$gray600'
                }}
            >
                <I icon="cloud-arrow-up" />
            </Flex>

            <Spacer height="3" />

            <Flex gap="1">
                <Box color="primary700" fontWeight="regular">
                    {translate('cliquez_pour_s_77962')}
                </Box>
                <Box color={isDraggingOver ? 'primary600' : 'gray500'}>
                    {translate('ou_glissez_d_po_61590')}
                </Box>
            </Flex>

            {extensions.length > 0 && (
                <>
                    <Spacer height="1" />

                    <Box
                        color={isDraggingOver ? 'primary600' : 'gray500'}
                        css={{ textTransform: 'uppercase' }}
                    >
                        {extensions.join(', ')}
                    </Box>
                </>
            )}

            <Box css={{ display: 'none' }}>
                <input
                    type="file"
                    accept={props.accept ? acceptString(props.accept) : undefined}
                    ref={inputRef}
                    onChange={(e) => {
                        if (e.target.files?.[0]) {
                            props.onChange(e.target.files[0]);
                        }
                    }}
                />
            </Box>
        </Flex>
    );
};
