import {
    FormMissionsOptionsFragment,
    FormMissionsQuery,
    PositionId,
    PositionsCategoryId,
    RegisterPositionDisplay,
    VolunteersRegistrationsSlotInput
} from 'common/src/generated/types';
import { UserInfoFields } from 'common/src/vo/field';
import { range } from 'lodash-es';
import * as React from 'react';
import { FormMissionsRankedCategories } from './formMissionsRankedCategories';
import { FormMissionsRankedPositions } from './formMissionsRankedPositions';

interface IFormMissionsRankedProps {
    categories: FormMissionsQuery['event']['positionsCategories'];
    options: FormMissionsOptionsFragment;
    positionsCategoriesIds: PositionsCategoryId[];
    positionsIds: PositionId[];
    prefix: string;
    slots: VolunteersRegistrationsSlotInput[];
    userInfoFields: UserInfoFields;

    change(name: string, value: any): void;
}

export const FormMissionsRanked = (props: IFormMissionsRankedProps) => (
        <>
            {range(0, props.options.positionLimit).map((i) => props.options.positionDisplay === RegisterPositionDisplay.Category ? (
                    <FormMissionsRankedCategories
                        key={i}
                        categories={props.categories}
                        index={i}
                        options={props.options}
                        positionsCategoriesIds={props.positionsCategoriesIds}
                        prefix={props.prefix}
                        change={props.change}
                    />
                ) : (
                    <FormMissionsRankedPositions
                        key={i}
                        categories={props.categories}
                        index={i}
                        options={props.options}
                        positionsIds={props.positionsIds}
                        prefix={props.prefix}
                        slots={props.slots}
                        userInfoFields={props.userInfoFields}
                        change={props.change}
                    />
                ))}
        </>
    );
