import { useVolunteersParamsLocalStorage } from 'common-front/src/hooks/useVolunteersParamsLocalStorage';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { EventPage } from '../common/eventPage';
import { useRegisterInfosQuery } from '../generated/graphqlHooks';
import { RegisterForm } from './registerForm';

interface IRegisterProps {
    isEmbed: boolean;
}

export const Register = (props: IRegisterProps) => {
    const { eventId } = useParams();
    const { data, loader } = useRegisterInfosQuery({ eventId });
    useVolunteersParamsLocalStorage();

    React.useEffect(() => {
        if (data.event) {
            data.event.positionsCategories = sortBy(
                data.event.positionsCategories,
                (category) => category.name
            );

            data.event.positionsCategories.forEach((category) => {
                category.positions = sortBy(category.positions, (position) => position.name);
            });
        }
    }, [data.event]);

    return (
        loader || (
            <EventPage event={data.event} isEmbed={props.isEmbed}>
                <RegisterForm event={data.event} />
            </EventPage>
        )
    );
};
