import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Grid } from 'common/src/designSystem/components/grid';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import { useUniqueIds } from '../../hooks/useUniqueIds';
import { BaseInputProps } from './input/baseInputProps';
import { Description } from './input/description';
import { Hint } from './input/hint';
import { Label } from './input/label';
import { StyledInputContainer } from './input/styledInputContainer';

interface ITeaxtareaProps extends BaseInputProps {
    value: string;

    onChange(value: string): void;
}

export const Textarea = (props: ITeaxtareaProps) => {
    const { inputId, descId, errorId } = useUniqueIds();

    return (
        <Flex css={props.css} direction="column" width={1}>
            <Label htmlFor={inputId}>{props.label}</Label>

            <Description id={descId}>{props.description}</Description>

            {(props.label || props.description) && <Spacer height="1" />}

            <StyledInputContainer
                cursor="text"
                height="fluid"
                state={props.state}
                icon={props.icon}
                rightIcon={props.rightIcon}
            >
                <Grid
                    css={{
                        flex: '1',
                        minHeight: '100px',
                        py: '$3',
                        '& .replicated-text': {
                            whiteSpace: 'pre-wrap',
                            visibility: 'hidden'
                        },
                        '& textarea': {
                            outline: 'none',
                            overflow: 'hidden',
                            resize: 'none'
                        },
                        '& .replicated-text, & textarea': {
                            background: 'transparent',
                            border: 'none',
                            gridArea: '1 / 1 / 2 / 2',
                            width: '100%'
                        },
                        '& textarea::placeholder': {
                            color: '$gray500',
                            opacity: 1
                        },
                        '& input::-ms-input-placeholder': {
                            color: '$gray500',
                            opacity: 1
                        }
                    }}
                >
                    <textarea
                        id={inputId}
                        aria-describedby={props.description ? descId : undefined}
                        aria-invalid={props.state === 'error'}
                        aria-errormessage={props.state === 'error' ? errorId : undefined}
                        placeholder={props.placeholder}
                        disabled={props.state === 'disabled'}
                        value={props.value}
                        onChange={(e) => {
                            props.onChange(e.target.value);
                        }}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                    />

                    <Box className="replicated-text">{props.value} </Box>
                </Grid>
            </StyledInputContainer>

            <Hint id={errorId} state={props.state}>
                {props.hint}
            </Hint>
        </Flex>
    );
};
