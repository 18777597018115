import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Button } from '../../../designSystem/components/button';
import { SlotCard } from '../../../designSystem/components/slotCard';
import { DateInput } from '../../../designSystem/form/date/dateInput';
import { TimeInput } from '../../../designSystem/form/date/timeInput';

interface IFormSlotsSlotProps {
    index: number;
    min: DateTime;
    max: DateTime;
    prefix: string;

    remove(): void;
}

export const FormSlotsSlot = (props: IFormSlotsSlotProps) => {
    const translate = useTranslate();

    return (
        <SlotCard
            buttons={
                <>
                    <Box>
                        <Button
                            color="white"
                            size="sm"
                            leftIcon="trash-can"
                            onClick={props.remove}
                        />
                    </Box>
                </>
            }
            title={translate('disponibilit_n_50484', props.index + 1)}
        >
            <Flex direction={{ '@initial': 'column', '@desktop': 'row' }} gap="4">
                <DateInput
                    label={translate('date_de_d_but_43380')}
                    name={`${props.prefix}.startDate`}
                    min={props.min}
                    max={props.max}
                />

                <TimeInput
                    label={translate('heure_de_d_but_27566')}
                    name={`${props.prefix}.startTime`}
                />

                <DateInput
                    label={translate('date_de_fin_86920')}
                    name={`${props.prefix}.endDate`}
                    min={props.min}
                    max={props.max}
                />

                <TimeInput
                    label={translate('heure_de_fin_95071')}
                    name={`${props.prefix}.endTime`}
                />
            </Flex>
        </SlotCard>
    );
};
