import { Badge, IBadgeColor } from 'common/src/designSystem/components/badge';
import { fromIconVO } from 'common/src/designSystem/components/i';
import { Icon } from 'common/src/generated/types';
import { getColorByBackgroundColor } from 'common/src/util/color';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';

export interface IBadgesPlusBadge {
    id: number | string;
    color: string;
    icon: Emptyable<Icon>;
    text: string;
}

interface IBadgesPlusBadgeProps {
    badge: IBadgesPlusBadge;
}

export const BadgesPlusBadge = ({ badge }: IBadgesPlusBadgeProps) => {
    const isDefaultColor = badge.color === 'gray' || badge.color === 'primary';

    return (
        <Badge
            color={isDefaultColor ? (badge.color as IBadgeColor) : undefined}
            leftIcon={badge.icon ? fromIconVO(badge.icon) : undefined}
            css={
                isDefaultColor
                    ? {
                          cursor: 'inherit'
                      }
                    : {
                          background: badge.color,
                          color: getColorByBackgroundColor(badge.color),
                          cursor: 'inherit'
                      }
            }
        >
            {badge.text}
        </Badge>
    );
};
