import { FormErrors } from 'common-front/src/components/form/formErrors';
import { UserCreateForm } from 'common-front/src/components/users/create/v2/userCreateForm';
import { Button } from 'common-front/src/designSystem/components/button';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { FormElementDisplayFragment } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { UserInfoFields } from 'common/src/vo/field';
import * as React from 'react';

interface IFormInformationsProps {
    elements: FormElementDisplayFragment[];
    isFinalStep: boolean;
    registerButtonText: string;
    values: UserInfoFields;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

export const FormInformations = (props: IFormInformationsProps) => {
    const translate = useTranslate();

    return (
        <>
            <FormErrors />

            <UserCreateForm
                autoComplete={true}
                elements={props.elements}
                prefix="formRegister.userInfo.fields."
                values={props.values}
                change={props.change}
            />

            <Spacer height={{ '@initial': '6', '@tablet': '7' }} />

            <Flex>
                <Button onClick={props.handleSubmit}>
                    {props.isFinalStep ? props.registerButtonText : translate('suivant_62774')}
                </Button>
            </Flex>
        </>
    );
};
