import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    Event,
    FormRegisterCustomSlotPeriodFragment,
    RegisterDaysDisplay,
    VolunteersRegistrationsSlotInput
} from 'common/src/generated/types';
import { toInterval } from 'common/src/util/luxon';
import { addRemoveSlot, getIntervals } from 'common/src/vo/volunteerRegistrationSlot';
import { Interval } from 'luxon';
import * as React from 'react';
import { FormSlotsCustomPeriod } from './formSlotsCustomPeriod';

interface IFormSlotsCustomProps {
    currentSlots: VolunteersRegistrationsSlotInput[];
    customSlotsPeriods: FormRegisterCustomSlotPeriodFragment[];
    daysDisplay: RegisterDaysDisplay;
    event: Pick<Event, 'startAt' | 'endAt' | 'ranges'>;
    prefix: string;

    change(name: string, value: any): void;
}

export const FormSlotsCustom = (props: IFormSlotsCustomProps) => {
    const allIntervals = React.useMemo(() => props.customSlotsPeriods
            .flatMap((customSlotPeriod) => getIntervals(
                    customSlotPeriod.startDate ?? props.event.startAt,
                    customSlotPeriod.endDate ?? props.event.endAt,
                    customSlotPeriod.customSlots,
                    props.event.ranges,
                    props.daysDisplay
                ))
            .map(({ interval }) => interval), [props.customSlotsPeriods, props.event, props.daysDisplay]);
    const [selected, _setSelected] = React.useState(
        Object.fromEntries(
            allIntervals.map((interval) => {
                const initialIsSelected = props.currentSlots.some((s) =>
                    toInterval(s).equals(interval)
                );

                return [interval.toISO(), initialIsSelected];
            })
        )
    );
    const isSelected = React.useCallback(
        (interval: Interval) => selected[interval.toISO()],
        [selected]
    );
    const setSelected = React.useCallback(
        (interval: Interval, newIsSelected: boolean) => {
            const key = interval.toISO();

            _setSelected((currentSelected) => ({
                ...currentSelected,
                [key]: newIsSelected
            }));

            props.change(
                `${props.prefix}.slots`,
                addRemoveSlot(props.currentSlots, interval, newIsSelected)
            );
        },
        [props.currentSlots, _setSelected]
    );
    const setSelectedBatch = React.useCallback(
        (intervals: Array<{ interval: Interval; isSelected: boolean }>) => {
            const newSlots = intervals.reduce((currentSlots, { interval, isSelected }) => {
                const key = interval.toISO();

                _setSelected((currentSelected) => ({
                    ...currentSelected,
                    [key]: isSelected
                }));

                return addRemoveSlot(currentSlots, interval, isSelected);
            }, props.currentSlots);

            props.change(`${props.prefix}.slots`, newSlots);
        },
        [props.currentSlots, _setSelected]
    );

    return (
        <>
            {props.customSlotsPeriods.map((customSlotPeriod, index) => (
                    <React.Fragment key={index}>
                        {index !== 0 && <Spacer height="6" />}

                        <FormSlotsCustomPeriod
                            customSlotPeriod={customSlotPeriod}
                            daysDisplay={props.daysDisplay}
                            event={props.event}
                            isSelected={isSelected}
                            setSelected={setSelected}
                            setSelectedBatch={setSelectedBatch}
                        />
                    </React.Fragment>
                ))}
        </>
    );
};
