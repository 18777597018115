import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IPageProps {
    active: boolean;
    children: React.ReactNode;
    cursor: 'pointer' | 'default';
    disabled: boolean;

    onClick(): void;
}

export const Page = (props: IPageProps) => (
        <Flex
            align="center"
            justify="center"
            height={32}
            width={40}
            css={{
                background: props.active ? '$gray100' : 'white',
                borderLeft: '1px solid $gray300',
                color: props.disabled ? '$gray400' : '$gray700',
                cursor: props.cursor,
                fontWeight: '$medium'
            }}
            onClick={() => {
                if (!props.disabled) {
                    props.onClick();
                }
            }}
        >
            {props.children}
        </Flex>
    );
