import * as React from 'react';
import { Flex } from '../flex';
import { CSS } from '../stitches';

interface IHeaderCellProps {
    css?: CSS;
    children?: React.ReactNode;
    width?: number;

    onClick?(): void;
}

export const HeaderCell = (props: IHeaderCellProps) => (
        <Flex
            className="table-header-cell"
            align="center"
            height={1}
            css={{
                color: '$gray500',
                flex: props.width ? `0 ${props.width}px` : '1',
                overflow: 'hidden',
                px: '$6',
                ...(props.css as any)
            }}
            onClick={() => {
                props.onClick?.();
            }}
        >
            {props.children}
        </Flex>
    );
