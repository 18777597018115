import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { Label } from '../label/label';

const _Checkbox = styled('div', {
    alignItems: 'center',
    border: '1px solid $oldGrayBorder',
    borderRadius: '3px',
    color: '$oldBlack',
    display: 'flex',
    height: '18px',
    justifyContent: 'center',
    width: '18px',
    variants: {
        checked: {
            true: {
                background: '$oldBlue',
                border: '1px solid $oldBlue',
                '& > i': {
                    color: 'white',
                    fontSize: '10px'
                }
            }
        }
    }
});

interface ICheckboxComponentProps extends FieldRenderProps<boolean, HTMLElement> {
    label?: string;
    text?: string;
    subtext?: string | React.ReactNode;
    noMargin?: boolean;
    disabled?: boolean;
}

const CheckboxComponent = (props: ICheckboxComponentProps) => {
    const hasSubtext = props.subtext !== undefined && props.subtext !== null;

    return (
        <div>
            {props.label && <Label htmlFor={props.input.name}>{props.label}</Label>}

            <Flex
                align="center"
                css={{
                    cursor: 'pointer',
                    marginBottom: props.noMargin !== true ? '24px' : undefined,
                    userSelect: 'none',
                    width: '100%',
                    '&:hover .h-checkbox': {
                        border: '1px solid $oldBlue'
                    },
                    '&:hover .h-checked': {
                        border: '1px solid $oldBlue'
                    },
                    '&:hover .h-disabled': {
                        border: '1px solid $oldGrayBorder'
                    }
                }}
                onClick={() => {
                    if (props.disabled !== true) {
                        props.input.onChange(!props.input.value);
                    }
                }}
            >
                <Flex align="center" justify="center" width={18}>
                    <_Checkbox
                        checked={props.disabled !== true && props.input.value}
                        className={
                            props.disabled
                                ? 'h-disabled'
                                : props.input.value
                                ? 'h-checked'
                                : 'h-checkbox'
                        }
                    >
                        {props.input.value ? <i className="fas fa-check" /> : null}
                    </_Checkbox>
                </Flex>

                {props.text && (
                    <Flex
                        direction="column"
                        css={{
                            marginLeft: '10px',
                            width: 'calc(100% - 30px)'
                        }}
                    >
                        <Box
                            css={{
                                fontWeight: hasSubtext ? '$oldBold' : ''
                            }}
                        >
                            {props.text}
                        </Box>

                        {hasSubtext && (
                            <Box
                                css={{
                                    color: '$oldBlackLight'
                                }}
                            >
                                {props.subtext}
                            </Box>
                        )}
                    </Flex>
                )}
            </Flex>
        </div>
    );
};

interface ICheckboxFieldProps extends FieldProps<boolean, FieldRenderProps<boolean, HTMLElement>> {
    label?: string;
    name: string;
    text?: string;
    subtext?: string | React.ReactNode;
    noMargin?: boolean;
    disabled?: boolean;
}

export const Checkbox = (props: ICheckboxFieldProps) => <Field {...props} component={CheckboxComponent as any} />;

interface ICheckboxOnlyProps {
    value: boolean;

    onClick(value: boolean, e: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
}

export const CheckboxOnly = (props: ICheckboxOnlyProps) => (
        <Flex
            align="center"
            css={{
                cursor: 'pointer',
                userSelect: 'none',
                width: '100%',
                '&:hover .h-checkbox': {
                    border: '1px solid $oldBlue'
                },
                '&:hover .h-checked': {
                    border: '1px solid $oldBlue'
                },
                '&:hover .h-disabled': {
                    border: '1px solid $oldGrayBorder'
                }
            }}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                props.onClick(!props.value, e);
            }}
        >
            <Flex align="center" justify="center" width={18}>
                <_Checkbox
                    checked={props.value}
                    className={props.value ? 'h-checked' : 'h-checkbox'}
                >
                    {props.value ? <i className="fas fa-check" /> : null}
                </_Checkbox>
            </Flex>
        </Flex>
    );
