import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { DATE_TIME_FORM_FORMAT } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useUniqueIds } from '../../../hooks/useUniqueIds';
import { BaseInputProps } from '../input/baseInputProps';
import { Description } from '../input/description';
import { Hint } from '../input/hint';
import { Label } from '../input/label';
import { StyledInputContainer } from '../input/styledInputContainer';

type IDatetimeProps = {
    min?: DateTime;
    max?: DateTime;
    value?: DateTime | string | null;

    onChange(value: DateTime): void;
} & BaseInputProps;

export const DatetimeInput = (props: IDatetimeProps) => {
    const dateTimeValue = props.value
        ? isNonEmptyString(props.value)
            ? DateTime.fromISO(props.value, { zone: 'utc' })
            : props.value
        : null;
    const value = dateTimeValue?.isValid ? dateTimeValue.toFormat(DATE_TIME_FORM_FORMAT) : '';
    const { inputId, descId, errorId } = useUniqueIds();

    return (
        <Flex css={props.css} direction="column" width={1}>
            <Label htmlFor={inputId}>{props.label}</Label>

            <Description id={descId}>{props.description}</Description>

            {(props.label || props.description) && <Spacer height="1" />}

            <StyledInputContainer icon={props.icon} state={props.state} cursor="default">
                <Box
                    css={{
                        flex: '1',
                        height: '100%',
                        '& input': {
                            background: 'transparent',
                            border: 'none',
                            color: '$gray800',
                            height: '100%',
                            outline: 'none',
                            width: '100%'
                        }
                    }}
                >
                    <input
                        id={inputId}
                        aria-describedby={props.description ? descId : undefined}
                        aria-invalid={props.state === 'error'}
                        aria-errormessage={props.state === 'error' ? errorId : undefined}
                        type="datetime-local"
                        placeholder={props.placeholder}
                        disabled={props.state === 'disabled'}
                        min={props.min?.toFormat(DATE_TIME_FORM_FORMAT)}
                        max={props.max?.toFormat(DATE_TIME_FORM_FORMAT)}
                        onFocus={props.onFocus}
                        onBlur={props.onBlur}
                        value={value}
                        onChange={(e) => {
                            if (isNonEmptyString(e.target.value)) {
                                props.onChange(
                                    DateTime.fromFormat(e.target.value, DATE_TIME_FORM_FORMAT, {
                                        zone: 'UTC'
                                    })
                                );
                            } else {
                                props.onChange(DateTime.invalid('Invalid'));
                            }
                        }}
                    />
                </Box>
            </StyledInputContainer>

            <Hint id={errorId} state={props.state}>
                {props.hint}
            </Hint>
        </Flex>
    );
};
