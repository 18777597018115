import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CSS, styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

const _Checkbox = styled('div', {
    alignItems: 'center',
    border: '1px solid $gray300',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '10px',
    fontWeight: '$semiBold',
    justifyContent: 'center',
    '& > i': {
        fontSize: '10px',
        fontWeight: '$semiBold'
    },
    variants: {
        size: {
            sm: {
                borderRadius: '4px',
                height: '16px',
                width: '16px'
            },
            md: {
                borderRadius: '6px',
                height: '20px',
                width: '20px'
            }
        },
        state: {
            unchecked: {
                background: 'white',
                '&:hover': {
                    border: '1px solid $primary600'
                }
            },
            checked: {
                background: '$primary600',
                border: 'none',
                color: 'white'
            },
            indeterminate: {
                background: '$primary600',
                border: 'none',
                color: 'white',
                padding: '$1',
                '& > div': {
                    borderTop: '2px solid white',
                    width: '100%'
                }
            }
        },
        disabled: {
            true: {}
        }
    },
    compoundVariants: [
        {
            state: 'unchecked',
            disabled: true,
            css: {
                background: '$gray100',
                borderColor: '$gray200',
                '&:hover': {
                    borderColor: '$gray200'
                }
            }
        },
        {
            state: 'checked',
            disabled: true,
            css: {
                background: '$gray100',
                borderColor: '$gray200',
                color: '$gray300'
            }
        },
        {
            state: 'indeterminate',
            disabled: true,
            css: {
                background: '$gray100',
                borderColor: '$gray200',
                color: '$gray300',
                '& > div': {
                    borderTopColor: '$gray300'
                }
            }
        }
    ],
    defaultVariants: {
        size: 'sm',
        state: 'unchecked'
    }
});

export type ICheckboxSize = 'sm' | 'md';

export type ICheckboxState = 'unchecked' | 'checked' | 'indeterminate';

interface ICheckboxProps {
    size?: ICheckboxSize;
    state?: ICheckboxState;
    disabled?: boolean;

    onClick(state: ICheckboxState, e: React.MouseEvent<HTMLDivElement>): void;
}

export const Checkbox = (props: ICheckboxProps) => (
        <_Checkbox
            size={props.size}
            state={props.state}
            disabled={props.disabled}
            onClick={(e) => {
                if (props.disabled !== true) {
                    if (props.state === 'unchecked' || !props.state) {
                        props.onClick('checked', e);
                    } else {
                        props.onClick('unchecked', e);
                    }
                }
            }}
        >
            {props.state === 'checked' && <i className="far fa-check" />}

            {props.state === 'indeterminate' && <div />}
        </_Checkbox>
    );

interface ICheckboxTextProps extends ICheckboxProps {
    css?: CSS;
    children: React.ReactNode;
    subtext?: React.ReactNode;
}

export const CheckboxText = (props: ICheckboxTextProps) => {
    const hasSubtext = props.subtext !== undefined && props.subtext !== null;

    return (
        <Flex
            css={{
                cursor: 'pointer',
                userSelect: 'none',
                ...(props.css as any)
            }}
            onClick={(e) => {
                if (props.disabled !== true) {
                    if (props.state === 'unchecked' || !props.state) {
                        props.onClick('checked', e);
                    } else {
                        props.onClick('unchecked', e);
                    }
                }
            }}
        >
            <Flex
                css={{
                    alignItems: 'center',
                    height: props.size === 'md' ? '23px' : '20px'
                }}
            >
                <Checkbox
                    size={props.size}
                    state={props.state}
                    disabled={props.disabled}
                    onClick={() => {
                        // do nothing
                    }}
                />
            </Flex>

            <Spacer width="2" />

            <Flex
                css={{
                    flexDirection: 'column',
                    fontSize: props.size === 'md' ? '$3' : '$2'
                }}
            >
                <Box
                    fontWeight="medium"
                    css={{
                        color: props.disabled && !hasSubtext ? '$gray300' : '$gray800',
                        '& div': { cursor: 'pointer !important' }
                    }}
                >
                    {props.children}
                </Box>

                {hasSubtext && (
                    <Box
                        css={{
                            color: '$gray500'
                        }}
                    >
                        {props.subtext}
                    </Box>
                )}
            </Flex>
        </Flex>
    );
};
