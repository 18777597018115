import { CountriesService } from 'common/src/services/countriesService';
import * as React from 'react';
import { OnChange } from 'react-final-form-listeners';
import { useService } from 'common/src/util/dependencies/dependencies';

interface IAddressPlaceChangeProps {
    name: string;
    prefix: string;
    changeCity: boolean;
    changePostalCode: boolean;
    changeCountry: boolean;

    change(name: string, value: any): void;
}

export const AddressPlaceChange = (props: IAddressPlaceChangeProps) => {
    const countriesService = useService(CountriesService);

    return (
        <OnChange name={props.name}>
            {(place: google.maps.places.PlaceResult) => {
                props.change(`${props.prefix}street`, place.name);

                const city = place.address_components?.find(({ types }) =>
                    types.includes('locality')
                );
                const postalCode = place.address_components?.find(({ types }) =>
                    types.includes('postal_code')
                );
                const countryCode = place.address_components?.find(({ types }) =>
                    types.includes('country')
                )?.short_name;

                if (city && props.changeCity) {
                    props.change(`${props.prefix}city`, city.long_name);
                }

                if (postalCode && props.changePostalCode) {
                    props.change(`${props.prefix}postalCode`, postalCode.long_name);
                }

                if (countriesService.countryCodeExists(countryCode) && props.changeCountry) {
                    props.change(`${props.prefix}country`, countryCode);
                }
            }}
        </OnChange>
    );
};
