import { Interval } from 'luxon';
import {
    FormMissionsOptionsFragment,
    FormRegisterCategoryFragment,
    RegisterPositionFilter,
    RegisterSlotDisplay,
    VolunteersRegistrationsSlotInput
} from '../generated/types';
import { UserInfoFields } from './field';
import { canSelect as canSelectPosition, canSelectV2 as canSelectPositionV2 } from './position';

export function canSelect(
    category: FormRegisterCategoryFragment,
    ranges: Array<Interval | VolunteersRegistrationsSlotInput>,
    userFields: UserInfoFields,
    slotDisplay: RegisterSlotDisplay,
    positionFilter: RegisterPositionFilter
): boolean {
    return category.positions.some((position) => canSelectPosition(
            position,
            position.slots,
            userFields,
            ranges,
            slotDisplay,
            positionFilter
        ));
}

export function canSelectV2(
    category: FormRegisterCategoryFragment,
    ranges: Array<Interval | VolunteersRegistrationsSlotInput>,
    userFields: UserInfoFields,
    options: FormMissionsOptionsFragment
): boolean {
    return (
        !options.hiddenPositionsCategoriesIds.includes(category.id) &&
        (options.displayedPositionsCategoriesIds.length === 0 ||
            options.displayedPositionsCategoriesIds.includes(category.id)) &&
        category.positions.some((position) => canSelectPositionV2(position, userFields, ranges, options))
    );
}
