import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Link as _Link, LinkProps } from 'react-router-dom';

interface ILinkProps extends LinkProps {
    children: React.ReactNode;
}

export const Link = ({ children, download, onClick, style, target, to }: ILinkProps) => {
    // Catch any accidentally passed paths for other (sub)domains
    if (isNonEmptyString(to) && to.startsWith('http')) {
        to = { pathname: to };
    }

    return (
        <_Link
            download={download}
            onClick={onClick}
            style={{ color: 'inherit', cursor: 'pointer', textDecoration: 'none', ...style }}
            target={target}
            to={to}
        >
            {children}
        </_Link>
    );
};
