import { Box } from 'common/src/designSystem/components/box';
import { styled } from 'common/src/designSystem/components/stitches';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { normalizeInteger } from '../../../util/form';
import { isInvalid } from '../isInvalid';
import { Label } from '../label/label';
import { fieldSubscriptions } from '../subscriptions';
import { Subtitle } from './subtitle';

export const _OldInputStyled = styled('input', {
    background: 'white',
    border: '1px solid $oldGrayBorder',
    borderRadius: '$1',
    display: 'block',
    height: '40px',
    marginBottom: '$6',
    padding: '$2 $3',
    resize: 'none',
    width: '100%',
    '&::-webkit-input-placeholder': {
        color: '$oldBlueGray',
        opacity: 0.6
    },
    '&:-moz-placeholder': {
        color: '$oldBlueGray',
        opacity: 0.6
    },
    '&:-ms-input-placeholder': {
        color: '$oldBlueGray',
        opacity: 0.6
    },
    '&[disabled]': {
        background: '$oldGrayDisabled'
    },
    variants: {
        invalid: {
            true: {
                border: '1px solid $oldRed',
                marginBottom: '$2'
            }
        },
        filters: {
            true: {
                background: '$oldGrayLight',
                border: 'none',
                borderRadius: '$1',
                color: '$oldBlackLight',
                display: 'flex',
                marginBottom: '$6',
                width: '100%'
            }
        }
    }
});

export const _OldTextareaStyled = styled('input', {
    background: 'white',
    border: '1px solid $oldGrayBorder',
    borderRadius: '$1',
    display: 'block',
    height: '160px',
    marginBottom: '$6',
    padding: '$2 $3',
    width: '100%',
    '&::-webkit-input-placeholder': {
        color: '$oldBlueGray',
        opacity: 0.6
    },
    '&:-moz-placeholder': { color: '$oldBlueGray', opacity: 0.6 },
    '&:-ms-input-placeholder': { color: '$oldBlueGray', opacity: 0.6 },
    variants: {
        invalid: {
            true: {
                border: '1px solid $oldRed',
                marginBottom: '$2'
            }
        }
    }
});

interface ITextInputComponentProps extends FieldRenderProps<any, HTMLInputElement> {
    label: string;
    subtitle?: string;
    placeholder: string;
    parseInt?: boolean;
    isEmail?: boolean;
    isOptional?: boolean;
    forwardedRef: React.Ref<HTMLInputElement>;
    autocomplete?: string;
}

export const TextInputComponent = (props: ITextInputComponentProps) => {
    if (props.parseInt === true && props.isEmail === true) {
        throw new Error('TextInput.parseInt and TexTnput.isEmail are not compatible');
    }

    const invalid = isInvalid(props.meta);
    const additionalInputProps: any = {};

    if (isNonEmptyString(props.autocomplete)) {
        additionalInputProps.autoComplete = props.autocomplete;
    }

    return (
        <div>
            <Label htmlFor={props.input.name} isOptional={props.isOptional}>
                {props.label}
            </Label>

            {isNonEmptyString(props.subtitle) && <Subtitle text={props.subtitle} />}

            <_OldInputStyled
                {...props.input}
                {...additionalInputProps}
                type="text"
                invalid={invalid}
                placeholder={props.placeholder}
                onChange={(e) => {
                    let value: string | number | null = e.target.value;

                    if (props.parseInt === true) {
                        value = normalizeInteger(value);
                    }

                    if (props.isEmail && isNonEmptyString(value)) {
                        value = value.toLowerCase();
                    }

                    props.input.onChange(value);
                }}
                value={(props.input.value || '').toString()}
                ref={props.forwardedRef}
                disabled={props.disabled}
            />

            {invalid && (
                <Box
                    css={{
                        color: '$oldRed',
                        marginBottom: '$6'
                    }}
                >
                    {props.meta.error}
                </Box>
            )}
        </div>
    );
};

interface ITextInputProps {
    label: string;
    subtitle?: string;
    name: string;
    placeholder?: string;
    disabled?: boolean;
    parseInt?: boolean;
    isEmail?: boolean;
    isOptional?: boolean;
    autocomplete?: string;
}

export const TextInput = React.forwardRef(
    (props: ITextInputProps, ref: React.Ref<HTMLInputElement>) => (
            <Field
                {...props}
                component={TextInputComponent as any}
                placeholder={props.placeholder || props.label}
                subscription={fieldSubscriptions}
                forwardedRef={ref}
            />
        )
);
