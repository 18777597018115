import * as React from 'react';

export interface IReferrerContext {
    getReferrerPath(): string | null;
    setReferrerPath(): void;
}

const ReferrerContext = React.createContext<IReferrerContext>({} as any);

interface IReferrerContextProviderProps {
    children: React.ReactNode;
}

export const ReferrerContextProvider = ({ children }: IReferrerContextProviderProps) => {
    const [referrerPath, setReferrerPath] = React.useState<string | null>(null);

    return (
        <ReferrerContext.Provider
            value={{
                getReferrerPath() {
                    const path = referrerPath;
                    setReferrerPath(null);
                    return path;
                },
                setReferrerPath() {
                    setReferrerPath(location.pathname);
                }
            }}
        >
            {children}
        </ReferrerContext.Provider>
    );
};

export function useReferrerContext(): IReferrerContext {
    return React.useContext(ReferrerContext);
}
