import { Box } from 'common/src/designSystem/components/box';
import {
    AccreditationState,
    ALL_ACCREDITATIONSTATE,
    ALL_VOLUNTEERREGISTRATIONSTATE,
    EventId,
    VolunteerRegistrationState
} from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { DropdownFilters } from '../../../designSystem/components/dropdownFilters';
import { Select } from '../../../designSystem/components/select/select';
import { TextInput } from '../../../designSystem/components/textInput';
import { useEnumToOptions } from '../../../hooks/useEnumToOptions';
import { useHeavent } from '../../../hooks/useHeavent';

interface IDelegationMembersFiltersProps {
    accreditationState: Emptyable<AccreditationState>;
    assignmentState: Emptyable<VolunteerRegistrationState>;
    eventId: Emptyable<EventId>;
    search: string;

    setAccreditationState(accreditationState: Emptyable<AccreditationState>): void;
    setAssignmentState(assignmentState: Emptyable<VolunteerRegistrationState>): void;
    setOffset(offset: number): void;
    setSearch(search: string): void;
}

export const DelegationMembersFilters = (props: IDelegationMembersFiltersProps) => {
    const { translate } = useHeavent();
    const enumToOptions = useEnumToOptions();

    return (
        <>
            <Box width={320}>
                <TextInput
                    value={props.search}
                    onChange={(newName: string) => {
                        props.setSearch(newName);
                        props.setOffset(0);
                    }}
                    icon="magnifying-glass"
                    placeholder={translate('rechercher_un_m_05904')}
                />
            </Box>

            {props.eventId && (
                <DropdownFilters color="white" showButtons={false}>
                    <Select
                        label={translate('statut_d_accr_d_75527')}
                        value={props.accreditationState || ''}
                        onChange={(newState) => {
                            props.setAccreditationState(
                                isNonEmptyString(newState) ? (newState as AccreditationState) : null
                            );
                        }}
                    >
                        <option value="">{translate('tous_les_status_67644')}</option>
                        {enumToOptions(ALL_ACCREDITATIONSTATE)}
                    </Select>

                    <Select
                        label={translate('status_d_affect_75769')}
                        value={props.assignmentState || ''}
                        onChange={(newState) => {
                            props.setAssignmentState(
                                isNonEmptyString(newState)
                                    ? (newState as VolunteerRegistrationState)
                                    : null
                            );
                        }}
                    >
                        <option value="">{translate('tous_les_status_67644')}</option>
                        {enumToOptions(ALL_VOLUNTEERREGISTRATIONSTATE)}
                    </Select>
                </DropdownFilters>
            )}
        </>
    );
};
