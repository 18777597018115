import * as React from 'react';
import { Fields } from '../../components/form/fields';
import { isInvalid } from '../../components/reactFinalForm/isInvalid';
import { BaseInputProps } from '../components/input/baseInputProps';
import { PhoneInput as PhoneInputComponent } from '../components/phoneInput';

interface IPhoneInputProps extends BaseInputProps {
    prefix: string;
}

export const PhoneInput = (props: IPhoneInputProps) => {
    const country = `${props.prefix}country`;
    const code = `${props.prefix}code`;
    const _number = `${props.prefix}number`;

    return (
        <Fields
            names={[country, code, _number]}
            render={(fieldsProps) => {
                const invalid =
                    isInvalid(fieldsProps[code].meta) || isInvalid(fieldsProps[_number].meta);

                return (
                    <PhoneInputComponent
                        {...props}
                        hint={
                            invalid
                                ? fieldsProps[code].meta.error || fieldsProps[_number].meta.error
                                : props.hint
                        }
                        state={invalid ? 'error' : props.state}
                        value={{
                            country: fieldsProps[country].input.value,
                            code: fieldsProps[code].input.value,
                            number: fieldsProps[_number].input.value
                        }}
                        onChange={(newPhone) => {
                            fieldsProps[country].input.onChange(newPhone.country);
                            fieldsProps[code].input.onChange(newPhone.code);
                            fieldsProps[_number].input.onChange(newPhone.number);
                        }}
                    />
                );
            }}
        />
    );
};
