import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    FormMissionsOptionsFragment,
    FormMissionsQuery,
    PositionsSlotId,
    VolunteersRegistrationsSlotInput
} from 'common/src/generated/types';
import { UserInfoFields } from 'common/src/vo/field';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { FormMissionsSlotsCategory } from './formMissionsSlotsCategory';

interface IFormMissionsSlotsProps {
    categories: FormMissionsQuery['event']['positionsCategories'];
    options: FormMissionsOptionsFragment;
    positionsSlotsIds: PositionsSlotId[];
    prefix: string;
    slots: VolunteersRegistrationsSlotInput[];
    userInfoFields: UserInfoFields;

    change(name: string, value: any): void;
}

export const FormMissionsSlots = (props: IFormMissionsSlotsProps) => {
    const categories = React.useMemo(() => sortBy(props.categories, (c) => c.name), [props.categories]);

    return (
        <>
            {categories.map((category, index) => (
                    <React.Fragment key={category.id}>
                        {index !== 0 && <Spacer height="7" />}

                        <FormMissionsSlotsCategory
                            key={category.id}
                            category={category}
                            options={props.options}
                            positionsSlotsIds={props.positionsSlotsIds}
                            prefix={props.prefix}
                            slots={props.slots}
                            userInfoFields={props.userInfoFields}
                            change={props.change}
                        />
                    </React.Fragment>
                ))}
        </>
    );
};
