import { Select } from 'common-front/src/designSystem/components/select/select';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useDesktopQuery } from 'common-front/src/hooks/useDesktopQuery';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useTabletQuery } from '../hooks/useTabletQuery';

interface ITabViewResponsive {
    sections: {
        id: number;
        title: string;
        elem: React.ReactElement;

        getPath(isRouteComponent?: boolean): string;
    }[];
    tabsOnTablet?: boolean;
}

export const TabViewResponsive = ({ sections, tabsOnTablet = false }: ITabViewResponsive) => {
    const { history } = useHeavent();
    const [currentSectionPath, setCurrentSectionPath] = React.useState(window.location.pathname);
    const isDesktop = useDesktopQuery();
    const isTablet = useTabletQuery();
    const isTabs = isDesktop || (isTablet && tabsOnTablet);

    const sectionSelector = ({
        id,
        getPath,
        title
    }: {
        id: number;
        title: string;

        getPath(isRouteComponent?: boolean): string;
    }) => isTabs ? (
            <Tab key={id} path={getPath()}>
                {title}
            </Tab>
        ) : (
            <option value={getPath()}>{title}</option>
        );

    const selectedSection = ({
        id,
        elem,
        getPath
    }: {
        id: number;
        elem: React.ReactElement;

        getPath(isRouteComponent?: boolean): string;
    }) => isTabs ? (
            <TabPanel key={id} path={getPath(true)}>
                {elem}
            </TabPanel>
        ) : (
            <Route path={getPath(true)}>{elem}</Route>
        );

    return (
        <>
            {isTabs ? (
                <Tabs height={1}>
                    <TabList>
                        {sections.map(({ id, getPath, title }) =>
                            sectionSelector({
                                id,
                                getPath,
                                title
                            })
                        )}
                    </TabList>

                    <Spacer height="7" />

                    {sections.map(({ id, elem, getPath }) =>
                        selectedSection({
                            id,
                            elem,
                            getPath
                        })
                    )}
                </Tabs>
            ) : (
                <>
                    <Select
                        css={{ marginTop: '$4' }}
                        value={currentSectionPath}
                        onChange={(path) => {
                            setCurrentSectionPath(
                                sections.find(({ getPath: p }) => p() == path)!.getPath()
                            );
                            history.push(path);
                        }}
                    >
                        {sections.map(({ id, getPath, title }) =>
                            sectionSelector({
                                id,
                                title,
                                getPath
                            })
                        )}
                    </Select>

                    <Spacer height="5" />

                    <Switch>
                        {sections.map(({ id, elem, getPath }) =>
                            selectedSection({
                                id,
                                elem,
                                getPath
                            })
                        )}
                    </Switch>
                </>
            )}
        </>
    );
};
