import { BaseFlexProps, Flex, pickBaseFlexProps } from 'common/src/designSystem/components/flex';
import { partition } from 'lodash-es';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

interface ITabsProps extends BaseFlexProps {
    children: React.ReactNode;
}

export const Tabs = (props: ITabsProps) => {
    const children = React.Children.toArray(props.children);
    const [tabPanels, others] = partition(
        children,
        (c: any) => c?.type?.displayName === 'TabPanel'
    );

    return (
        <Flex direction="column" width={1} {...pickBaseFlexProps(props)}>
            {others}

            <Switch>
                {tabPanels.map((tabPanel: any, index) => <Route key={index} path={tabPanel.props.path} children={tabPanel} />)}
            </Switch>
        </Flex>
    );
};
