import { Blank } from 'common-front/src/components/blank/blank';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { UserInformationForm } from 'common-front/src/users/information/userInformationForm';
import { Svgs } from 'common-front/src/util/assets';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { MemberRegistrationQuery } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { MembersPaths } from 'common/src/util/membersPaths';
import * as React from 'react';

interface IMemberRegistrationInformationProps {
    userInfo: MemberRegistrationQuery['organization']['userInfo'];
}

export const MemberRegistrationInformation = ({
    userInfo
}: IMemberRegistrationInformationProps) => {
    const {
        params: { eventId, organizationId },
        translate
    } = useHeavent();

    const fuis = React.useMemo(() => {
        const fuis = userInfo.formsUsersInfos.filter((fui) =>
            eventId ? fui.eventId !== null : fui.eventId === null
        );

        if (isNonEmptyArray(fuis)) {
            return fuis.flatMap((fui) => fui.form.elements.length > 0 ? [fui] : []);
        } else {
            return [];
        }
    }, [userInfo.formsUsersInfos, eventId]);

    return (
        <>
            {isNonEmptyArray(fuis) ? (
                <>
                    {fuis.map((formUserInfo, index) => (
                            <React.Fragment key={formUserInfo.form.id}>
                                {index !== 0 && <Spacer height="4" />}

                                <UserInformationForm
                                    filledAt={formUserInfo.insertedAt}
                                    form={formUserInfo.form}
                                    showIsFilled={true}
                                    userInfo={userInfo}
                                    getEditUserFormPath={() => MembersPaths.MEMBER_REGISTRATION_INFORMATION_EDIT(
                                            organizationId,
                                            userInfo.id,
                                            eventId
                                        )}
                                />
                            </React.Fragment>
                        ))}
                </>
            ) : (
                <Blank
                    imageSrc={Svgs.FormsElementsBlank}
                    title={translate('aucun_formulair_41857')}
                />
            )}
        </>
    );
};
