import { CSS } from '@stitches/react';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { LinkProps } from 'react-router-dom';
import { Link } from '../../../util/link';
import { closeOtherDropdowns } from './dropdown';

const _Item = styled('div', {
    alignItems: 'center',
    display: 'flex',
    fontSize: '$textSm',
    fontWeight: '$regular',
    minHeight: '40px',
    px: '$4',
    py: '$2',
    userSelect: 'none',
    width: '100%',
    ellipsis: '',
    variants: {
        color: {
            default: {
                cursor: 'pointer',
                color: '$gray700',
                '&:hover': {
                    background: '$primary50',
                    color: '$primary700'
                }
            },
            disabled: {
                color: '$gray400',
                cursor: 'not-allowed'
            },
            heading: {
                color: '$gray500',
                fontSize: '$textXs',
                fontWeight: '$semiBold',
                cursor: 'default'
            },
            red: {
                cursor: 'pointer',
                color: '$error700',
                '&:hover': {
                    background: '$error50'
                }
            }
        }
    },
    defaultVariants: {
        color: 'default'
    }
});

type IItemColor = 'default' | 'red' | 'disabled' | 'heading';

interface IItemProps
    extends Omit<LinkProps, 'onClick' | 'to'>,
        Partial<Pick<LinkProps, 'to'>>,
        // Required to allow `onClick.e` to be `React.MouseEvent<HTMLDivElement>`
        Pick<React.AnchorHTMLAttributes<any>, 'onClick'> {
    color?: IItemColor;
    css?: CSS;
    children: React.ReactNode;
}

export const Item = ({ children, color, css, download, to: path, onClick, target }: IItemProps) => color === 'disabled' || !path ? (
        <_Item css={css} color={color || 'default'} onClick={(e) => onClick?.(e)}>
            {children}
        </_Item>
    ) : (
        <Link download={download} onClick={(e) => onClick?.(e)} target={target} to={path}>
            <_Item css={css} color={color || 'default'}>
                {children}
            </_Item>
        </Link>
    );

interface IItemIconProps extends IItemProps {
    icon: IIcon;
    isDisabled?: boolean;
}

export const ItemIcon = ({
    children,
    color,
    download,
    icon,
    isDisabled = false,
    onClick,
    to: path,
    target
}: IItemIconProps) => (
        <Item
            color={color}
            css={{
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                opacity: isDisabled ? '0.6' : 'inherit'
            }}
            download={download}
            to={path}
            onClick={(e) => {
                if (!isDisabled) {
                    closeOtherDropdowns(e.target);

                    onClick?.(e);
                }
            }}
            target={target}
        >
            <Flex align="center" gap="3" height={1} width={1}>
                <Flex align="center" justify="start" height={1} width={16}>
                    <I icon={icon} />
                </Flex>

                <Box css={{ flex: '1', ellipsis: '' }}>{children}</Box>
            </Flex>
        </Item>
    );
