import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';

const _FullScreenPopup = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    zIndex: '10',
    '& .fsp-header': {
        px: '$7'
    },
    '& .fsp-category': {
        fontSize: '11px',
        fontWeight: '$semiBold',
        textTransform: 'uppercase'
    },
    '& .fsp-title': {
        fontSize: '$textMd',
        fontWeight: '$semiBold'
    },
    variants: {
        color: {
            white: {
                '& .fsp-header': {
                    background: 'white',
                    borderBottom: '1px solid $gray200'
                },
                '& .fsp-category': {
                    color: '$gray600'
                },
                '& .fsp-title': {
                    color: '$gray900'
                }
            },
            dark: {
                '& .fsp-header': {
                    background: '$gray900'
                },
                '& .fsp-category': {
                    color: 'white'
                },
                '& .fsp-title': {
                    color: 'white'
                }
            }
        }
    },
    defaultVariants: {
        color: 'white'
    }
});

interface IFullScreenPopupProps {
    button?: React.ReactNode;
    category?: string;
    children: React.ReactNode;
    closePath?: string;
    color?: 'white' | 'dark';
    title: string;

    close?(): void;
}

export const FullScreenPopup = (props: IFullScreenPopupProps) => (
        <_FullScreenPopup color={props.color}>
            <Flex className="fsp-header" align="center" gap="4" height={65} width={1}>
                <Box width={300} />

                <Flex direction="column" align="center" css={{ flex: '1' }}>
                    {props.category && <Box className="fsp-category">{props.category}</Box>}

                    <Box className="fsp-title">{props.title}</Box>
                </Flex>

                <Flex justify="end" gap="4" width={300}>
                    {props.button}

                    <Button
                        color={props.color === 'dark' ? 'gray' : 'white'}
                        leftIcon="xmark"
                        to={props.closePath}
                        onClick={props.close}
                    />
                </Flex>
            </Flex>

            <Box width={1} css={{ height: 'calc(100% - 65px)' }}>
                {props.children}
            </Box>
        </_FullScreenPopup>
    );
