import { CSS, styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

const _H3 = styled('h3', {
    fontSize: '16px',
    fontWeight: '$oldBold',
    lineHeight: '24px',
    margin: 0
});

interface IH3Props {
    css?: CSS;
    children?: React.ReactNode;
    margin?: boolean;
    marginBottom?: boolean;
    marginTop?: boolean;
}

export const H3 = (props: IH3Props) => (
        <_H3
            css={props.css}
            style={{
                marginBottom:
                    props.margin === true || props.marginBottom === true ? `16px` : undefined,
                marginTop: props.margin === true || props.marginTop === true ? `16px` : undefined
            }}
        >
            {props.children}
        </_H3>
    );
