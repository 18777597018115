import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { executeQuery } from 'common-front/src/components/graphql/graphql';
import { Alert } from 'common-front/src/designSystem/components/alert';
import { Button } from 'common-front/src/designSystem/components/button';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import {
    Mutations,
    useLoginCodeEmailCreateMutation
} from 'common-front/src/generated/graphqlHooks';
import { useUserContext } from 'common-front/src/userContext';
import {
    getToken,
    IInitiateAuthResult,
    initiateAuthWithSignUp,
    reportAuthError,
    sendCustomChallengeAnswer,
    signOutNoRedirect
} from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { LoginCodeSource, UserCreateMutation } from 'common/src/generated/types';
import { AuthInputService, IFormEmailValues } from 'common/src/input/authInput';
import { ValidateService } from 'common/src/services/validateService';
import { useParams, useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { noop } from 'lodash-es';
import { Duration } from 'luxon';
import * as React from 'react';
import { executeUserExistsQuery } from '../../../generated/graphqlHooks';
import { FormBox } from './formBox';

interface IFormEmailProps {
    description: string;
    emailTitle: string;
    isOpen: boolean;
    isPreview: boolean;

    onSuccess(email: string, token?: string): Promise<any>;
}

export const FormEmail = (props: IFormEmailProps) => {
    const translate = useTranslate();
    const { organizationId, eventId, formId } = useParams();
    const validateService = useService(ValidateService);
    const authInput = useService(AuthInputService);
    const { user: currentUser } = useUserContext();
    const { mutate: loginCodeEmailCreate } = useLoginCodeEmailCreateMutation();
    const onSuccess = React.useCallback(
        async (token: string) => {
            if (!props.isPreview) {
                const { userCreate } = await executeQuery<UserCreateMutation>(
                    Mutations.UserCreate,
                    {
                        token
                    }
                );

                await props.onSuccess(userCreate.email, token);
            }
        },
        [props.onSuccess]
    );
    const [currentInitiateAuthResult, setInitiateAuthResult] =
        React.useState<IInitiateAuthResult | null>(null);
    const [isCodeVisible, setIsCodeVisible] = React.useState(false);
    const [currentAttempt, setCurrentAttempt] = React.useState(0);
    const [isRestartVisible, setIsRestartVisible] = React.useState(false);
    const [challengeStartTime, setChallengeStartTime] = React.useState(Date.now());
    const restart = () => {
        location.reload();
    };

    return (
        <Form
            direction="column"
            align="center"
            width={1}
            hideErrors={true}
            initialValues={{
                email: currentUser?.email,
                emailConfirm: currentUser?.email,
                code: ''
            }}
            validate={(values: IFormEmailValues) => {
                if (isCodeVisible) {
                    return validateService.validateForForm(authInput.emailCodeSchema())(values);
                } else {
                    return validateService.validateForForm(authInput.emailConfirmSchema())(values);
                }
            }}
            onSubmit={async (values: IFormEmailValues) => {
                if (!props.isPreview) {
                    if (isNonEmptyString(values.code)) {
                        const { user, callbacks } = currentInitiateAuthResult!;

                        return sendCustomChallengeAnswer({
                            code: values.code,
                            user,
                            callbacks
                        }).then(onSuccess, (error) => {
                            if (error.type === 'RETRY') {
                                setCurrentAttempt(currentAttempt + 1);

                                return {
                                    code: translate(
                                        'le_code_n_est_p_95065',
                                        3 - (currentAttempt + 1)
                                    )
                                };
                            } else {
                                reportAuthError(error.message, values.email, challengeStartTime);
                                setIsRestartVisible(true);

                                let FORM_ERROR;
                                if (
                                    Duration.fromMillis(Date.now() - challengeStartTime).as(
                                        'minutes'
                                    ) > 3
                                ) {
                                    FORM_ERROR = translate('le_code_a_expir_45975');
                                } else {
                                    FORM_ERROR = translate('le_code_n_est_p_12317');
                                }

                                return {
                                    FORM_ERROR
                                };
                            }
                        });
                    } else if (currentUser?.email === values.email) {
                        await onSuccess((await getToken())!);
                    } else {
                        await signOutNoRedirect().catch(() => true);
                        const { userExists } = await executeUserExistsQuery(
                            { email: values.email },
                            null
                        );

                        if (userExists) {
                            await loginCodeEmailCreate({
                                loginCodeEmail: {
                                    email: values.email,
                                    organizationId,
                                    eventId,
                                    formId,
                                    source: LoginCodeSource.VolunteerRegister
                                }
                            });

                            setChallengeStartTime(Date.now());
                            const initiateAuthResult = await initiateAuthWithSignUp(
                                values.email,
                                noop,
                                noop
                            );

                            setInitiateAuthResult(initiateAuthResult);
                            setIsCodeVisible(true);
                        } else {
                            await props.onSuccess(values.email);
                        }
                    }
                }
            }}
            render={({ handleSubmit, submitting, values }) => (
                    <FormBox
                        canSelect={false}
                        isCompleted={!props.isOpen}
                        isOpen={props.isOpen}
                        number="1"
                        title={
                            isNonEmptyString(props.emailTitle)
                                ? props.emailTitle
                                : translate('Email')
                        }
                        onSelect={noop}
                    >
                        <FormErrors />

                        {isNonEmptyString(props.description) && (
                            <>
                                <RichText text={props.description} />

                                <Spacer height="4" />
                            </>
                        )}

                        <TextInput
                            autoComplete="email"
                            label={translate('Email')}
                            name="email"
                            isEmail={true}
                            state={isCodeVisible || isRestartVisible ? 'disabled' : undefined}
                        />

                        <Spacer height="4" />

                        <TextInput
                            autoComplete="email"
                            label={translate('confirmation_de_51185')}
                            name="emailConfirm"
                            isEmail={true}
                            state={isCodeVisible || isRestartVisible ? 'disabled' : undefined}
                        />

                        {isCodeVisible && (
                            <>
                                <Spacer height="7" />

                                <Alert color="gray" leftIcon="circle-exclamation">
                                    <Box>{translate('un_code_6_chi_33026', values.email)}</Box>
                                    <Box css={{ fontStyle: 'italic' }}>
                                        {translate('attention_ne_16312')}
                                    </Box>
                                </Alert>

                                <Spacer height="4" />

                                <TextInput
                                    autoComplete="one-time-code"
                                    label={translate('code_20843')}
                                    name="code"
                                    state={isRestartVisible ? 'disabled' : undefined}
                                    type="number"
                                />
                            </>
                        )}

                        <Spacer height={{ '@initial': '6', '@tablet': '7' }} />

                        <Flex>
                            {isRestartVisible ? (
                                <Button onClick={restart}>{translate('recommencer_14712')}</Button>
                            ) : (
                                <Button onClick={handleSubmit} isLoading={submitting}>
                                    {translate('suivant_62774')}
                                </Button>
                            )}
                        </Flex>
                    </FormBox>
                )}
        />
    );
};
