import { useDelegationAccreditationsQuery } from 'common-front/src/generated/graphqlHooks';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { AccreditationsSlotId } from 'common/src/generated/types';
import { isFuzzyMatch } from 'common/src/util/string';
import * as React from 'react';
import { AccreditationCategories } from '../../../components/accreditations/accreditationCategories';
import { AccreditationSlotMembersPanel } from '../../../components/accreditations/sidebars/accreditationSlotMembersPanel';
import { TextInput } from '../../../designSystem/components/textInput';
import { useHeavent } from '../../../hooks/useHeavent';

interface IDelegationAccreditationsProps {
    isEventAdmin?: boolean;
}

export const DelegationAccreditations = ({ isEventAdmin }: IDelegationAccreditationsProps) => {
    const {
        params: { organizationId, delegationId },
        translate
    } = useHeavent();

    const { data, loader, reload } = useDelegationAccreditationsQuery({
        organizationId,
        delegationId,
        onlyVisible: !isEventAdmin
    });

    const [searchText, setSearchText] = React.useState('');

    const filteredAccreditations = React.useMemo(
        () =>
            data.organization?.delegation?.accreditations.filter(
                (accreditation) =>
                    isFuzzyMatch(accreditation.accreditationSlot.name, searchText) ||
                    isFuzzyMatch(accreditation.accreditationSlot.accreditation.name, searchText) ||
                    isFuzzyMatch(
                        accreditation.accreditationSlot.accreditationCategory.name,
                        searchText
                    )
            ),
        [data.organization?.delegation?.accreditations, searchText]
    );

    return (
        loader || (
            <>
                <Box width={320}>
                    <TextInput
                        value={searchText}
                        onChange={(newName: string) => {
                            setSearchText(newName);
                        }}
                        icon="magnifying-glass"
                        placeholder={translate('rechercher_une_65646')}
                    />
                </Box>

                <Spacer height="6" />

                <AccreditationCategories
                    accreditationsSlots={filteredAccreditations.map((da) => da.accreditationSlot)}
                    delegationAccreditationsSlots={filteredAccreditations.map((da) => ({
                            ...da.accreditationSlot,
                            assignedResources: da.assignedResources,
                            assignedWishedResources: da.assignedWishedResources,
                            maxResources: da.maxResources
                        }))}
                    panel={(
                        setIsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>,
                        slotId: AccreditationsSlotId
                    ) => (
                        <AccreditationSlotMembersPanel
                            accreditationSlotId={slotId}
                            isEventAdmin={isEventAdmin}
                            onClose={() => {
                                setIsPanelOpen(false);
                                reload();
                            }}
                        />
                    )}
                    showFillingRate={true}
                />
            </>
        )
    );
};
