import { injectable } from 'inversify';
import * as yup from 'yup';
import { TranslationService } from '../services/translationService';
import { fileInfos } from '../util/file';
import { MimeType, mimeType } from '../util/mimeType';
import { InputService } from './inputService';

export const MAX_ARCHIVE_SIZE = 5_000_000_000; // 5 GB. Arbitrary.
export const MAX_ARCHIVE_FILE_SIZE = 20_000_000; // 20 MB. Arbitrary.

@injectable()
export class ArchiveInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    schema() {
        const required = this.t('le_document_est_86403');

        return yup.object().shape({
            acl: yup.string().required(required),
            key: yup
                .string()
                .required(required)
                .test('is-a-zip', this.t('le_fichier_n_es_91526'), (key: string) => mimeType(fileInfos(key).extension) === MimeType.Zip),
            name: yup.string().required(required),
            file: yup
                .mixed()
                .test('is-not-too-big', this.t('le_fichier_est_17522'), (file) =>
                    file ? (file as File).size < MAX_ARCHIVE_SIZE : true
                )
        });
    }
}
