export function loadScript(src: string, done: () => void, error?: () => void) {
    const script = document.createElement('script');

    script.onload = () => {
        done();
    };

    script.onerror = () => {
        if (error) {
            error();
        }
    };

    script.src = src;

    document.body.appendChild(script);
}

export function loadScriptPromise(src: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
        loadScript(
            src,
            () => {
                resolve(true);
            },
            () => {
                reject(new Error(`Could not load ${src}`));
            }
        );
    });
}
