import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Document } from 'common/src/generated/types';
import { fileInfos } from 'common/src/util/file';
import { isNonEmptyString } from 'common/src/util/string';
import { getS3Url } from 'common/src/vo/document';
import * as React from 'react';
import { Portal } from 'react-portal';
import { AWS_UPLOADS_BUCKET } from '../../../../consts';
import { download } from '../../../../util/file';
import { FileViewer } from '../../../fileViewer/fileViewer';
import { FullScreenPopup } from '../../../fullScreenPopup/fullScreenPopup';

interface IFileSimpleViewProps {
    document: Pick<Document, 'acl' | 'key' | 'name'> & { url?: string };
    isEditHidden?: boolean;
    isDeleteHidden?: boolean;

    onEdit?(): void;

    onDelete?(): void;
}

export const FileSimpleView = (props: IFileSimpleViewProps) => {
    const [isFileViewOpen, setIsFileViewOpen] = React.useState(false);
    const canViewDownload =
        props.document.acl === 'public-read' || isNonEmptyString(props.document.url);
    const url = isNonEmptyString(props.document.url)
        ? props.document.url
        : getS3Url(props.document, AWS_UPLOADS_BUCKET);

    return (
        <>
            <Flex
                css={{
                    background: '#fafbfd',
                    borderRadius: '3px',
                    height: '100px',
                    padding: '$6',
                    width: '100%'
                }}
            >
                <Flex
                    align="center"
                    justify="center"
                    css={{
                        background: '$oldBlue',
                        borderRadius: '3px',
                        color: 'white',
                        height: '48px',
                        textTransform: 'uppercase',
                        width: '48px'
                    }}
                >
                    {fileInfos(props.document.name).extension}
                </Flex>

                <Flex
                    align="center"
                    css={{
                        flex: '1',
                        fontWeight: '$oldBold',
                        marginLeft: '$4',
                        marginRight: '$4',
                        overflowY: 'hidden',
                        '& > div': {
                            ellipsis: true,
                            width: '100%'
                        }
                    }}
                >
                    <div>{props.document.name}</div>
                </Flex>

                <Flex
                    align="center"
                    css={{
                        '& > i': {
                            color: '#fafbfd',
                            cursor: 'pointer',
                            marginLeft: '24px'
                        }
                    }}
                >
                    {canViewDownload && (
                        <i
                            className="far fa-eye"
                            onClick={() => {
                                setIsFileViewOpen(true);
                            }}
                        />
                    )}

                    {canViewDownload && (
                        <i
                            className="far fa-download"
                            onClick={() => {
                                download(url, props.document.name);
                            }}
                        />
                    )}

                    {props.isEditHidden !== true && (
                        <i className="fas fa-pen" onClick={props.onEdit} />
                    )}

                    {props.isDeleteHidden !== true && (
                        <i className="fas fa-trash-alt" onClick={props.onDelete} />
                    )}
                </Flex>
            </Flex>

            {isFileViewOpen && (
                <Portal>
                    <Box
                        css={{
                            background: 'white',
                            height: '100%',
                            left: '0',
                            position: 'absolute',
                            top: '0',
                            width: '100%',
                            zIndex: '10'
                        }}
                    >
                        <FullScreenPopup
                            title={props.document.name}
                            close={() => {
                                setIsFileViewOpen(false);
                            }}
                        >
                            <FileViewer fileUrl={url} />
                        </FullScreenPopup>
                    </Box>
                </Portal>
            )}
        </>
    );
};
