import * as React from 'react';
import { Flex } from '../flex';
import { Skeleton } from '../skeleton';
import { CSS } from '../stitches';

interface IRowSkeletonProps {
    bx?: boolean;
    css?: CSS;
}

export const RowSkeleton = (props: IRowSkeletonProps) => (
        <Flex
            align="center"
            height={72}
            width={1}
            css={{
                borderBottom: '1px solid $gray200',
                borderLeft: props.bx ? '1px solid $gray200' : undefined,
                borderRight: props.bx ? '1px solid $gray200' : undefined,
                padding: '$3',
                ...(props.css as any)
            }}
        >
            <Skeleton height={1} width={1} borderRadius="$1" />
        </Flex>
    );
