import { Box } from 'common/src/designSystem/components/box';
import {
    EventRegisterSlotsPositionsFragment,
    PositionId,
    PositionsCategoryId,
    PositionsSlotId,
    RegisterPositionDisplay,
    VolunteersRegistrationInput
} from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { addOrRemove } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { canSelect as canSelectPosition } from 'common/src/vo/position';
import { canSelect as canSelectCategory } from 'common/src/vo/positionCategory';
import { canSelect as canSelectSlot } from 'common/src/vo/positionSlot';
import { min, sortBy } from 'lodash-es';
import * as React from 'react';
import { OnChange } from 'react-final-form-listeners';
import { Checkbox } from '../reactFinalForm/input/checkbox';
import { H3 } from '../titles/h3';

interface IVolunteerRegistrationPositionsProps {
    event: EventRegisterSlotsPositionsFragment;
    values: VolunteersRegistrationInput;
    showHiddenPositions: boolean;

    change(name: string, value: any): void;
}

export const VolunteerRegistrationPositions = (props: IVolunteerRegistrationPositionsProps) => {
    const intervalService = useService(IntervalService);
    const positionsCategories = React.useMemo(() => sortBy(
            props.event.positionsCategories.filter((positionCategory) => (
                    canSelectCategory(
                        positionCategory,
                        props.values.slots,
                        props.values.userInfo.fields,
                        props.event.slotDisplay,
                        props.event.positionFilter
                    ) && positionCategory.positions.some((position) => position.isVisible)
                )),
            (pc) => min(
                    pc.positions.flatMap((p) => p.slots.flatMap((s) => s.range.start!.toMillis()))
                )
        ), [props.event, props.values.slots, props.values.userInfo.fields]);
    const addRemovePositionsCategoryId = (id: PositionsCategoryId) => (value: boolean) => {
            props.change(
                'volunteerRegistration.positionsCategoriesIds',
                addOrRemove(props.values.positionsCategoriesIds, id, value)
            );
        };
    const addRemovePositionId = (id: PositionId) => (value: boolean) => {
            props.change(
                'volunteerRegistration.positionsIds',
                addOrRemove(props.values.positionsIds, id, value)
            );
        };
    const addRemovePositionsSlotId = (id: PositionsSlotId) => (value: boolean) => {
            props.change(
                'volunteerRegistration.positionsSlotsIds',
                addOrRemove(props.values.positionsSlotsIds, id, value)
            );
        };

    return (
        <>
            {props.event.positionDisplay === RegisterPositionDisplay.Category &&
                positionsCategories.map((positionCategory) => (
                        <React.Fragment key={positionCategory.id}>
                            <OnChange name={`positionCategory${positionCategory.id}`}>
                                {addRemovePositionsCategoryId(positionCategory.id)}
                            </OnChange>

                            <Checkbox
                                name={`positionCategory${positionCategory.id}`}
                                text={positionCategory.name}
                            />
                        </React.Fragment>
                    ))}

            {props.event.positionDisplay === RegisterPositionDisplay.Position &&
                positionsCategories.flatMap((positionCategory) => (
                        <React.Fragment key={positionCategory.id}>
                            <Box
                                css={{
                                    marginBottom: '$6',
                                    marginTop: '$6',
                                    '&:first-child': {
                                        marginTop: '0'
                                    }
                                }}
                            >
                                <H3>{positionCategory.name}</H3>
                            </Box>

                            {positionCategory.positions.map((position) => {
                                if (
                                    (props.showHiddenPositions || position.isVisible) &&
                                    canSelectPosition(
                                        position,
                                        position.slots,
                                        props.values.userInfo.fields,
                                        props.values.slots,
                                        props.event.slotDisplay,
                                        props.event.positionFilter
                                    )
                                ) {
                                    return (
                                        <React.Fragment key={position.id}>
                                            <OnChange name={`position${position.id}`}>
                                                {addRemovePositionId(position.id)}
                                            </OnChange>
                                            <Checkbox
                                                name={`position${position.id}`}
                                                text={position.name}
                                            />
                                        </React.Fragment>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </React.Fragment>
                    ))}

            {props.event.positionDisplay === RegisterPositionDisplay.Slot &&
                positionsCategories.flatMap((positionCategory) => positionCategory.positions
                        .filter((position) => (
                                (props.showHiddenPositions || position.isVisible) &&
                                canSelectPosition(
                                    position,
                                    position.slots,
                                    props.values.userInfo.fields,
                                    props.values.slots,
                                    props.event.slotDisplay,
                                    props.event.positionFilter
                                )
                            ))
                        .map((position) => (
                                <React.Fragment key={position.id}>
                                    <Box
                                        css={{
                                            marginBottom: '$6',
                                            marginTop: '$6',
                                            '&:first-child': {
                                                marginTop: '0'
                                            }
                                        }}
                                    >
                                        <H3>
                                            {positionCategory.name} - {position.name}
                                        </H3>
                                    </Box>

                                    {position.slots.map((slot) => {
                                        const shouldDisplay = canSelectSlot(
                                            slot,
                                            props.values.slots,
                                            props.event.slotDisplay,
                                            props.event.positionFilter
                                        );

                                        return (
                                            shouldDisplay && (
                                                <React.Fragment key={slot.id}>
                                                    <OnChange name={`slot${slot.id}`}>
                                                        {addRemovePositionsSlotId(slot.id)}
                                                    </OnChange>

                                                    <Checkbox
                                                        name={`slot${slot.id}`}
                                                        text={intervalService.toDisplayString(
                                                            slot.range,
                                                            { formats: { displayWeekday: true } }
                                                        )}
                                                    />
                                                </React.Fragment>
                                            )
                                        );
                                    })}
                                </React.Fragment>
                            )))}
        </>
    );
};
