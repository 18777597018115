import { Button } from 'common-front/src/designSystem/components/button';
import { RadioText } from 'common-front/src/designSystem/components/radio';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UserInfoEditFragment, UsersInfoId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';

interface IFormProfilesProps {
    userInfoId: Emptyable<UsersInfoId>;
    usersInfos: UserInfoEditFragment[];

    onSuccess(userInfoId: Emptyable<UsersInfoId>, forOther: boolean): void;
}

export const FormProfiles = (props: IFormProfilesProps) => {
    const translate = useTranslate();
    const [userInfoId, setUserInfoId] = React.useState<Emptyable<UsersInfoId>>(props.userInfoId);
    const [forOther, setForOther] = React.useState(!props.userInfoId);

    return (
        <>
            <Box font="gray800 textSm semiBold">{translate('avec_quel_profi_89563')}</Box>

            <Spacer height="4" />

            <Flex direction="column" gap="3" width={1}>
                {props.usersInfos.map((userInfo) => (
                        <RadioText
                            key={userInfo.id}
                            state={userInfoId === userInfo.id ? 'checked' : 'unchecked'}
                            onClick={(state) => {
                                if (state === 'checked') {
                                    setUserInfoId(userInfo.id);
                                    setForOther(false);
                                }
                            }}
                        >
                            {userInfo.nameOrEmail}
                        </RadioText>
                    ))}

                <RadioText
                    state={forOther ? 'checked' : 'unchecked'}
                    onClick={(state) => {
                        if (state === 'checked') {
                            setUserInfoId(null);
                            setForOther(true);
                        }
                    }}
                >
                    {translate('s_inscrire_avec_00521')}
                </RadioText>
            </Flex>

            <Spacer height={{ '@initial': '6', '@tablet': '7' }} />

            <Flex>
                <Button
                    onClick={() => {
                        props.onSuccess(userInfoId, forOther);
                    }}
                >
                    {translate('suivant_62774')}
                </Button>
            </Flex>
        </>
    );
};
