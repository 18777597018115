import { FlexedButton } from 'common-front/src/components/button/button';
import { H3WithSubtitle } from 'common-front/src/components/titles/h3WithSubtitle';
import { VolunteerRegistrationPositions } from 'common-front/src/components/volunteerRegistrationPositions/volunteerRegistrationPositions';
import { RegisterInfosQuery, RegisterPositionDisplay } from 'common/src/generated/types';
import { IRegisterValues } from 'common/src/input/volunteerRegistrationInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { FormApi } from 'final-form';
import * as React from 'react';

interface IPositionsProps {
    event: RegisterInfosQuery['event'];
    form: FormApi<IRegisterValues>;
    values: IRegisterValues;
    invalid: boolean;

    handleSubmit(): void;
}

export const RegisterPositions = (props: IPositionsProps) => {
    const translate = useTranslate();

    return (
        <>
            {props.event.positionDisplay === RegisterPositionDisplay.Category ? (
                <H3WithSubtitle
                    title={translate('cat_gories_de_m_09461')}
                    subtitle={translate('s_lectionnez_le_17459')}
                    marginBottom={24}
                />
            ) : (
                <H3WithSubtitle
                    title={translate('missions_souhai_23225')}
                    subtitle={translate('s_lectionnez_le_37648')}
                    marginBottom={24}
                />
            )}

            <VolunteerRegistrationPositions
                event={props.event}
                values={props.values.volunteerRegistration}
                showHiddenPositions={false}
                change={props.form.change}
            />

            <FlexedButton onClick={props.handleSubmit} isInactive={props.invalid}>
                {translate('s_inscrire_1_82684', props.event.name)}
            </FlexedButton>
        </>
    );
};
