import { FlexedButton } from 'common-front/src/components/button/button';
import { H3WithSubtitle } from 'common-front/src/components/titles/h3WithSubtitle';
import { VolunteerRegistrationSlots } from 'common-front/src/components/volunteerRegistrationSlots/volunteerRegistrationSlots';
import { RegisterInfosQuery, RegisterPositionDisplay } from 'common/src/generated/types';
import { IRegisterValues } from 'common/src/input/volunteerRegistrationInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { FormApi } from 'final-form';
import * as React from 'react';

interface ISlotsProps {
    event: RegisterInfosQuery['event'];
    invalid: boolean;
    form: FormApi<IRegisterValues>;
    values: IRegisterValues;

    handleSubmit(): void;
}

export const RegisterSlots = (props: ISlotsProps) => {
    const translate = useTranslate();

    return (
        <>
            <H3WithSubtitle
                title={translate('cr_neaux_de_dis_89497')}
                subtitle={translate('ajoutez_vos_dis_02011')}
                marginBottom={24}
            />

            <VolunteerRegistrationSlots
                event={props.event}
                values={props.values.volunteerRegistration}
                change={props.form.change}
            />

            <FlexedButton onClick={props.handleSubmit} isInactive={props.invalid}>
                {props.event.positionDisplay === RegisterPositionDisplay.None
                    ? translate('s_inscrire_1_82684', props.event.name)
                    : translate('suivant_cr_ne_20377')}
            </FlexedButton>
        </>
    );
};
