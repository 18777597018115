import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useUniqueIds } from '../../../hooks/useUniqueIds';
import { BaseInputProps } from '../input/baseInputProps';
import { Description } from '../input/description';
import { Hint } from '../input/hint';
import { Label } from '../input/label';
import { StyledInputContainer } from '../input/styledInputContainer';

interface ITimeInputProps extends BaseInputProps {
    value: string;

    onChange(value: string): void;
}

export const TimeInput = (props: ITimeInputProps) => {
    const value =
        isNonEmptyString(props.value) && props.value.length > 5
            ? props.value.substring(0, 5)
            : props.value;
    const { inputId, descId, errorId } = useUniqueIds();

    return (
        <Flex css={props.css} direction="column" width={1}>
            <Label htmlFor={inputId}>{props.label}</Label>

            <Description id={descId}>{props.description}</Description>

            {(props.label || props.description) && <Spacer height="1" />}

            <StyledInputContainer icon={props.icon} state={props.state} cursor="default">
                <Box
                    css={{
                        flex: '1',
                        height: '100%',
                        '& input': {
                            background: 'transparent',
                            border: 'none',
                            color: '$gray800',
                            height: '100%',
                            outline: 'none',
                            width: '100%'
                        }
                    }}
                >
                    <input
                        {...props}
                        id={inputId}
                        aria-describedby={props.description ? descId : undefined}
                        aria-invalid={props.state === 'error'}
                        aria-errormessage={props.state === 'error' ? errorId : undefined}
                        type="time"
                        placeholder={props.placeholder}
                        disabled={props.state === 'disabled'}
                        value={value}
                        onChange={(e) => {
                            props.onChange(e.target.value);
                        }}
                    />
                </Box>
            </StyledInputContainer>

            <Hint id={errorId} state={props.state}>
                {props.hint}
            </Hint>
        </Flex>
    );
};
