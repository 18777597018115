import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Fields } from '../../../components/form/fields';
import { FileS3InputValue } from '../../components/file/fileS3InputValue';
import { ImageInput as ImageInputComponent } from '../../components/image/imageInput';
import { BaseInputProps } from '../../components/input/baseInputProps';

interface IImageInputProps extends Pick<BaseInputProps, 'autoComplete' | 'css' | 'state'> {
    label: React.ReactNode;
    hint?: string;
    prefix: string;
}

export const ImageInput = (props: IImageInputProps) => {
    const key = `${props.prefix}key`;
    const name = `${props.prefix}name`;
    const acl = `${props.prefix}acl`;

    return (
        <Fields
            names={[key, name, acl]}
            render={(fieldsProps) => {
                let value: FileS3InputValue | null = null;

                if (
                    isNonEmptyString(fieldsProps[key].input.value) &&
                    isNonEmptyString(fieldsProps[name].input.value) &&
                    isNonEmptyString(fieldsProps[acl].input.value)
                ) {
                    value = {
                        key: fieldsProps[key].input.value,
                        name: fieldsProps[name].input.value,
                        acl: fieldsProps[acl].input.value
                    };
                }

                return (
                    <ImageInputComponent
                        {...props}
                        value={value}
                        onChange={(newValue) => {
                            fieldsProps[key].input.onChange(newValue?.key ?? '');
                            fieldsProps[name].input.onChange(newValue?.name ?? '');
                            fieldsProps[acl].input.onChange(newValue?.acl ?? '');
                        }}
                    />
                );
            }}
        />
    );
};
