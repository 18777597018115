import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { range } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { displayTime } from './slotPickerUtil';

const _Hour = styled('div', {
    alignItems: 'center',
    color: '$oldBlueBayoux',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '20px',
    fontWeight: '$oldBold',
    height: '36px',
    justifyContent: 'center',
    width: '100%',
    '&:hover': {
        background: '$oldBlue',
        borderRadius: '3px',
        color: 'white'
    },
    variants: {
        selected: {
            true: {
                background: '$oldBlue',
                borderRadius: '3px',
                color: 'white'
            }
        }
    }
});

interface IHoursProps {
    text: string;
    startTime: string | null;
    endTime: string | null;
    showAllDay: boolean;

    selectAllDay(): void;

    selectTime(time: string): void;
}

export const Hours = (props: IHoursProps) => {
    const translate = useTranslate();
    const startOfDay = DateTime.local().startOf('day');

    return (
        <Flex
            css={{
                background: 'white',
                borderRadius: '3px',
                boxShadow: '0 0 24px 0 rgba(0, 0, 0, 0.22), 0 24px 24px 0 rgba(0, 0, 0, 0.3)',
                height: '256px',
                minHeight: '256px',
                userSelect: 'none',
                width: '530px',
                '& > div:first-child': {
                    flex: '1',
                    padding: '$4 $5 $5 $5'
                },
                '& > div:last-child': {
                    flex: '1',
                    padding: '$4 $5 $5 $5'
                }
            }}
        >
            <Flex align="center" justify="end">
                {props.text}
            </Flex>

            <Box
                css={{
                    borderLeft: '1px solid $oldGrayBorder',
                    margin: '$2 0',
                    width: '1px'
                }}
            />

            <Box height={1} width={1} css={{ overflow: 'auto' }}>
                {props.showAllDay && (
                    <_Hour onClick={props.selectAllDay}>{translate('toute_la_journ_93724')}</_Hour>
                )}

                {range(0, 48).map((i: number) => {
                    const hour = startOfDay.plus({ minutes: i * 30 });
                    const time = hour.toFormat('HH:mm');
                    const isSelected: boolean =
                        (props.startTime !== null &&
                            displayTime(translate, props.startTime) === time) ||
                        (props.endTime !== null && displayTime(translate, props.endTime) === time);

                    return (
                        <_Hour
                            selected={isSelected}
                            key={i}
                            onClick={() => {
                                props.selectTime(time);
                            }}
                        >
                            {time}
                        </_Hour>
                    );
                })}
            </Box>
        </Flex>
    );
};
