import { Box } from 'common/src/designSystem/components/box';
import * as React from 'react';

interface ITitleProps {
    children: React.ReactNode;
}

export const Title = (props: ITitleProps) => (
        <Box font="gray500 textXs semiBold" css={{ padding: '$2 $3' }}>
            {props.children}
        </Box>
    );
