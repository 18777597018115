import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import {
    AccreditationDisplay,
    AccreditationsSlotId,
    DelegationAccreditationFragment,
    FormAccreditationsOptionsFragment,
    FormRegisterAccreditationFragment,
    VolunteersRegistrationsSlotInput
} from 'common/src/generated/types';
import { addOrRemove } from 'common/src/util/array';
import { isNonEmptyString } from 'common/src/util/string';
import { canSelect } from 'common/src/vo/accreditationSlot';
import { compact, difference, sortBy } from 'lodash-es';
import * as React from 'react';
import { CheckboxText, ICheckboxState } from '../../../designSystem/components/checkbox';
import { FormMissionDescriptionModal } from '../../missions/formMissionDescriptionModal';
import { FormAccreditationsSlotsCalendars } from './formAccreditationsSlotsCalendars';
import { FormAccreditationsSlotsList } from './formAccreditationsSlotsList';
import { FormAccreditationsSlotsRadio } from './formAccreditationsSlotsRadio';

interface IFormAccreditationsSlotsAccreditationProps {
    accreditation: FormRegisterAccreditationFragment;
    accreditationsSlotsIds: AccreditationsSlotId[];
    delegationAccreditations: DelegationAccreditationFragment[];
    options: FormAccreditationsOptionsFragment;
    prefix: string;
    slots: VolunteersRegistrationsSlotInput[];

    change(name: string, value: any): void;
}

export const FormAccreditationsSlotsAccreditation = (
    props: IFormAccreditationsSlotsAccreditationProps
) => {
    const [state, setState] = React.useState<ICheckboxState>('checked');
    const [isDescriptionOpen, setIsDescriptionOpen] = React.useState(false);
    const slots = React.useMemo(() => sortBy(
            props.accreditation.slots.filter((slot) => canSelect(slot, props.slots, props.options, props.delegationAccreditations)),
            (as) => compact([as.date?.toMillis(), as.name.toLowerCase()])
        ), [props.accreditation.slots, props.delegationAccreditations, props.slots, props.options]);
    const toggle = React.useCallback(() => {
        const newState = state === 'checked' ? 'unchecked' : 'checked';

        setState(newState);

        if (newState === 'unchecked') {
            props.change(
                `${props.prefix}accreditationsSlotsIds`,
                difference(
                    props.accreditationsSlotsIds,
                    slots.map((s) => s.id)
                )
            );
        }
    }, [props.accreditationsSlotsIds, slots, state, setState, props.change]);
    const showDescription =
        isNonEmptyString(props.accreditation.description) &&
        props.options.showAccreditationDescription;

    return (
        <>
            {props.accreditation.hasSlots ? (
                <Flex
                    direction="column"
                    css={{
                        border: '1px solid $gray200',
                        borderRadius: '$1',
                        boxShadow: '$xs'
                    }}
                >
                    <Flex
                        align="center"
                        gap="2"
                        css={{ cursor: 'pointer', padding: '$4 $5' }}
                        onClick={toggle}
                    >
                        <Box color="gray900" fontSize="textXs">
                            <I icon={state === 'checked' ? 'chevron-down' : 'chevron-up'} />
                        </Box>

                        <Box font="gray800 textSm medium">{props.accreditation.name}</Box>

                        {showDescription && (
                            <Box
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                    setIsDescriptionOpen(true);
                                }}
                            >
                                <I icon="circle-exclamation" />
                            </Box>
                        )}
                    </Flex>

                    {state === 'checked' && (
                        <Flex
                            direction="column"
                            gap="3"
                            css={{
                                borderTop: '1px solid $gray200',
                                padding: '$4 $5'
                            }}
                        >
                            {props.accreditation.accreditationDisplay ===
                            AccreditationDisplay.Calendar ? (
                                <FormAccreditationsSlotsCalendars
                                    accreditationsSlotsIds={props.accreditationsSlotsIds}
                                    prefix={props.prefix}
                                    slots={slots}
                                    change={props.change}
                                />
                            ) : props.accreditation.accreditationDisplay ===
                              AccreditationDisplay.Radio ? (
                                <FormAccreditationsSlotsRadio
                                    accreditation={props.accreditation}
                                    accreditationsSlotsIds={props.accreditationsSlotsIds}
                                    prefix={props.prefix}
                                    slots={slots}
                                    change={props.change}
                                />
                            ) : (
                                <FormAccreditationsSlotsList
                                    accreditation={props.accreditation}
                                    accreditationsSlotsIds={props.accreditationsSlotsIds}
                                    prefix={props.prefix}
                                    slots={slots}
                                    change={props.change}
                                />
                            )}
                        </Flex>
                    )}
                </Flex>
            ) : (
                <Flex
                    align="center"
                    gap="2"
                    css={{
                        border: '1px solid $gray200',
                        borderRadius: '$1',
                        boxShadow: '$xs',
                        padding: '$4 $5'
                    }}
                >
                    <CheckboxText
                        state={
                            props.accreditationsSlotsIds.includes(props.accreditation.hiddenSlotId)
                                ? 'checked'
                                : 'unchecked'
                        }
                        onClick={(state) => {
                            props.change(
                                `${props.prefix}accreditationsSlotsIds`,
                                addOrRemove(
                                    props.accreditationsSlotsIds,
                                    props.accreditation.hiddenSlotId,
                                    state === 'checked'
                                )
                            );
                        }}
                    >
                        {props.accreditation.name}
                    </CheckboxText>

                    {showDescription && (
                        <Box
                            css={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                setIsDescriptionOpen(true);
                            }}
                        >
                            <I icon="circle-exclamation" />
                        </Box>
                    )}
                </Flex>
            )}

            {isDescriptionOpen && (
                <FormMissionDescriptionModal
                    description={props.accreditation.description}
                    name={props.accreditation.name}
                    onClose={() => {
                        setIsDescriptionOpen(false);
                    }}
                />
            )}
        </>
    );
};
