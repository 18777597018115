import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    EventRegisterSlotsPositionsFragment,
    RegisterSlotDisplay,
    VolunteersRegistrationInput
} from 'common/src/generated/types';
import { VolunteerRegistrationSlotInputService } from 'common/src/input/volunteerRegistrationSlotInput';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Link } from '../link/link';
import { SlotInput } from '../reactFinalForm/input/slot/slot';
import { UppercaseTitle } from '../titles/uppercaseTitle';
import { VolunteerRegistrationCustomSlots } from './volunteerRegistrationCustomSlots';
import { VolunteerRegistrationSlotsDays } from './volunteerRegistrationSlotsDays';

interface IVolunteerRegistrationSlotsProps {
    event: EventRegisterSlotsPositionsFragment;
    values: VolunteersRegistrationInput;

    change(name: string, value: any): void;
}

export const VolunteerRegistrationSlots = (props: IVolunteerRegistrationSlotsProps) => {
    const translate = useTranslate();
    const volunteerRegistrationSlotInput = useService(VolunteerRegistrationSlotInputService);
    const [mainKey, setMainKey] = React.useState(0);

    if (props.event.slotDisplay === RegisterSlotDisplay.Display) {
        return (
            <FieldArray name="volunteerRegistration.slots" key={mainKey}>
                {({ fields }) => (
                        <>
                            {fields.map((field, index) => {
                                const canDelete = fields.length! > 1;

                                return (
                                    <div key={index}>
                                        <UppercaseTitle
                                            title={translate('cr_neau_n_1_80362', index + 1)}
                                        />

                                        <Flex
                                            css={{
                                                '& > div:not(:last-child)': {
                                                    marginRight: '$4'
                                                },
                                                '& > .h-remove': {
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    marginTop: '36px'
                                                }
                                            }}
                                        >
                                            <SlotInput
                                                prefix={`${field}.`}
                                                min={props.event.startAt}
                                                max={props.event.endAt.endOf('day')}
                                            />

                                            <div
                                                className="h-remove"
                                                onClick={() => {
                                                    if (canDelete) {
                                                        fields.remove(index);
                                                        setMainKey(mainKey + 1);
                                                    }
                                                }}
                                            >
                                                {canDelete && <span>&#10005;</span>}
                                            </div>
                                        </Flex>
                                    </div>
                                );
                            })}

                            <Box css={{ marginBottom: '$7' }}>
                                <Link
                                    onClick={() => {
                                        fields.push(
                                            volunteerRegistrationSlotInput.volunteersRegistrationsSlotInputDefault(
                                                props.event.startAt
                                            )
                                        );
                                        setMainKey(mainKey + 1);
                                    }}
                                >
                                    + {translate('ajouter_un_cr_n_62790')}
                                </Link>
                            </Box>
                        </>
                    )}
            </FieldArray>
        );
    } else if (props.event.slotDisplay === RegisterSlotDisplay.DisplayDays) {
        return (
            <VolunteerRegistrationSlotsDays
                event={props.event}
                values={props.values}
                change={props.change}
            />
        );
    } else if (props.event.slotDisplay === RegisterSlotDisplay.Custom) {
        return (
            <VolunteerRegistrationCustomSlots
                event={props.event}
                values={props.values}
                change={props.change}
            />
        );
    } else {
        return null;
    }
};
