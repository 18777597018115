import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isEqual, sortBy, without } from 'lodash-es';
import * as React from 'react';
import { Dropdown } from '../dropdown/dropdown';
import { Menu } from '../dropdown/menu';
import { Trigger } from '../dropdown/trigger';
import { BaseInputProps } from '../input/baseInputProps';
import { Description } from '../input/description';
import { Hint } from '../input/hint';
import { Label } from '../input/label';
import { StyledInputContainer } from '../input/styledInputContainer';
import { TextInput } from '../textInput';
import { RichSelectGroup } from './richSelectGroup';
import { RichSelectItem } from './richSelectItem';
import { getValueToName, parseSelectChildren } from './selectUtil';

export type BaseRichSelectProps = {
    createText?: string;
    isCreateVisible?: boolean;
    isSearchVisible?: boolean;
    isSelectAllVisible?: boolean;
    multiple?: boolean;
    renderOnPortal?: boolean;
    searchElement?: React.ReactNode;
    searchPlaceholder?: string;
    triggerElem?: React.ReactNode;

    onCreateClick?(): void;
    onClick?(value: number, isSelected: boolean): void;
};

type IRichSelectProps<T> = {
    values: T[];

    onChange(values: T[]): void;
} & BaseRichSelectProps &
    BaseInputProps;

export const RichSelect = <T extends {}>(props: IRichSelectProps<T>) => {
    const translate = useTranslate();
    const textInputRef = React.useCallback((node: HTMLInputElement) => {
        node?.focus();
    }, []);
    const [isOpen, setIsOpen] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const parsedChildren = React.useMemo(() => parseSelectChildren<T>(props.children), [props.children]);
    const allValues = React.useMemo(() => parsedChildren.flatMap((c) => c.type === 'option' ? [c.value] : []), [parsedChildren]);
    const displayedChildren = React.useMemo(() => {
        const s = (search || '').trim().toLowerCase();

        return parsedChildren
            .filter((c) => s === '' || c.type === 'group' || c.text.toLowerCase().includes(s))
            .filter((c, index, array) => {
                const nextChild = array[index + 1];

                return c.type === 'option' || nextChild?.type === 'option';
            });
    }, [parsedChildren, search]);
    const valueToName = React.useMemo(() => getValueToName(parsedChildren), [parsedChildren]);
    const isAllSelected = React.useMemo(() => isEqual(sortBy(props.values), sortBy(allValues)), [props.values, allValues]);
    const onSelectAllClick = React.useCallback(() => {
        if (isAllSelected) {
            props.onChange([]);
        } else {
            props.onChange(allValues);
        }
    }, [props.onChange, allValues, isAllSelected]);

    React.useEffect(() => {
        if (!isOpen) {
            setSearch('');
        }
    }, [isOpen]);

    return (
        <Flex direction="column" width={1} css={props.css}>
            <Label>{props.label}</Label>

            <Description>{props.description}</Description>

            {(props.label || props.description) && <Spacer height="1" />}

            <Dropdown
                isOpen={isOpen}
                onStateChange={(newIsOpen) => {
                    setIsOpen(newIsOpen);
                }}
            >
                <Trigger>
                    {props.triggerElem || (
                        <StyledInputContainer
                            icon={props.icon}
                            rightIcon={isOpen ? 'chevron-up' : 'chevron-down'}
                            height="fluid"
                            state={props.state}
                            cursor="pointer"
                            css={{
                                maxHeight: '200px',
                                overflowY: 'auto',
                                padding: '$2 $3',
                                userSelect: 'none'
                            }}
                        >
                            {isNonEmptyArray(props.values) ? (
                                props.multiple ? (
                                    <Flex
                                        gap="2"
                                        wrap="wrap"
                                        height={1}
                                        css={{ flex: '1', overflowY: 'auto' }}
                                    >
                                        {props.values.map((value, index) => (
                                                <Badge
                                                    key={index}
                                                    rightIcon="xmark"
                                                    ellipsis={true}
                                                    onRightIconClick={(e) => {
                                                        e.stopPropagation();
                                                        e.nativeEvent.stopImmediatePropagation();

                                                        props.onChange(
                                                            without(props.values, value)
                                                        );
                                                    }}
                                                >
                                                    {valueToName[value]}
                                                </Badge>
                                            ))}
                                    </Flex>
                                ) : (
                                    <Box color="gray800" css={{ flex: '1' }}>
                                        {valueToName[props.values[0]]}
                                    </Box>
                                )
                            ) : (
                                <Box color="gray500" css={{ flex: '1' }}>
                                    {props.placeholder}
                                </Box>
                            )}
                        </StyledInputContainer>
                    )}
                </Trigger>

                <Menu
                    placement="bottom"
                    renderOnPortal={props.renderOnPortal}
                    width="match"
                    css={{ padding: '0' }}
                >
                    <Flex direction="column" width={1}>
                        <Flex direction="column" width={1} css={{ padding: '$3' }}>
                            {props.isSearchVisible && (
                                <>
                                    {props.searchElement ? (
                                        props.searchElement
                                    ) : (
                                        <TextInput
                                            ref={textInputRef}
                                            icon="magnifying-glass"
                                            placeholder={
                                                props.searchPlaceholder ||
                                                translate('rechercher_50038')
                                            }
                                            state="search"
                                            value={search}
                                            onChange={setSearch}
                                        />
                                    )}

                                    <Spacer height="3" />
                                </>
                            )}

                            <Box css={{ maxHeight: '250px', overflowY: 'auto' }}>
                                {displayedChildren.map((child, index) => {
                                    if (child.type === 'group') {
                                        return (
                                            <RichSelectGroup key={index} index={index}>
                                                {child.text}
                                            </RichSelectGroup>
                                        );
                                    } else {
                                        const value = child.value as any;
                                        const isSelected =
                                            child.selected ?? props.values.includes(value);

                                        return (
                                            <RichSelectItem
                                                key={index}
                                                isCheckbox={props.multiple === true}
                                                isSelected={isSelected}
                                                value={child.value}
                                                onClick={() => {
                                                    if (props.onClick) {
                                                        props.onClick(value, isSelected);
                                                    } else if (props.multiple) {
                                                        props.onChange(
                                                            isSelected
                                                                ? without(props.values, value)
                                                                : props.values.concat(value)
                                                        );
                                                    } else {
                                                        props.onChange(isSelected ? [] : [value]);
                                                        setIsOpen(false);
                                                    }
                                                }}
                                            >
                                                {child.text}
                                            </RichSelectItem>
                                        );
                                    }
                                })}
                            </Box>
                        </Flex>

                        {props.isCreateVisible && (
                            <Flex
                                width={1}
                                css={{
                                    background: '$gray50',
                                    boxShadow:
                                        '0px -1px 3px rgba(16, 24, 40, 0.1), 0px -1px 2px rgba(16, 24, 40, 0.06)',
                                    color: '$primary700',
                                    cursor: 'pointer',
                                    padding: '$3 $4',
                                    userSelect: 'none'
                                }}
                                onClick={() => {
                                    props.onCreateClick?.();

                                    setIsOpen(false);
                                }}
                            >
                                + {props.createText || translate('cr_er_82895')}
                            </Flex>
                        )}

                        {props.isSelectAllVisible && (
                            <Flex
                                width={1}
                                css={{
                                    background: '$gray50',
                                    boxShadow:
                                        '0px -1px 3px rgba(16, 24, 40, 0.1), 0px -1px 2px rgba(16, 24, 40, 0.06)',
                                    color: '$primary700',
                                    cursor: 'pointer',
                                    padding: '$3 $4',
                                    userSelect: 'none'
                                }}
                                onClick={onSelectAllClick}
                            >
                                {isAllSelected
                                    ? translate('tout_d_s_lectio_37372')
                                    : translate('tout_s_lectionn_48027')}
                            </Flex>
                        )}
                    </Flex>
                </Menu>
            </Dropdown>

            <Hint state={props.state}>{props.hint}</Hint>
        </Flex>
    );
};
