import { injectable } from 'inversify';
import {
    ALL_ICON,
    ALL_POSITIONSTATE,
    FilterType,
    PositionsSegmentsQuery,
    SegmentCustomFieldFragment
} from '../../generated/types';
import { CountriesService } from '../../services/countriesService';
import { DateTimeService } from '../../services/dateTimeService';
import { TranslationService } from '../../services/translationService';
import { isNonEmptyArray } from '../../util/array';
import { Filter, PossibleColumn, SegmentService } from '../segment';

@injectable()
export class PositionsSegmentsService extends SegmentService {
    constructor(
        countriesService: CountriesService,
        dateTimeService: DateTimeService,
        translationService: TranslationService
    ) {
        super(countriesService, dateTimeService, translationService);
    }

    getPositionsFilters(
        eventInfos: PositionsSegmentsQuery['event'],
        customFields: SegmentCustomFieldFragment[]
    ): Filter[] {
        const filters: Filter[] = [
            {
                color: 'warning',
                slug: 'name',
                name: this.t('nom_de_la_missi_64605'),
                fieldType: FilterType.Text,
                hideEmpty: true
            }
        ];

        if (isNonEmptyArray(eventInfos.positionsCategories)) {
            filters.push({
                color: 'warning',
                slug: 'positionCategory',
                name: this.t('cat_gorie_00291'),
                fieldType: FilterType.Select,
                values: eventInfos.positionsCategories,
                hideEmpty: true
            });
        }

        filters.push(
            {
                color: 'warning',
                slug: 'positionSlot',
                name: this.t('cr_neau_11117'),
                fieldType: FilterType.DateTimeRange,
                startAt: eventInfos.startAt,
                endAt: eventInfos.endAt
            },
            {
                color: 'warning',
                slug: 'positionSlotName',
                name: this.t('nom_du_cr_neau_54351'),
                fieldType: FilterType.Text
            }
        );

        filters.push({
            color: 'warning',
            slug: 'tag',
            name: this.t('Tag'),
            fieldType: FilterType.Select,
            needLoading: true,
            organizationId: eventInfos.organizationId
        });

        filters.push(
            {
                color: 'warning',
                slug: 'acronym',
                name: this.t('Acronym'),
                fieldType: FilterType.Text
            },
            {
                color: 'warning',
                slug: 'color',
                name: this.t('Color'),
                fieldType: FilterType.Text,
                hideEmpty: true
            },
            {
                color: 'warning',
                slug: 'icon',
                name: this.t('ic_ne_55554'),
                fieldType: FilterType.Select,
                values: ALL_ICON.map((icon) => ({
                    id: icon,
                    name: this.t(icon)
                })),
                hideEmpty: true
            },
            {
                color: 'warning',
                slug: 'address',
                name: this.t('Address'),
                fieldType: FilterType.Text
            },
            {
                color: 'warning',
                slug: 'latitude',
                name: this.t('Latitude'),
                fieldType: FilterType.Number
            },
            {
                color: 'warning',
                slug: 'longitude',
                name: this.t('Longitude'),
                fieldType: FilterType.Number
            },
            {
                color: 'warning',
                slug: 'state',
                name: this.t('statut_des_ress_73550'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                values: ALL_POSITIONSTATE.map((positionState) => ({
                    id: positionState,
                    name: this.t(positionState)
                }))
            },
            {
                color: 'warning',
                slug: 'fillingRate',
                name: this.t('taux_de_remplis_11913'),
                fieldType: FilterType.Number,
                hideEmpty: true
            }
        );

        filters.push(...this.customFieldsToFilters(customFields));

        return filters;
    }

    getPositionsPossibleColumns(customFields: SegmentCustomFieldFragment[]): PossibleColumn[] {
        const possiblesColumns: PossibleColumn[] = [
            {
                slug: 'name',
                name: this.t('nom_de_la_missi_64605')
            },
            {
                slug: 'category',
                name: this.t('cat_gorie_00291')
            },
            {
                slug: 'slot',
                name: this.t('cr_neau_11117')
            },
            {
                slug: 'resources',
                name: this.t('membres_11310')
            },
            {
                slug: 'tags',
                name: this.t('tags_79499')
            },
            {
                slug: 'address',
                name: this.t('Address')
            },
            {
                slug: 'latitude',
                name: this.t('latitude_46176')
            },
            {
                slug: 'longitude',
                name: this.t('longitude_01695')
            },
            {
                slug: 'acronym',
                name: this.t('Acronym')
            },
            {
                slug: 'color',
                name: this.t('Color')
            },
            {
                slug: 'icon',
                name: this.t('ic_ne_55554')
            },
            {
                slug: 'state',
                name: this.t('statut_des_ress_73550')
            },
            {
                slug: 'fillingRate',
                name: this.t('taux_de_remplis_11913')
            }
        ];

        return possiblesColumns.concat(this.customFieldsToPossibleColumns(customFields));
    }
}
