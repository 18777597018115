import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IDelegationMemberCreateUpdateBoxProps {
    children: React.ReactNode;
    title?: string;
}

export const DelegationMemberCreateUpdateBox = (props: IDelegationMemberCreateUpdateBoxProps) => (
        <Flex
            direction="column"
            css={{
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                padding: '$6'
            }}
        >
            {isNonEmptyString(props.title) && (
                <>
                    <Box font="gray800 textMd semiBold">{props.title}</Box>

                    <Spacer height="5" />
                </>
            )}

            {props.children}
        </Flex>
    );
