import { Flex } from 'common/src/designSystem/components/flex';
import {
    AccreditationsSlotId,
    FormRegisterAccreditationSlotFragment
} from 'common/src/generated/types';
import {
    dateSlotsStats,
    newCalendarsAccreditationsSlotsIds
} from 'common/src/vo/accreditationSlot';
import { range } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { CalendarInput } from '../../../designSystem/components/date/calendarInput';

interface IFormAccreditationsSlotsCalendarsProps {
    accreditationsSlotsIds: AccreditationsSlotId[];
    prefix: string;
    slots: FormRegisterAccreditationSlotFragment[];

    change(name: string, value: any): void;
}

export const FormAccreditationsSlotsCalendars = ({
    accreditationsSlotsIds,
    change,
    prefix,
    slots
}: IFormAccreditationsSlotsCalendarsProps) => {
    const { minDate, maxDate, numberOfMonths } = React.useMemo(() => dateSlotsStats(slots), [slots]);
    const availableValues = React.useMemo(() => slots.flatMap((slot) => {
            if (slot.date?.isValid) {
                return [{ date: slot.date!, isSelectable: true }];
            } else {
                return [];
            }
        }), [slots, accreditationsSlotsIds]);
    const selectedDates = React.useMemo(() => slots.flatMap((slot) => {
            if (slot.date?.isValid && accreditationsSlotsIds.includes(slot.id)) {
                return [slot.date];
            } else {
                return [];
            }
        }), [slots, accreditationsSlotsIds]);

    return (
        <Flex gap="6" wrap="wrap">
            {range(0, numberOfMonths).map((i) => {
                const firstDayOfMonth = minDate.plus({ month: i }).startOf('month');
                const min = i === 0 ? minDate : firstDayOfMonth;
                const max = i === numberOfMonths - 1 ? maxDate : firstDayOfMonth.endOf('month');
                const values = selectedDates.filter((date) =>
                    date.startOf('month').equals(firstDayOfMonth)
                );

                return (
                    <CalendarInput
                        key={i}
                        availableValues={availableValues}
                        firstDayOfMonth={firstDayOfMonth}
                        min={min}
                        max={max}
                        values={values}
                        onChange={(newDateTimes: DateTime[]) => {
                            change(
                                `${prefix}accreditationsSlotsIds`,
                                newCalendarsAccreditationsSlotsIds(
                                    accreditationsSlotsIds,
                                    slots,
                                    firstDayOfMonth,
                                    newDateTimes
                                )
                            );
                        }}
                    />
                );
            })}
        </Flex>
    );
};
