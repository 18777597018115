import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { MembersPaths } from 'common/src/util/membersPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useMemberCampaignsQuery } from '../../../generated/graphqlHooks';
import { DocumentSkeleton } from '../../common/documents/document';
import { SpaceContainer } from '../../common/spaceContainer';
import { MemberCampaignsCampaign } from './memberCampaignsCampaign';

export const MemberCampaignsList = () => {
    const {
        history,
        translate,
        params: { organizationId, userInfoId }
    } = useHeavent();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const { data, isLoading } = useMemberCampaignsQuery({
        organizationId,
        userInfoId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined
    });

    return (
        <SpaceContainer
            isLoading={isLoading}
            organization={data.organization}
            spaceDesign={{
                themeColors: data.organization?.membersSpaceDesign?.colors,
                bannerSrc: data.organization?.membersSpaceDesign?.banner?.url,
                logoSrc: data.organization?.membersSpaceDesign?.logo?.url
            }}
        >
            <Box
                color="primary700"
                fontWeight="medium"
                css={{ cursor: 'pointer' }}
                onClick={() => {
                    history.push(MembersPaths.MEMBER(organizationId, userInfoId));
                }}
            >
                <I icon="arrow-left" /> {translate('retour_l_accu_00810')}
            </Box>

            <Spacer height={{ '@initial': '2', '@desktop': '3' }} />

            <Box font="gray900 displayXs semiBold">{translate('vos_messages_14954')}</Box>

            <Spacer height={{ '@initial': '6', '@tablet': '7', '@desktop': '8' }} />

            <Flex
                width={1}
                css={{
                    background: '$gray100',
                    borderRadius: '$1',
                    padding: '$4'
                }}
            >
                <TextInput
                    icon="magnifying-glass"
                    placeholder={translate('rechercher_un_m_84486')}
                    value={name}
                    onChange={setName}
                />
            </Flex>

            <Spacer height="6" />

            {isLoading ? (
                <>
                    <DocumentSkeleton />
                    <Spacer height="4" />
                    <DocumentSkeleton />
                </>
            ) : (
                data.userInfo.campaigns.nodes.map((campaign, index) => (
                        <React.Fragment key={campaign.id}>
                            <MemberCampaignsCampaign campaign={campaign} />

                            {index !== data.userInfo.campaigns.nodes.length - 1 && (
                                <Spacer height="4" />
                            )}
                        </React.Fragment>
                    ))
            )}
        </SpaceContainer>
    );
};
