import { injectable } from 'inversify';
import { camelCase } from 'lodash-es';
import { parse } from 'papaparse';
import * as yup from 'yup';
import { DocumentInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { isNonEmptyString } from '../util/string';
import { CsvInputService } from './csvInput';
import { DocumentInputService } from './documentInput';
import { InputService } from './inputService';

export type DelegationsImportInput = {
    csv: DocumentInput;
};

export const DELEGATION_COLUMNS = [
    'name',
    'leadersEmails',
    'leadersFirstnames',
    'leadersLastnames',
    'formId',
    'deadline',
    'maxResources',
    'privateNote',
    'tags'
] as const;

const anyEmptyHeaderColumn = (data: string[][]) => data[0].filter((column) => !isNonEmptyString(column)).length > 0;

const anyMissingHeaderColumn = (data: string[][]) => !DELEGATION_COLUMNS.reduce((acc, column) => acc && data[0].map(camelCase).includes(column), true);

@injectable()
export class DelegationsImportInputService extends InputService {
    constructor(
        private documentInputService: DocumentInputService,
        private csvInputService: CsvInputService,
        translationService: TranslationService
    ) {
        super(translationService);
    }

    default(): { input: DelegationsImportInput } {
        return {
            input: {
                csv: this.documentInputService.documentInputDefault()
            }
        };
    }

    schema() {
        return yup.object().shape({
            input: yup.object().shape({
                csv: yup.object().shape({
                    acl: yup.reach(this.csvInputService.schema(), 'acl'),
                    key: yup.reach(this.csvInputService.schema(), 'key'),
                    name: yup.reach(this.csvInputService.schema(), 'name'),
                    file: (
                        yup.reach(this.csvInputService.schema(), 'file') as yup.MixedSchema
                    ).test({
                        name: 'header-is-complete-for-delegations',
                        test: (file, { createError, path }) => {
                            if (!file) {
                                return true;
                            } else {
                                return new Promise((resolve) => {
                                    parse<string[]>(file as File, {
                                        preview: 1,
                                        complete: ({ data, errors }) => {
                                            if (errors.length || anyEmptyHeaderColumn(data)) {
                                                resolve(
                                                    createError({
                                                        message: this.t('le_fichier_csv_14924'),
                                                        path
                                                    })
                                                );
                                            } else if (anyMissingHeaderColumn(data)) {
                                                resolve(
                                                    createError({
                                                        message: this.t(
                                                            'l_en_t_te_du_fi_79224',
                                                            this.translationService.formatListValues(
                                                                DELEGATION_COLUMNS as unknown as string[]
                                                            )
                                                        ),
                                                        path
                                                    })
                                                );
                                            } else {
                                                resolve(true);
                                            }
                                        }
                                    });
                                });
                            }
                        }
                    })
                })
            })
        });
    }
}
