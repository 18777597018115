import { Box } from 'common/src/designSystem/components/box';
import * as React from 'react';

interface ISubtitleProps {
    text: string;
}

export const Subtitle = (props: ISubtitleProps) => (
        <Box
            css={{
                color: '$oldBlueBayoux',
                marginBottom: '$1'
            }}
        >
            {props.text}
        </Box>
    );
