import { Box } from 'common/src/designSystem/components/box';
import {
    CustomFieldWithConditionFragment,
    FormPositionRanking,
    LoginCodeSource,
    RegisterInfosQuery,
    RegisterPositionDisplay,
    RegisterSlotDisplay,
    UserEmail,
    UserInfoEditFragment
} from 'common/src/generated/types';
import {
    IRegisterValues,
    VolunteerRegistrationInputService
} from 'common/src/input/volunteerRegistrationInput';
import { ValidateService } from 'common/src/services/validateService';
import { useParams, useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { MutableState } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { isEqual, noop } from 'lodash-es';
import * as React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { Email } from '../auth/email';
import {
    executeUserInfosQuery,
    useVolunteerRegistrationCreateMutation
} from '../generated/graphqlHooks';
import { Closed } from './closed';
import { Informations } from './informations';
import { RegisterPositions } from './registerPositions';
import { RegisterSlots } from './registerSlots';
import { Step } from './step';
import { Success } from './success';

interface IRegisterFormProps {
    event: RegisterInfosQuery['event'];
}

export const RegisterForm = (props: IRegisterFormProps) => {
    const translate = useTranslate();
    const volunteerRegistrationInput = useService(VolunteerRegistrationInputService);
    const validateService = useService(ValidateService);
    const { organizationId, eventId } = useParams();
    const [currentEmail, setEmail] = React.useState<string | undefined>(undefined);
    const customFields: CustomFieldWithConditionFragment[] = React.useMemo(() => props.event.eventsFields.map(({ field }) => field) ?? [], []);
    const [usersInfos, setUsersInfos] = React.useState<UserInfoEditFragment[]>([]);
    const steps = React.useMemo(() => {
        const allSteps: any[] = [
            {
                title: translate('informations_pe_37892'),
                schema: volunteerRegistrationInput.volunteerRegistrationInformationsSchema(
                    props.event.eventsFields
                ),
                component({
                    form,
                    values,
                    invalid,
                    handleSubmit,
                    errors
                }: FormRenderProps<IRegisterValues>) {
                    return (
                        <Informations
                            event={props.event}
                            form={form}
                            values={values}
                            invalid={invalid}
                            customFields={customFields}
                            usersInfos={usersInfos}
                            email={currentEmail!}
                            errors={errors}
                            handleSubmit={handleSubmit}
                        />
                    );
                }
            }
        ];

        if (props.event.slotDisplay !== RegisterSlotDisplay.Hide) {
            allSteps.push({
                title: translate('disponibilit_s_49923'),
                schema: volunteerRegistrationInput.volunteerRegistrationSlotsSchema(
                    props.event.slotDisplay,
                    props.event.startAt,
                    props.event.endAt
                ),
                component({
                    form,
                    values,
                    invalid,
                    handleSubmit
                }: FormRenderProps<IRegisterValues>) {
                    return (
                        <RegisterSlots
                            event={props.event}
                            form={form}
                            values={values}
                            invalid={invalid}
                            handleSubmit={handleSubmit}
                        />
                    );
                }
            });
        }

        if (props.event.positionDisplay !== RegisterPositionDisplay.None) {
            allSteps.push({
                title: translate('missions_63972'),
                schema: volunteerRegistrationInput.volunteerRegistrationWishedPositionsSchema(
                    FormPositionRanking.Unranked,
                    props.event.positionDisplay
                ),
                component({
                    form,
                    values,
                    invalid,
                    handleSubmit
                }: FormRenderProps<IRegisterValues>) {
                    return (
                        <RegisterPositions
                            event={props.event}
                            form={form}
                            values={values}
                            invalid={invalid}
                            handleSubmit={handleSubmit}
                        />
                    );
                }
            });
        }

        return allSteps;
    }, [currentEmail, usersInfos]);
    const emailStepIndex = 0;
    const successStepIndex = steps.length + 1;
    const [stepIndex, _setStepIndex] = React.useState(0);
    const [initialValues, setInitialValues] = React.useState<IRegisterValues | null>(null);
    const [currentToken, setToken] = React.useState<string | undefined>(undefined);
    const { mutate } = useVolunteerRegistrationCreateMutation();
    const centeredContainerRef = React.useRef<HTMLDivElement | null>(null);
    const setStepIndex = (selectedStepIndex: number) => {
        if (centeredContainerRef.current) {
            centeredContainerRef.current.scrollTop = 0;
        }

        _setStepIndex(selectedStepIndex);
    };

    if (props.event.isFormOpen) {
        return (
            <>
                <Box css={{ marginBottom: '$6' }}>
                    <Step
                        stepNumber={emailStepIndex + 1}
                        title={translate('e_mail_13869')}
                        canSelect={stepIndex !== emailStepIndex && stepIndex !== successStepIndex}
                        isOpen={stepIndex === emailStepIndex}
                        isCompleted={stepIndex > emailStepIndex}
                        onSelect={() => {
                            if (stepIndex >= emailStepIndex) {
                                setStepIndex(emailStepIndex);
                            }
                        }}
                    >
                        <Email
                            buttonText={translate('suivant_infor_71828')}
                            source={LoginCodeSource.VolunteerRegister}
                            description={props.event.description}
                            onSuccess={async (email, token) => {
                                let userInfo: UserInfoEditFragment | null = null;

                                if (token) {
                                    const { user } = await executeUserInfosQuery(
                                        { organizationId },
                                        token
                                    );

                                    userInfo = user.userInfo;
                                    setUsersInfos(user.usersInfos);
                                }

                                const volunteerRegistration =
                                    volunteerRegistrationInput.volunteersRegistrationInputDefault(
                                        props.event,
                                        userInfo,
                                        customFields
                                    );
                                setInitialValues({
                                    userInfoId: userInfo?.id,
                                    forOther: false,
                                    volunteerRegistration
                                });

                                setEmail(email);
                                setToken(token);
                                setStepIndex(1);
                            }}
                        />
                    </Step>
                </Box>

                <Form
                    initialValues={initialValues!}
                    initialValuesEqual={isEqual}
                    validate={(values: IRegisterValues) => {
                        if (stepIndex >= 1 && stepIndex !== successStepIndex) {
                            return validateService.validateForForm(steps[stepIndex - 1].schema)(
                                values
                            );
                        } else {
                            return {};
                        }
                    }}
                    mutators={{
                        ...arrayMutators,
                        forceErrors(_args: any[], state: MutableState<IRegisterValues>) {
                            Object.values(state.fields).forEach((field) => {
                                field.data = {
                                    ...field.data,
                                    forceDisplayError: true
                                };
                            });
                        }
                    }}
                    onSubmit={async (values: IRegisterValues) => {
                        if (stepIndex === steps.length) {
                            await mutate(
                                {
                                    organizationId,
                                    eventId,
                                    email: currentEmail! as UserEmail,
                                    forOther: values.forOther,
                                    volunteersRegistration: values.volunteerRegistration
                                },
                                currentToken
                            );
                        }

                        setStepIndex(stepIndex + 1);
                    }}
                    render={(formProps) => steps.map((step, index: number) => (
                                <Box css={{ marginBottom: '$6' }} key={index}>
                                    <Step
                                        stepNumber={index + 2}
                                        title={step.title}
                                        canSelect={
                                            stepIndex >= index + 1 && stepIndex !== successStepIndex
                                        }
                                        isOpen={stepIndex === index + 1}
                                        isCompleted={stepIndex > index + 1}
                                        onSelect={() => {
                                            if (stepIndex >= index + 1) {
                                                setStepIndex(index + 1);
                                            }
                                        }}
                                    >
                                        {step.component(formProps)}
                                    </Step>
                                </Box>
                            ))}
                />

                <Box css={{ marginBottom: '$6' }}>
                    <Step
                        stepNumber={successStepIndex + 1}
                        title={translate('confirmation_d_79641')}
                        canSelect={stepIndex >= successStepIndex}
                        isOpen={stepIndex === successStepIndex}
                        isCompleted={stepIndex > successStepIndex}
                        onSelect={noop}
                    >
                        <Success registerSuccessMessage={props.event.registerSuccessMessage} />
                    </Step>
                </Box>
            </>
        );
    } else {
        return (
            <Box css={{ marginBottom: '$6' }}>
                <Step
                    stepNumber={1}
                    title={translate('inscriptions_cl_44201')}
                    canSelect={false}
                    isOpen={true}
                    isCompleted={false}
                    onSelect={noop}
                >
                    <Closed formClosedMessage={props.event.formClosedMessage} />
                </Step>
            </Box>
        );
    }
};
