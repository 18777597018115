import { Box } from 'common/src/designSystem/components/box';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { isInvalid } from '../isInvalid';
import { Label } from '../label/label';
import { fieldSubscriptions } from '../subscriptions';
import { Subtitle } from './subtitle';
import { _OldInputStyled } from './text';

const isTimeSupported = (() => {
    try {
        const input = document.createElement('input');
        input.type = 'time';
        input.value = 'Hello World';

        return input.type === input.getAttribute('type') && input.value !== 'Hello World';
    } catch (e) {
        return false;
    }
})();

interface ITimeComponentProps extends FieldRenderProps<any, HTMLElement> {
    label: string;
    isOptional?: boolean;
    subtitle?: string;
}

const TimeComponent = (props: ITimeComponentProps) => {
    const translate = useTranslate();
    const invalid = isInvalid(props.meta);

    return (
        <div>
            <Label htmlFor={props.input.name} isOptional={props.isOptional}>
                {props.label}
            </Label>

            {isNonEmptyString(props.subtitle) && <Subtitle text={props.subtitle} />}

            <_OldInputStyled
                {...props.input}
                type={isTimeSupported ? 'time' : 'text'}
                invalid={invalid}
                placeholder={isTimeSupported ? '' : translate('entrez_un_temps_67512')}
            />

            {invalid && (
                <Box
                    css={{
                        color: '$oldRed',
                        marginBottom: '$6'
                    }}
                >
                    {props.meta.error}
                </Box>
            )}
        </div>
    );
};

interface ITimeInputProps {
    label: string;
    name: string;
    isOptional?: boolean;
    subtitle?: string;
}

export const TimeInput = (() => (props: ITimeInputProps) => (
            <Field {...props} component={TimeComponent as any} subscription={fieldSubscriptions} />
        ))();
