import { random, range } from 'lodash-es';

const _randomLetter = () => String.fromCharCode(65 + random(25));

export function randomLetter(excludedLetters: string[] = []): string {
    const letter = _randomLetter();

    if (excludedLetters.includes(letter) === false) {
        return letter;
    } else {
        return randomLetter(excludedLetters);
    }
}

const _randomNumber = () => random(9);

export function randomNumber(excludedNumbers: number[] = []): number {
    const n = _randomNumber();

    if (excludedNumbers.includes(n) === false) {
        return n;
    } else {
        return randomNumber(excludedNumbers);
    }
}

export function randomLetterOrNumber(excludedLetters: string[], excludedNumbers: number[]) {
    if (random(1) === 0) {
        return randomLetter(excludedLetters);
    } else {
        return randomNumber(excludedNumbers).toString();
    }
}

const EXCLUDED_LETTERS = ['Q', 'O', 'I', 'L'];
const EXCLUDED_NUMBERS = [0, 1];

export function randomCode(length: number = 10) {
    const code = range(0, length - 1)
        .map(() => randomLetterOrNumber(EXCLUDED_LETTERS, EXCLUDED_NUMBERS))
        .join('');

    return `${randomLetter(EXCLUDED_LETTERS)}${code}`;
}

export function randomPrefix() {
    const prefix = range(0, 6)
        .map(() => randomLetter(EXCLUDED_LETTERS))
        .join('');

    return `${prefix}_`;
}
