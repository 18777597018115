import { Blank } from 'common-front/src/components/blank/blank';
import { Svgs } from 'common-front/src/util/assets';
import { Flex } from 'common/src/designSystem/components/flex';
import { MemberRegistrationQuery } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { MemberRegistrationAccreditation } from './memberRegistrationAccreditation';

interface IMemberRegistrationAccreditationsProps {
    userInfo: MemberRegistrationQuery['organization']['userInfo'];
}

export const MemberRegistrationAccreditations = ({
    userInfo
}: IMemberRegistrationAccreditationsProps) => {
    const translate = useTranslate();
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const auis = React.useMemo(() => sortBy(
            userInfo.volunteerRegistration!.accreditationsUsersInfos,
            (aui) => aui.accreditationSlot.date?.toMillis() ?? 0
        ), [userInfo]);

    return (
        <Flex direction="column" gap={{ '@initial': '6', '@desktop': '7' }}>
            {isNonEmptyArray(auis) ? (
                auis.map((aui, index) => (
                        <MemberRegistrationAccreditation
                            key={index}
                            isSelected={index === selectedIndex}
                            onClick={() => {
                                setSelectedIndex(index);
                            }}
                            accreditationUserInfo={aui}
                        />
                    ))
            ) : (
                <Blank imageSrc={Svgs.FormsElementsBlank} title={translate('no_accreditations')} />
            )}
        </Flex>
    );
};
