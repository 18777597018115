import {
    AccreditationsSlotId,
    DelegationAccreditationFragment,
    FormAccreditationsOptionsFragment,
    FormAccreditationsQuery,
    RegisterAccreditationDisplay,
    VolunteersRegistrationsSlotInput
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { canSelect } from 'common/src/vo/accreditationCategory';
import { UserInfoFields } from 'common/src/vo/field';
import * as React from 'react';
import { FormAccreditationsSlots } from './slots/formAccreditationsSlots';

interface IFormAccreditationsProps {
    accreditationsSlotsIds: AccreditationsSlotId[];
    delegationAccreditations: DelegationAccreditationFragment[];
    event: FormAccreditationsQuery['event'];
    options: FormAccreditationsOptionsFragment;
    prefix: string;
    slots: VolunteersRegistrationsSlotInput[];
    userInfosFields: UserInfoFields;

    change(name: string, value: any): void;
}

export const FormAccreditations = (props: IFormAccreditationsProps) => {
    const accreditationsCategories = React.useMemo(() => props.event.accreditationsCategories.filter((accreditationCategory) => canSelect(
                accreditationCategory,
                props.slots,
                props.userInfosFields,
                props.options,
                props.delegationAccreditations
            )), [props.event.accreditationsCategories, props.slots, props.userInfosFields, props.options]);

    if (
        isNonEmptyArray(props.delegationAccreditations) ||
        props.options.accreditationDisplay === RegisterAccreditationDisplay.Accreditation
    ) {
        return (
            <FormAccreditationsSlots
                accreditationsSlotsIds={props.accreditationsSlotsIds}
                categories={accreditationsCategories}
                delegationAccreditations={props.delegationAccreditations}
                options={props.options}
                prefix={props.prefix}
                slots={props.slots}
                userInfoFields={props.userInfosFields}
                change={props.change}
            />
        );
    } else {
        return null;
    }
};
