import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

interface IRichSelectGroupProps {
    children: React.ReactNode;
    index: number;
}

export const RichSelectGroup = (props: IRichSelectGroupProps) => (
        <>
            {props.index !== 0 && <Spacer height="6" />}

            <Box font="gray600 textXs semiBold" width={1}>
                {props.children}
            </Box>

            <Spacer height="2" />
        </>
    );
