import { Box } from 'common/src/designSystem/components/box';
import { useHistory, useLocation } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IItemProps {
    children: React.ReactNode;
    path: string;

    isActive?(pathname: string): boolean;
}

export const Item = (props: IItemProps) => {
    const history = useHistory();
    const location = useLocation();
    const isActive = props.isActive?.(location.pathname) ?? location.pathname === props.path;

    return (
        <Box
            css={{
                background: isActive ? '$primary100' : 'transparent',
                borderRadius: '6px',
                color: isActive ? '$primary700' : '$gray500',
                cursor: isActive ? 'default' : 'pointer',
                padding: '$2 $3'
            }}
            onClick={() => {
                history.push(props.path);
            }}
        >
            {props.children}
        </Box>
    );
};
