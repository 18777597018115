import { Flex } from 'common/src/designSystem/components/flex';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as luxon from 'luxon';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Portal } from 'react-portal';
import { useBodyClick } from '../../../../hooks/useBodyClick';
import { useEsc } from '../../../../hooks/useEsc';
import { Calendars } from './calendars';
import { DateTime } from './dateTime';
import { Hours } from './hours';
import { getPortalStyles, SelectMode } from './slotPickerUtil';

interface ISlotPickerProps {
    startDateProps: FieldRenderProps<luxon.DateTime, HTMLInputElement>;
    startTimeProps: FieldRenderProps<string, HTMLInputElement>;
    endDateProps: FieldRenderProps<luxon.DateTime, HTMLInputElement>;
    endTimeProps: FieldRenderProps<string, HTMLInputElement>;
    min?: luxon.DateTime;
    max?: luxon.DateTime;
}

export const SlotPicker = (props: ISlotPickerProps) => {
    const translate = useTranslate();
    const divRef = React.useRef<HTMLDivElement | null>(null);
    const [areCalendarsOpen, setAreCalendarsOpen] = React.useState(false);
    const [areHoursOpen, setAreHoursOpen] = React.useState(false);
    const [selectMode, setSelectMode] = React.useState(SelectMode.Start);

    useBodyClick(() => {
        setAreCalendarsOpen(false);
        setAreHoursOpen(false);
    });
    useEsc(() => {
        setAreCalendarsOpen(false);
        setAreHoursOpen(false);
    });

    const startDate = props.startDateProps.input.value;
    const startTime = props.startTimeProps.input.value;
    const endDate = props.endDateProps.input.value;
    const endTime = props.endTimeProps.input.value;

    return (
        <Flex
            css={{
                '& > div:first-child': {
                    marginRight: '$6'
                }
            }}
            ref={divRef}
            onClick={(e) => {
                e.nativeEvent.stopImmediatePropagation();
            }}
        >
            <DateTime
                htmlFor="start"
                label={translate('d_but_41840')}
                dateProps={props.startDateProps}
                timeProps={props.startTimeProps}
                placeholder={translate('d_but_41840')}
                areHoursOpen={areHoursOpen}
                onDateClick={() => {
                    setSelectMode(SelectMode.Start);
                    setAreCalendarsOpen(true);
                    setAreHoursOpen(false);
                }}
                onTimeClick={() => {
                    setSelectMode(SelectMode.Start);
                    setAreCalendarsOpen(false);
                    setAreHoursOpen(true);
                }}
                onPlaceholderClick={() => {
                    setSelectMode(SelectMode.Start);
                    setAreCalendarsOpen(true);
                    setAreHoursOpen(false);
                }}
            />

            <DateTime
                htmlFor="end"
                label={translate('fin_60104')}
                dateProps={props.endDateProps}
                timeProps={props.endTimeProps}
                placeholder={translate('fin_60104')}
                areHoursOpen={areHoursOpen}
                onDateClick={() => {
                    setSelectMode(SelectMode.End);
                    setAreCalendarsOpen(true);
                    setAreHoursOpen(false);
                }}
                onTimeClick={() => {
                    setSelectMode(SelectMode.End);
                    setAreCalendarsOpen(false);
                    setAreHoursOpen(true);
                }}
                onPlaceholderClick={() => {
                    setSelectMode(SelectMode.End);
                    setAreCalendarsOpen(true);
                    setAreHoursOpen(false);
                }}
            />

            {areCalendarsOpen && (
                <Portal>
                    <div style={getPortalStyles(divRef.current?.getBoundingClientRect())}>
                        <Calendars
                            startDate={selectMode === SelectMode.End ? startDate : null}
                            endDate={selectMode === SelectMode.Start ? endDate : null}
                            min={props.min}
                            max={props.max}
                            selectDate={(date) => {
                                if (selectMode === SelectMode.Start) {
                                    if (endDate && endDate.isValid && date > endDate) {
                                        props.startDateProps.input.onChange(date);
                                        props.endDateProps.input.onChange(date);
                                    } else {
                                        props.startDateProps.input.onChange(date);
                                    }

                                    if (!startTime) {
                                        setAreHoursOpen(true);
                                    }
                                } else {
                                    if (startDate && startDate.isValid && date < startDate) {
                                        props.endDateProps.input.onChange(date);
                                        props.startDateProps.input.onChange(date);
                                    } else {
                                        props.endDateProps.input.onChange(date);
                                    }

                                    if (!endTime) {
                                        setAreHoursOpen(true);
                                    }
                                }

                                setAreCalendarsOpen(false);
                            }}
                        />
                    </div>
                </Portal>
            )}

            {areHoursOpen && (
                <Portal>
                    <div style={getPortalStyles(divRef.current?.getBoundingClientRect())}>
                        <Hours
                            text={
                                selectMode === SelectMode.Start
                                    ? translate('heure_de_d_but_27566')
                                    : translate('heure_de_fin_95071')
                            }
                            startTime={selectMode === SelectMode.Start ? startTime : null}
                            endTime={selectMode === SelectMode.End ? endTime : null}
                            showAllDay={selectMode === SelectMode.Start}
                            selectAllDay={() => {
                                setAreHoursOpen(false);

                                props.startTimeProps.input.onChange('00:00');
                                props.endDateProps.input.onChange(props.startDateProps.input.value);
                                props.endTimeProps.input.onChange('23:59');
                            }}
                            selectTime={(time) => {
                                setAreHoursOpen(false);

                                if (selectMode === SelectMode.Start) {
                                    props.startTimeProps.input.onChange(time);

                                    if (!endDate || !endDate.isValid) {
                                        setSelectMode(SelectMode.End);
                                        setAreCalendarsOpen(true);
                                    }
                                } else {
                                    props.endTimeProps.input.onChange(time);

                                    if (!startDate || !startDate.isValid) {
                                        setSelectMode(SelectMode.Start);
                                        setAreCalendarsOpen(true);
                                    }
                                }
                            }}
                        />
                    </div>
                </Portal>
            )}
        </Flex>
    );
};
