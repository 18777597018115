import { Item } from 'common-front/src/designSystem/components/leftMenu/item';
import { LeftMenu } from 'common-front/src/designSystem/components/leftMenu/leftMenu';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import {
    FormId,
    FormMemberRegistrationFragment,
    MemberRegistrationUserInfoFragment
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { sortBy } from 'lodash-es';
import * as React from 'react';

interface IUpdateFormSidebarProps {
    backPath: string;
    forms: FormMemberRegistrationFragment[];
    title: string;
    userInfo: MemberRegistrationUserInfoFragment;

    getEditPath(formId: FormId): string;
}

export const UpdateFormSidebar = ({
    backPath,
    forms,
    getEditPath,
    title,
    userInfo
}: IUpdateFormSidebarProps) => {
    const { history, translate } = useHeavent();

    return (
        <Flex css={{ flex: '1' }} gap="6" direction="column">
            <Flex
                align="center"
                gap="2"
                css={{
                    color: '$primary700',
                    cursor: 'pointer'
                }}
                onClick={() => {
                    history.push(backPath);
                }}
            >
                <Box>
                    <I icon="arrow-left" />
                </Box>

                <Box>{translate('retour_92202')}</Box>
            </Flex>

            <Flex
                direction="column"
                gap="3"
                css={{
                    border: '1px solid $gray200',
                    borderRadius: '$2',
                    boxShadow: '$xs',
                    padding: '$6'
                }}
            >
                <Avatar
                    size={64}
                    name={userInfo.name}
                    image={userInfo.picture?.url}
                    email={userInfo.email}
                />

                <Box>
                    <Box color="gray500" fontSize="textXs" fontWeight={'medium'}>
                        {title}
                    </Box>

                    <Box color="gray800" fontSize="textXl" fontWeight="semiBold">
                        {userInfo.name}
                    </Box>
                </Box>

                <Separator direction="horizontal" />

                <LeftMenu>
                    {isNonEmptyArray(forms) ? (
                        sortBy(forms, 'name').map((form) => (
                                <Item key={form.id} path={getEditPath(form.id)}>
                                    {form.name}
                                </Item>
                            ))
                    ) : (
                        <Item path={getEditPath(-1 as FormId)}>
                            {translate('aucun_formulair_41857')}
                        </Item>
                    )}
                </LeftMenu>
            </Flex>
        </Flex>
    );
};
