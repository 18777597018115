import * as React from 'react';

interface ISpacerProps {
    height?: number;
    width?: number;
}

export const Spacer = (props: ISpacerProps) => {
    const style: any = {};

    if (props.height) {
        style.minHeight = props.height <= 1 ? `${props.height * 100}%` : `${props.height}px`;
    } else {
        style.height = '100%';
    }

    if (props.width) {
        style.minWidth = props.width <= 1 ? `${props.width * 100}%` : `${props.width}px`;
    } else {
        style.width = '100%';
    }

    return <div style={style} />;
};
