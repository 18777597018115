import { Emptyable } from '../emptyable';
import {
    DelegationIdPathParam,
    EventIdPathParam,
    OrganizationIdPathParam,
    UserInfoIdPathParam
} from '../pathsTypes';
import { buildPath, PathComponents as CommonPathComponents } from './commonPaths';
import { CommunityPaths } from './communityPaths';
import { EventsPaths } from './eventsPaths';

const PathComponents = {
    ITEMS: {
        DELEGATIONS: {
            DELEGATION: 'delegation',
            DELEGATIONS: 'delegations'
        }
    },
    SECTIONS: {
        ACCREDITATIONS: 'accreditations',
        DASHBOARD: 'dashboard',
        MEMBER: 'member',
        MEMBERS: 'members'
    }
};

const ItemPaths = {
    DELEGATIONS: PathComponents.ITEMS.DELEGATIONS.DELEGATIONS,
    DELEGATION: (delegationId: DelegationIdPathParam) =>
        buildPath([PathComponents.ITEMS.DELEGATIONS.DELEGATION, delegationId])
};

export const DelegationsPaths = {
    // Access Paths
    DELEGATION: (
        organizationId: OrganizationIdPathParam,
        eventId: Emptyable<EventIdPathParam>,
        delegationId: DelegationIdPathParam
    ) =>
        buildPath([
            eventId
                ? EventsPaths.EVENT(organizationId, eventId)
                : CommunityPaths.COMMUNITY(organizationId),
            ItemPaths.DELEGATION(delegationId)
        ]),
    DELEGATION_DASHBOARD: (
        organizationId: OrganizationIdPathParam,
        eventId: Emptyable<EventIdPathParam>,
        delegationId: DelegationIdPathParam
    ) =>
        buildPath([
            DelegationsPaths.DELEGATION(organizationId, eventId, delegationId),
            PathComponents.SECTIONS.DASHBOARD
        ]),
    DELEGATION_MEMBERS: (
        organizationId: OrganizationIdPathParam,
        eventId: Emptyable<EventIdPathParam>,
        delegationId: DelegationIdPathParam
    ) =>
        buildPath([
            DelegationsPaths.DELEGATION(organizationId, eventId, delegationId),
            PathComponents.SECTIONS.MEMBERS
        ]),
    DELEGATION_MEMBER: (
        organizationId: OrganizationIdPathParam,
        eventId: Emptyable<EventIdPathParam>,
        delegationId: DelegationIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) =>
        buildPath([
            DelegationsPaths.DELEGATION_MEMBERS(organizationId, eventId, delegationId),
            PathComponents.SECTIONS.MEMBER,
            userInfoId
        ]),
    DELEGATION_MEMBER_INFORMATION: (
        organizationId: OrganizationIdPathParam,
        eventId: Emptyable<EventIdPathParam>,
        delegationId: DelegationIdPathParam,
        userInfoId: UserInfoIdPathParam
    ) =>
        buildPath([
            DelegationsPaths.DELEGATION_MEMBER(organizationId, eventId, delegationId, userInfoId),
            CommonPathComponents.SECTIONS.INFORMATION
        ]),
    DELEGATION_ACCREDITATIONS: (
        organizationId: OrganizationIdPathParam,
        eventId: EventIdPathParam,
        delegationId: DelegationIdPathParam
    ) =>
        buildPath([
            DelegationsPaths.DELEGATION(organizationId, eventId, delegationId),
            PathComponents.SECTIONS.ACCREDITATIONS
        ])
};
