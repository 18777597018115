import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import { LocaleFormats } from 'common/src/util/luxon';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Days } from './days';

const _MontContainer = styled('div', {
    display: 'flex',
    marginBottom: '$2',
    '& > div:first-child': {
        alignItems: 'center',
        color: '$oldGrayIcon',
        display: 'flex',
        width: '10px'
    },
    '& > div:last-child': {
        alignItems: 'center',
        color: '$oldGrayIcon',
        display: 'flex',
        width: '10px'
    },
    '& i': {
        cursor: 'pointer',
        fontSize: '12px'
    }
});
const _Month = styled('div', {
    flex: '1',
    fontWeight: '$oldBold',
    textAlign: 'center',
    textTransform: 'capitalize'
});

const useDate = (date?: DateTime | null): DateTime | null => date && date.isValid ? date : null;

interface ICalendarsProps {
    startDate: DateTime | null;
    endDate: DateTime | null;
    min?: DateTime;
    max?: DateTime;

    selectDate(date: DateTime): void;
}

export const Calendars = (props: ICalendarsProps) => {
    const now = DateTime.local().startOf('day');
    const [firstDayOfFirstMonth, setFirstDayOfFirstMonth] = React.useState(
        (useDate(props.startDate) ?? useDate(props.endDate) ?? useDate(props.min) ?? now).startOf(
            'month'
        )
    );
    const lastDayOfFirstMonth = firstDayOfFirstMonth.endOf('month');
    const firstMondayOfFirstMonth = firstDayOfFirstMonth.minus({
        days: firstDayOfFirstMonth.weekday - 1
    });
    const lastSundayOfFirstMonth = lastDayOfFirstMonth.plus({
        days: 7 - lastDayOfFirstMonth.weekday
    });
    const numberOfDaysInFirstMonth = Math.ceil(
        lastSundayOfFirstMonth.diff(firstMondayOfFirstMonth, 'days').days
    );
    const firstDayOfSecondMonth = firstDayOfFirstMonth.plus({ month: 1 });
    const lastDayOfSecondMonth = firstDayOfSecondMonth.endOf('month');
    const firstMondayOfSecondMonth = firstDayOfSecondMonth.minus({
        days: firstDayOfSecondMonth.weekday - 1
    });
    const lastSundayOfSecondMonth = lastDayOfSecondMonth.plus({
        days: 7 - lastDayOfSecondMonth.weekday
    });
    const numberOfDaysInSecondMonth = Math.ceil(
        lastSundayOfSecondMonth.diff(firstMondayOfSecondMonth, 'days').days
    );
    const [currentHoverDate, setCurrentHoverDate] = React.useState<DateTime | null>(null);

    return (
        <Flex
            css={{
                background: 'white',
                borderRadius: '3px',
                boxShadow: '0 0 24px 0 rgba(0, 0, 0, 0.22), 0 24px 24px 0 rgba(0, 0, 0, 0.3)',
                minHeight: '256px',
                userSelect: 'none',
                width: '530px',
                '& > div:first-child': {
                    flex: '1',
                    padding: '$4 $5 $5 $5'
                },
                '& > div:last-child': {
                    flex: '1',
                    padding: '$4 $5 $5 $5'
                }
            }}
        >
            <div>
                <_MontContainer>
                    <div
                        onClick={() => {
                            setFirstDayOfFirstMonth(firstDayOfFirstMonth.minus({ month: 1 }));
                        }}
                    >
                        <i className="far fa-chevron-left" />
                    </div>

                    <_Month>
                        {firstDayOfFirstMonth.toLocaleString(LocaleFormats.DateOnly.MonthLongYear)}
                    </_Month>

                    <div />
                </_MontContainer>

                <Days
                    now={now}
                    numberOfDaysInMonth={numberOfDaysInFirstMonth}
                    firstMondayOfMonth={firstMondayOfFirstMonth}
                    firstDayOfMonth={firstDayOfFirstMonth}
                    currentHoverDate={currentHoverDate}
                    startDate={props.startDate}
                    endDate={props.endDate}
                    min={props.min}
                    max={props.max}
                    setCurrentHoverDate={setCurrentHoverDate}
                    selectDate={props.selectDate}
                />
            </div>

            <Box
                css={{
                    borderLeft: '1px solid $oldGrayBorder',
                    margin: '$2 0',
                    width: '1px'
                }}
            />

            <div>
                <_MontContainer>
                    <div />

                    <_Month>
                        {firstDayOfSecondMonth.toLocaleString(LocaleFormats.DateOnly.MonthLongYear)}
                    </_Month>

                    <div
                        onClick={() => {
                            setFirstDayOfFirstMonth(firstDayOfFirstMonth.plus({ month: 1 }));
                        }}
                    >
                        <i className="far fa-chevron-right" />
                    </div>
                </_MontContainer>

                <Days
                    now={now}
                    numberOfDaysInMonth={numberOfDaysInSecondMonth}
                    firstMondayOfMonth={firstMondayOfSecondMonth}
                    firstDayOfMonth={firstDayOfSecondMonth}
                    currentHoverDate={currentHoverDate}
                    startDate={props.startDate}
                    endDate={props.endDate}
                    min={props.min}
                    max={props.max}
                    setCurrentHoverDate={setCurrentHoverDate}
                    selectDate={props.selectDate}
                />
            </div>
        </Flex>
    );
};
