import { Form } from 'common-front/src/components/form/form';
import {
    EventId,
    FieldType,
    FormElementType,
    FormId,
    OrganizationId,
    OrganizationMembersSpaceFragment,
    TeamCode,
    UpdateUserFormFragment,
    UpdateUserInfosEventQuery,
    UpdateUserInfosOrganizationQuery,
    UsersInfoId
} from 'common/src/generated/types';
import { IUpdateUserInfoValues, UserInfoInputService } from 'common/src/input/userInfoInput';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useUserInfoUpdateMutation } from '../../generated/graphqlHooks';
import { UpdateUser } from './updateUser';
import { UserUpdateLayoutOrigin } from './userUpdateLayout';

const TEXT_FIELD_TYPES = [FieldType.Address, FieldType.Text, FieldType.Textarea, FieldType.Time];

interface IUpdateUserFormProps {
    closePath: string;
    customFields: UpdateUserInfosOrganizationQuery['organization']['customFields']['nodes'];
    country: Emptyable<string>;
    eventId: Emptyable<EventId>;
    formIdToInsertedAt: Record<FormId, DateTime>;
    formIdToTeamCode: Record<FormId, TeamCode>;
    forms: UpdateUserFormFragment[];
    organizationId: OrganizationId;
    organization?: OrganizationMembersSpaceFragment;
    origin: UserUpdateLayoutOrigin;
    showAllAndPrivate: boolean;
    showIsFilled: boolean;
    userInfo: Omit<UpdateUserInfosEventQuery['organization']['userInfo'], 'formsUsersInfos'>;
    userInfoId: UsersInfoId;

    getEditUserFormPath(formId: FormId): string;
    onSuccess(): void;
}

export const UpdateUserForm = (props: IUpdateUserFormProps) => {
    const userInfoInput = useService(UserInfoInputService);
    const { mutate: userInfoUpdate, isLoading } = useUserInfoUpdateMutation();
    const customFields = React.useMemo(() => props.forms
            .flatMap((form) => form.elements.flatMap((element) => {
                    if (element.elementType === FormElementType.Field) {
                        return [element.customField!];
                    } else {
                        return [];
                    }
                }))
            .concat(props.customFields), [props.forms, props.customFields]);
    const filledFormsIds = React.useMemo(() => Object.keys(props.formIdToInsertedAt).map((k) => parseInt(k, 10) as FormId), [props.formIdToInsertedAt]);
    const formKeyToTeamCode = React.useMemo(() => Object.fromEntries(
            Object.entries(props.formIdToTeamCode).map(([key, value]) => [`f${key}`, value])
        ), [props.formIdToTeamCode]);

    return (
        <Form
            direction="column"
            height={1}
            width={1}
            initialValues={{
                filledFormsIds,
                formKeyToTeamCode,
                userInfo: userInfoInput.userInfoUpdateInputDefault(
                    props.userInfo,
                    customFields,
                    props.country
                )
            }}
            onSubmit={async (values: IUpdateUserInfoValues) => {
                const fields = values.userInfo.fields;

                // special case for deletion, instead of undefined set it to '' or null
                // this way it will really be deleted
                customFields.forEach((customField) => {
                    if (
                        TEXT_FIELD_TYPES.includes(customField.fieldType) &&
                        !fields[customField.slug]
                    ) {
                        fields[customField.slug] = '';
                    } else if (
                        customField.fieldType === FieldType.File &&
                        !fields[customField.slug]
                    ) {
                        fields[customField.slug] = null;
                    }
                });

                await userInfoUpdate({
                    organizationId: props.organizationId,
                    eventId: props.eventId,
                    userId: props.userInfo.userId,
                    userInfo: {
                        id: props.userInfo.id,
                        fields: values.userInfo.fields
                    },
                    filledFormsIds: props.showIsFilled ? values.filledFormsIds : undefined,
                    formKeyToTeamCode: values.formKeyToTeamCode
                });

                props.onSuccess();
            }}
            render={({ form, handleSubmit, values }) => (
                    <UpdateUser
                        closePath={props.closePath}
                        customFields={props.customFields}
                        formIdToInsertedAt={props.formIdToInsertedAt}
                        forms={props.forms}
                        isLoading={isLoading}
                        origin={props.origin}
                        organization={props.organization}
                        showAllAndPrivate={props.showAllAndPrivate}
                        showIsFilled={props.showIsFilled}
                        userInfo={props.userInfo}
                        values={values}
                        change={form.change}
                        getEditUserFormPath={props.getEditUserFormPath}
                        handleSubmit={handleSubmit}
                    />
                )}
        />
    );
};
