export const fieldSubscriptions = {
    data: true,
    error: true,
    invalid: true,
    pristine: true,
    touched: true,
    value: true
};

export const formSubscriptions = {
    invalid: true
};
