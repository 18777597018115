import { getS3Url } from 'common/src/vo/document';
import * as React from 'react';
import { AWS_UPLOADS_BUCKET } from '../../../consts';
import { uploadFile } from '../../../util/file';
import { FileS3InputState } from '../file/fileS3Input';
import { FileS3InputValue } from '../file/fileS3InputValue';
import { BaseInputProps } from '../input/baseInputProps';
import { Image } from './image';
import { ImageLoader } from './imageLoader';
import { ImageUploader } from './imageUploader';

interface IImageInputProps extends Pick<BaseInputProps, 'autoComplete' | 'css' | 'state'> {
    label: React.ReactNode;
    hint?: string;
    value: FileS3InputValue | null;

    onChange(value: FileS3InputValue | null): void;
}

export const ImageInput = (props: IImageInputProps) => {
    const [state, setState] = React.useState(
        props.value ? FileS3InputState.File : FileS3InputState.Uploader
    );
    const onChange = async (file: File) => {
        setState(FileS3InputState.Uploading);

        try {
            const doc = await uploadFile(file, 'public-read');

            props.onChange(doc);
            setState(FileS3InputState.File);
        } catch {
            setState(FileS3InputState.Uploader);
        }
    };

    return state === FileS3InputState.File && props.value ? (
        <Image
            label={props.label}
            hint={props.hint}
            url={getS3Url(props.value, AWS_UPLOADS_BUCKET)}
            onDelete={() => {
                props.onChange(null);
                setState(FileS3InputState.Uploader);
            }}
        />
    ) : state === FileS3InputState.Uploading ? (
        <ImageLoader label={props.label} hint={props.hint} />
    ) : (
        <ImageUploader
            autoComplete={props.autoComplete}
            label={props.label}
            hint={props.hint}
            onChange={onChange}
        />
    );
};
