import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import {
    FormMissionsOptionsFragment,
    FormMissionsQuery,
    PositionsSlotId,
    VolunteersRegistrationsSlotInput
} from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import { UserInfoFields } from 'common/src/vo/field';
import { canSelectV2 } from 'common/src/vo/position';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { FormMissionDescriptionModal } from '../formMissionDescriptionModal';
import { FormMissionsSlotsPosition } from './formMissionsSlotsPosition';

interface IFormMissionsSlotsCategoryProps {
    category: FormMissionsQuery['event']['positionsCategories'][0];
    options: FormMissionsOptionsFragment;
    positionsSlotsIds: PositionsSlotId[];
    prefix: string;
    slots: VolunteersRegistrationsSlotInput[];
    userInfoFields: UserInfoFields;

    change(name: string, value: any): void;
}

export const FormMissionsSlotsCategory = (props: IFormMissionsSlotsCategoryProps) => {
    const positions = React.useMemo(() => sortBy(
            props.category.positions.filter((position) => canSelectV2(position, props.userInfoFields, props.slots, props.options)),
            (p) => p.name
        ), [props.category, props.userInfoFields, props.slots, props.options]);
    const [isDescriptionOpen, setIsDescriptionOpen] = React.useState(false);
    const showDescription =
        isNonEmptyString(props.category.description) &&
        props.options.showPositionCategoryDescription;

    return (
        <>
            <Flex align="center" gap="2">
                <Box
                    color="gray500"
                    fontSize="textXs"
                    fontWeight="semiBold"
                    css={{ textTransform: 'uppercase' }}
                >
                    {props.category.name}
                </Box>

                {showDescription && (
                    <Box
                        color="gray700"
                        onClick={() => {
                            setIsDescriptionOpen(true);
                        }}
                    >
                        <I icon="circle-exclamation" />
                    </Box>
                )}
            </Flex>

            {positions.map((position) => (
                    <FormMissionsSlotsPosition
                        key={position.id}
                        options={props.options}
                        position={position}
                        positionsSlotsIds={props.positionsSlotsIds}
                        prefix={props.prefix}
                        slots={props.slots}
                        change={props.change}
                    />
                ))}

            {isDescriptionOpen && (
                <FormMissionDescriptionModal
                    description={props.category.description}
                    name={props.category.name}
                    onClose={() => {
                        setIsDescriptionOpen(false);
                    }}
                />
            )}
        </>
    );
};
