import { Badge } from 'common/src/designSystem/components/badge';
import { Flex } from 'common/src/designSystem/components/flex';
import { isNonEmptyArray } from 'common/src/util/array';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { getTextWidth } from '../../util/text';
import { BadgesPlusBadge, IBadgesPlusBadge } from './badgesPlusBadge';
import { BadgesPlusDropdown } from './badgesPlusDropdown';

const BADGE_MARGIN = 35;
const BADGE_GAP = 8;
const PLUS_BADGE_WIDTH = 37;

interface IBadgesPlusProps {
    badges: IBadgesPlusBadge[];
    doNotSort?: boolean;

    setIsSidebarOpen?(isOpen: boolean): void;
    setVolunteerRegistrationId?(): void;
}

export const BadgesPlus = ({
    badges: _badges,
    doNotSort,
    setIsSidebarOpen,
    setVolunteerRegistrationId
}: IBadgesPlusProps) => {
    const [width, setWidth] = React.useState(0);
    const flexRef = React.useCallback((node: HTMLDivElement | null) => {
        if (node) {
            setWidth(node.getBoundingClientRect().width);
        }
    }, []);
    const badges = React.useMemo(() => {
        const badgesWithWidth = _badges.map((badge) => ({
            ...badge,
            textWidth: Math.floor(getTextWidth(badge.text, { fontSize: '12px' })) + BADGE_MARGIN
        }));

        return doNotSort === true
            ? badgesWithWidth
            : sortBy(badgesWithWidth, ({ textWidth }) => textWidth);
    }, [_badges, doNotSort]);
    const [badgesToDisplay, otherBadges] = React.useMemo(() => {
        let widthSum = PLUS_BADGE_WIDTH;
        const toDisplay = [];
        const others = [];

        for (const badge of badges) {
            widthSum += badge.textWidth + BADGE_GAP;

            if (widthSum < width) {
                toDisplay.push(badge);
            } else {
                others.push(badge);
            }
        }

        return [toDisplay, others];
    }, [width, badges]);
    const displayPlusBadge = React.useMemo(() => isNonEmptyArray(otherBadges), [otherBadges]);

    return (
        <Flex
            ref={flexRef}
            align="center"
            css={{ cursor: 'pointer', '&:hover': { color: 'black' } }}
            gap="2"
            height={1}
            width={1}
            onClick={(e) => {
                if (setIsSidebarOpen && isNonEmptyArray(badges)) {
                    e.nativeEvent.stopImmediatePropagation();
                    e.stopPropagation();
                    setVolunteerRegistrationId?.();
                    setIsSidebarOpen(true);
                }
            }}
        >
            {badgesToDisplay.map((badge) => <BadgesPlusBadge key={badge.id} badge={badge} />)}

            {displayPlusBadge &&
                (setIsSidebarOpen ? (
                    <Badge css={{ cursor: 'pointer' }}>
                        +{badges.length - badgesToDisplay.length}
                    </Badge>
                ) : (
                    <BadgesPlusDropdown badges={otherBadges} />
                ))}
        </Flex>
    );
};
