import { compact, intersection, sortBy, uniqBy } from 'lodash-es';
import { Interval } from 'luxon';
import {
    ConditionsOperator,
    FieldType,
    FormMissionsOptionsFragment,
    FormRegisterPositionFragment,
    Icon,
    Position,
    PositionPositionsCustomFieldsFragment,
    PositionsSlot,
    RegisterPositionFilter,
    RegisterSlotDisplay,
    VolunteerRegistrationFragment,
    VolunteersRegistrationsSlotInput
} from '../generated/types';
import { IntervalService } from '../services/intervalService';
import { isNonEmptyArray } from '../util/array';
import { assertUnreachable } from '../util/assertUnreachable';
import { Emptyable } from '../util/emptyable';
import { LocaleFormats } from '../util/luxon';
import { isValidNumber } from '../util/number';
import { isNonEmptyString } from '../util/string';
import { UserInfoFields } from './field';
import {
    canSelect as canSelectSlot,
    canSelectV2 as canSelectSlotV2,
    fullName
} from './positionSlot';

function meetPositionCustomFieldsCondition(
    conditionCustomField: PositionPositionsCustomFieldsFragment['conditionsCustomFields'][number],
    userFields: UserInfoFields
): boolean {
    const fieldValue = userFields[conditionCustomField.customField.slug];

    if (
        typeof fieldValue === 'boolean' &&
        conditionCustomField.customField.fieldType === FieldType.Checkbox
    ) {
        return fieldValue === conditionCustomField.conditionValue;
    } else if (
        conditionCustomField.customField.fieldType === FieldType.Select &&
        conditionCustomField.customField.canSelectMultiple &&
        isNonEmptyArray(fieldValue)
    ) {
        return intersection(conditionCustomField.conditionValue, fieldValue ?? []).length > 0;
    } else if (
        conditionCustomField.customField.fieldType === FieldType.Select &&
        typeof fieldValue === 'number'
    ) {
        return conditionCustomField.conditionValue.includes(fieldValue);
    } else {
        return false;
    }
}

export function meetPositionCustomFieldsConditions(
    position: PositionPositionsCustomFieldsFragment,
    userFields: UserInfoFields
): boolean {
    if (isNonEmptyArray(position.conditionsCustomFields)) {
        switch (position.conditionsOperator) {
            case ConditionsOperator.Or:
                return position.conditionsCustomFields.some((positionCustomField) => meetPositionCustomFieldsCondition(positionCustomField, userFields));
            case ConditionsOperator.And:
                return position.conditionsCustomFields.every((positionCustomField) => meetPositionCustomFieldsCondition(positionCustomField, userFields));
            default:
                return assertUnreachable(position.conditionsOperator);
        }
    } else {
        return true;
    }
}

export function canSelect(
    position: PositionPositionsCustomFieldsFragment,
    positionsSlots: Array<Pick<PositionsSlot, 'range'>>,
    userFields: UserInfoFields,
    ranges: Array<Interval | VolunteersRegistrationsSlotInput>,
    slotDisplay: RegisterSlotDisplay,
    positionFilter: RegisterPositionFilter
): boolean {
    return (
        meetPositionCustomFieldsConditions(position, userFields) &&
        positionsSlots.some((slot) => canSelectSlot(slot, ranges, slotDisplay, positionFilter))
    );
}

export function canSelectV2(
    position: FormRegisterPositionFragment,
    userFields: UserInfoFields,
    ranges: Array<Interval | VolunteersRegistrationsSlotInput>,
    options: FormMissionsOptionsFragment
): boolean {
    return (
        !options.hiddenPositionsIds.includes(position.id) &&
        (options.displayedPositionsIds.length === 0 ||
            options.displayedPositionsIds.includes(position.id)) &&
        meetPositionCustomFieldsConditions(position, userFields) &&
        position.slots.some((slot) => canSelectSlotV2(slot, ranges, options))
    );
}

export function hasAddress(position: Pick<Position, 'address'>): position is { address: string } {
    return isNonEmptyString(position.address);
}

export function hasCoordinates(
    position: Pick<Position, 'latitude' | 'longitude'>
): position is { latitude: number; longitude: number } {
    return isValidNumber(position.latitude) && isValidNumber(position.longitude);
}

export function hasAddressOrCoordinates(
    position: Pick<Position, 'address' | 'latitude' | 'longitude'>
): boolean {
    return hasAddress(position) || hasCoordinates(position);
}

export function getPositionsBadges(
    intervalService: IntervalService,
    psuis: VolunteerRegistrationFragment['positionsSlotsUsersInfos']
) {
    return sortBy(psuis, (psui) => {
        console.log(psui.positionCategory);

        return compact([
            psui.positionCategory.name,
            psui.position.name,
            psui.positionSlot.range.start!.toMillis(),
            psui.positionSlot.name
        ]);
    }).map(({ position, positionSlot }) => {
        const name = isNonEmptyString(position.acronym) ? position.acronym : position.name;
        const slotName = fullName(intervalService, positionSlot, {
            formats: { localeFormat: LocaleFormats.DateOnly.Numeric }
        });
        const text = `${name} | ${slotName}`;

        return {
            id: positionSlot.id,
            color: position.color,
            icon: position.icon,
            text
        };
    });
}

export function getWishedPositionsBadges(
    intervalService: IntervalService,
    wishedCategories: VolunteerRegistrationFragment['positionsCategories'],
    wishedPositions: VolunteerRegistrationFragment['positions'],
    wishedSlots: VolunteerRegistrationFragment['positionsSlots']
) {
    return uniqBy(wishedCategories, (c) => c.id)
        .map((category) => ({
            id: `c-${category.id}`,
            color: 'gray',
            icon: null as Emptyable<Icon>,
            text: category.name
        }))
        .concat(
            uniqBy(wishedPositions, (p) => p.id).map((position) => ({
                id: `p-${position.id}`,
                color: position.color,
                icon: position.icon,
                text: isNonEmptyString(position.acronym) ? position.acronym : position.name
            }))
        )
        .concat(
            uniqBy(wishedSlots, (s) => s.id).map((slot) => {
                const positionName = isNonEmptyString(slot.position.acronym)
                    ? slot.position.acronym
                    : slot.position.name;
                const slotName = fullName(intervalService, slot, {
                    formats: {
                        localeFormat: LocaleFormats.DateOnly.Numeric
                    }
                });

                return {
                    id: `s-${slot.id}`,
                    color: slot.position.color,
                    icon: slot.position.icon,
                    text: `${positionName} | ${slotName}`
                };
            })
        );
}
