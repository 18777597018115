import { H3 } from 'common-front/src/components/titles/h3';
import { useVolunteersParamsLocalStorage } from 'common-front/src/hooks/useVolunteersParamsLocalStorage';
import { LoginCodeSource } from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { EventPage } from '../common/eventPage';
import { VolunteersBox } from '../common/volunteersBox';
import { useAuthInfosQuery } from '../generated/graphqlHooks';
import { Email } from './email';

interface IAuthProps {
    isEmbed: boolean;
}

export const Auth = (props: IAuthProps) => {
    const translate = useTranslate();
    const { eventId } = useParams();
    const { data, loader } = useAuthInfosQuery({ eventId });
    useVolunteersParamsLocalStorage();

    return (
        loader || (
            <EventPage event={data.event} isEmbed={props.isEmbed}>
                <VolunteersBox
                    header={<H3>{translate('connexion_mon_88378')}</H3>}
                    showPoweredBy={true}
                >
                    <Email
                        buttonText={translate('connexion_05887')}
                        source={LoginCodeSource.VolunteerAssignments}
                        onSuccess={async () => {
                            location.reload();
                        }}
                    />
                </VolunteersBox>
            </EventPage>
        )
    );
};
