import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { DEFAULT_PAGINATION_LIMIT, POSSIBLE_LIMITS } from 'common/src/vo/pagination';
import { noop, range } from 'lodash-es';
import * as React from 'react';
import { Page } from './page';

interface IPaginationCellProps {
    limit?: number;
    numberOfPages: number;
    offset: number;
    showLimits?: boolean;
    totalCount: number;

    setLimit?(limit: number): void;
    setOffset(offset: number): void;
}

export const PaginationCell = (props: IPaginationCellProps) => {
    const translate = useTranslate();
    const limit = props.limit ?? DEFAULT_PAGINATION_LIMIT;
    const page = props.offset / limit;
    const pagesToDisplay = range(Math.max(0, page - 2), Math.min(props.numberOfPages, page + 3));
    const isPreviousDisabled = page === 0;
    const isNextDisabled = page === props.numberOfPages - 1;
    const upperLimit = Math.min(props.totalCount, (page + 1) * limit);

    return (
        <Cell
            css={{
                gap: '$6',
                justifyContent: 'space-between'
            }}
        >
            <Box width={150}>
                {props.showLimits && (
                    <Flex
                        css={{
                            border: '1px solid $gray300',
                            borderRadius: '$1',
                            overflow: 'hidden',
                            width: 'fit-content',
                            '& > div:first-child': {
                                borderLeft: 'none'
                            }
                        }}
                    >
                        {POSSIBLE_LIMITS.map((possibleLimit, index) => (
                                <Page
                                    key={index}
                                    active={limit === possibleLimit}
                                    cursor="pointer"
                                    disabled={false}
                                    onClick={() => {
                                        props.setLimit?.(possibleLimit);
                                    }}
                                >
                                    {possibleLimit}
                                </Page>
                            ))}
                    </Flex>
                )}
            </Box>

            {props.totalCount > limit ? (
                <Flex
                    css={{
                        border: '1px solid $gray300',
                        borderRadius: '$1',
                        overflow: 'hidden',
                        '& > div:first-child': {
                            borderLeft: 'none'
                        }
                    }}
                >
                    <Page
                        active={false}
                        cursor={isPreviousDisabled ? 'default' : 'pointer'}
                        disabled={isPreviousDisabled}
                        onClick={() => {
                            props.setOffset(props.offset - limit);
                        }}
                    >
                        <I icon="arrow-left" />
                    </Page>

                    {props.numberOfPages <= 7 ? (
                        range(0, props.numberOfPages).map((i) => (
                                <Page
                                    key={i}
                                    cursor="pointer"
                                    disabled={false}
                                    active={page === i}
                                    onClick={() => {
                                        props.setOffset(i * limit);
                                    }}
                                >
                                    {i + 1}
                                </Page>
                            ))
                    ) : (
                        <>
                            {!pagesToDisplay.includes(0) && (
                                <Page
                                    active={false}
                                    disabled={false}
                                    cursor="pointer"
                                    onClick={() => {
                                        props.setOffset(0);
                                    }}
                                >
                                    1
                                </Page>
                            )}

                            {!pagesToDisplay.includes(1) && (
                                <Page
                                    active={false}
                                    cursor="default"
                                    disabled={false}
                                    onClick={noop}
                                >
                                    <I icon="ellipsis" />
                                </Page>
                            )}

                            {pagesToDisplay.map((i) => (
                                    <Page
                                        key={i}
                                        active={page === i}
                                        cursor="pointer"
                                        disabled={false}
                                        onClick={() => {
                                            props.setOffset(i * limit);
                                        }}
                                    >
                                        {i + 1}
                                    </Page>
                                ))}

                            {!pagesToDisplay.includes(props.numberOfPages - 2) && (
                                <Page
                                    active={false}
                                    cursor="default"
                                    disabled={false}
                                    onClick={noop}
                                >
                                    <I icon="ellipsis" />
                                </Page>
                            )}

                            {!pagesToDisplay.includes(props.numberOfPages - 1) && (
                                <Page
                                    active={false}
                                    cursor="pointer"
                                    disabled={false}
                                    onClick={() => {
                                        props.setOffset((props.numberOfPages - 1) * limit);
                                    }}
                                >
                                    {props.numberOfPages}
                                </Page>
                            )}
                        </>
                    )}

                    <Page
                        active={false}
                        cursor={isNextDisabled ? 'default' : 'pointer'}
                        disabled={isNextDisabled}
                        onClick={() => {
                            props.setOffset(props.offset + limit);
                        }}
                    >
                        <I icon="arrow-right" />
                    </Page>
                </Flex>
            ) : (
                <Box />
            )}

            <Box width={150} textAlign="end">
                {translate('_1_2_sur_3_79592', page * limit + 1, upperLimit, props.totalCount)}
            </Box>
        </Cell>
    );
};
