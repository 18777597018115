import { IIcon } from 'common/src/designSystem/components/i';
import {
    CustomFieldsCategory,
    CustomFieldWithConditionFragment,
    FieldProperty,
    FieldType,
    FilterType,
    FormElementType,
    FormUserOverlayFragment
} from 'common/src/generated/types';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import { uuidv4 } from 'common/src/util/uuid';
import { groupBy, sortBy } from 'lodash-es';

export function customFieldTypeToIcon(fieldType: FieldType, fieldProperty: FieldProperty): IIcon {
    switch (fieldType) {
        case FieldType.Text:
        case FieldType.Textarea:
            return 'text';
        case FieldType.Checkbox:
            return 'square-check';
        case FieldType.Validation:
            return 'check';
        case FieldType.Select:
            if (fieldProperty === 'population') {
                return 'users';
            } else {
                return 'list-ul';
            }
        case FieldType.Date:
            return 'calendar';
        case FieldType.Datetime:
            return 'calendar-day';
        case FieldType.Time:
            return 'clock';
        case FieldType.File:
            return 'file';
        case FieldType.Phone:
            return 'phone';
        case FieldType.Language:
            return 'language';
        case FieldType.Number:
            return '00';
        case FieldType.Sex:
            return 'venus-mars';
        case FieldType.Nationality:
        case FieldType.Country:
            return 'flag';
        case FieldType.Address:
            return 'map-location';
        default:
            return assertUnreachable(fieldType);
    }
}

export function filterTypeToIcon(filterType: FilterType): IIcon {
    switch (filterType) {
        case FilterType.Text:
        case FilterType.Textarea:
            return 'text';
        case FilterType.Checkbox:
            return 'square-check';
        case FilterType.Validation:
            return 'check';
        case FilterType.Select:
            return 'list-ul';
        case FilterType.Date:
            return 'calendar';
        case FilterType.Datetime:
            return 'calendar-day';
        case FilterType.Time:
            return 'clock';
        case FilterType.File:
            return 'file';
        case FilterType.Phone:
            return 'phone';
        case FilterType.Language:
            return 'language';
        case FilterType.Number:
            return '00';
        case FilterType.Sex:
            return 'venus-mars';
        case FilterType.Nationality:
        case FilterType.Country:
            return 'flag';
        case FilterType.Address:
            return 'map-location';
        case FilterType.DateTimeRange:
            return 'calendar-range';
        case FilterType.Campaign:
            return 'paper-plane';
        default:
            return assertUnreachable(filterType);
    }
}

type CustomFieldFormElement = CustomFieldWithConditionFragment & {
    category: Pick<CustomFieldsCategory, 'name'>;
};

export function toFormElements(customFields: CustomFieldFormElement[]) {
    const fieldsGrouped = sortBy(
        Object.entries(groupBy(customFields, (cf) => cf.category.name)),
        ([name]) => name
    );

    return fieldsGrouped.flatMap(([section, fields]) => [
            {
                id: uuidv4(),
                elementType: FormElementType.Section,
                section
            } as FormUserOverlayFragment['elements'][0]
        ].concat(
            fields.map((customField) => ({
                id: uuidv4(),
                elementType: FormElementType.Field,
                customField
            }))
        ));
}
