import { Settings } from 'luxon';
import * as React from 'react';
import { API_BASE_URL } from '../../consts';
import { IUserContext } from '../../userContext';
import { loadScriptPromise } from '../../util/loadScript';
import { CurrentUser, currentUser } from '../../vo/user';
import { Loader } from '../loader/loader';

const loadUser = async (): Promise<IUserContext> => {
    try {
        const userContext = await currentUser();

        Settings.defaultLocale = userContext.user.language.toLowerCase();

        return userContext;
    } catch {
        return {
            user: null
        };
    }
};

const loadLang = (user: CurrentUser | null): Promise<boolean> => {
    const url = new URL(location.href);
    let language = url.searchParams.get('lang');

    if (user) {
        language = user.language.toLowerCase();
    }

    const langParams = language ? `lang=${language}` : '';

    return loadScriptPromise(`${API_BASE_URL}translation/language.js?${langParams}`);
};

const loadAll = async (): Promise<IUserContext> => {
    const { user } = await loadUser();
    await loadLang(user);

    return {
        user
    };
};

interface IMainLoaderProps {
    render(userContext: IUserContext): JSX.Element;
}

export const MainLoader = (props: IMainLoaderProps) => {
    const [userContext, setUserContext] = React.useState<IUserContext | null>(null);
    React.useEffect(() => {
        loadAll()
            .then((uc) => {
                setUserContext(uc);
            })
            .catch(() => {
                alert('Could not load application, please try again.');
            });
    }, []);

    if (userContext) {
        return props.render(userContext);
    } else {
        return <Loader />;
    }
};
