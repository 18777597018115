import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Document } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { getS3Url } from 'common/src/vo/document';
import * as React from 'react';
import { AWS_UPLOADS_BUCKET } from '../../../../consts';
import { Accept, acceptString } from '../../../../util/accept';
import { Button, Color } from '../../../button/button';

interface IFileImageViewProps {
    document: Pick<Document, 'key' | 'name'>;
    label: string;
    size: number;
    subtext?: string;
    isOptional?: boolean;

    onEdit(): void;
    onDelete(): void;
}

export const FileImageView = (props: IFileImageViewProps) => {
    const translate = useTranslate();

    return (
        <Flex
            css={{
                height: `${props.size}px`,
                overflow: 'hidden',
                position: 'relative',
                width: '100%',
                '& > img': {
                    borderRadius: '3px',
                    display: 'block'
                },
                '& > input': {
                    position: 'absolute',
                    right: '-10px',
                    top: '-10px',
                    visibility: 'hidden'
                }
            }}
        >
            <img
                src={getS3Url(props.document, AWS_UPLOADS_BUCKET)}
                alt={props.document.name}
                style={{
                    height: `${props.size}px`,
                    width: `${props.size}px`
                }}
            />

            <Flex
                direction="column"
                justify="between"
                css={{
                    flex: '1',
                    height: '100%',
                    marginLeft: '$4'
                }}
            >
                <Box css={{ fontWeight: '$oldBold' }}>
                    {props.label}
                    {props.isOptional && <span> ({translate('facultatif_76747')})</span>}
                </Box>

                <Box
                    css={{
                        color: '$oldBlueBayoux',
                        flex: '1'
                    }}
                >
                    {props.subtext || ''}
                </Box>

                <Flex
                    css={{
                        '& > div': {
                            marginRight: '$4'
                        }
                    }}
                >
                    <Button onClick={props.onEdit}>{translate('modifier_96725')}</Button>

                    <Button onClick={props.onDelete} color={Color.Gray}>
                        {translate('supprimer_43083')}
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
};

interface IFileImageUploaderProps {
    accept: Accept | Accept[];
    label: string;
    size: number;
    subtext?: string;
    isOptional?: boolean;

    onSelect(file: File): void;
}

export const FileImageUploader = (props: IFileImageUploaderProps) => {
    const translate = useTranslate();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [isDraggingOver, setIsDraggingOver] = React.useState(false);

    return (
        <Flex
            css={{
                height: `${props.size}px`,
                overflow: 'hidden',
                position: 'relative',
                width: '100%',
                '& > img': {
                    borderRadius: '3px',
                    display: 'block'
                },
                '& > input': {
                    position: 'absolute',
                    right: '-10px',
                    top: '-10px',
                    visibility: 'hidden'
                }
            }}
        >
            <Flex
                align="center"
                justify="center"
                css={{
                    background: isDraggingOver ? 'white' : '$oldGray',
                    border: '1px solid $oldGrayBorder',
                    borderRadius: '3px',
                    cursor: 'pointer',
                    height: `${props.size}px`,
                    width: `${props.size}px`
                }}
                onDragEnter={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setIsDraggingOver(true);
                }}
                onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (!isDraggingOver) {
                        setIsDraggingOver(true);
                    }
                }}
                onDragLeave={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setIsDraggingOver(false);
                }}
                onDrop={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setIsDraggingOver(false);

                    props.onSelect(e.dataTransfer.files.item(0)!);
                }}
                onClick={() => {
                    inputRef.current?.click();
                }}
            >
                <Flex
                    align="center"
                    justify="center"
                    css={{
                        background: '$oldBlue',
                        borderRadius: '32px',
                        color: 'white',
                        fontSize: '26px',
                        height: '32px',
                        width: '32px'
                    }}
                >
                    +
                </Flex>
            </Flex>

            <Flex
                direction="column"
                justify="between"
                css={{
                    flex: '1',
                    height: '100%',
                    marginLeft: '$4'
                }}
            >
                <Box css={{ fontWeight: '$oldBold' }}>
                    {props.label}
                    {props.isOptional && <span> ({translate('facultatif_76747')})</span>}
                </Box>

                <Box
                    css={{
                        color: '$oldBlueBayoux',
                        flex: '1'
                    }}
                >
                    {props.subtext || ''}
                </Box>

                <Flex
                    css={{
                        '& > div': {
                            marginRight: '$4'
                        }
                    }}
                >
                    <Button
                        onClick={() => {
                            inputRef.current?.click();
                        }}
                        color={Color.Transparent}
                    >
                        {translate('s_lectionnez_un_87142')}
                    </Button>
                </Flex>
            </Flex>

            <input
                type="file"
                accept={acceptString(props.accept)}
                ref={inputRef}
                onChange={() => {
                    props.onSelect(inputRef.current!.files![0]);
                }}
            />
        </Flex>
    );
};
