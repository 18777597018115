import { groupBy, range as lodashRange, sortBy } from 'lodash-es';
import { DateTime, Duration, Interval } from 'luxon';
import {
    AssignmentSlotsQuery,
    FormMissionsOptionsFragment,
    FormRegisterSlotFragment,
    PositionId,
    PositionsCategoryId,
    PositionsSlot,
    PositionsSlotId,
    PositionsSlotInput,
    RegisterPositionFilter,
    RegisterSlotDisplay,
    VolunteersRegistrationsSlotInput
} from '../generated/types';
import { IntervalService, IToDisplayStringOptions } from '../services/intervalService';
import { isNonEmptyArray } from '../util/array';
import { mergeOverlapping } from '../util/interval';
import { fromRange } from '../util/luxon';
import { isNonEmptyString } from '../util/string';
import { parseTime } from '../util/time';
import { UserInfoFields } from './field';
import { meetPositionCustomFieldsConditions } from './position';

export function fullName(
    intervalService: IntervalService,
    slot: Pick<PositionsSlot, 'name' | 'range'>,
    options: IToDisplayStringOptions = {}
) {
    return isNonEmptyString(slot.name)
        ? slot.name
        : intervalService.toDisplayString(slot.range, options);
}

type PositionSlotRange = Pick<PositionsSlot, 'id' | 'range'>;

export function overlaps(
    positionSlot: PositionSlotRange,
    selectedPositionsSlots: PositionSlotRange[]
): boolean {
    return selectedPositionsSlots.some(({ id, range }) => positionSlot.id !== id && range.overlaps(positionSlot.range));
}

export function filterSameDaySlots<T extends Pick<PositionsSlot, 'range'>>(
    day: DateTime,
    slots: T[]
) {
    const positionStart = day.toISODate();

    return slots.filter((s) => s.range.start!.toISODate() === positionStart);
}

// we should be able to select a slot if:
//   - we hide (do not ask) to the volunteer the time slots when he is available
//   - OR we do not want to filter the positions slots according to the time slots selected
//     by the volunteer
//   - OR we can select it, meaning the time slots selected at the previous step engulf its range
export function canSelect(
    slot: Pick<PositionsSlot, 'range'>,
    ranges: Array<Interval | VolunteersRegistrationsSlotInput>,
    slotDisplay: RegisterSlotDisplay,
    positionFilter: RegisterPositionFilter
): boolean {
    return (
        slotDisplay === RegisterSlotDisplay.Hide ||
        positionFilter === RegisterPositionFilter.None ||
        mergeOverlapping(ranges.map(fromRange)).some((interval) => interval.engulfs(slot.range))
    );
}

export function canSelectV2(
    slot: FormRegisterSlotFragment,
    ranges: Array<Interval | VolunteersRegistrationsSlotInput>,
    options: FormMissionsOptionsFragment
): boolean {
    return (
        !options.hiddenPositionsSlotsIds.includes(slot.id) &&
        (options.displayedPositionsSlotsIds.length === 0 ||
            options.displayedPositionsSlotsIds.includes(slot.id)) &&
        (options.showFullPosition || !slot.isFull) &&
        (options.slotDisplay === RegisterSlotDisplay.Hide ||
            options.positionFilter === RegisterPositionFilter.None ||
            mergeOverlapping(ranges.map(fromRange)).some((interval) => interval.engulfs(slot.range)))
    );
}

export function totalDuration(slots: Array<Pick<PositionsSlot, 'range'>>): Duration {
    return slots.reduce((currentDuration, { range }) => currentDuration.plus(range.toDuration()), Duration.fromMillis(0));
}

export type AssignmentSlot = AssignmentSlotsQuery['event']['positionsSlots']['nodes'][0];

export interface ISplitSlotsReturn {
    wishedPositionsSlots: AssignmentSlot[];
    possiblePositionsSlots: AssignmentSlot[];
    otherPositionsSlots: AssignmentSlot[];
}

export function splitSlots(
    positionsSlots: AssignmentSlot[],
    wishedPositionsCategoriesIds: PositionsCategoryId[],
    wishedPositionsIds: PositionId[],
    wishedPositionsSlotsIds: PositionsSlotId[],
    wishedRanges: Interval[],
    userFields: UserInfoFields
): ISplitSlotsReturn {
    const wishedPositionsSlots: AssignmentSlot[] = [];
    const possiblePositionsSlots: AssignmentSlot[] = [];
    const otherPositionsSlots: AssignmentSlot[] = [];

    const canSelectPosition = (positionsSlot: AssignmentSlot) => meetPositionCustomFieldsConditions(positionsSlot.position, userFields);
    const canSelectSlot = (slot: Pick<PositionsSlot, 'range'>, ranges: Interval[]) => ranges.some((r) => r.engulfs(slot.range));
    const hasRanges = isNonEmptyArray(wishedRanges);
    const hasPositions =
        isNonEmptyArray(wishedPositionsSlotsIds) ||
        isNonEmptyArray(wishedPositionsIds) ||
        isNonEmptyArray(wishedPositionsCategoriesIds);
    const hasRangesPositions =
        hasRanges && hasPositions
            ? 'both'
            : hasRanges
            ? 'ranges'
            : hasPositions
            ? 'positions'
            : 'none';

    positionsSlots.forEach((positionSlot) => {
        const isPositionSlotSelected =
            wishedPositionsSlotsIds.includes(positionSlot.id) ||
            wishedPositionsIds.includes(positionSlot.position.id) ||
            wishedPositionsCategoriesIds.includes(positionSlot.positionCategory.id);

        if (
            hasRangesPositions === 'both' &&
            canSelectSlot(positionSlot, wishedRanges) &&
            canSelectPosition(positionSlot) &&
            isPositionSlotSelected
        ) {
            wishedPositionsSlots.push(positionSlot);
        } else if (
            hasRangesPositions === 'ranges' &&
            canSelectSlot(positionSlot, wishedRanges) &&
            canSelectPosition(positionSlot)
        ) {
            wishedPositionsSlots.push(positionSlot);
        } else if (
            hasRangesPositions === 'positions' &&
            canSelectPosition(positionSlot) &&
            isPositionSlotSelected
        ) {
            wishedPositionsSlots.push(positionSlot);
        } else if (
            canSelectPosition(positionSlot) &&
            (wishedRanges.length === 0 || canSelectSlot(positionSlot, wishedRanges))
        ) {
            possiblePositionsSlots.push(positionSlot);
        } else {
            otherPositionsSlots.push(positionSlot);
        }
    });

    const sortFn = (slot: Pick<PositionsSlot, 'range'>) => slot.range.start!.toMillis();

    return {
        wishedPositionsSlots: sortBy(wishedPositionsSlots, sortFn),
        possiblePositionsSlots: sortBy(possiblePositionsSlots, sortFn),
        otherPositionsSlots: sortBy(otherPositionsSlots, sortFn)
    };
}

export type SplitSlotsCategory = AssignmentSlot['positionCategory'] & {
    positions: Array<AssignmentSlot['position']>;
};

export interface ISplitSlotsPreAssignReturn {
    wishedPositionsCategories: SplitSlotsCategory[];
    possiblePositionsCategories: SplitSlotsCategory[];
    otherPositionsCategories: SplitSlotsCategory[];
}

export function splitSlotsPreAssign(
    positionsSlots: AssignmentSlot[],
    wishedPositionsCategoriesIds: PositionsCategoryId[],
    wishedPositionsIds: PositionId[],
    wishedPositionsSlotsIds: PositionsSlotId[],
    wishedRanges: Interval[],
    userFields: UserInfoFields
): ISplitSlotsPreAssignReturn {
    const { wishedPositionsSlots, possiblePositionsSlots, otherPositionsSlots } = splitSlots(
        positionsSlots,
        wishedPositionsCategoriesIds,
        wishedPositionsIds,
        wishedPositionsSlotsIds,
        wishedRanges,
        userFields
    );
    const alreadyUsedPositionsIds = new Set<PositionId>();
    const groupCategoriesPositions = (slots: AssignmentSlot[]) => Object.values(groupBy(slots, (s) => s.positionCategory.id)).flatMap(
            (categorySlots) => {
                const positions = Object.values(
                    groupBy(categorySlots, (s) => s.position.id)
                ).flatMap((positionSlots) => {
                    const position = positionSlots[0].position;

                    if (alreadyUsedPositionsIds.has(position.id)) {
                        return [];
                    } else {
                        alreadyUsedPositionsIds.add(position.id);

                        return position;
                    }
                });

                if (positions.length === 0) {
                    return [];
                } else {
                    return [
                        {
                            ...categorySlots[0].positionCategory,
                            positions
                        }
                    ];
                }
            }
        );
    const wishedPositionsCategories = groupCategoriesPositions(wishedPositionsSlots);
    const possiblePositionsCategories = groupCategoriesPositions(possiblePositionsSlots);
    const otherPositionsCategories = groupCategoriesPositions(otherPositionsSlots);

    return {
        wishedPositionsCategories,
        possiblePositionsCategories,
        otherPositionsCategories
    };
}

export function repeatSlot(
    slot: PositionsSlotInput,
    value: number,
    unit: string
): PositionsSlotInput[] {
    const startTime = parseTime(slot.startTime);
    let startDate = slot.startDate;

    if (startTime) {
        startDate = startDate.set({
            hour: startTime[0],
            minute: startTime[1]
        });
    }

    const endTime = parseTime(slot.endTime);
    let endDate = slot.endDate;

    if (endTime) {
        endDate = endDate.set({
            hour: endTime[0],
            minute: endTime[1]
        });
    }

    return lodashRange(1, value + 1).map((i) => {
        if (unit === 'hour') {
            const newStartDate = startDate.plus({ hour: i });
            const newEndDate = endDate.plus({ hour: i });

            return {
                startDate: newStartDate,
                startTime: newStartDate.toFormat('HH:mm'),
                endDate: newEndDate,
                endTime: newEndDate.toFormat('HH:mm'),
                resources: slot.resources
            };
        } else if (unit === 'day') {
            const newStartDate = startDate.plus({ day: i });
            const newEndDate = endDate.plus({ day: i });

            return {
                startDate: newStartDate,
                startTime: newStartDate.toFormat('HH:mm'),
                endDate: newEndDate,
                endTime: newEndDate.toFormat('HH:mm'),
                resources: slot.resources
            };
        } else if (unit === 'week') {
            const newStartDate = startDate.plus({ week: i });
            const newEndDate = endDate.plus({ week: i });

            return {
                startDate: newStartDate,
                startTime: newStartDate.toFormat('HH:mm'),
                endDate: newEndDate,
                endTime: newEndDate.toFormat('HH:mm'),
                resources: slot.resources
            };
        } else {
            throw new Error('Wrong unit');
        }
    });
}
