import { injectable } from 'inversify';
import { sortBy } from 'lodash-es';
import { SupportedLanguage } from '../generated/types';
import { toArray } from '../util/array';
import { Emptyable } from '../util/emptyable';
import { normalize } from '../util/string';
import { TranslationService } from './translationService';

export interface ICountry {
    code: string;
    emoji: string;
    phoneCode: string | string[];
    language?: string | string[];
    name: string;
    nationality: string;
}

export interface IIdName {
    id: string;
    name: string;
}

export interface IDefaultCountryValues {
    user: { language: SupportedLanguage; country: string; nationality: string };
    phone: { country: string; code: string };
}

@injectable()
export class CountriesService {
    countries: ICountry[];
    countriesCodes: string[];
    phoneCodes: string[];
    countriesIdName: IIdName[];
    nationalitiesIdName: IIdName[];

    constructor(translationService: TranslationService) {
        const translate = translationService.translate.bind(translationService);

        this.countries = sortBy(
            [
                {
                    phoneCode: '+93',
                    code: 'AF',
                    name: translate('afghanistan_59344'),
                    nationality: translate('afghan_10143'),
                    emoji: '🇦🇫',
                    language: ['ps', 'ps-AR']
                },
                {
                    phoneCode: '+35818',
                    code: 'AX',
                    name: translate('_land_islands_92853'),
                    nationality: translate('_land_island_62794'),
                    emoji: '🇦🇽'
                },
                {
                    phoneCode: '+355',
                    code: 'AL',
                    name: translate('albania_07621'),
                    nationality: translate('albanian_82021'),
                    emoji: '🇦🇱',
                    language: ['sq', 'sq-AL']
                },
                {
                    phoneCode: '+213',
                    code: 'DZ',
                    name: translate('algeria_37723'),
                    nationality: translate('algerian_97855'),
                    emoji: '🇩🇿',
                    language: 'ar-DZ'
                },
                {
                    phoneCode: '+1684',
                    code: 'AS',
                    name: translate('american_samoa_95023'),
                    nationality: translate('american_samoan_76272'),
                    emoji: '🇦🇸'
                },
                {
                    phoneCode: '+376',
                    code: 'AD',
                    name: translate('andorra_45150'),
                    nationality: translate('andorran_97838'),
                    emoji: '🇦🇩'
                },
                {
                    phoneCode: '+244',
                    code: 'AO',
                    name: translate('angola_15815'),
                    nationality: translate('angolan_91942'),
                    emoji: '🇦🇴'
                },
                {
                    phoneCode: '+1264',
                    code: 'AI',
                    name: translate('anguilla_53408'),
                    nationality: translate('anguillan_92612'),
                    emoji: '🇦🇮'
                },
                {
                    phoneCode: '+1268',
                    code: 'AG',
                    name: translate('antigua_and_bar_05164'),
                    nationality: translate('antiguan_or_bar_26833'),
                    emoji: '🇦🇬'
                },
                {
                    phoneCode: '+54',
                    code: 'AR',
                    name: translate('argentina_92219'),
                    nationality: translate('argentine_14780'),
                    emoji: '🇦🇷',
                    language: 'es-AR'
                },
                {
                    phoneCode: '+374',
                    code: 'AM',
                    name: translate('armenia_90330'),
                    nationality: translate('armenian_87788'),
                    language: ['hy', 'hy-AM'],
                    emoji: '🇦🇲'
                },
                {
                    phoneCode: '+297',
                    code: 'AW',
                    name: translate('aruba_62942'),
                    nationality: translate('aruban_18515'),
                    emoji: '🇦🇼'
                },
                {
                    phoneCode: '+61',
                    code: 'AU',
                    name: translate('australia_84647'),
                    nationality: translate('australian_83553'),
                    emoji: '🇦🇺',
                    language: 'en-AU'
                },
                {
                    phoneCode: '+43',
                    code: 'AT',
                    name: translate('austria_67457'),
                    nationality: translate('austrian_39761'),
                    emoji: '🇦🇹',
                    language: 'de-AT'
                },
                {
                    phoneCode: '+994',
                    code: 'AZ',
                    name: translate('azerbaijan_14476'),
                    nationality: translate('azerbaijani_az_92428'),
                    emoji: '🇦🇿',
                    language: 'az-AZ'
                },
                {
                    phoneCode: '+1242',
                    code: 'BS',
                    name: translate('bahamas_33701'),
                    nationality: translate('bahamian_86817'),
                    emoji: '🇧🇸'
                },
                {
                    phoneCode: '+973',
                    code: 'BH',
                    name: translate('bahrain_43417'),
                    nationality: translate('bahraini_76209'),
                    emoji: '🇧🇭',
                    language: 'ar-BH'
                },
                {
                    phoneCode: '+880',
                    code: 'BD',
                    name: translate('bangladesh_48531'),
                    nationality: translate('bangladeshi_66399'),
                    emoji: '🇧🇩'
                },
                {
                    phoneCode: '+1246',
                    code: 'BB',
                    name: translate('barbados_57235'),
                    nationality: translate('barbadian_02319'),
                    emoji: '🇧🇧'
                },
                {
                    phoneCode: '+375',
                    code: 'BY',
                    name: translate('belarus_97102'),
                    nationality: translate('belarusian_60742'),
                    emoji: '🇧🇾',
                    language: ['be', 'be-BY']
                },
                {
                    phoneCode: '+32',
                    code: 'BE',
                    name: translate('belgium_21048'),
                    nationality: translate('belgian_00407'),
                    emoji: '🇧🇪',
                    language: ['fr-BE', 'nl-BE']
                },
                {
                    phoneCode: '+501',
                    code: 'BZ',
                    name: translate('belize_94460'),
                    nationality: translate('belizean_90827'),
                    emoji: '🇧🇿',
                    language: 'en-BZ'
                },
                {
                    phoneCode: '+229',
                    code: 'BJ',
                    name: translate('benin_88614'),
                    nationality: translate('beninese_benin_55987'),
                    emoji: '🇧🇯'
                },
                {
                    phoneCode: '+1441',
                    code: 'BM',
                    name: translate('bermuda_84448'),
                    nationality: translate('bermudian_berm_58878'),
                    emoji: '🇧🇲'
                },
                {
                    phoneCode: '+975',
                    code: 'BT',
                    name: translate('bhutan_12608'),
                    nationality: translate('bhutanese_34101'),
                    emoji: '🇧🇹'
                },
                {
                    phoneCode: '+591',
                    code: 'BO',
                    name: translate('bolivia_plurin_43912'),
                    nationality: translate('bolivian_98429'),
                    emoji: '🇧🇴',
                    language: ['es-BO', 'qu-BO']
                },
                {
                    phoneCode: '+5997',
                    code: 'BQ',
                    name: translate('bonaire_sint_e_69605'),
                    nationality: translate('bonaire_07340'),
                    emoji: '🇧🇶'
                },
                {
                    phoneCode: '+387',
                    code: 'BA',
                    name: translate('bosnia_and_herz_91990'),
                    nationality: translate('bosnian_or_herz_88694'),
                    emoji: '🇧🇦',
                    language: ['bs-BA', 'hr-BA', 'sr-BA']
                },
                {
                    phoneCode: '+267',
                    code: 'BW',
                    name: translate('botswana_49258'),
                    nationality: translate('motswana_botsw_32461'),
                    emoji: '🇧🇼'
                },
                {
                    phoneCode: '+55',
                    code: 'BR',
                    name: translate('brazil_03275'),
                    nationality: translate('brazilian_74116'),
                    emoji: '🇧🇷',
                    language: 'pt-BR'
                },
                {
                    phoneCode: '+246',
                    code: 'IO',
                    name: translate('british_indian_92949'),
                    nationality: translate('british_43680'),
                    emoji: '🇮🇴'
                },
                {
                    phoneCode: '+673',
                    code: 'BN',
                    name: translate('brunei_darussal_84695'),
                    nationality: translate('bruneian_06218'),
                    emoji: '🇧🇳',
                    language: 'ms-BN'
                },
                {
                    phoneCode: '+359',
                    code: 'BG',
                    name: translate('bulgaria_97045'),
                    nationality: translate('bulgarian_79288'),
                    emoji: '🇧🇬',
                    language: ['bg', 'bg-BG']
                },
                {
                    phoneCode: '+226',
                    code: 'BF',
                    name: translate('burkina_faso_55299'),
                    nationality: translate('burkinab_02690'),
                    emoji: '🇧🇫'
                },
                {
                    phoneCode: '+257',
                    code: 'BI',
                    name: translate('burundi_85561'),
                    nationality: translate('burundian_35605'),
                    emoji: '🇧🇮'
                },
                {
                    phoneCode: '+238',
                    code: 'CV',
                    name: translate('cabo_verde_28261'),
                    nationality: translate('cabo_verdean_88842'),
                    emoji: '🇨🇻'
                },
                {
                    phoneCode: '+855',
                    code: 'KH',
                    name: translate('cambodia_77971'),
                    nationality: translate('cambodian_19577'),
                    emoji: '🇰🇭'
                },
                {
                    phoneCode: '+237',
                    code: 'CM',
                    name: translate('cameroon_44200'),
                    nationality: translate('cameroonian_12953'),
                    emoji: '🇨🇲'
                },
                {
                    phoneCode: '+1',
                    code: 'CA',
                    name: translate('canada_52584'),
                    nationality: translate('canadian_90836'),
                    emoji: '🇨🇦',
                    language: ['en-CA', 'fr-CA']
                },
                {
                    phoneCode: '+1345',
                    code: 'KY',
                    name: translate('cayman_islands_80923'),
                    nationality: translate('caymanian_07521'),
                    emoji: '🇰🇾'
                },
                {
                    phoneCode: '+236',
                    code: 'CF',
                    name: translate('central_african_74182'),
                    nationality: translate('central_african_89873'),
                    emoji: '🇨🇫'
                },
                {
                    phoneCode: '+235',
                    code: 'TD',
                    name: translate('chad_58721'),
                    nationality: translate('chadian_55061'),
                    emoji: '🇹🇩'
                },
                {
                    phoneCode: '+56',
                    code: 'CL',
                    name: translate('chile_06923'),
                    nationality: translate('chilean_69498'),
                    emoji: '🇨🇱',
                    language: 'es-CL'
                },
                {
                    phoneCode: '+86',
                    code: 'CN',
                    name: translate('china_40708'),
                    nationality: translate('chinese_12117'),
                    emoji: '🇨🇳',
                    language: ['zh', 'zh-CN']
                },
                {
                    phoneCode: '+6189164',
                    code: 'CX',
                    name: translate('christmas_islan_46649'),
                    nationality: translate('christmas_islan_46649'),
                    emoji: '🇨🇽'
                },
                {
                    phoneCode: '+6189162',
                    code: 'CC',
                    name: translate('cocos_keeling_98180'),
                    nationality: translate('cocos_island_82627'),
                    emoji: '🇨🇨'
                },
                {
                    phoneCode: '+57',
                    code: 'CO',
                    name: translate('colombia_37632'),
                    nationality: translate('colombian_28222'),
                    emoji: '🇨🇴',
                    language: 'es-CO'
                },
                {
                    phoneCode: '+269',
                    code: 'KM',
                    name: translate('comoros_35439'),
                    nationality: translate('comoran_comori_49754'),
                    emoji: '🇰🇲'
                },
                {
                    phoneCode: '+242',
                    code: 'CG',
                    name: translate('congo_republic_97304'),
                    nationality: translate('congolese_04965'),
                    emoji: '🇨🇬'
                },
                {
                    phoneCode: '+243',
                    code: 'CD',
                    name: translate('congo_democrat_80102'),
                    nationality: translate('congolese_04965'),
                    emoji: '🇨🇩'
                },
                {
                    phoneCode: '+682',
                    code: 'CK',
                    name: translate('cook_islands_51542'),
                    nationality: translate('cook_island_99158'),
                    emoji: '🇨🇰'
                },
                {
                    phoneCode: '+506',
                    code: 'CR',
                    name: translate('costa_rica_57058'),
                    nationality: translate('costa_rican_17127'),
                    emoji: '🇨🇷',
                    language: 'es-CR'
                },
                {
                    phoneCode: '+225',
                    code: 'CI',
                    name: translate('c_te_d_ivoire_55380'),
                    nationality: translate('ivorian_01729'),
                    emoji: '🇨🇮'
                },
                {
                    phoneCode: '+385',
                    code: 'HR',
                    name: translate('croatia_52101'),
                    nationality: translate('croatian_62900'),
                    emoji: '🇭🇷',
                    language: ['hr', 'hr-HR']
                },
                {
                    phoneCode: '+53',
                    code: 'CU',
                    name: translate('cuba_11922'),
                    nationality: translate('cuban_00956'),
                    emoji: '🇨🇺'
                },
                {
                    phoneCode: '+5999',
                    code: 'CW',
                    name: translate('cura_ao_46587'),
                    nationality: translate('cura_aoan_65055'),
                    emoji: '🇨🇼'
                },
                {
                    phoneCode: '+357',
                    code: 'CY',
                    name: translate('cyprus_86967'),
                    nationality: translate('cypriot_85282'),
                    emoji: '🇨🇾'
                },
                {
                    phoneCode: '+420',
                    code: 'CZ',
                    name: translate('czech_republic_57570'),
                    nationality: translate('czech_42120'),
                    emoji: '🇨🇿',
                    language: ['cs', 'cs-CZ']
                },
                {
                    phoneCode: '+45',
                    code: 'DK',
                    name: translate('denmark_28356'),
                    nationality: translate('danish_44544'),
                    emoji: '🇩🇰',
                    language: ['da', 'da-DK']
                },
                {
                    phoneCode: '+253',
                    code: 'DJ',
                    name: translate('djibouti_38156'),
                    nationality: translate('djiboutian_72034'),
                    emoji: '🇩🇯'
                },
                {
                    phoneCode: '+1767',
                    code: 'DM',
                    name: translate('dominica_13395'),
                    nationality: translate('dominican_54004'),
                    emoji: '🇩🇲'
                },
                {
                    phoneCode: ['+1809', '+1829', '+1849'],
                    code: 'DO',
                    name: translate('dominican_repub_08891'),
                    nationality: translate('dominican_54004'),
                    emoji: '🇩🇴',
                    language: 'es-DO'
                },
                {
                    phoneCode: '+593',
                    code: 'EC',
                    name: translate('ecuador_25933'),
                    nationality: translate('ecuadorian_50591'),
                    emoji: '🇪🇨',
                    language: ['es-EC', 'qu-EC']
                },
                {
                    phoneCode: '+20',
                    code: 'EG',
                    name: translate('egypt_61058'),
                    nationality: translate('egyptian_75798'),
                    emoji: '🇪🇬',
                    language: 'ar-EG'
                },
                {
                    phoneCode: '+503',
                    code: 'SV',
                    name: translate('el_salvador_37036'),
                    nationality: translate('salvadoran_02475'),
                    emoji: '🇸🇻',
                    language: 'es-SV'
                },
                {
                    phoneCode: '+240',
                    code: 'GQ',
                    name: translate('equatorial_guin_39570'),
                    nationality: translate('equatorial_guin_44537'),
                    emoji: '🇬🇶'
                },
                {
                    phoneCode: '+291',
                    code: 'ER',
                    name: translate('eritrea_67366'),
                    nationality: translate('eritrean_20355'),
                    emoji: '🇪🇷'
                },
                {
                    phoneCode: '+372',
                    code: 'EE',
                    name: translate('estonia_58256'),
                    nationality: translate('estonian_46354'),
                    emoji: '🇪🇪',
                    language: ['et', 'et-EE']
                },
                {
                    phoneCode: '+251',
                    code: 'ET',
                    name: translate('ethiopia_72971'),
                    nationality: translate('ethiopian_03019'),
                    emoji: '🇪🇹'
                },
                {
                    phoneCode: '+500',
                    code: 'FK',
                    name: translate('falkland_island_59316'),
                    nationality: translate('falkland_island_15848'),
                    emoji: '🇫🇰'
                },
                {
                    phoneCode: '+298',
                    code: 'FO',
                    name: translate('faroe_islands_71549'),
                    nationality: translate('faroese_32806'),
                    emoji: '🇫🇴',
                    language: ['fo', 'fo-FO']
                },
                {
                    phoneCode: '+679',
                    code: 'FJ',
                    name: translate('fiji_36369'),
                    nationality: translate('fijian_39649'),
                    emoji: '🇫🇯'
                },
                {
                    phoneCode: '+358',
                    code: 'FI',
                    name: translate('finland_07531'),
                    nationality: translate('finnish_24918'),
                    emoji: '🇫🇮',
                    language: ['fi', 'fi-FI', 'se-FI', 'sv-FI']
                },
                {
                    phoneCode: '+33',
                    code: 'FR',
                    name: translate('france_26614'),
                    nationality: translate('french_86034'),
                    emoji: '🇫🇷',
                    language: ['fr', 'fr-FR']
                },
                {
                    phoneCode: '+594',
                    code: 'GF',
                    name: translate('french_guiana_80598'),
                    nationality: translate('french_guianese_02041'),
                    emoji: '🇬🇫'
                },
                {
                    phoneCode: '+689',
                    code: 'PF',
                    name: translate('french_polynesi_50842'),
                    nationality: translate('french_polynesi_47751'),
                    emoji: '🇵🇫'
                },
                {
                    phoneCode: '+241',
                    code: 'GA',
                    name: translate('gabon_58286'),
                    nationality: translate('gabonese_62984'),
                    emoji: '🇬🇦'
                },
                {
                    phoneCode: '+220',
                    code: 'GM',
                    name: translate('gambia_93575'),
                    nationality: translate('gambian_30767'),
                    emoji: '🇬🇲'
                },
                {
                    phoneCode: '+995',
                    code: 'GE',
                    name: translate('georgia_93003'),
                    nationality: translate('georgian_79366'),
                    emoji: '🇬🇪',
                    language: ['ka', 'ka-GE']
                },
                {
                    phoneCode: '+49',
                    code: 'DE',
                    name: translate('germany_31193'),
                    nationality: translate('german_36386'),
                    emoji: '🇩🇪',
                    language: ['de', 'de-DE']
                },
                {
                    phoneCode: '+233',
                    code: 'GH',
                    name: translate('ghana_32464'),
                    nationality: translate('ghanaian_99745'),
                    emoji: '🇬🇭'
                },
                {
                    phoneCode: '+350',
                    code: 'GI',
                    name: translate('gibraltar_24360'),
                    nationality: translate('gibraltar_24360'),
                    emoji: '🇬🇮'
                },
                {
                    phoneCode: '+30',
                    code: 'GR',
                    name: translate('greece_28837'),
                    nationality: translate('greek_hellenic_39006'),
                    emoji: '🇬🇷',
                    language: ['el', 'el-GR']
                },
                {
                    phoneCode: '+299',
                    code: 'GL',
                    name: translate('greenland_80916'),
                    nationality: translate('greenlandic_67508'),
                    emoji: '🇬🇱'
                },
                {
                    phoneCode: '+1473',
                    code: 'GD',
                    name: translate('grenada_04221'),
                    nationality: translate('grenadian_25686'),
                    emoji: '🇬🇩'
                },
                {
                    phoneCode: '+590',
                    code: 'GP',
                    name: translate('guadeloupe_95839'),
                    nationality: translate('guadeloupe_95839'),
                    emoji: '🇬🇵'
                },
                {
                    phoneCode: '+1671',
                    code: 'GU',
                    name: translate('guam_76384'),
                    nationality: translate('guamanian_guam_01132'),
                    emoji: '🇬🇺'
                },
                {
                    phoneCode: '+502',
                    code: 'GT',
                    name: translate('guatemala_49597'),
                    nationality: translate('guatemalan_60946'),
                    emoji: '🇬🇹',
                    language: 'es-GT'
                },
                {
                    phoneCode: ['+441481', '+447781', '+447839', '+447911'],
                    code: 'GG',
                    name: translate('guernsey_87507'),
                    nationality: translate('channel_island_53762'),
                    emoji: '🇬🇬'
                },
                {
                    phoneCode: '+224',
                    code: 'GN',
                    name: translate('guinea_20770'),
                    nationality: translate('guinean_42564'),
                    emoji: '🇬🇳'
                },
                {
                    phoneCode: '+245',
                    code: 'GW',
                    name: translate('guinea_bissau_80728'),
                    nationality: translate('bissau_guinean_66742'),
                    emoji: '🇬🇼'
                },
                {
                    phoneCode: '+592',
                    code: 'GY',
                    name: translate('guyana_92443'),
                    nationality: translate('guyanese_56902'),
                    emoji: '🇬🇾'
                },
                {
                    phoneCode: '+509',
                    code: 'HT',
                    name: translate('haiti_99471'),
                    nationality: translate('haitian_50142'),
                    emoji: '🇭🇹'
                },
                {
                    phoneCode: '+379',
                    code: 'VA',
                    name: translate('vatican_city_st_63732'),
                    nationality: translate('vatican_36370'),
                    emoji: '🇻🇦'
                },
                {
                    phoneCode: '+504',
                    code: 'HN',
                    name: translate('honduras_79402'),
                    nationality: translate('honduran_35392'),
                    emoji: '🇭🇳',
                    language: 'es-HN'
                },
                {
                    phoneCode: '+852',
                    code: 'HK',
                    name: translate('hong_kong_96239'),
                    nationality: translate('hong_kong_hong_64846'),
                    emoji: '🇭🇰',
                    language: 'zh-HK'
                },
                {
                    phoneCode: '+36',
                    code: 'HU',
                    name: translate('hungary_57981'),
                    nationality: translate('hungarian_magy_62212'),
                    emoji: '🇭🇺',
                    language: ['hu', 'hu-HU']
                },
                {
                    phoneCode: '+354',
                    code: 'IS',
                    name: translate('iceland_79438'),
                    nationality: translate('icelandic_17689'),
                    emoji: '🇮🇸',
                    language: ['is', 'is-IS']
                },
                {
                    phoneCode: '+91',
                    code: 'IN',
                    name: translate('india_39466'),
                    nationality: translate('indian_25592'),
                    emoji: '🇮🇳',
                    language: [
                        'gu',
                        'gu-IN',
                        'hi',
                        'hi-IN',
                        'kn',
                        'kn-IN',
                        'kok',
                        'kok-IN',
                        'mr',
                        'mr-IN',
                        'pa',
                        'pa-IN',
                        'sa',
                        'sa-IN',
                        'ta',
                        'ta-IN',
                        'te',
                        'te-IN'
                    ]
                },
                {
                    phoneCode: '+62',
                    code: 'ID',
                    name: translate('indonesia_65003'),
                    nationality: translate('indonesian_29723'),
                    emoji: '🇮🇩',
                    language: ['id', 'id-ID']
                },
                {
                    phoneCode: '+98',
                    code: 'IR',
                    name: translate('iran_76293'),
                    nationality: translate('iranian_persia_76791'),
                    emoji: '🇮🇷',
                    language: ['fa', 'fa-IR']
                },
                {
                    phoneCode: '+964',
                    code: 'IQ',
                    name: translate('iraq_82027'),
                    nationality: translate('iraqi_31025'),
                    emoji: '🇮🇶',
                    language: 'ar-IQ'
                },
                {
                    phoneCode: '+353',
                    code: 'IE',
                    name: translate('ireland_62034'),
                    nationality: translate('irish_35774'),
                    emoji: '🇮🇪',
                    language: 'en-IE'
                },
                {
                    phoneCode: ['+441624', '+447524', '+447624', '+447924'],
                    code: 'IM',
                    name: translate('isle_of_man_64982'),
                    nationality: translate('manx_86314'),
                    emoji: '🇮🇲'
                },
                {
                    phoneCode: '+972',
                    code: 'IL',
                    name: translate('israel_20033'),
                    nationality: translate('israeli_69936'),
                    emoji: '🇮🇱',
                    language: ['he', 'he-IL']
                },
                {
                    phoneCode: '+39',
                    code: 'IT',
                    name: translate('italy_14828'),
                    nationality: translate('italian_56623'),
                    emoji: '🇮🇹',
                    language: ['it', 'it-IT']
                },
                {
                    phoneCode: '+1876',
                    code: 'JM',
                    name: translate('jamaica_55090'),
                    nationality: translate('jamaican_58881'),
                    emoji: '🇯🇲',
                    language: 'en-JM'
                },
                {
                    phoneCode: '+81',
                    code: 'JP',
                    name: translate('japan_61810'),
                    nationality: translate('japanese_82636'),
                    emoji: '🇯🇵',
                    language: ['ja', 'ja-JP']
                },
                {
                    phoneCode: '+441534',
                    code: 'JE',
                    name: translate('jersey_78898'),
                    nationality: translate('channel_island_53762'),
                    emoji: '🇯🇪'
                },
                {
                    phoneCode: '+962',
                    code: 'JO',
                    name: translate('jordan_36280'),
                    nationality: translate('jordanian_41310'),
                    emoji: '🇯🇴',
                    language: 'ar-JO'
                },
                {
                    phoneCode: ['+76', '+77'],
                    code: 'KZ',
                    name: translate('kazakhstan_30541'),
                    nationality: translate('kazakhstani_ka_27277'),
                    emoji: '🇰🇿',
                    language: ['kk', 'kk-KZ']
                },
                {
                    phoneCode: '+254',
                    code: 'KE',
                    name: translate('kenya_67944'),
                    nationality: translate('kenyan_92311'),
                    emoji: '🇰🇪',
                    language: ['sw', 'sw-KE']
                },
                {
                    phoneCode: '+686',
                    code: 'KI',
                    name: translate('kiribati_25369'),
                    nationality: translate('i_kiribati_66745'),
                    emoji: '🇰🇮'
                },
                {
                    phoneCode: '+850',
                    code: 'KP',
                    name: translate('korea_democrat_48584'),
                    nationality: translate('north_korean_16553'),
                    emoji: '🇰🇵'
                },
                {
                    phoneCode: '+82',
                    code: 'KR',
                    name: translate('korea_republic_72269'),
                    nationality: translate('south_korean_89943'),
                    emoji: '🇰🇷',
                    language: ['ko', 'ko-KR']
                },
                {
                    phoneCode: '+965',
                    code: 'KW',
                    name: translate('kuwait_22026'),
                    nationality: translate('kuwaiti_76148'),
                    emoji: '🇰🇼',
                    language: 'ar-KW'
                },
                {
                    phoneCode: '+996',
                    code: 'KG',
                    name: translate('kyrgyzstan_84406'),
                    nationality: translate('kyrgyzstani_ky_19120'),
                    emoji: '🇰🇬',
                    language: ['ky', 'ky-KG']
                },
                {
                    phoneCode: '+856',
                    code: 'LA',
                    name: translate('lao_people_s_de_31069'),
                    nationality: translate('lao_laotian_28318'),
                    emoji: '🇱🇦'
                },
                {
                    phoneCode: '+371',
                    code: 'LV',
                    name: translate('latvia_73526'),
                    nationality: translate('latvian_53651'),
                    emoji: '🇱🇻',
                    language: ['lv', 'lv-LV']
                },
                {
                    phoneCode: '+961',
                    code: 'LB',
                    name: translate('lebanon_49493'),
                    nationality: translate('lebanese_43182'),
                    emoji: '🇱🇧',
                    language: 'ar-LB'
                },
                {
                    phoneCode: '+266',
                    code: 'LS',
                    name: translate('lesotho_91086'),
                    nationality: translate('basotho_52532'),
                    emoji: '🇱🇸'
                },
                {
                    phoneCode: '+231',
                    code: 'LR',
                    name: translate('liberia_12077'),
                    nationality: translate('liberian_64388'),
                    emoji: '🇱🇷'
                },
                {
                    phoneCode: '+218',
                    code: 'LY',
                    name: translate('libya_26741'),
                    nationality: translate('libyan_35337'),
                    emoji: '🇱🇾',
                    language: 'ar-LY'
                },
                {
                    phoneCode: '+424',
                    code: 'LI',
                    name: translate('liechtenstein_56974'),
                    nationality: translate('liechtenstein_56974'),
                    emoji: '🇱🇮',
                    language: 'de-LI'
                },
                {
                    phoneCode: '+370',
                    code: 'LT',
                    name: translate('lithuania_95793'),
                    nationality: translate('lithuanian_94145'),
                    emoji: '🇱🇹',
                    language: ['lt', 'lt-LT']
                },
                {
                    phoneCode: '+352',
                    code: 'LU',
                    name: translate('luxembourg_15613'),
                    nationality: translate('luxembourg_lux_47318'),
                    emoji: '🇱🇺',
                    language: ['de-LU', 'fr-LU']
                },
                {
                    phoneCode: '+853',
                    code: 'MO',
                    name: translate('macao_74812'),
                    nationality: translate('macanese_chine_74224'),
                    emoji: '🇲🇴',
                    language: 'zh-MO'
                },
                {
                    phoneCode: '+389',
                    code: 'MK',
                    name: translate('macedonia_the_19021'),
                    nationality: translate('macedonian_20446'),
                    emoji: '🇲🇰',
                    language: ['mk', 'mk-MK']
                },
                {
                    phoneCode: '+261',
                    code: 'MG',
                    name: translate('madagascar_57052'),
                    nationality: translate('malagasy_02353'),
                    emoji: '🇲🇬'
                },
                {
                    phoneCode: '+265',
                    code: 'MW',
                    name: translate('malawi_27143'),
                    nationality: translate('malawian_10093'),
                    emoji: '🇲🇼'
                },
                {
                    phoneCode: '+60',
                    code: 'MY',
                    name: translate('malaysia_54920'),
                    nationality: translate('malaysian_81474'),
                    emoji: '🇲🇾',
                    language: ['ms', 'ms-MY']
                },
                {
                    phoneCode: '+960',
                    code: 'MV',
                    name: translate('maldives_67432'),
                    nationality: translate('maldivian_67878'),
                    emoji: '🇲🇻',
                    language: ['dv', 'dv-MV']
                },
                {
                    phoneCode: '+223',
                    code: 'ML',
                    name: translate('mali_95091'),
                    nationality: translate('malian_malines_66005'),
                    emoji: '🇲🇱'
                },
                {
                    phoneCode: '+356',
                    code: 'MT',
                    name: translate('malta_73993'),
                    nationality: translate('maltese_52729'),
                    emoji: '🇲🇹',
                    language: ['mt', 'mt-MT']
                },
                {
                    phoneCode: '+692',
                    code: 'MH',
                    name: translate('marshall_island_92726'),
                    nationality: translate('marshallese_30337'),
                    emoji: '🇲🇭'
                },
                {
                    phoneCode: '+586',
                    code: 'MQ',
                    name: translate('martinique_79541'),
                    nationality: translate('martiniquais_m_01923'),
                    emoji: '🇲🇶'
                },
                {
                    phoneCode: '+222',
                    code: 'MR',
                    name: translate('mauritania_77906'),
                    nationality: translate('mauritanian_68205'),
                    emoji: '🇲🇷'
                },
                {
                    phoneCode: '+230',
                    code: 'MU',
                    name: translate('mauritius_10801'),
                    nationality: translate('mauritian_09142'),
                    emoji: '🇲🇺'
                },
                {
                    phoneCode: ['+262269', '+262639'],
                    code: 'YT',
                    name: translate('mayotte_91167'),
                    nationality: translate('mahoran_45424'),
                    emoji: '🇾🇹'
                },
                {
                    phoneCode: '+52',
                    code: 'MX',
                    name: translate('mexico_21962'),
                    nationality: translate('mexican_80494'),
                    emoji: '🇲🇽',
                    language: 'es-MX'
                },
                {
                    phoneCode: '+691',
                    code: 'FM',
                    name: translate('micronesia_fed_39555'),
                    nationality: translate('micronesian_97912'),
                    emoji: '🇫🇲'
                },
                {
                    phoneCode: '+373',
                    code: 'MD',
                    name: translate('moldova_republ_08796'),
                    nationality: translate('moldovan_76486'),
                    emoji: '🇲🇩'
                },
                {
                    phoneCode: '+377',
                    code: 'MC',
                    name: translate('monaco_72117'),
                    nationality: translate('mon_gasque_mon_17281'),
                    emoji: '🇲🇨',
                    language: 'fr-MC'
                },
                {
                    phoneCode: '+976',
                    code: 'MN',
                    name: translate('mongolia_36757'),
                    nationality: translate('mongolian_33711'),
                    emoji: '🇲🇳',
                    language: ['mn', 'mn-MN']
                },
                {
                    phoneCode: '+382',
                    code: 'ME',
                    name: translate('montenegro_78236'),
                    nationality: translate('montenegrin_68334'),
                    emoji: '🇲🇪'
                },
                {
                    phoneCode: '+1664',
                    code: 'MS',
                    name: translate('montserrat_38432'),
                    nationality: translate('montserratian_13585'),
                    emoji: '🇲🇸'
                },
                {
                    phoneCode: '+212',
                    code: 'MA',
                    name: translate('morocco_52420'),
                    nationality: translate('moroccan_14305'),
                    emoji: '🇲🇦',
                    language: 'ar-MA'
                },
                {
                    phoneCode: '+258',
                    code: 'MZ',
                    name: translate('mozambique_60988'),
                    nationality: translate('mozambican_68300'),
                    emoji: '🇲🇿'
                },
                {
                    phoneCode: '+95',
                    code: 'MM',
                    name: translate('myanmar_78678'),
                    nationality: translate('burmese_66869'),
                    emoji: '🇲🇲'
                },
                {
                    phoneCode: '+264',
                    code: 'NA',
                    name: translate('namibia_19026'),
                    nationality: translate('namibian_01490'),
                    emoji: '🇳🇦'
                },
                {
                    phoneCode: '+674',
                    code: 'NR',
                    name: translate('nauru_16303'),
                    nationality: translate('nauruan_52578'),
                    emoji: '🇳🇷'
                },
                {
                    phoneCode: '+977',
                    code: 'NP',
                    name: translate('nepal_49088'),
                    nationality: translate('nepali_nepales_64761'),
                    emoji: '🇳🇵'
                },
                {
                    phoneCode: '+31',
                    code: 'NL',
                    name: translate('netherlands_38005'),
                    nationality: translate('dutch_netherla_42451'),
                    emoji: '🇳🇱',
                    language: ['nl', 'nl-NL']
                },
                {
                    phoneCode: '+687',
                    code: 'NC',
                    name: translate('new_caledonia_14574'),
                    nationality: translate('new_caledonian_12113'),
                    emoji: '🇳🇨'
                },
                {
                    phoneCode: '+64',
                    code: 'NZ',
                    name: translate('new_zealand_61929'),
                    nationality: translate('new_zealander_71823'),
                    emoji: '🇳🇿',
                    language: ['en-NZ', 'mi', 'mi-NZ']
                },
                {
                    phoneCode: '+505',
                    code: 'NI',
                    name: translate('nicaragua_14531'),
                    nationality: translate('nicaraguan_06649'),
                    emoji: '🇳🇮',
                    language: 'es-NI'
                },
                {
                    phoneCode: '+227',
                    code: 'NE',
                    name: translate('niger_59137'),
                    nationality: translate('nigerien_06144'),
                    emoji: '🇳🇪'
                },
                {
                    phoneCode: '+234',
                    code: 'NG',
                    name: translate('nigeria_77810'),
                    nationality: translate('nigerian_43407'),
                    emoji: '🇳🇬'
                },
                {
                    phoneCode: '+683',
                    code: 'NU',
                    name: translate('niue_97570'),
                    nationality: translate('niuean_70518'),
                    emoji: '🇳🇺'
                },
                {
                    phoneCode: '+6723',
                    code: 'NF',
                    name: translate('norfolk_island_62377'),
                    nationality: translate('norfolk_island_62377'),
                    emoji: '🇳🇫'
                },
                {
                    phoneCode: '+1670',
                    code: 'MP',
                    name: translate('northern_marian_94262'),
                    nationality: translate('northern_marian_82360'),
                    emoji: '🇲🇵'
                },
                {
                    phoneCode: '+47',
                    code: 'NO',
                    name: translate('norway_14236'),
                    nationality: translate('norwegian_50751'),
                    emoji: '🇳🇴',
                    language: ['nb', 'nb-NO', 'nn-NO', 'se-NO']
                },
                {
                    phoneCode: '+968',
                    code: 'OM',
                    name: translate('oman_20900'),
                    nationality: translate('omani_15042'),
                    emoji: '🇴🇲',
                    language: 'ar-OM'
                },
                {
                    phoneCode: '+92',
                    code: 'PK',
                    name: translate('pakistan_59697'),
                    nationality: translate('pakistani_12622'),
                    emoji: '🇵🇰',
                    language: ['ur', 'ur-PK']
                },
                {
                    phoneCode: '+680',
                    code: 'PW',
                    name: translate('palau_46088'),
                    nationality: translate('palauan_73033'),
                    emoji: '🇵🇼'
                },
                {
                    phoneCode: '+970',
                    code: 'PS',
                    name: translate('palestine_stat_33253'),
                    nationality: translate('palestinian_06829'),
                    emoji: '🇵🇸'
                },
                {
                    phoneCode: '+507',
                    code: 'PA',
                    name: translate('panama_16821'),
                    nationality: translate('panamanian_07882'),
                    emoji: '🇵🇦',
                    language: 'es-PA'
                },
                {
                    phoneCode: '+675',
                    code: 'PG',
                    name: translate('papua_new_guine_56959'),
                    nationality: translate('papua_new_guine_50302'),
                    emoji: '🇵🇬'
                },
                {
                    phoneCode: '+595',
                    code: 'PY',
                    name: translate('paraguay_66859'),
                    nationality: translate('paraguayan_50310'),
                    emoji: '🇵🇾',
                    language: 'es-PY'
                },
                {
                    phoneCode: '+51',
                    code: 'PE',
                    name: translate('peru_23048'),
                    nationality: translate('peruvian_85045'),
                    emoji: '🇵🇪',
                    language: ['es-PE', 'qu-PE']
                },
                {
                    phoneCode: '+63',
                    code: 'PH',
                    name: translate('philippines_65361'),
                    nationality: translate('philippine_fil_40400'),
                    emoji: '🇵🇭',
                    language: ['en-PH', 'tl', 'tl-PH']
                },
                {
                    phoneCode: '+64',
                    code: 'PN',
                    name: translate('pitcairn_59104'),
                    nationality: translate('pitcairn_island_58961'),
                    emoji: '🇵🇳'
                },
                {
                    phoneCode: '+48',
                    code: 'PL',
                    name: translate('poland_13527'),
                    nationality: translate('polish_26779'),
                    emoji: '🇵🇱',
                    language: ['pl', 'pl-PL']
                },
                {
                    phoneCode: '+351',
                    code: 'PT',
                    name: translate('portugal_19698'),
                    nationality: translate('portuguese_08463'),
                    emoji: '🇵🇹',
                    language: ['pt', 'pt-PT']
                },
                {
                    phoneCode: ['+1787', '+1939'],
                    code: 'PR',
                    name: translate('puerto_rico_88176'),
                    nationality: translate('puerto_rican_10401'),
                    emoji: '🇵🇷',
                    language: 'es-PR'
                },
                {
                    phoneCode: '+974',
                    code: 'QA',
                    name: translate('qatar_70548'),
                    nationality: translate('qatari_58016'),
                    emoji: '🇶🇦',
                    language: 'ar-QA'
                },
                {
                    phoneCode: '+262',
                    code: 'RE',
                    name: translate('r_union_34094'),
                    nationality: translate('r_unionese_r_u_86871'),
                    emoji: '🇷🇪'
                },
                {
                    phoneCode: '+40',
                    code: 'RO',
                    name: translate('romania_41618'),
                    nationality: translate('romanian_49647'),
                    emoji: '🇷🇴',
                    language: ['ro', 'ro-RO']
                },
                {
                    phoneCode: '+7',
                    code: 'RU',
                    name: translate('russian_federat_68392'),
                    nationality: translate('russian_12506'),
                    emoji: '🇷🇺',
                    language: ['ru', 'ru-RU', 'tt', 'tt-RU']
                },
                {
                    phoneCode: '+250',
                    code: 'RW',
                    name: translate('rwanda_84901'),
                    nationality: translate('rwandan_47719'),
                    emoji: '🇷🇼'
                },
                {
                    phoneCode: '+590',
                    code: 'BL',
                    name: translate('saint_barth_lem_80830'),
                    nationality: translate('barth_lemois_35003'),
                    emoji: '🇧🇱'
                },
                {
                    phoneCode: '+290',
                    code: 'SH',
                    name: translate('saint_helena_a_39384'),
                    nationality: translate('saint_helenian_19012'),
                    emoji: '🇸🇭'
                },
                {
                    phoneCode: '+1869',
                    code: 'KN',
                    name: translate('saint_kitts_and_74455'),
                    nationality: translate('kittitian_or_ne_99867'),
                    emoji: '🇰🇳'
                },
                {
                    phoneCode: '+1758',
                    code: 'LC',
                    name: translate('saint_lucia_60679'),
                    nationality: translate('saint_lucian_97267'),
                    emoji: '🇱🇨'
                },
                {
                    phoneCode: '+590',
                    code: 'MF',
                    name: translate('saint_martin_f_87295'),
                    nationality: translate('saint_martinois_75974'),
                    emoji: '🇸🇽'
                },
                {
                    phoneCode: '+508',
                    code: 'PM',
                    name: translate('saint_pierre_an_55649'),
                    nationality: translate('saint_pierrais_60177'),
                    emoji: '🇵🇲'
                },
                {
                    phoneCode: '+1784',
                    code: 'VC',
                    name: translate('saint_vincent_a_23833'),
                    nationality: translate('saint_vincentia_81882'),
                    emoji: '🇻🇨'
                },
                {
                    phoneCode: '+685',
                    code: 'WS',
                    name: translate('samoa_25648'),
                    nationality: translate('samoan_97653'),
                    emoji: '🇼🇸'
                },
                {
                    phoneCode: '+378',
                    code: 'SM',
                    name: translate('san_marino_47437'),
                    nationality: translate('sammarinese_71553'),
                    emoji: '🇸🇲'
                },
                {
                    phoneCode: '+239',
                    code: 'ST',
                    name: translate('sao_tome_and_pr_10491'),
                    nationality: translate('s_o_tom_an_21489'),
                    emoji: '🇸🇹'
                },
                {
                    phoneCode: '+966',
                    code: 'SA',
                    name: translate('saudi_arabia_06433'),
                    nationality: translate('saudi_saudi_ar_22967'),
                    emoji: '🇸🇦',
                    language: 'ar-SA'
                },
                {
                    phoneCode: '+221',
                    code: 'SN',
                    name: translate('senegal_38099'),
                    nationality: translate('senegalese_64680'),
                    emoji: '🇸🇳'
                },
                {
                    phoneCode: '+381',
                    code: 'RS',
                    name: translate('serbia_45061'),
                    nationality: translate('serbian_68735'),
                    emoji: '🇷🇸'
                },
                {
                    phoneCode: '+248',
                    code: 'SC',
                    name: translate('seychelles_67712'),
                    nationality: translate('seychellois_69858'),
                    emoji: '🇸🇨'
                },
                {
                    phoneCode: '+232',
                    code: 'SL',
                    name: translate('sierra_leone_02995'),
                    nationality: translate('sierra_leonean_86179'),
                    emoji: '🇸🇱'
                },
                {
                    phoneCode: '+65',
                    code: 'SG',
                    name: translate('singapore_37928'),
                    nationality: translate('singaporean_41916'),
                    emoji: '🇸🇬',
                    language: 'zh-SG'
                },
                {
                    phoneCode: '+1721',
                    code: 'SX',
                    name: translate('sint_maarten_d_57391'),
                    nationality: translate('sint_maarten_82241'),
                    emoji: '🇸🇽'
                },
                {
                    phoneCode: '+421',
                    code: 'SK',
                    name: translate('slovakia_10924'),
                    nationality: translate('slovak_69840'),
                    emoji: '🇸🇰',
                    language: ['sk', 'sk-SK']
                },
                {
                    phoneCode: '+386',
                    code: 'SI',
                    name: translate('slovenia_75447'),
                    nationality: translate('slovenian_slov_38562'),
                    emoji: '🇸🇮',
                    language: ['sl', 'sl-SI']
                },
                {
                    phoneCode: '+677',
                    code: 'SB',
                    name: translate('solomon_islands_05259'),
                    nationality: translate('solomon_island_59052'),
                    emoji: '🇸🇧'
                },
                {
                    phoneCode: '+252',
                    code: 'SO',
                    name: translate('somalia_56358'),
                    nationality: translate('somali_somalia_97997'),
                    emoji: '🇸🇴'
                },
                {
                    phoneCode: '+27',
                    code: 'ZA',
                    name: translate('south_africa_61184'),
                    nationality: translate('south_african_82152'),
                    emoji: '🇿🇦',
                    language: [
                        'af-ZA',
                        'en-ZA',
                        'ns',
                        'ns-ZA',
                        'tn',
                        'tn-ZA',
                        'xh',
                        'xh-ZA',
                        'zu',
                        'zu-ZA'
                    ]
                },
                {
                    phoneCode: '+500',
                    code: 'GS',
                    name: translate('south_georgia_a_39555'),
                    nationality: translate('south_georgia_o_65055'),
                    emoji: '🇬🇸'
                },
                {
                    phoneCode: '+211',
                    code: 'SS',
                    name: translate('south_sudan_88232'),
                    nationality: translate('south_sudanese_69140'),
                    emoji: '🇸🇸'
                },
                {
                    phoneCode: '+34',
                    code: 'ES',
                    name: translate('spain_40048'),
                    nationality: translate('spanish_74199'),
                    emoji: '🇪🇸',
                    language: ['ca', 'ca-ES', 'es', 'es-ES', 'eu-ES', 'gl-ES']
                },
                {
                    phoneCode: '+94',
                    code: 'LK',
                    name: translate('sri_lanka_51563'),
                    nationality: translate('sri_lankan_09436'),
                    emoji: '🇱🇰'
                },
                {
                    phoneCode: '+249',
                    code: 'SD',
                    name: translate('sudan_18427'),
                    nationality: translate('sudanese_42330'),
                    emoji: '🇸🇩'
                },
                {
                    phoneCode: '+597',
                    code: 'SR',
                    name: translate('suriname_78561'),
                    nationality: translate('surinamese_38193'),
                    emoji: '🇸🇷'
                },
                {
                    phoneCode: '+4779',
                    code: 'SJ',
                    name: translate('svalbard_and_ja_38511'),
                    nationality: translate('svalbard_26353'),
                    emoji: '🇸🇯'
                },
                {
                    phoneCode: '+268',
                    code: 'SZ',
                    name: translate('swaziland_91614'),
                    nationality: translate('swazi_88825'),
                    emoji: '🇸🇿'
                },
                {
                    phoneCode: '+46',
                    code: 'SE',
                    name: translate('sweden_85663'),
                    nationality: translate('swedish_90836'),
                    emoji: '🇸🇪',
                    language: ['se-SE', 'sv', 'sv-SE']
                },
                {
                    phoneCode: '+41',
                    code: 'CH',
                    name: translate('switzerland_07889'),
                    nationality: translate('swiss_23240'),
                    emoji: '🇨🇭',
                    language: ['de-CH', 'fr-CH', 'it-CH']
                },
                {
                    phoneCode: '+963',
                    code: 'SY',
                    name: translate('syrian_arab_rep_72539'),
                    nationality: translate('syrian_06992'),
                    emoji: '🇸🇾',
                    language: ['ar-SY', 'syr', 'syr-SY']
                },
                {
                    phoneCode: '+886',
                    code: 'TW',
                    name: translate('taiwan_provinc_87264'),
                    nationality: translate('chinese_taiwan_24003'),
                    emoji: '🇹🇼',
                    language: 'zh-TW'
                },
                {
                    phoneCode: '+992',
                    code: 'TJ',
                    name: translate('tajikistan_97104'),
                    nationality: translate('tajikistani_21375'),
                    emoji: '🇹🇯'
                },
                {
                    phoneCode: '+255',
                    code: 'TZ',
                    name: translate('tanzania_unite_52757'),
                    nationality: translate('tanzanian_71935'),
                    emoji: '🇹🇿'
                },
                {
                    phoneCode: '+66',
                    code: 'TH',
                    name: translate('thailand_97396'),
                    nationality: translate('thai_51972'),
                    emoji: '🇹🇭',
                    language: ['th', 'th-TH']
                },
                {
                    phoneCode: '+670',
                    code: 'TL',
                    name: translate('timor_leste_95604'),
                    nationality: translate('timorese_87542'),
                    emoji: '🇹🇱'
                },
                {
                    phoneCode: '+228',
                    code: 'TG',
                    name: translate('togo_60267'),
                    nationality: translate('togolese_55079'),
                    emoji: '🇹🇬'
                },
                {
                    phoneCode: '+690',
                    code: 'TK',
                    name: translate('tokelau_79536'),
                    nationality: translate('tokelauan_89444'),
                    emoji: '🇹🇰'
                },
                {
                    phoneCode: '+676',
                    code: 'TO',
                    name: translate('tonga_96982'),
                    nationality: translate('tongan_52630'),
                    emoji: '🇹🇴'
                },
                {
                    phoneCode: '+1868',
                    code: 'TT',
                    name: translate('trinidad_and_to_31939'),
                    nationality: translate('trinidadian_or_65515'),
                    emoji: '🇹🇹',
                    language: 'en-TT'
                },
                {
                    phoneCode: '+216',
                    code: 'TN',
                    name: translate('tunisia_36732'),
                    nationality: translate('tunisian_19451'),
                    emoji: '🇹🇳',
                    language: 'ar-TN'
                },
                {
                    phoneCode: '+90',
                    code: 'TR',
                    name: translate('turkey_65699'),
                    nationality: translate('turkish_85889'),
                    emoji: '🇹🇷',
                    language: ['tr', 'tr-TR']
                },
                {
                    phoneCode: '+993',
                    code: 'TM',
                    name: translate('turkmenistan_86069'),
                    nationality: translate('turkmen_63945'),
                    emoji: '🇹🇲'
                },
                {
                    phoneCode: '+1649',
                    code: 'TC',
                    name: translate('turks_and_caico_34033'),
                    nationality: translate('turks_and_caico_41685'),
                    emoji: '🇹🇨'
                },
                {
                    phoneCode: '+688',
                    code: 'TV',
                    name: translate('tuvalu_52410'),
                    nationality: translate('tuvaluan_49403'),
                    emoji: '🇹🇻'
                },
                {
                    phoneCode: '+256',
                    code: 'UG',
                    name: translate('uganda_42928'),
                    nationality: translate('ugandan_07085'),
                    emoji: '🇺🇬'
                },
                {
                    phoneCode: '+380',
                    code: 'UA',
                    name: translate('ukraine_86632'),
                    nationality: translate('ukrainian_77624'),
                    emoji: '🇺🇦',
                    language: ['uk', 'uk-UA']
                },
                {
                    phoneCode: '+971',
                    code: 'AE',
                    name: translate('united_arab_emi_58689'),
                    nationality: translate('emirati_emiria_12413'),
                    emoji: '🇦🇪',
                    language: 'ar-AE'
                },
                {
                    phoneCode: '+44',
                    code: 'GB',
                    name: translate('united_kingdom_16783'),
                    nationality: translate('british_43680'),
                    emoji: '🇬🇧',
                    language: ['cy', 'cy-GB', 'en', 'en-GB']
                },
                {
                    phoneCode: '+1',
                    code: 'US',
                    name: translate('united_states_o_43878'),
                    nationality: translate('american_36800'),
                    emoji: '🇺🇸',
                    language: 'en-US'
                },
                {
                    phoneCode: '+598',
                    code: 'UY',
                    name: translate('uruguay_21979'),
                    nationality: translate('uruguayan_28212'),
                    emoji: '🇺🇾',
                    language: 'es-UY'
                },
                {
                    phoneCode: '+998',
                    code: 'UZ',
                    name: translate('uzbekistan_27729'),
                    nationality: translate('uzbekistani_uz_10593'),
                    emoji: '🇺🇿',
                    language: ['uz', 'uz-UZ']
                },
                {
                    phoneCode: '+678',
                    code: 'VU',
                    name: translate('vanuatu_82756'),
                    nationality: translate('ni_vanuatu_van_02924'),
                    emoji: '🇻🇺'
                },
                {
                    phoneCode: '+58',
                    code: 'VE',
                    name: translate('venezuela_boli_00470'),
                    nationality: translate('venezuelan_52634'),
                    emoji: '🇻🇪',
                    language: 'es-VE'
                },
                {
                    phoneCode: '+84',
                    code: 'VN',
                    name: translate('vietnam_82968'),
                    nationality: translate('vietnamese_55360'),
                    emoji: '🇻🇳',
                    language: ['vi', 'vi-VN']
                },
                {
                    phoneCode: '+1284',
                    code: 'VG',
                    name: translate('virgin_islands_14222'),
                    nationality: translate('british_43680'),
                    emoji: '🇻🇬'
                },
                {
                    phoneCode: '+1340',
                    code: 'VI',
                    name: translate('virgin_islands_12516'),
                    nationality: translate('american_36800'),
                    emoji: '🇻🇮'
                },
                {
                    phoneCode: '+681',
                    code: 'WF',
                    name: translate('wallis_and_futu_93206'),
                    nationality: translate('wallisian_or_fu_60327'),
                    emoji: '🇼🇫'
                },
                {
                    phoneCode: '+212',
                    code: 'EH',
                    name: translate('western_sahara_02173'),
                    nationality: translate('sahrawi_sahraw_96671'),
                    emoji: '🇪🇭'
                },
                {
                    phoneCode: '+967',
                    code: 'YE',
                    name: translate('yemen_76340'),
                    nationality: translate('yemeni_26632'),
                    emoji: '🇾🇪',
                    language: 'ar-YE'
                },
                {
                    phoneCode: '+260',
                    code: 'ZM',
                    name: translate('zambia_50513'),
                    nationality: translate('zambian_85114'),
                    emoji: '🇿🇲'
                },
                {
                    phoneCode: '+263',
                    code: 'ZW',
                    name: translate('zimbabwe_70209'),
                    nationality: translate('zimbabwean_42291'),
                    emoji: '🇿🇼',
                    language: 'en-ZW'
                }
            ],
            ({ name }) => normalize(name)
        );
        this.countriesCodes = this.countries.map(({ code }) => code);
        this.phoneCodes = this.countries.flatMap(({ phoneCode }) => toArray(phoneCode));
        this.countriesIdName = this.countries.map(({ code, name }) => ({
            id: code,
            name
        }));
        this.nationalitiesIdName = this.countries.map(({ code, nationality }) => ({
            id: code,
            name: nationality
        }));
    }

    countryCodeExists(code?: string): boolean {
        if (code) {
            return this.countries.find((country) => country.code === code) !== undefined;
        } else {
            return false;
        }
    }

    getNationality(code: string): string | undefined {
        return this.countries.find((country) => country.code === code)?.nationality;
    }

    getName(code: string): string | undefined {
        return this.countries.find((country) => country.code === code)?.name;
    }

    getDefaultCountryValues(countryCode?: Emptyable<string>): IDefaultCountryValues {
        let country: ICountry | undefined = this.countries.find(({ code }) => code === countryCode);

        if (!country) {
            try {
                country = this.countries.find((c) => toArray(c.language || [])
                        .map((l) => l.toLowerCase())
                        .includes(navigator.language.toLowerCase()));
            } catch {
                // do nothing
            }

            if (!country) {
                country = this.countries.find(({ code }) => code === 'FR');
            }
        }

        const languages = toArray(country!.language || []);
        const language = languages.some((l) => l.toLowerCase().startsWith('fr'))
            ? SupportedLanguage.Fr
            : languages.some((l) => l.toLowerCase().startsWith('es'))
            ? SupportedLanguage.Es
            : languages.some((l) => l.toLowerCase().startsWith('de'))
            ? SupportedLanguage.De
            : languages.some((l) => l.toLowerCase().startsWith('nl'))
            ? SupportedLanguage.Nl
            : SupportedLanguage.En;

        return {
            user: {
                language,
                country: country!.code,
                nationality: country!.code
            },
            phone: {
                country: country!.code,
                code: toArray(country!.phoneCode)[0]
            }
        };
    }
}
