import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { intervalFinalName } from 'common/src/vo/volunteerRegistrationSlot';
import { Interval } from 'luxon';
import * as React from 'react';
import { CheckboxText, ICheckboxState } from '../../../designSystem/components/checkbox';

interface IFormSlotsCustomDayProps {
    day: string;
    intervals: Array<{ name: string; interval: Interval }>;

    isSelected(inteval: Interval): boolean;
    setSelected(interval: Interval, isSelected: boolean): void;
}

export const FormSlotsCustomDay = (props: IFormSlotsCustomDayProps) => {
    const [state, setState] = React.useState<ICheckboxState>(
        props.intervals.some(({ interval }) => props.isSelected(interval))
            ? 'checked'
            : 'unchecked'
    );
    const toggle = React.useCallback(() => {
        const newState = state === 'checked' ? 'unchecked' : 'checked';

        setState(newState);

        if (newState === 'unchecked') {
            props.intervals.forEach(({ interval }) => {
                props.setSelected(interval, false);
            });
        }
    }, [props.intervals, state, setState, props.setSelected]);

    return (
        <Flex
            direction="column"
            css={{
                border: '1px solid $gray200',
                borderRadius: '$1',
                boxShadow: '$xs'
            }}
        >
            <Flex
                align="center"
                gap="2"
                css={{ cursor: 'pointer', padding: '$4 $5' }}
                onClick={toggle}
            >
                <Box color="gray900" fontSize="textXs">
                    <I icon={state === 'checked' ? 'chevron-down' : 'chevron-right'} />
                </Box>

                <Box font="gray800 textSm medium">{props.day}</Box>
            </Flex>

            {state === 'checked' && (
                <Flex
                    direction="column"
                    gap="3"
                    css={{
                        borderTop: '1px solid $gray200',
                        padding: '$4 $5'
                    }}
                >
                    {props.intervals.map(({ name, interval }, intervalIndex) => (
                            <CheckboxText
                                key={intervalIndex}
                                state={props.isSelected(interval) ? 'checked' : 'unchecked'}
                                onClick={(newState) => {
                                    props.setSelected(interval, newState === 'checked');
                                }}
                            >
                                {intervalFinalName(name, interval)}
                            </CheckboxText>
                        ))}
                </Flex>
            )}
        </Flex>
    );
};
