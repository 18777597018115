import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { OrganizationLogo } from '../../organizationLogo';
import { OrganizationPublicContextProvider } from '../../organizationPublicContext';
import { ProfilesListContainer, ProfilesListSkeleton } from './profilesListSkeleton';
import { ProfilesProfile } from './profilesProfile';
import { useProfilesList } from './useProfilesList';

export const ProfilesList = () => {
    const translate = useTranslate();
    const { organizationId } = useParams();
    const { usersInfos, isLoading } = useProfilesList();

    return (
        <OrganizationPublicContextProvider
            isLoading={isLoading}
            organizationId={organizationId}
            loadDelegationsSpace={false}
            loadMembersSpace={true}
            skeleton={<ProfilesListSkeleton />}
        >
            <ProfilesListContainer>
                <OrganizationLogo />

                <Flex
                    direction="column"
                    gap="3"
                    css={{
                        width: '100%',
                        '@desktop': {
                            width: '$modalMd'
                        }
                    }}
                >
                    <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                        {translate('_quel_profil_s_67307')}
                    </Box>

                    {(usersInfos ?? []).map((userInfo) => <ProfilesProfile key={userInfo.id} userInfo={userInfo} />)}
                </Flex>
            </ProfilesListContainer>
        </OrganizationPublicContextProvider>
    );
};
