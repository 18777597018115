import { Separator } from 'common-front/src/designSystem/components/separator';
import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { DelegationSpaceDashboardQuery } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IDelegationManagersCardProps {
    leaders: DelegationSpaceDashboardQuery['organization']['delegation']['leaders'];
}

export const DelegationManagersCard = ({ leaders }: IDelegationManagersCardProps) => {
    const translate = useTranslate();

    return (
        <Flex direction="column" gap="4" width={1}>
            {isNonEmptyArray(leaders) ? (
                leaders.map((user, index) => (
                    <React.Fragment key={user.id}>
                        {index > 0 && <Separator direction="horizontal" />}

                        <Flex align="center" gap="3">
                            <Flex
                                align="center"
                                justify="center"
                                height={40}
                                width={40}
                                css={{
                                    borderRadius: '$2',
                                    color: '$gray700'
                                }}
                            >
                                <Avatar
                                    size={40}
                                    image={user.picture?.url}
                                    email={user.email}
                                    name={user.name}
                                />
                            </Flex>

                            <Flex direction="column" gap="1">
                                <Box font="gray800 textSm medium">{user.name}</Box>

                                <Box color="gray500" css={{ fontWeight: '400' }}>
                                    {user.email}
                                </Box>
                            </Flex>
                        </Flex>
                    </React.Fragment>
                ))
            ) : (
                <Box color="gray500" textAlign="center">
                    {translate('aucun_reponsabl_38504')}
                </Box>
            )}
        </Flex>
    );
};

export const DelegationManagersCardSkeleton = () => <Skeleton height={40} width={1} borderRadius="$2" />;
