import { injectable } from 'inversify';
import { EventDesignInput, EventDesignQuery } from '../generated/types';
import { DocumentInputService } from './documentInput';

export interface IEventDesignValues {
    event: EventDesignInput;
}

export interface IEventDesignDefaults {
    colors: {
        background: string;
        stepBackground: string;
        font: string;
        button: string;
    };
}

@injectable()
export class EventDesignInputService {
    constructor(private documentInputService: DocumentInputService) {}

    eventDesignInputDefault(
        event: EventDesignQuery['event'],
        { colors }: IEventDesignDefaults
    ): EventDesignInput {
        return {
            background: event.background || this.documentInputService.documentInputDefault(),
            parameters: {
                colors: {
                    background: event.parameters?.colors?.background ?? colors.background,
                    stepBackground:
                        event.parameters?.colors?.stepBackground ?? colors.stepBackground,
                    font: event.parameters?.colors?.font ?? colors.font,
                    button: event.parameters?.colors?.button ?? colors.button
                }
            }
        };
    }
}
