import { Spacer } from 'common/src/designSystem/components/spacer';
import { FormRegisterSlotFragment, PositionsSlotId } from 'common/src/generated/types';
import { usePositionSlotIsRenamedRange } from 'common/src/hooks/usePositionSlotIsRenamedRange';
import { addOrRemove } from 'common/src/util/array';
import * as React from 'react';
import { CheckboxText } from '../../../designSystem/components/checkbox';

interface IFormMissionsSlotsSlotProps {
    index: number;
    positionsSlotsIds: PositionsSlotId[];
    prefix: string;
    slot: FormRegisterSlotFragment;

    change(name: string, value: any): void;
}

export const FormMissionsSlotsSlot = (props: IFormMissionsSlotsSlotProps) => {
    const { rangeString, finalName, isRenamed } = usePositionSlotIsRenamedRange(props.slot);

    return (
        <React.Fragment key={props.slot.id}>
            {props.index !== 0 && <Spacer height="3" />}

            <CheckboxText
                state={props.positionsSlotsIds.includes(props.slot.id) ? 'checked' : 'unchecked'}
                onClick={(state) => {
                    props.change(
                        `${props.prefix}positionsSlotsIds`,
                        addOrRemove(props.positionsSlotsIds, props.slot.id, state === 'checked')
                    );
                }}
                subtext={isRenamed ? rangeString : undefined}
            >
                {finalName}
            </CheckboxText>
        </React.Fragment>
    );
};
