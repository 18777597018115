import { Separator } from 'common-front/src/designSystem/components/separator';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { toFormElements } from 'common-front/src/vo/customField';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    FormElementType,
    FormId,
    OrganizationMembersSpaceFragment,
    UpdateUserFormFragment,
    UpdateUserInfosEventQuery,
    UpdateUserInfosOrganizationQuery
} from 'common/src/generated/types';
import { IUpdateUserInfoValues } from 'common/src/input/userInfoInput';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { addOrRemove } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { DateTime } from 'luxon';
import * as React from 'react';
import { UserCreateForm } from '../../components/users/create/v2/userCreateForm';
import { Select } from '../../designSystem/components/select/select';
import { useReferrerContext } from '../../util/referrerContext';
import { UpdateUserTeamCode } from './updateUserTeamCode';
import { UserUpdateLayout, UserUpdateLayoutOrigin } from './userUpdateLayout';

interface IUpdateUserProps {
    closePath: string;
    customFields: UpdateUserInfosOrganizationQuery['organization']['customFields']['nodes'];
    formIdToInsertedAt: Record<FormId, DateTime>;
    forms: UpdateUserFormFragment[];
    isLoading: boolean;
    origin: UserUpdateLayoutOrigin;
    organization?: OrganizationMembersSpaceFragment;
    showAllAndPrivate: boolean;
    showIsFilled: boolean;
    userInfo: Omit<UpdateUserInfosEventQuery['organization']['userInfo'], 'formsUsersInfos'>;
    values: IUpdateUserInfoValues;

    change(name: string, value: any): void;
    getEditUserFormPath(formId: FormId | 'all' | 'private'): string;
    handleSubmit(): void;
}

export const UpdateUser = (props: IUpdateUserProps) => {
    const { history, translate } = useHeavent();
    const { getReferrerPath } = useReferrerContext();
    const dateTimeService = useService(DateTimeService);
    const privateElements = React.useMemo(() => props.customFields
            .filter((cf) => cf.isPrivate)
            .map((customField) => ({
                elementType: FormElementType.Field,
                customField
            })), [props.customFields]);
    const allElements = React.useMemo(() => toFormElements(props.customFields), [props.customFields]);
    const renderSection = React.useCallback((section: string, index: number) => (
            <>
                {index !== 0 && <Spacer height="7" />}

                <Box font="gray900 textLg medium">{section}</Box>

                <Spacer height="4" />

                <Separator direction="horizontal" />
            </>
        ), []);

    return (
        <UserUpdateLayout
            extraTabs={
                props.showAllAndPrivate ? (
                    <>
                        <Tab path={props.getEditUserFormPath('all')}>
                            {translate('tous_les_champs_57939')}
                        </Tab>

                        <Tab path={props.getEditUserFormPath('private')}>
                            {translate('champs_priv_s_84670')}
                        </Tab>
                    </>
                ) : undefined
            }
            forms={props.forms}
            isLoading={props.isLoading}
            origin={props.origin}
            organization={props.organization}
            title={
                props.origin === 'members-space'
                    ? translate('mise_jour_des_00596')
                    : translate('mise_jour_de_88322', props.userInfo.nameOrEmail)
            }
            getTabPath={props.getEditUserFormPath}
            onCancel={() => {
                history.push(getReferrerPath() ?? props.closePath);
            }}
            onUpdate={props.handleSubmit}
        >
            {props.forms.map((form) => {
                const insertedAt = props.formIdToInsertedAt[form.id];

                return (
                    <TabPanel key={form.id} path={props.getEditUserFormPath(form.id)}>
                        <Spacer height="7" />

                        {props.showIsFilled && (
                            <>
                                <Flex
                                    align="center"
                                    gap="4"
                                    css={{
                                        background: '$gray100',
                                        borderRadius: '$2',
                                        padding: '$4'
                                    }}
                                >
                                    <Box font="gray800 textMd semiBold" css={{ flex: '1' }}>
                                        {translate('statut_du_formu_08195')}
                                    </Box>

                                    <Box width={320}>
                                        <Select
                                            state={insertedAt ? 'disabled' : 'active'}
                                            parseBoolean={true}
                                            value={props.values.filledFormsIds.includes(form.id)}
                                            onChange={(shouldAdd) => {
                                                props.change(
                                                    'filledFormsIds',
                                                    addOrRemove(
                                                        props.values.filledFormsIds,
                                                        form.id,
                                                        shouldAdd
                                                    )
                                                );
                                            }}
                                        >
                                            <option value="true">
                                                {insertedAt
                                                    ? translate(
                                                          'rempli_le_1_29292',
                                                          dateTimeService.toLocaleString(
                                                              insertedAt,
                                                              LocaleFormats.DateTime
                                                          )
                                                      )
                                                    : translate('rempli_78674')}
                                            </option>
                                            <option value="false">
                                                {translate('non_rempli_80877')}
                                            </option>
                                        </Select>
                                    </Box>
                                </Flex>

                                <Spacer height="6" />

                                {form.areTeamsAllowed && (
                                    <UpdateUserTeamCode form={form} change={props.change} />
                                )}
                            </>
                        )}

                        <UserCreateForm
                            elements={form.elements}
                            prefix="userInfo.fields."
                            values={props.values.userInfo.fields}
                            change={props.change}
                            renderSection={renderSection}
                        />
                    </TabPanel>
                );
            })}

            {props.showAllAndPrivate && (
                <TabPanel path={props.getEditUserFormPath('all')}>
                    <Spacer height="7" />

                    <UserCreateForm
                        elements={allElements}
                        prefix="userInfo.fields."
                        values={props.values.userInfo.fields}
                        change={props.change}
                        renderSection={renderSection}
                    />
                </TabPanel>
            )}

            {props.showAllAndPrivate && (
                <TabPanel path={props.getEditUserFormPath('private')}>
                    <Spacer height="7" />

                    <UserCreateForm
                        elements={privateElements}
                        prefix="userInfo.fields."
                        values={props.values.userInfo.fields}
                        change={props.change}
                        renderSection={renderSection}
                    />
                </TabPanel>
            )}
        </UserUpdateLayout>
    );
};
