import * as React from 'react';
import { Box } from '../designSystem/components/box';
import { Flex } from '../designSystem/components/flex';
import { styled } from '../designSystem/components/stitches';
import { Avatar } from './avatar/avatar';

const _Flex = styled(Flex, {
    gap: '$3',
    variants: {
        avatarOnTop: {
            true: {
                flexDirection: 'column',
                gap: '$2'
            },
            false: {
                flexDirection: 'row'
            }
        }
    }
});

interface IAvatarNameEmailProps {
    disabled?: boolean;
    userInfo: { email: string; picture?: any; name: string };
    hasName: boolean;
    badge?: React.ReactNode;
}

export const AvatarNameEmail = (props: IAvatarNameEmailProps) => (
        <_Flex width={1} avatarOnTop={{ '@initial': !!props.badge, '@tablet': false }}>
            <Avatar
                size={40}
                image={props.userInfo.picture?.url}
                email={props.userInfo.email}
                name={props.hasName ? props.userInfo.name : null}
            />

            <Flex direction="column" justify="center" css={{ flex: '1', overflow: 'hidden' }}>
                <Flex align="center" gap="2">
                    <Box
                        color={props.disabled ? 'gray300' : 'gray800'}
                        fontWeight="medium"
                        css={{ ellipsis: '' }}
                        title={props.hasName ? props.userInfo.name : props.userInfo.email}
                    >
                        {props.hasName ? props.userInfo.name : props.userInfo.email}
                    </Box>

                    <Box css={{ flexShrink: 0 }}>{props.badge}</Box>
                </Flex>

                {props.hasName && (
                    <Box
                        color={props.disabled ? 'gray300' : 'gray500'}
                        fontSize="textSm"
                        css={{ ellipsis: '' }}
                        title={props.userInfo.email}
                    >
                        {props.userInfo.email}
                    </Box>
                )}
            </Flex>
        </_Flex>
    );
