import { Button } from 'common-front/src/components/button/button';
import { Color, Link } from 'common-front/src/components/link/link';
import { Separator, SeparatorColor } from 'common-front/src/components/separator/separator';
import { Field } from 'common-front/src/components/users/show/field';
import { signOut } from 'common-front/src/util/aws/cognito';
import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Span } from 'common/src/designSystem/components/span';
import { VolunteerAssignmentsQuery, VolunteerRegistrationState } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import {
    useHistory,
    useParams,
    useService,
    useTranslate
} from 'common/src/util/dependencies/dependencies';
import { getEmbedPath, VolunteersPaths } from 'common/src/util/heaventPaths';
import { shouldDisplay } from 'common/src/vo/field';
import { totalDuration } from 'common/src/vo/positionSlot';
import * as React from 'react';
import { useEventPageContext } from '../../common/eventPageContext';
import { VolunteersBox } from '../../common/volunteersBox';
import { Registration } from '../registration';

interface IVolunteerInfosProps {
    event: VolunteerAssignmentsQuery['event'];
    registration: Registration;
}

export const VolunteerInfos = (props: IVolunteerInfosProps) => {
    const translate = useTranslate();
    const { organizationId, eventId, volunteerRegistrationId } = useParams();
    const history = useHistory();
    const intervalService = useService(IntervalService);
    const { isEmbed } = useEventPageContext();
    const registration = props.registration;
    const userInfo = registration.userInfo;
    const eventsFieldsToDisplay = props.event.eventsFields.filter(({ field }) => !['picture', 'firstName', 'lastName'].includes(field.fieldProperty));
    const fields = props.event.eventsFields.map(({ field }) => field);
    const duration = totalDuration(
        registration.positionsSlotsUsersInfos.map(({ positionSlot }) => positionSlot)
    ).shiftTo('hours', 'minutes');

    return (
        <VolunteersBox>
            <Flex align="center" justify="center" width={1}>
                <Avatar
                    size={310}
                    image={userInfo.fields.picture?.url}
                    name={userInfo.name}
                    email={userInfo.email}
                />
            </Flex>

            <Box
                css={{
                    fontSize: '22px',
                    fontWeight: '$oldBold',
                    marginTop: '$5',
                    textAlign: 'center'
                }}
            >
                {userInfo.name || '-'}
            </Box>

            <Box
                css={{
                    color: '$oldRed',
                    fontWeight: '$oldBold',
                    marginTop: '$3',
                    textAlign: 'center'
                }}
            >
                &bull; {translate(registration.state)}
            </Box>

            <Separator color={SeparatorColor.Solitude} margin={24} />

            <Box
                css={{
                    marginBottom: '$4',
                    '& > div:nth-child(1)': {
                        color: '$oldBlueBayoux',
                        fontSize: '14px'
                    },
                    '& > div:nth-child(2)': {
                        marginTop: '$1'
                    }
                }}
            >
                <div>{translate('e_mail_13869')}</div>
                <div>{userInfo.email}</div>
            </Box>

            {registration.slots.length > 0 && (
                <Box
                    css={{
                        marginBottom: '$4',
                        '& > div:nth-child(1)': {
                            color: '$oldBlueBayoux',
                            fontSize: '14px'
                        },
                        '& > div:nth-child(2)': {
                            marginTop: '$1'
                        }
                    }}
                >
                    <div>{translate('disponibilit_s_49923')}</div>
                    <div>
                        {registration.slots
                            .map(({ range }) => intervalService.toDisplayString(range))
                            .join(', ')}
                    </div>
                </Box>
            )}

            {eventsFieldsToDisplay.map((eventField) => (
                    shouldDisplay(eventField.field, userInfo.fields, fields) && (
                        <Box
                            key={eventField.field.id}
                            css={{
                                marginBottom: '$4',
                                '& > div:nth-child(1)': {
                                    color: '$oldBlueBayoux',
                                    fontSize: '14px'
                                },
                                '& > div:nth-child(2)': {
                                    marginTop: '$1'
                                }
                            }}
                        >
                            <div>{eventField.field.name}</div>
                            <Field field={eventField.field} userInfo={userInfo} />
                        </Box>
                    )
                ))}

            {registration.state === VolunteerRegistrationState.Assigned && (
                <>
                    <Separator color={SeparatorColor.Solitude} margin={24} />

                    <Box
                        css={{
                            '&:not(:last-child)': {
                                marginBottom: '$6'
                            },
                            '& > div:first-child': {
                                fontSize: '32px',
                                fontWeight: '$oldBold'
                            },
                            '& > div:nth-child(2)': {
                                color: '$oldBlueBayoux'
                            }
                        }}
                    >
                        <div>{registration.positionsSlotsUsersInfos.length}</div>
                        <div>
                            {translate(
                                'affectation_u_11128',
                                registration.positionsSlotsUsersInfos.length
                            )}
                        </div>
                    </Box>

                    <Box
                        css={{
                            '&:not(:last-child)': {
                                marginBottom: '$6'
                            },
                            '& > div:first-child': {
                                fontSize: '32px',
                                fontWeight: '$oldBold'
                            },
                            '& > div:nth-child(2)': {
                                color: '$oldBlueBayoux'
                            }
                        }}
                    >
                        <div>
                            {duration.hours > 0 && (
                                <>
                                    <span>{duration.hours}</span>
                                    <Span css={{ fontSize: '20px' }}>{translate('h_12895')} </Span>
                                </>
                            )}
                            {duration.minutes > 0 && (
                                <>
                                    <span>{duration.minutes}</span>
                                    <Span css={{ fontSize: '20px' }}>{translate('min_74949')}</Span>
                                </>
                            )}
                        </div>
                        <div>
                            {translate(
                                'dur_e_totale_de_28112',
                                registration.positionsSlotsUsersInfos.length
                            )}
                        </div>
                    </Box>
                </>
            )}

            <Separator color={SeparatorColor.Solitude} margin={24} />

            <div>
                <Button
                    onClick={() => {
                        history.push(
                            getEmbedPath(
                                isEmbed,
                                VolunteersPaths.EDIT_ASSIGNMENTS_REGISTRATION(
                                    organizationId,
                                    eventId,
                                    volunteerRegistrationId
                                )
                            )
                        );
                    }}
                >
                    {translate('modifier_mes_in_27067')}
                </Button>

                <Box css={{ marginTop: '$6', textAlign: 'center' }}>
                    <Link onClick={signOut} color={Color.Red}>
                        {translate('se_d_connecter_74151')}
                    </Link>
                </Box>
            </div>
        </VolunteersBox>
    );
};
