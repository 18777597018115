import * as React from 'react';
import { Flex, Gap } from '../flex';
import { ResponsiveProperty } from '../stitches';
import { Cell } from './cell';

interface ICellControlsProps {
    children: React.ReactNode;
    gap?: ResponsiveProperty<Gap>;
    justify: 'center' | 'end';
}

export const CellControls = ({ children, gap, justify }: ICellControlsProps) => (
        <Cell width={100}>
            <Flex
                className="h-controls"
                height={1}
                width={1}
                align="center"
                justify={justify}
                gap={gap ?? '6'}
                css={{ cursor: 'pointer', display: 'none' }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {children}
            </Flex>
        </Cell>
    );
