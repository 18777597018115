import { Empty } from 'common-front/src/components/empty/empty';
import { useVolunteersParamsLocalStorage } from 'common-front/src/hooks/useVolunteersParamsLocalStorage';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { VolunteersPaths, getEmbedPath } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { EventPage } from '../common/eventPage';
import { useVolunteerAssignmentsQuery } from '../generated/graphqlHooks';
import { NotRegistered } from './notRegistered';
import { AssignmentPage } from './show/assignmentPage';
import { UpdateRegistration } from './update/updateRegistrations';

interface IVolunteerAssignmentsProps {
    isEmbed: boolean;
}

export const VolunteerAssignments = (props: IVolunteerAssignmentsProps) => {
    const params = useParams();
    const { data, loader, reload } = useVolunteerAssignmentsQuery({ eventId: params.eventId });
    useVolunteersParamsLocalStorage();

    return (
        loader || (
            <EventPage event={data.event} isEmbed={props.isEmbed}>
                <Switch>
                    <Route
                        exact
                        path={getEmbedPath(
                            props.isEmbed,
                            VolunteersPaths.EDIT_ASSIGNMENTS_REGISTRATION(
                                ':organizationId',
                                ':eventId',
                                ':volunteerRegistrationId'
                            )
                        )}
                        render={() => (
                                <UpdateRegistration
                                    event={data.event}
                                    user={data.user}
                                    reload={reload}
                                />
                            )}
                    />

                    <Route
                        exact
                        path={getEmbedPath(
                            props.isEmbed,
                            VolunteersPaths.ASSIGNMENTS_REGISTRATION(
                                ':organizationId',
                                ':eventId',
                                ':volunteerRegistrationId'
                            )
                        )}
                        render={() => <AssignmentPage event={data.event} user={data.user} />}
                    />

                    <Route
                        render={() => {
                            if (data.user.volunteersRegistrations.length > 0) {
                                return (
                                    <Empty
                                        path={getEmbedPath(
                                            props.isEmbed,
                                            VolunteersPaths.ASSIGNMENTS_REGISTRATION(
                                                params.organizationId,
                                                params.eventId,
                                                data.user.volunteersRegistrations[0].id
                                            )
                                        )}
                                        replace={true}
                                    />
                                );
                            } else {
                                return <NotRegistered isEmbed={props.isEmbed} />;
                            }
                        }}
                    />
                </Switch>
            </EventPage>
        )
    );
};
