import { Event, VolunteersRegistrationInput } from 'common/src/generated/types';
import { fromInterval, LocaleFormats, toInterval } from 'common/src/util/luxon';
import { getDays } from 'common/src/vo/volunteerRegistrationSlot';
import { Interval } from 'luxon';
import * as React from 'react';
import { OnChange } from 'react-final-form-listeners';
import { Checkbox } from '../reactFinalForm/input/checkbox';

interface IVolunteerRegistrationSlotsDaysProps {
    event: Pick<Event, 'startAt' | 'endAt' | 'daysDisplay' | 'ranges'>;
    values: VolunteersRegistrationInput;

    change(name: string, value: any): void;
}

export const VolunteerRegistrationSlotsDays = (props: IVolunteerRegistrationSlotsDaysProps) => {
    const days = React.useMemo(() => getDays(
            props.event.startAt,
            props.event.endAt,
            props.event.ranges,
            props.event.daysDisplay
        ), [props.event.daysDisplay]);
    const addRemoveSlot = (interval: Interval) => (value: boolean) => {
            const currentSlotsWithout = props.values.slots.filter((slot) => !interval.equals(toInterval(slot)));

            if (currentSlotsWithout.length === props.values.slots.length && value) {
                props.change('volunteerRegistration.slots', [
                    ...props.values.slots,
                    fromInterval(interval)
                ]);
            } else if (currentSlotsWithout.length !== props.values.slots.length && !value) {
                props.change('volunteerRegistration.slots', currentSlotsWithout);
            }
        };

    return (
        <>
            {days.map((day, index) => (
                    <React.Fragment key={index}>
                        <OnChange name={`day${index}`}>
                            {addRemoveSlot(Interval.fromDateTimes(day, day.plus({ day: 1 })))}
                        </OnChange>

                        <Checkbox
                            name={`day${index}`}
                            text={day.toLocaleString(LocaleFormats.DateOnly.WeekdayLongMonthLong)}
                        />
                    </React.Fragment>
                ))}
        </>
    );
};
