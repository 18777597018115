import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { useMobileQuery } from '../../hooks/useMobileQuery';
import { Dropdown } from './dropdown/dropdown';
import { Menu } from './dropdown/menu';
import { Trigger } from './dropdown/trigger';

interface IDetailBoxProps {
    children: React.ReactNode;
    css?: CSS;
    id?: string;
    items?: React.ReactNode;
    rightIcon?: IIcon;
    title: React.ReactNode;

    rightIconClick?(e: React.MouseEvent<HTMLDivElement>): void;
}

const DetailDesktopBox = (props: IDetailBoxProps) => (
        <Flex
            id={props.id}
            direction="column"
            width={1}
            css={{
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                ...(props.css as any)
            }}
        >
            <Flex gap="3" width={1} css={{ borderBottom: '1px solid $gray200', padding: '$5 $6' }}>
                <Box color="gray900" fontSize="textMd" fontWeight="semiBold" css={{ flex: '1' }}>
                    {props.title}
                </Box>

                {props.rightIcon && (
                    <Box
                        color="gray900"
                        fontSize="textMd"
                        fontWeight="semiBold"
                        textAlign="center"
                        width={20}
                        css={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            props.rightIconClick?.(e);
                        }}
                    >
                        <I icon={props.rightIcon} />
                    </Box>
                )}

                {props.items && (
                    <Dropdown>
                        <Trigger>
                            <Box
                                color="gray900"
                                fontSize="textMd"
                                fontWeight="semiBold"
                                textAlign="center"
                                width={20}
                                css={{ cursor: 'pointer' }}
                            >
                                <I icon="ellipsis-vertical" />
                            </Box>
                        </Trigger>

                        <Menu placement="bottom-end">{props.items}</Menu>
                    </Dropdown>
                )}
            </Flex>

            <Box className="h-detail-box-content" width={1} css={{ flex: '1', padding: '$6' }}>
                {props.children}
            </Box>
        </Flex>
    );

const DetailMobileBox = ({ css, title, children }: IDetailBoxProps) => (
        <Flex direction="column" gap="3">
            <Box color="gray900" fontWeight="semiBold" fontSize="textLg">
                {title}
            </Box>
            <Flex
                direction="column"
                css={
                    {
                        border: '1px solid $gray200',
                        borderRadius: '$2',
                        boxShadow: '$xs',
                        padding: '$4',
                        ...css
                    } as CSS
                }
            >
                {children}
            </Flex>
        </Flex>
    );

export const DetailBox = ({ children, ...props }: IDetailBoxProps) => {
    const onMobile = useMobileQuery();
    const _DetailBox = onMobile ? DetailMobileBox : DetailDesktopBox;

    return <_DetailBox {...props}>{children}</_DetailBox>;
};
