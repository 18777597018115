import { injectable } from 'inversify';
import * as yup from 'yup';
import {
    ALL_FEATURE,
    BackofficeOrganizationInput,
    BackofficeOrganizationQuery
} from '../generated/types';
import { TranslationService } from '../services/translationService';
import { DateTimeSchemaType } from '../util/yup/dateTimeSchemaType';

export interface IUpdateBackofficeOrganizationValues {
    organization: BackofficeOrganizationInput;
}

@injectable()
export class BackofficeOrganizationInputService {
    constructor(private translationService: TranslationService) {}

    organizationInputDefault(
        organization: BackofficeOrganizationQuery['backoffice']['organization']
    ): BackofficeOrganizationInput {
        return {
            name: organization.name,
            features: organization.features,
            customBadges: organization.customBadges,
            periodEndAt: organization.periodEndAt,
            usersIds: organization.users.map(({ id }) => id),
            isBlocked: organization.isBlocked
        };
    }

    organizationInputSchema() {
        return yup.object().shape({
            name: yup.string().required(this.translationService.translate('le_nom_de_l_org_60618')),
            periodEndAt: DateTimeSchemaType.required(
                this.translationService.translate('la_date_de_fin_04076')
            ).typeError(this.translationService.translate('la_date_de_fin_04076')),
            features: yup
                .array()
                .of(
                    yup
                        .string()
                        .oneOf(
                            ALL_FEATURE,
                            this.translationService.translate('les_fonctionnal_36778')
                        )
                ),
            usersIds: yup.array().of(yup.number()),
            isBlocked: yup.boolean().required()
        });
    }

    updateOrganizationSchema() {
        return yup.object().shape({
            organization: this.organizationInputSchema()
        });
    }
}
