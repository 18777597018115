import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import * as React from 'react';

export const OrganizationLogoSkeleton = () => (
        <Flex
            justify="center"
            width={1}
            css={{
                marginBottom: '$9',
                '@tablet': {
                    marginBottom: '$10'
                }
            }}
        >
            <Skeleton height={140} width={140} borderRadius="$1" />
        </Flex>
    );
