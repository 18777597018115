import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import * as React from 'react';
import { AsoAuthContainer } from '../../../auth/aso/asoAuthContainer';

export const AsoProfilesListSkeleton = () => (
        <AsoAuthContainer>
            <Flex
                direction="column"
                gap="3"
                css={{
                    width: '100%',
                    '@desktop': {
                        width: '$modalMd'
                    }
                }}
            >
                <Skeleton height={23} width={1} borderRadius="$1" />
                <Skeleton height={74} width={1} borderRadius="$2" />
                <Skeleton height={74} width={1} borderRadius="$2" />
                <Skeleton height={74} width={1} borderRadius="$2" />
            </Flex>
        </AsoAuthContainer>
    );
