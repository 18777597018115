import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface ISlotCardProps {
    buttons: React.ReactNode;
    children: React.ReactNode;
    title: string;
}

export const SlotCard = (props: ISlotCardProps) => (
        <Flex
            direction="column"
            gap="4"
            width={1}
            css={{
                background: '$gray50',
                borderRadius: '$1',
                padding: '$6'
            }}
        >
            <Flex align="center" gap="4">
                <Box font="gray800 textXs semiBold" css={{ flex: '1', textTransform: 'uppercase' }}>
                    {props.title}
                </Box>

                {props.buttons}
            </Flex>

            {props.children}
        </Flex>
    );
