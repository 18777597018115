import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { useUniqueIds } from '../../../hooks/useUniqueIds';
import { BaseInputProps } from '../input/baseInputProps';
import { Description } from '../input/description';
import { Hint } from '../input/hint';
import { Label } from '../input/label';
import { StyledInputContainer } from '../input/styledInputContainer';
import { getValueToName, parseSelectChildren } from '../richSelect/selectUtil';

export const _Select = styled('select', {
    backgroundColor:
        '$white' /* Even though opacity is set to 0, this fixes a bug on Safari where the height isn’t 100%. */,
    cursor: 'pointer',
    height: '100%',
    left: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    width: '100%'
});

export const _Div = styled('div', {
    ellipsis: ''
});

type StringProps = {
    value: string;
    parseInt?: false;
    parseBoolean?: false;

    onChange(value: string): void;
};

type IntProps = {
    value: number;
    parseInt: true;
    parseBoolean?: false;

    onChange(value: number): void;
};

type BooleanProps = {
    value: boolean;
    parseInt?: false;
    parseBoolean: true;

    onChange(value: boolean): void;
};

type ValueProps = StringProps | IntProps | BooleanProps;

type ISelectProps = BaseInputProps & ValueProps;

export const Select = (props: ISelectProps) => {
    const valueToName = React.useMemo(() => getValueToName(parseSelectChildren(props.children)), [props.children]);
    const { inputId, descId, errorId } = useUniqueIds();

    return (
        <Flex css={props.css} direction="column" width={1}>
            <Label htmlFor={inputId}>{props.label}</Label>

            <Description id={descId}>{props.description}</Description>

            {(props.label || props.description) && <Spacer height="1" />}

            <StyledInputContainer icon={props.icon} state={props.state} cursor="default">
                <Flex
                    align="center"
                    height={1}
                    width={1}
                    css={{
                        flex: '1',
                        overflow: 'hidden'
                    }}
                >
                    <_Div>{valueToName[props.value.toString()]}</_Div>
                </Flex>

                <Flex
                    css={{
                        color: '$gray500'
                    }}
                >
                    <I icon="chevron-down" />
                </Flex>

                <_Select
                    id={inputId}
                    aria-describedby={props.description ? descId : undefined}
                    aria-invalid={props.state === 'error'}
                    aria-errormessage={props.state === 'error' ? errorId : undefined}
                    autoComplete={props.autoComplete ?? 'off'}
                    disabled={props.state === 'disabled'}
                    value={props.value.toString()}
                    onChange={(e) => {
                        if (props.parseInt === true) {
                            props.onChange(parseInt(e.target.value, 10));
                        } else if (props.parseBoolean === true) {
                            props.onChange(e.target.value === 'true');
                        } else {
                            props.onChange(e.target.value);
                        }
                    }}
                    onBlur={props.onBlur}
                    onFocus={props.onFocus}
                >
                    {props.children}
                </_Select>
            </StyledInputContainer>

            <Hint id={errorId} state={props.state}>
                {props.hint}
            </Hint>
        </Flex>
    );
};
