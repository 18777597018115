import { setIn } from 'final-form';
import { injectable } from 'inversify';
import * as yup from 'yup';

export type ValidateErrors<T> = { [P in keyof T]?: any };

@injectable()
export class ValidateService {
    validate<T, U>(schema: yup.Schema<T>) {
        return async (input: U): Promise<ValidateErrors<U>> => {
            try {
                await schema.validate(input, { abortEarly: false, strict: true });

                return {};
            } catch (error) {
                return this.errorToObject(error);
            }
        };
    }

    validateSync<T, U>(schema: yup.Schema<T>, input: U): ValidateErrors<U> {
        try {
            schema.validateSync(input, { abortEarly: false, strict: true });

            return {};
        } catch (error) {
            return this.errorToObject(error);
        }
    }

    isValidSync<T, U>(schema: yup.Schema<T>, input: U): boolean {
        return Object.keys(this.validateSync(schema, input)).length === 0;
    }

    validateForForm<T, U>(schema: yup.Schema<T>) {
        return async (input: U): Promise<ValidateErrors<U>> => {
            try {
                await schema.validate(input, { abortEarly: false, strict: true });

                return {};
            } catch (error) {
                return error.inner.reduce((formErrors: any, innerError: any) => setIn(formErrors, innerError.path, innerError.message), {});
            }
        };
    }

    private errorToObject<U>(error: any): ValidateErrors<U> {
        return error.inner.reduce((formErrors: any, innerError: any) => ({
                ...formErrors,
                [innerError.path]: innerError.message
            }), {});
    }
}
