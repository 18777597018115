import { Document, DocumentInput } from 'common/src/generated/types';
import * as React from 'react';
import { Accept } from '../../../../util/accept';
import { Acl, IUploadFileResult, uploadFile } from '../../../../util/file';
import { Loader } from '../../../loader/loader';
import { FileImageUploader, FileImageView } from './fileImage';
import { FileSimpleUploader } from './fileSimpleUploader';
import { FileSimpleView } from './fileSimpleView';

enum UploaderState {
    Uploading = 'Uploading',
    Uploader = 'Uploader',
    File = 'File'
}

export enum UploaderMode {
    Simple = 'Simple',
    Image = 'Image'
}

export interface IUploaderProps {
    acl: Acl;
    accept: Accept | Accept[];
    document: Pick<Document, 'acl' | 'key' | 'name'> | null;
    label: string;
    mode: UploaderMode;
    size?: number;
    subtext?: string;
    isOptional?: boolean;

    onChangeEnd(result: IUploadFileResult): void;
    onDelete(): void;
}

export const Uploader = (props: IUploaderProps) => {
    const [state, setState] = React.useState(
        props.document ? UploaderState.File : UploaderState.Uploader
    );
    const [document, setDocument] = React.useState<DocumentInput | null>(props.document);
    React.useEffect(() => {
        setState(props.document ? UploaderState.File : UploaderState.Uploader);
        setDocument(props.document);
    }, [props.document]);
    const onFileChange = async (file: File) => {
        setState(UploaderState.Uploading);

        try {
            const doc = await uploadFile(file, props.acl);

            props.onChangeEnd(doc);
            setDocument(doc);
            setState(UploaderState.File);
        } catch {
            setState(UploaderState.Uploader);
        }
    };

    if (state === UploaderState.File && document) {
        if (props.mode === UploaderMode.Image) {
            return (
                <FileImageView
                    document={document}
                    label={props.label}
                    size={props.size!}
                    subtext={props.subtext}
                    isOptional={props.isOptional}
                    onEdit={() => {
                        setState(UploaderState.Uploader);
                    }}
                    onDelete={() => {
                        props.onDelete();
                        setState(UploaderState.Uploader);
                    }}
                />
            );
        } else {
            return (
                <FileSimpleView
                    document={document}
                    onEdit={() => {
                        setState(UploaderState.Uploader);
                    }}
                    onDelete={() => {
                        props.onDelete();
                        setState(UploaderState.Uploader);
                    }}
                />
            );
        }
    } else if (state === UploaderState.Uploader) {
        if (props.mode === UploaderMode.Image) {
            return (
                <FileImageUploader
                    accept={props.accept}
                    label={props.label}
                    size={props.size!}
                    subtext={props.subtext}
                    isOptional={props.isOptional}
                    onSelect={onFileChange}
                />
            );
        } else {
            return <FileSimpleUploader accept={props.accept} onSelect={onFileChange} />;
        }
    } else {
        return (
            <div
                style={{
                    height: `${props.size ?? 150}px`,
                    width: '100%'
                }}
            >
                <Loader />
            </div>
        );
    }
};
