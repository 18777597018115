import { Badge } from 'common/src/designSystem/components/badge';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';
import { Dropdown } from '../../designSystem/components/dropdown/dropdown';
import { Menu } from '../../designSystem/components/dropdown/menu';
import { Trigger } from '../../designSystem/components/dropdown/trigger';
import { BadgesPlusBadge, IBadgesPlusBadge } from './badgesPlusBadge';

interface IBadgesPlusDropdownProps {
    badges: IBadgesPlusBadge[];
}

export const BadgesPlusDropdown = ({ badges }: IBadgesPlusDropdownProps) => (
        <Dropdown>
            <Trigger>
                <Badge cursor="pointer">+{badges.length}</Badge>
            </Trigger>

            <Menu
                maxHeight={300}
                placement="top-end"
                width={400}
                css={{ flexWrap: 'wrap', gap: '$2', overflow: 'auto', padding: '$4', zIndex: 100 }}
            >
                {badges.map((badge) => (
                        <Flex key={badge.id}>
                            <BadgesPlusBadge badge={badge} />
                        </Flex>
                    ))}
            </Menu>
        </Dropdown>
    );
