import { H1 } from 'common-front/src/components/titles/h1';
import { Svgs } from 'common-front/src/util/assets';
import { Flex } from 'common/src/designSystem/components/flex';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export const NoAssignments = () => {
    const translate = useTranslate();

    return (
        <Flex
            direction="column"
            align="center"
            justify="center"
            css={{
                background: '$oldEventStepBackground',
                borderRadius: '3px',
                boxShadow: '$old',
                padding: '80px'
            }}
        >
            <img src={Svgs.AssignmentsBlank} width="450px" height="300px" alt="Volunteers blank" />

            <H1 marginTop={true}>{translate('aucune_affectat_04761')}</H1>

            <div>{translate('un_peu_de_patie_47766')}</div>
        </Flex>
    );
};
