import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Row } from 'common/src/designSystem/components/table/row';
import { Table } from 'common/src/designSystem/components/table/table';
import { AccreditationsSlotId } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { fullName } from 'common/src/vo/accreditationSlot';
import * as React from 'react';
import { DropdownEllipsis } from '../../../designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from '../../../designSystem/components/dropdown/item';
import { HorizontalTabs } from '../../../designSystem/components/horizontalTabs/horizontalTabs';
import { Tab } from '../../../designSystem/components/horizontalTabs/tab';
import { IconBackground } from '../../../designSystem/components/iconBackground';
import { RightPanel } from '../../../designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from '../../../designSystem/components/rightPanel/rightPanelBody';
import { RightPanelHeader } from '../../../designSystem/components/rightPanel/rightPanelHeader';
import {
    useAccreditationSlotAssignedMemberQuery,
    useVolunteerRegistrationAccreditMutation,
    useVolunteerRegistrationDeaccreditMutation
} from '../../../generated/graphqlHooks';
import { useHeavent } from '../../../hooks/useHeavent';

interface IAccreditationSlotMembersPanelProps {
    accreditationSlotId: AccreditationsSlotId;
    isEventAdmin?: boolean;
    onClose(): void;
}

export const AccreditationSlotMembersPanel = ({
    accreditationSlotId,
    isEventAdmin,
    onClose
}: IAccreditationSlotMembersPanelProps) => {
    const {
        params: { eventId, delegationId },
        translate
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const [selectedTab, setSelectedTab] = React.useState<string>('assigned');
    const { data, loader, reload } = useAccreditationSlotAssignedMemberQuery({
        eventId,
        delegationId,
        accreditationSlotId,
        predicates: [
            {
                operator: 'is',
                slug: 'wishedAccreditationsSlots',
                value: [accreditationSlotId]
            },
            {
                operator: 'isNot',
                slug: 'accreditationSlotId',
                value: [accreditationSlotId]
            }
        ]
    });
    const { mutate: accreditMember } = useVolunteerRegistrationAccreditMutation();
    const { mutate: deaccreditMember } = useVolunteerRegistrationDeaccreditMutation();
    const slotName = React.useMemo(() => {
        if (data.event && !data.event.accreditationSlot.isHidden) {
            return fullName(dateTimeService, data.event.accreditationSlot, '');
        } else {
            return '';
        }
    }, [data.event]);
    const members = React.useMemo(() => {
        if (data.event) {
            return selectedTab === 'assigned'
                ? data.event.volunteersRegistrations.nodes
                : data.event.wishedMembers.nodes;
        } else {
            return [];
        }
    }, [selectedTab, data.event]);

    return (
        <RightPanel size="md" onClose={onClose}>
            {loader || (
                <>
                    <RightPanelHeader>
                        <Flex direction="column">
                            <Box>{data.event.accreditationSlot.accreditation.name}</Box>

                            {isNonEmptyString(slotName) && (
                                <Box font="gray500 textSm regular">{slotName}</Box>
                            )}
                        </Flex>
                    </RightPanelHeader>

                    <RightPanelBody>
                        <Spacer height="6" />

                        <HorizontalTabs selectedItem={selectedTab} onChange={setSelectedTab}>
                            <Tab item="assigned">{translate('accr_ditations_33155')}</Tab>
                            <Tab item="wished">{translate('accr_ditations_74375')}</Tab>
                        </HorizontalTabs>

                        <Spacer height="7" />

                        <Box font="gray900 textMd semiBold">
                            {translate('liste_des_membr_89037')}
                        </Box>

                        <Spacer height="4" />

                        <Table>
                            <HeaderRow>
                                <HeaderCell>{translate('nom_du_membre_69353')}</HeaderCell>
                            </HeaderRow>

                            {isNonEmptyArray(members) ? (
                                members.map((vr) => (
                                        <Row key={vr.id}>
                                            <Cell>
                                                <AvatarNameEmail
                                                    userInfo={vr.userInfo}
                                                    hasName={true}
                                                />

                                                {isEventAdmin && (
                                                    <CellControls gap="2" justify="end">
                                                        {selectedTab === 'assigned' ? (
                                                            <DropdownEllipsis>
                                                                <ItemIcon
                                                                    icon="minus"
                                                                    color={'red'}
                                                                    onClick={async () => {
                                                                        await deaccreditMember({
                                                                            eventId,
                                                                            volunteerRegistrationId:
                                                                                vr.id,
                                                                            deaccredit: {
                                                                                accreditationsSlotsIds:
                                                                                    [
                                                                                        accreditationSlotId
                                                                                    ]
                                                                            }
                                                                        });

                                                                        reload();
                                                                    }}
                                                                >
                                                                    {translate(
                                                                        'deaccredit_memb_96566'
                                                                    )}
                                                                </ItemIcon>
                                                            </DropdownEllipsis>
                                                        ) : (
                                                            <DropdownEllipsis>
                                                                <ItemIcon
                                                                    icon="plus"
                                                                    onClick={async () => {
                                                                        await accreditMember({
                                                                            eventId,
                                                                            volunteerRegistrationId:
                                                                                vr.id,
                                                                            accredit: {
                                                                                accreditationsSlotsIds:
                                                                                    [
                                                                                        accreditationSlotId
                                                                                    ],
                                                                                isAdditive: true
                                                                            }
                                                                        });

                                                                        reload();
                                                                    }}
                                                                >
                                                                    {translate(
                                                                        'accredit_member_73476'
                                                                    )}
                                                                </ItemIcon>
                                                            </DropdownEllipsis>
                                                        )}
                                                    </CellControls>
                                                )}
                                            </Cell>
                                        </Row>
                                    ))
                            ) : (
                                // TODO - replace in PR #177
                                <Row css={{ height: 'fit-content' }}>
                                    <Cell>
                                        <Flex
                                            direction="column"
                                            align="center"
                                            justify="center"
                                            width={1}
                                            css={{ padding: '$8 0' }}
                                        >
                                            <IconBackground color="primary" icon={'people-group'} />

                                            <Spacer height="4" />

                                            <Box
                                                color="gray900"
                                                css={{
                                                    '@tablet': {
                                                        color: '$gray900',
                                                        fontSize: '$textMd',
                                                        fontWeight: '$medium'
                                                    }
                                                }}
                                                fontSize="textMd"
                                                fontWeight="semiBold"
                                            >
                                                {translate('no_members_foun_89564')}
                                            </Box>
                                        </Flex>
                                    </Cell>
                                </Row>
                            )}
                        </Table>
                    </RightPanelBody>
                </>
            )}
        </RightPanel>
    );
};
