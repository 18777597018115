import { H1 } from 'common-front/src/components/titles/h1';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface ISuccessProps {
    registerSuccessMessage: any;
}

export const Success = (props: ISuccessProps) => {
    const translate = useTranslate();

    return (
        <div>
            {isNonEmptyString(props.registerSuccessMessage) ? (
                <div>
                    <RichText text={props.registerSuccessMessage} />
                </div>
            ) : (
                <Flex
                    direction="column"
                    align="center"
                    justify="center"
                    css={{
                        '& img': {
                            width: '160px'
                        },
                        '& h1': {
                            marginBottom: '$3',
                            marginTop: '$6'
                        }
                    }}
                >
                    <img src={Svgs.Done} width="450px" alt="Volunteers blank" />

                    <H1>{translate('merci_70020')}</H1>

                    <Box css={{ color: '$oldBlueBayoux' }}>
                        {translate('vous_allez_rece_60880')}
                    </Box>
                </Flex>
            )}
        </div>
    );
};
