import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';
import { usePopper } from 'react-popper';
import { useTooltipContext } from './tooltip';

interface IContentProps {
    children: React.ReactNode;
    placement:
        | 'top'
        | 'top-start'
        | 'top-end'
        | 'bottom'
        | 'bottom-start'
        | 'bottom-end'
        | 'right'
        | 'right-start'
        | 'right-end'
        | 'left'
        | 'left-start'
        | 'left-end';
}

export const Content = (props: IContentProps) => {
    const { isVisible, referenceElement, popperElement, setPopperElement } = useTooltipContext();
    const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
        placement: props.placement,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 4]
                }
            }
        ]
    });

    React.useEffect(() => {
        update?.();
    }, [isVisible, referenceElement]);

    return (
        <Flex
            ref={setPopperElement}
            align="center"
            justify="center"
            css={{
                background: '$gray900',
                borderRadius: '$1',
                color: 'white',
                display: isVisible ? 'flex' : 'none',
                px: '$3',
                py: '6px',
                zIndex: 1000,
                ...styles.popper
            }}
            {...attributes.popper}
        >
            {props.children}
        </Flex>
    );
};
