import { useLocalStorage, useParams } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { LocalStorageKeys } from '../util/localStorage';

export function useVolunteersParamsLocalStorage() {
    const { organizationId, eventId } = useParams();
    const { setItem } = useLocalStorage();

    React.useEffect(() => {
        try {
            setItem(LocalStorageKeys.EVENT_ID, eventId.toString());
            setItem(LocalStorageKeys.ORGANIZATION_ID, organizationId.toString());
        } catch {
            // do nothing
        }
    }, [organizationId, eventId]);
}
