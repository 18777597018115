export const NEED_LOGGED_IN_MESSAGE = 'You need to be logged-in';

const _getErrorsStrings = (errors: any[], errorsStrings: Set<string>): Set<string> => {
    errors.forEach((error) => {
        if (typeof error === 'object') {
            _getErrorsStrings(Object.values(error || {}), errorsStrings);
        } else {
            errorsStrings.add(error);
        }
    });

    return errorsStrings;
};

export function getErrorsStrings(errors: any): string[] | null {
    const values = Object.values(errors || {});

    if (values.length === 0) {
        return null;
    } else {
        return Array.from(_getErrorsStrings(values, new Set()));
    }
}
