import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    AccreditationsSlotId,
    CustomBadge,
    DelegationMembersQuery,
    FormId,
    SegmentCustomFieldFragment,
    UsersInfoId
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { IntervalService } from 'common/src/services/intervalService';
import { useService } from 'common/src/util/dependencies/dependencies';
import {
    getAccreditationsBadges,
    getWishedAccreditationsBadges
} from 'common/src/vo/accreditation';
import { FieldService } from 'common/src/vo/field';
import { getPositionsBadges, getWishedPositionsBadges } from 'common/src/vo/position';
import { PossibleColumn } from 'common/src/vo/segment';
import { VolunteerDefaultColumns } from 'common/src/vo/segments/volunteersSegmentsService';
import {
    getAccreditationStateBadgeColorIcon,
    getStateBadgeColorIcon
} from 'common/src/vo/volunteerRegistration';
import * as React from 'react';
import { AccreditationCategories } from '../../../components/accreditations/accreditationCategories';
import { BadgesPlus } from '../../../components/badgesPlus/badgesPlus';
import { DropdownEllipsis } from '../../../designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from '../../../designSystem/components/dropdown/item';
import { RightPanel } from '../../../designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from '../../../designSystem/components/rightPanel/rightPanelBody';
import { RightPanelHeader } from '../../../designSystem/components/rightPanel/rightPanelHeader';
import { Content } from '../../../designSystem/components/tooltip/content';
import { Tooltip } from '../../../designSystem/components/tooltip/tooltip';
import { Trigger } from '../../../designSystem/components/tooltip/trigger';
import {
    useVolunteerRegistrationAccreditMutation,
    useVolunteerRegistrationDeaccreditMutation
} from '../../../generated/graphqlHooks';
import { DelegationMemberRowDropdown } from './delegationMemberRowDropdown';

interface IDelegationMemberRowProps {
    canEditMembers: boolean;
    columns: PossibleColumn[];
    customBadges: CustomBadge[];
    customFields: SegmentCustomFieldFragment[];
    deleteFromEvent?: boolean;
    editPath: string;
    formId: FormId;
    isEventAdmin?: boolean;
    member: DelegationMembersQuery['organization']['usersInfos']['nodes'][number];
    showAccreditActions: boolean;
    showDeleteFromEvent: boolean;
    showDocumentsDownload: boolean;

    onClick?(memberId: UsersInfoId): void;
    reload(): void;
}

export const DelegationMemberRow = ({
    canEditMembers,
    columns,
    customBadges,
    customFields,
    deleteFromEvent,
    editPath,
    formId,
    isEventAdmin,
    member,
    onClick,
    showAccreditActions,
    showDeleteFromEvent,
    showDocumentsDownload,
    reload
}: IDelegationMemberRowProps) => {
    const { history, translate } = useHeavent();

    const { mutate: accreditMember } = useVolunteerRegistrationAccreditMutation();
    const { mutate: deaccreditMember } = useVolunteerRegistrationDeaccreditMutation();

    const dateTimeService = useService(DateTimeService);
    const intervalService = useService(IntervalService);
    const fieldService = useService(FieldService);

    const accreditationsBadges = React.useMemo(() => getAccreditationsBadges(
            dateTimeService,
            member.volunteerRegistration?.accreditationsUsersInfos || []
        ), [member.volunteerRegistration?.accreditationsUsersInfos]);
    const wishedAccreditationsBadges = React.useMemo(() => getWishedAccreditationsBadges(
            dateTimeService,
            member.volunteerRegistration?.accreditationsSlots || []
        ), [member.volunteerRegistration?.accreditationsSlots]);
    const positionsBadges = React.useMemo(() => getPositionsBadges(
            intervalService,
            member.volunteerRegistration?.positionsSlotsUsersInfos || []
        ), [member.volunteerRegistration?.positionsSlotsUsersInfos]);
    const wishedPositionsBadges = React.useMemo(() => getWishedPositionsBadges(
            intervalService,
            member.volunteerRegistration?.positionsCategories || [],
            member.volunteerRegistration?.positions || [],
            member.volunteerRegistration?.positionsSlots || []
        ), [member.volunteerRegistration]);
    const slugToCustomField = React.useMemo(() => new Map(customFields.map((cf) => [cf.slug, cf])), [customFields]);
    const auiSlotsIds = React.useMemo(
        () =>
            (member.volunteerRegistration?.accreditationsUsersInfos || []).map(
                (aui) => aui.accreditationSlot.id
            ),
        [member.volunteerRegistration?.accreditationsUsersInfos]
    );

    const [isAccreditationsSidebarOpen, setIsAccreditationsSidebarOpen] = React.useState(false);
    const [isWishedAccreditationsSidebarOpen, setIsWishedAccreditationsSidebarOpen] =
        React.useState(false);

    return (
        <>
            <Row
                key={member.id}
                css={{
                    background: 'white',
                    cursor: onClick ? 'pointer' : 'default',
                    '&:hover': {
                        background: onClick ? '$gray100' : 'inherit'
                    }
                }}
                onClick={() => {
                    if (onClick) {
                        onClick(member.id);
                    }
                }}
            >
                {columns.map((column) => {
                    const customField = slugToCustomField.get(column.slug);
                    const vr = member.volunteerRegistration;

                    if (column.slug === VolunteerDefaultColumns.Name) {
                        return (
                            <Cell key={column.slug}>
                                <AvatarNameEmail userInfo={member} hasName={true} />
                            </Cell>
                        );
                    } else if (column.slug === VolunteerDefaultColumns.Email) {
                        return <Cell key={column.slug}>{member.email}</Cell>;
                    } else if (
                        vr &&
                        column.slug === VolunteerDefaultColumns.VolunteerRegistrationState
                    ) {
                        return (
                            <Cell
                                key={column.slug}
                                width={240}
                                css={{
                                    border: '2px solid transparent'
                                }}
                            >
                                <Badge {...getStateBadgeColorIcon(vr.state)}>
                                    {translate(vr.state)}
                                </Badge>
                            </Cell>
                        );
                    } else if (vr && column.slug === VolunteerDefaultColumns.AccreditationState) {
                        return (
                            <Cell
                                key={column.slug}
                                width={240}
                                css={{
                                    border: '2px solid transparent'
                                }}
                            >
                                <Badge
                                    {...getAccreditationStateBadgeColorIcon(vr.accreditationState)}
                                >
                                    {translate(vr.accreditationState)}
                                </Badge>
                            </Cell>
                        );
                    } else if (vr && column.slug === VolunteerDefaultColumns.Accreditations) {
                        return (
                            <Cell key={column.slug} css={{ border: '2px solid transparent' }}>
                                <BadgesPlus
                                    badges={accreditationsBadges}
                                    doNotSort={true}
                                    setIsSidebarOpen={setIsAccreditationsSidebarOpen}
                                />
                            </Cell>
                        );
                    } else if (
                        vr &&
                        column.slug === VolunteerDefaultColumns.WishedAccreditationSlots
                    ) {
                        return (
                            <Cell key={column.slug} css={{ border: '2px solid transparent' }}>
                                <BadgesPlus
                                    badges={wishedAccreditationsBadges}
                                    doNotSort={true}
                                    setIsSidebarOpen={setIsWishedAccreditationsSidebarOpen}
                                />
                            </Cell>
                        );
                    } else if (vr && column.slug === VolunteerDefaultColumns.Positions) {
                        return (
                            <Cell key={column.slug} css={{ border: '2px solid transparent' }}>
                                <BadgesPlus badges={positionsBadges} doNotSort={true} />
                            </Cell>
                        );
                    } else if (vr && column.slug === VolunteerDefaultColumns.WishedPositions) {
                        return (
                            <Cell key={column.slug} css={{ border: '2px solid transparent' }}>
                                <BadgesPlus badges={wishedPositionsBadges} doNotSort={true} />
                            </Cell>
                        );
                    } else {
                        return (
                            <Cell key={column.slug}>
                                {customField
                                    ? fieldService.getValueString(customField, member, {
                                          fileReturnValue: 'name'
                                      })
                                    : ''}
                            </Cell>
                        );
                    }
                })}

                <CellControls justify="center">
                    {canEditMembers && (
                        <Tooltip>
                            <Trigger>
                                <Box>
                                    <I icon="pen" onClick={() => history.push(editPath)} />
                                </Box>
                            </Trigger>

                            <Content placement="top">{translate('_diter_62574')}</Content>
                        </Tooltip>
                    )}

                    <DelegationMemberRowDropdown
                        accreditationState={member.volunteerRegistration?.accreditationState}
                        canEditMembers={canEditMembers}
                        customBadges={customBadges}
                        deleteFromEvent={deleteFromEvent}
                        formId={formId}
                        member={member}
                        showAccreditActions={showAccreditActions}
                        showDeleteFromEvent={showDeleteFromEvent}
                        showDocumentsDownload={showDocumentsDownload}
                        reload={reload}
                    />
                </CellControls>
            </Row>

            {member.volunteerRegistration && (
                <>
                    {isAccreditationsSidebarOpen && (
                        <RightPanel size="md" onClose={() => setIsAccreditationsSidebarOpen(false)}>
                            <RightPanelHeader>{translate('accr_ditations_39450')}</RightPanelHeader>

                            <RightPanelBody>
                                <Spacer height="9" />
                                <AccreditationCategories
                                    accreditationsSlots={member.volunteerRegistration.accreditationsUsersInfos.map(
                                        (aui) => aui.accreditationSlot
                                    )}
                                    actions={(accreditationSlotId: AccreditationsSlotId) =>
                                        isEventAdmin && (
                                            <DropdownEllipsis>
                                                <ItemIcon
                                                    icon="minus"
                                                    color={'red'}
                                                    onClick={async () => {
                                                        await deaccreditMember({
                                                            eventId:
                                                                member.volunteerRegistration!
                                                                    .eventId,
                                                            volunteerRegistrationId:
                                                                member.volunteerRegistration!.id,
                                                            deaccredit: {
                                                                accreditationsSlotsIds: [
                                                                    accreditationSlotId
                                                                ]
                                                            }
                                                        });

                                                        reload();
                                                    }}
                                                >
                                                    {translate('deaccredit_memb_96566')}
                                                </ItemIcon>
                                            </DropdownEllipsis>
                                        )
                                    }
                                />
                            </RightPanelBody>
                        </RightPanel>
                    )}

                    {isWishedAccreditationsSidebarOpen && (
                        <RightPanel
                            size="md"
                            onClose={() => setIsWishedAccreditationsSidebarOpen(false)}
                        >
                            <RightPanelHeader>
                                {translate('desired_accreditations')}
                            </RightPanelHeader>

                            <RightPanelBody>
                                <Spacer height="9" />
                                <AccreditationCategories
                                    accreditationsSlots={
                                        member.volunteerRegistration.accreditationsSlots
                                    }
                                    actions={(accreditationSlotId: AccreditationsSlotId) =>
                                        isEventAdmin &&
                                        auiSlotsIds.includes(accreditationSlotId) ? undefined : (
                                            <DropdownEllipsis>
                                                <ItemIcon
                                                    icon="plus"
                                                    onClick={async () => {
                                                        await accreditMember({
                                                            eventId:
                                                                member.volunteerRegistration!
                                                                    .eventId,
                                                            volunteerRegistrationId:
                                                                member.volunteerRegistration!.id,
                                                            accredit: {
                                                                accreditationsSlotsIds: [
                                                                    accreditationSlotId
                                                                ]
                                                            }
                                                        });

                                                        reload();
                                                    }}
                                                >
                                                    {translate('accredit_member_73476')}
                                                </ItemIcon>
                                            </DropdownEllipsis>
                                        )
                                    }
                                />
                            </RightPanelBody>
                        </RightPanel>
                    )}
                </>
            )}
        </>
    );
};
