import * as React from 'react';
import { useDropdownContext } from './dropdown';

interface ITriggerProps {
    children: React.ReactNode;
}

export const Trigger = (props: ITriggerProps) => {
    const { isOpen, setIsOpen, setReferenceElement } = useDropdownContext();
    const children = props.children as any;
    const child = React.cloneElement(React.Children.only(children), {
        ref(ref: HTMLDivElement) {
            setReferenceElement(ref);
        },
        onClick(e) {
            e.nativeEvent.stopImmediatePropagation();
            e.stopPropagation();

            setIsOpen(!isOpen);

            children.props.onClick?.(e);
        }
    });

    return <>{child}</>;
};
