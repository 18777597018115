import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface ILeftMenuProps {
    children: React.ReactNode;
}

export const LeftMenu = (props: ILeftMenuProps) => (
        <Flex direction="column" gap="2" width={1}>
            {props.children}
        </Flex>
    );
