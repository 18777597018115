import { Box } from 'common/src/designSystem/components/box';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { normalizeInteger } from '../../../util/form';
import { Label } from '../label/label';
import { fieldSubscriptions } from '../subscriptions';
import { Subtitle } from './subtitle';
import { _OldInputStyled } from './text';

interface ISelectComponentProps extends FieldRenderProps<any, HTMLElement> {
    children?: React.ReactNode;
    label?: string;
    parseInt?: boolean;
    isFilter: boolean;
    isOptional?: boolean;
    subtitle?: string;
}

const SelectComponent = (props: ISelectComponentProps) => {
    const invalid = props.meta.touched && props.meta.invalid;

    return (
        <div>
            {props.label && (
                <Label htmlFor={props.input.name} isOptional={props.isOptional}>
                    {props.label}
                </Label>
            )}

            {isNonEmptyString(props.subtitle) && <Subtitle text={props.subtitle} />}

            <_OldInputStyled
                as="select"
                {...props.input}
                invalid={invalid}
                filters={props.isFilter}
                onChange={(e: any) => {
                    let value: string | number | boolean | null = e.target.value;

                    if (props.parseInt === true) {
                        value = normalizeInteger(value as any);
                    }

                    props.input.onChange(value);
                }}
                value={(props.input.value || '').toString()}
            >
                {props.children}
            </_OldInputStyled>

            {invalid && (
                <Box
                    css={{
                        color: '$oldRed',
                        marginBottom: '$6'
                    }}
                >
                    {props.meta.error}
                </Box>
            )}
        </div>
    );
};

interface ISelectProps {
    label: string;
    name: string;
    children?: React.ReactNode;
    parseInt?: boolean;
    parseBoolean?: boolean;
    isOptional?: boolean;
    subtitle?: string;
}

export const Select = (props: ISelectProps) => (
        <Field
            {...props}
            render={(renderProps) => (
                    <SelectComponent
                        {...renderProps}
                        label={props.label}
                        subtitle={props.subtitle}
                        isFilter={false}
                    />
                )}
            subscription={fieldSubscriptions}
            format={(value: any) => {
                if (props.parseBoolean === true) {
                    return value === true ? 'true' : 'false';
                } else {
                    return value;
                }
            }}
            parse={(value: any) => {
                if (props.parseBoolean === true) {
                    return value === 'true';
                } else {
                    return value;
                }
            }}
        />
    );

interface ISelectFilterProps {
    name: string;
    children?: React.ReactNode;
}
export const SelectFilter = (props: ISelectFilterProps) => (
        <Field
            {...props}
            render={(renderProps) => <SelectComponent {...renderProps} isFilter={true} />}
            subscription={fieldSubscriptions}
        />
    );
