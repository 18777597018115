import { FlexedButton } from 'common-front/src/components/button/button';
import { TextInput } from 'common-front/src/components/reactFinalForm/input/text';
import { Separator } from 'common-front/src/components/separator/separator';
import { H1 } from 'common-front/src/components/titles/h1';
import { H3WithSubtitle } from 'common-front/src/components/titles/h3WithSubtitle';
import { UserForm } from 'common-front/src/components/users/create/userForm';
import { VolunteerRegistrationPositions } from 'common-front/src/components/volunteerRegistrationPositions/volunteerRegistrationPositions';
import { VolunteerRegistrationSlots } from 'common-front/src/components/volunteerRegistrationSlots/volunteerRegistrationSlots';
import { useVolunteerRegistrationUpdateMutation } from 'common-front/src/generated/graphqlHooks';
import { signOut } from 'common-front/src/util/aws/cognito';
import {
    FormPositionRanking,
    RegisterPositionDisplay,
    RegisterSlotDisplay,
    VolunteerAssignmentsQuery,
    VolunteerRegistrationState
} from 'common/src/generated/types';
import {
    IUpdateVolunteerRegistrationValues,
    VolunteerRegistrationInputService
} from 'common/src/input/volunteerRegistrationInput';
import { ValidateService } from 'common/src/services/validateService';
import {
    useHistory,
    useParams,
    useService,
    useTranslate
} from 'common/src/util/dependencies/dependencies';
import { getEmbedPath, VolunteersPaths } from 'common/src/util/heaventPaths';
import arrayMutators from 'final-form-arrays';
import { isEqual } from 'lodash-es';
import * as React from 'react';
import { Form } from 'react-final-form';
import { useEventPageContext } from '../../common/eventPageContext';
import { VolunteersBox } from '../../common/volunteersBox';
import { NamesSelector } from '../namesSelector';
import { WithRegistration } from '../withRegistration';

interface IUpdateRegistrationsProps {
    event: VolunteerAssignmentsQuery['event'];
    user: VolunteerAssignmentsQuery['user'];

    reload(): void;
}

export const UpdateRegistration = (props: IUpdateRegistrationsProps) => {
    const translate = useTranslate();
    const { organizationId, eventId } = useParams();
    const volunteerRegistrationInput = useService(VolunteerRegistrationInputService);
    const validateService = useService(ValidateService);
    const history = useHistory();
    const { mutate } = useVolunteerRegistrationUpdateMutation();
    const { isEmbed } = useEventPageContext();
    const registrations = props.user.volunteersRegistrations;
    const customFields = React.useMemo(() => props.event.eventsFields.map(({ field }) => field), [props.event.eventsFields]);

    return (
        <WithRegistration
            event={props.event}
            user={props.user}
            render={(registration) => {
                const schema = React.useMemo(() => volunteerRegistrationInput.updateVolunteerRegistrationSchema(
                        props.event.eventsFields,
                        [],
                        props.event.slotDisplay,
                        FormPositionRanking.Unranked,
                        props.event.positionDisplay,
                        props.event.startAt,
                        props.event.endAt
                    ), []);

                return (
                    <Form
                        initialValues={volunteerRegistrationInput.updateVolunteerRegistrationValuesDefault(
                            registration,
                            props.event,
                            customFields
                        )}
                        initialValuesEqual={isEqual}
                        validate={validateService.validateForForm(schema)}
                        mutators={{
                            ...arrayMutators
                        }}
                        onSubmit={async (values: IUpdateVolunteerRegistrationValues) => {
                            await mutate({
                                eventId,
                                email: values.email,
                                volunteerRegistrationId: registration.id,
                                volunteerRegistration: values.volunteerRegistration
                            });

                            if (values.email !== registration.userInfo.email) {
                                signOut();
                            } else {
                                props.reload();

                                history.push(
                                    getEmbedPath(
                                        isEmbed,
                                        VolunteersPaths.ASSIGNMENTS_REGISTRATION(
                                            organizationId,
                                            eventId,
                                            registration.id
                                        )
                                    )
                                );
                            }
                        }}
                        render={({ form, handleSubmit, invalid, values, submitting }) => (
                                <>
                                    <NamesSelector registrations={registrations} />

                                    <VolunteersBox>
                                        <H1 marginBottom={true}>
                                            {translate(
                                                'mettre_jour_l_27001',
                                                registration.userInfo.name
                                            )}
                                        </H1>

                                        <TextInput
                                            label={translate('e_mail_13869')}
                                            name="email"
                                            isEmail={true}
                                        />

                                        <UserForm
                                            fieldsToDisplay={props.event.eventsFields}
                                            customFields={customFields}
                                            prefix="volunteerRegistration.userInfo.fields."
                                            values={values.volunteerRegistration.userInfo.fields}
                                            change={form.change}
                                        />

                                        {registration.state !==
                                            VolunteerRegistrationState.Assigned &&
                                            props.event.slotDisplay !==
                                                RegisterSlotDisplay.Hide && (
                                                <>
                                                    <Separator margin={32} />

                                                    <H3WithSubtitle
                                                        title={translate('cr_neaux_de_dis_89497')}
                                                        subtitle={translate(
                                                            'ajoutez_vos_dis_02011'
                                                        )}
                                                        marginBottom={24}
                                                    />

                                                    <VolunteerRegistrationSlots
                                                        event={props.event}
                                                        values={values.volunteerRegistration}
                                                        change={form.change}
                                                    />
                                                </>
                                            )}

                                        {registration.state !==
                                            VolunteerRegistrationState.Assigned &&
                                            props.event.positionDisplay !==
                                                RegisterPositionDisplay.None && (
                                                <>
                                                    <Separator margin={32} />

                                                    {props.event.positionDisplay ===
                                                    RegisterPositionDisplay.Category ? (
                                                        <H3WithSubtitle
                                                            title={translate(
                                                                'cat_gories_de_m_09461'
                                                            )}
                                                            subtitle={translate(
                                                                's_lectionnez_le_17459'
                                                            )}
                                                            marginBottom={24}
                                                        />
                                                    ) : (
                                                        <H3WithSubtitle
                                                            title={translate(
                                                                'missions_souhai_23225'
                                                            )}
                                                            subtitle={translate(
                                                                's_lectionnez_le_37648'
                                                            )}
                                                            marginBottom={24}
                                                        />
                                                    )}

                                                    <VolunteerRegistrationPositions
                                                        event={props.event}
                                                        values={values.volunteerRegistration}
                                                        showHiddenPositions={false}
                                                        change={form.change}
                                                    />
                                                </>
                                            )}

                                        <FlexedButton
                                            onClick={handleSubmit}
                                            isInactive={invalid}
                                            isLoading={submitting}
                                        >
                                            {translate('mettre_jour_85141')}
                                        </FlexedButton>
                                    </VolunteersBox>
                                </>
                            )}
                    />
                );
            }}
        />
    );
};
