import { ProgressBar } from 'common-front/src/designSystem/components/progressBar';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { Link } from 'common-front/src/util/link';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { DelegationSpaceDashboardQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { NumberService } from 'common/src/services/numberService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { getFormLink } from 'common/src/vo/form';
import * as React from 'react';

interface IDelegationDetailsCardProps {
    delegation: DelegationSpaceDashboardQuery['organization']['delegation'];
}

export const DelegationDetailsCard = ({ delegation }: IDelegationDetailsCardProps) => {
    const dateTimeService = useService(DateTimeService);
    const numberService = useService(NumberService);
    const translate = useTranslate();

    const formLink = React.useMemo(() => getFormLink({
            organizationId: delegation.organizationId,
            eventId: delegation.eventId,
            delegationId: delegation.id,
            formId: delegation.form.id
        }), [delegation]);

    return (
        <Flex direction="column" gap="4" width={1}>
            {delegation.deadlineDate && (
                <>
                    <Flex align="center" gap="3" width="100%">
                        <Box font="gray800 textSm medium">
                            {translate('date_butoir_dinscription')}
                        </Box>

                        <Box css={{ flex: '1' }} />

                        <Box>
                            {dateTimeService.toLocaleString(
                                delegation.deadlineDate,
                                LocaleFormats.DateOnly.MonthShort
                            )}
                        </Box>
                    </Flex>

                    <Separator direction="horizontal" />
                </>
            )}

            <Flex direction="column" gap="3">
                <Flex align="center" gap="3">
                    <Box font="gray800 textSm medium">{translate('Form')}</Box>

                    <Box css={{ flex: '1' }} />

                    <Link to={{ pathname: formLink }} target="_blank">
                        <Flex
                            align="center"
                            gap="2"
                            css={{
                                color: '$primary700',
                                cursor: 'pointer',
                                fontWeight: '$medium',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            <Box>{delegation.form.name}</Box>

                            <Box>
                                <I icon="arrow-up-right-from-square" />
                            </Box>
                        </Flex>
                    </Link>
                </Flex>

                <Separator direction="horizontal" />

                <Flex direction="column" gap="1" width="100%">
                    <Flex align="center" gap="3">
                        <Box font="gray800 textSm medium">{translate('nombre_de_membr_08851')}</Box>

                        <Box css={{ flex: '1' }} />

                        <Box>{`${delegation.numberOfResources}/${numberService.toNumberOrInfinity(
                            delegation.maxResources
                        )} membres`}</Box>
                    </Flex>

                    {delegation.maxResources && (
                        <ProgressBar
                            isErrorIfOver={true}
                            percent={delegation.numberOfResources / delegation.maxResources}
                        />
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};

export const DelegationDetailsCardSkeleton = () => (
        <Flex direction="column" gap="4" width={1}>
            <Skeleton height={40} width={1} borderRadius="$2" />
            <Separator direction="horizontal" />
            <Skeleton height={40} width={1} borderRadius="$2" />
        </Flex>
    );
