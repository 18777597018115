import { Empty } from 'common-front/src/components/empty/empty';
import { DelegationAccreditations } from 'common-front/src/delegations/accreditations/show/delegationAccreditations';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { TabViewResponsive } from 'common-front/src/spaces/tabViewReponsive';
import { DelegationsPaths } from 'common/src/delegationsPaths';
import { Flex } from 'common/src/designSystem/components/flex';
import { DelegationsPaths as DelegationsPathsV2 } from 'common/src/util/paths/delegationsPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useDelegationsSpaceDelegationQuery } from '../../generated/graphqlHooks';
import { SpaceContainer } from '../common/spaceContainer';
import { DelegationDashboard } from './dashboard/delegationDashboard';
import { DelegationMenu } from './delegationMenu';
import { DelegationsSpaceHeader } from './delegationsSpaceHeader';
import {
    DelegationCreateMember,
    DelegationUpdateMember
} from './members/update/delegationCreateUpdateMember';

export const Delegation = () => {
    const {
        translate,
        params: { organizationId, eventId, delegationId }
    } = useHeavent();
    const { data, loader, reload } = useDelegationsSpaceDelegationQuery({
        delegationId,
        organizationId
    });
    const extraItems = React.useMemo(() => {
        const delegations = data.organization?.delegations.nodes ?? [];

        return <DelegationMenu delegations={delegations} />;
    }, [data.organization]);

    return (
        loader || (
            <SpaceContainer
                extraItems={extraItems}
                isLoading={false}
                organization={data.organization}
                spaceDesign={{
                    themeColors: data.organization?.delegationsSpaceDesign?.colors,
                    bannerSrc: data.organization?.delegationsSpaceDesign?.banner?.url,
                    logoSrc: data.organization?.delegationsSpaceDesign?.logo?.url
                }}
                userInfo={data.user.userInfo}
            >
                <Switch>
                    <Route
                        exact
                        path={[
                            DelegationsPaths.DELEGATION({
                                organizationId: ':organizationId',
                                delegationId: ':delegationId'
                            }),
                            DelegationsPaths.DELEGATION({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                delegationId: ':delegationId'
                            })
                        ]}
                        children={
                            <Empty
                                path={DelegationsPathsV2.DELEGATION_DASHBOARD(
                                    organizationId,
                                    eventId,
                                    delegationId
                                )}
                                replace={true}
                            />
                        }
                    />

                    <Route
                        path={[
                            DelegationsPaths.MEMBER_EDIT({
                                organizationId: ':organizationId',
                                delegationId: ':delegationId',
                                userInfoId: ':userInfoId',
                                formId: ':formId'
                            }),
                            DelegationsPaths.MEMBER_EDIT({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                delegationId: ':delegationId',
                                userInfoId: ':userInfoId',
                                formId: ':formId'
                            })
                        ]}
                        children={<DelegationUpdateMember reload={reload} />}
                    />

                    <Route
                        path={[
                            DelegationsPaths.MEMBER_CREATE({
                                organizationId: ':organizationId',
                                delegationId: ':delegationId',
                                formId: ':formId'
                            }),
                            DelegationsPaths.MEMBER_CREATE({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                delegationId: ':delegationId',
                                formId: ':formId'
                            })
                        ]}
                        children={<DelegationCreateMember reload={reload} />}
                    />

                    <Route
                        children={
                            <>
                                <DelegationsSpaceHeader
                                    delegation={data.organization.delegation}
                                    name={data.user.userInfo.firstName}
                                />

                                <Flex direction="column">
                                    <TabViewResponsive
                                        sections={[
                                            {
                                                id: 1,
                                                title: translate('tableau_de_bord_24627'),
                                                getPath: (isRouteComponent: boolean = false) =>
                                                    DelegationsPathsV2.DELEGATION_DASHBOARD(
                                                        isRouteComponent
                                                            ? ':organizationId'
                                                            : organizationId,
                                                        isRouteComponent
                                                            ? eventId
                                                                ? ':eventId'
                                                                : undefined
                                                            : eventId,
                                                        isRouteComponent
                                                            ? ':delegationId'
                                                            : delegationId
                                                    ),
                                                elem: <DelegationDashboard />
                                            },
                                            ...(eventId
                                                ? [
                                                      {
                                                          id: 2,
                                                          title: translate('accr_ditations_39450'),
                                                          getPath: (
                                                              isRouteComponent: boolean = false
                                                          ) =>
                                                              DelegationsPathsV2.DELEGATION_ACCREDITATIONS(
                                                                  isRouteComponent
                                                                      ? ':organizationId'
                                                                      : organizationId,
                                                                  isRouteComponent
                                                                      ? ':eventId'
                                                                      : eventId,
                                                                  isRouteComponent
                                                                      ? ':delegationId'
                                                                      : delegationId
                                                              ),
                                                          elem: <DelegationAccreditations />
                                                      }
                                                  ]
                                                : [])
                                        ]}
                                    />
                                </Flex>
                            </>
                        }
                    />
                </Switch>
            </SpaceContainer>
        )
    );
};
