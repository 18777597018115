import { Box } from 'common/src/designSystem/components/box';
import * as React from 'react';
import { H3 } from './h3';

interface IH3WithSubtitleProps {
    title: string;
    subtitle: string | React.ReactNode;
    marginBottom?: number;
    marginTop?: number;
}

export const H3WithSubtitle = (props: IH3WithSubtitleProps) => (
        <div
            style={{
                marginBottom: `${props.marginBottom ?? 0}px`,
                marginTop: `${props.marginTop ?? 0}px`
            }}
        >
            <H3>{props.title}</H3>

            <Box
                css={{
                    color: '$oldBlueBayoux',
                    '& a': {
                        color: '$oldBlueBayoux'
                    }
                }}
            >
                {props.subtitle}
            </Box>
        </div>
    );
