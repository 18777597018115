import { DateTime } from 'luxon';
import * as React from 'react';
import { Field } from 'react-final-form';
import { DatetimeInput as DatetimeInputComponent } from '../../components/date/datetimeInput';
import { BaseInputProps } from '../../components/input/baseInputProps';

interface IDatetimeInputProps extends BaseInputProps {
    name: string;
    min?: DateTime;
    max?: DateTime;
}

export const DatetimeInput = (props: IDatetimeInputProps) => (
        <Field
            name={props.name}
            render={(fieldProps) => {
                const invalid =
                    (fieldProps.meta.data?.forceDisplayError && fieldProps.meta.invalid) ||
                    (fieldProps.meta.touched && fieldProps.meta.invalid);

                return (
                    <DatetimeInputComponent
                        {...props}
                        hint={invalid ? fieldProps.meta.error : props.hint}
                        state={invalid ? 'error' : props.state}
                        value={fieldProps.input.value}
                        onChange={fieldProps.input.onChange}
                        onBlur={fieldProps.input.onBlur}
                        onFocus={fieldProps.input.onFocus}
                    />
                );
            }}
        />
    );
