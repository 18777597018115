import * as React from 'react';
import { Field } from 'react-final-form';
import { isInvalid } from '../../../components/reactFinalForm/isInvalid';
import { BaseInputProps } from '../../components/input/baseInputProps';
import { IInputState } from '../../components/input/state';
import { Select as SelectComponent } from '../../components/select/select';
import { AutoComplete } from '../../components/textInput';

interface ISelectProps extends BaseInputProps {
    autoComplete?: AutoComplete;
    name: string;
    state?: IInputState;
    children: React.ReactNode;
    parseInt?: boolean;
    parseBoolean?: boolean;
}

export const Select = (props: ISelectProps) => (
        <Field
            name={props.name}
            render={(fieldProps) => {
                const invalid = isInvalid(fieldProps.meta);

                return (
                    <SelectComponent
                        {...(props as any)}
                        hint={invalid ? fieldProps.meta.error : props.hint}
                        state={invalid ? 'error' : props.state}
                        value={fieldProps.input.value}
                        onChange={(value) => {
                            fieldProps.input.onChange(value);
                        }}
                        onBlur={fieldProps.input.onBlur}
                        onFocus={fieldProps.input.onFocus}
                    />
                );
            }}
        />
    );
