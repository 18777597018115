import { FieldProperty } from 'common/src/generated/types';
import { identity } from 'lodash-es';
import * as React from 'react';
import { ComponentProps } from 'react';
import { Field } from 'react-final-form';
import { isInvalid } from '../../components/reactFinalForm/isInvalid';
import { BaseInputProps } from '../components/input/baseInputProps';
import { AutoComplete, TextInput as TextInputComponent } from '../components/textInput';

interface IInputProps
    extends BaseInputProps,
        Omit<ComponentProps<typeof TextInputComponent>, 'onChange' | 'value'> {
    name: string;
}

export const TextInput = (props: IInputProps) => {
    const getAutocompleteAttribute = (property: string): AutoComplete | undefined => {
        switch (property) {
            case FieldProperty.City:
                // TODO - this relates to town for the UK and similar places,
                // but in some locales (e.g. CH) this should be 'address-level2'.
                // Currently I don't know if we have an easy way of detecting
                // the user's locale.
                // @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#administrative_levels_in_addresses
                return 'address-level1';
            case FieldProperty.DateOfBirth:
                return 'bday';
            case FieldProperty.FirstName:
                return 'given-name';
            case FieldProperty.LastName:
                return 'family-name';
            case FieldProperty.PostalCode:
                return 'postal-code';
            case FieldProperty.Street:
                return 'street-address';
            case FieldProperty.Country:
            case FieldProperty.Language:
            case FieldProperty.Nationality:
            case FieldProperty.Phone:
            case FieldProperty.Picture:
            case FieldProperty.Sex:
            default:
                return;
        }
    };

    return (
        <Field
            name={props.name}
            parse={identity}
            render={(fieldProps) => {
                const invalid = isInvalid(fieldProps.meta);

                return (
                    <TextInputComponent
                        autoComplete={
                            props.name.startsWith('formRegister.userInfo.fields.')
                                ? getAutocompleteAttribute(props.name.split('.').pop()!) ??
                                  props.autoComplete
                                : props.autoComplete
                        }
                        description={props.description}
                        hint={
                            invalid
                                ? fieldProps.meta.error || fieldProps.meta.submitError
                                : props.hint
                        }
                        icon={props.icon}
                        rightIcon={props.rightIcon}
                        isEmail={props.isEmail}
                        label={props.label}
                        min={props.min}
                        parseInt={(props.parseInt || false) as any}
                        placeholder={props.placeholder}
                        state={invalid ? 'error' : props.state}
                        value={fieldProps.input.value!}
                        onChange={(value) => {
                            fieldProps.input.onChange(value);
                        }}
                        onBlur={fieldProps.input.onBlur}
                        onFocus={fieldProps.input.onFocus}
                        onRightIconClick={props.onRightIconClick}
                        type={props.type}
                    />
                );
            }}
        />
    );
};
