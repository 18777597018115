import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

const _H1 = styled('h1', {
    fontSize: '24px',
    fontWeight: '$oldBold',
    lineHeight: '40px',
    margin: '0'
});

interface IH1Props {
    children?: React.ReactNode;
    margin?: boolean;
    marginBottom?: boolean;
    marginTop?: boolean;
}

export const H1 = (props: IH1Props) => (
        <_H1
            style={{
                marginBottom:
                    props.margin === true || props.marginBottom === true ? `48px` : undefined,
                marginTop: props.margin === true || props.marginTop === true ? `48px` : undefined
            }}
        >
            {props.children}
        </_H1>
    );
