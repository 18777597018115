import * as React from 'react';
import { AllDayInBadge } from '../documents/customBadges/allDayIn/allDayInBadge';
import { BadgeAmbert } from '../documents/customBadges/ambert/badgeAmbert';
import { PlanningAmbert } from '../documents/customBadges/ambert/planningAmbert';
import { AsoAssignment } from '../documents/customBadges/aso/asoAssignment';
import { BadgeAuborddeleau } from '../documents/customBadges/auborddeleau/badgeAuborddeleau';
import { AxeA4 } from '../documents/customBadges/axe/axeA4';
import { BelgiumHouseBadge } from '../documents/customBadges/belgiumHouse/belgiumHouseBadge';
import { BelgiumHouseBadge2 } from '../documents/customBadges/belgiumHouse/belgiumHouseBadge2';
import { BelgiumHouseContremarque } from '../documents/customBadges/belgiumHouse/belgiumHouseContremarque';
import { BelgiumHouseGuestBadge } from '../documents/customBadges/belgiumHouse/belgiumHouseGuestBadge';
import { BadgeBlot } from '../documents/customBadges/blot/badgeBlot';
import { BadgeBlotRennes } from '../documents/customBadges/blotRennes/badgeBlotRennes';
import { BadgeBobital } from '../documents/customBadges/bobital/badgeBobital';
import { BadgeBruleursA6 } from '../documents/customBadges/bruleurs/badgeBruleursA6';
import { BadgeElephants } from '../documents/customBadges/bruleurs/badgeElephants';
import { BadgesBruleurs } from '../documents/customBadges/bruleurs/badgesBruleurs';
import { BadgeClubFrance } from '../documents/customBadges/clubFrance/badgeClubFrance';
import { ContremarqueClubFrance } from '../documents/customBadges/clubFrance/contremarqueClubFrance';
import { BadgeClubSqy } from '../documents/customBadges/clubSqy/badgeClubSqy';
import { BadgesClubSqy } from '../documents/customBadges/clubSqy/badgesClubSqy';
import { BadgeCroixRouge } from '../documents/customBadges/croixRouge/badgeCroixRouge';
import { PositionSheetCroixRouge } from '../documents/customBadges/croixRouge/positionSheetCroixRouge';
import { BadgeDelta } from '../documents/customBadges/delta/badgeDelta';
import { BadgeDfcoA4 } from '../documents/customBadges/dfco/badgeDfcoA4';
import { BadgeDfcoSmall } from '../documents/customBadges/dfco/badgeDfcoSmall';
import { BadgeDinoA4 } from '../documents/customBadges/dino/badgeDinoA4';
import { BadgeDinoA6 } from '../documents/customBadges/dino/badgeDinoA6';
import { BadgesDino } from '../documents/customBadges/dino/badgesDino';
import { BadgeDoxart } from '../documents/customBadges/doxart/badgeDoxart';
import { BadgesDoxart } from '../documents/customBadges/doxart/badgesDoxart';
import { BadgeDubcamp } from '../documents/customBadges/dubcamp/badgeDubcamp';
import { Eurockeennes } from '../documents/customBadges/eurockeennes/eurockeennes';
import { EurockeennesA4 } from '../documents/customBadges/eurockeennes/eurockeennesA4';
import { BadgeFcgA4 } from '../documents/customBadges/fcg/badgeFcgA4';
import { BadgeFcgA6 } from '../documents/customBadges/fcg/badgeFcgA6';
import { BadgesFcg } from '../documents/customBadges/fcg/badgesFcg';
import { FiseBadge } from '../documents/customBadges/fise/fiseBadge';
import { FiseBadgeA4 } from '../documents/customBadges/fise/fiseBadgeA4';
import { FiseBadgeVide } from '../documents/customBadges/fise/fiseBadgeVide';
import { FiseContremarque } from '../documents/customBadges/fise/fiseContremarque';
import { BadgeFrancofoliesEsch } from '../documents/customBadges/francofoliesEsch/badgeFrancofoliesEsch';
import { BadgeGarorock } from '../documents/customBadges/garorock/badgeGarorock';
import { PlanningGarorock } from '../documents/customBadges/garorock/planningGarorock';
import { BadgeHighfiveA6 } from '../documents/customBadges/highfive/badgeHighfiveA6';
import { BadgesHighfive } from '../documents/customBadges/highfive/badgesHighfive';
import { BadgeJardinMichel } from '../documents/customBadges/jardinMichel/badgeJardinMichel';
import { BadgeJardinMichelPro } from '../documents/customBadges/jardinMichel/badgeJardinMichelPro';
import { BadgesJardinMichel } from '../documents/customBadges/jardinMichel/badgesJardinMichel';
import { BadgeJardinSonorePartenaire } from '../documents/customBadges/jardinSonore/badgeJardinSonorePartenaire';
import { BadgeJardinSonorePhotographe } from '../documents/customBadges/jardinSonore/badgeJardinSonorePhotographe';
import { ContremarqueJardinSonoreBenevole } from '../documents/customBadges/jardinSonore/contremarqueJardinSonoreBenevole';
import { ContremarqueJardinSonoreParking } from '../documents/customBadges/jardinSonore/contremarqueJardinSonoreParking';
import { ContremarqueJardinSonorePartenaire } from '../documents/customBadges/jardinSonore/contremarqueJardinSonorePartenaire';
import { BadgeJivahill } from '../documents/customBadges/jivahill/badgeJivahill';
import { BadgeJokersfrancesloveniaA4 } from '../documents/customBadges/jokersfranceslovenia/badgeJokersfrancesloveniaA4';
import { BadgeJokersfrancesloveniaA6 } from '../documents/customBadges/jokersfranceslovenia/badgeJokersfrancesloveniaA6';
import { BadgesJokersfranceslovenia } from '../documents/customBadges/jokersfranceslovenia/badgesJokersfranceslovenia';
import { BadgeKermesse } from '../documents/customBadges/kermesse/badgeKermesse';
import { BadgeLnh } from '../documents/customBadges/lnh/badgeLnh';
import { BadgeMafiozik } from '../documents/customBadges/mafiozik/badgeMafiozik';
import { BadgeMoorea } from '../documents/customBadges/moorea/badgeMoorea';
import { BadgeNuitsSecretes } from '../documents/customBadges/nuitsSecretes/badgeNuitsSecretes';
import { P2nAssignment } from '../documents/customBadges/p2n/p2nAssignment';
import { P2nAssignmentMontageDemontage } from '../documents/customBadges/p2n/p2nAssignmentMontageDemontage';
import { P2NBadge } from '../documents/customBadges/p2n/p2nBadge';
import { BadgeParis20km } from '../documents/customBadges/paris20km/badgeParis20km';
import { ParisAeroportBadge } from '../documents/customBadges/parisAeroport/parisAeroportBadge';
import { BadgePastourelle } from '../documents/customBadges/pastourelle/badgePastourelle';
import { BadgePastourelleexposant } from '../documents/customBadges/pastourelle/badgePastourelleexposant';
import { BadgePec } from '../documents/customBadges/pec/badgePec';
import { BadgePoc } from '../documents/customBadges/poc/badgePoc';
import { BadgePrimrose } from '../documents/customBadges/primrose/badgePrimrose';
import { BadgeRecrewdaysA6 } from '../documents/customBadges/recrewdays/badgeRecrewdaysA6';
import { RecrewdaysPass } from '../documents/customBadges/recrewdays/recrewdaysPass';
import { BadgeReims } from '../documents/customBadges/reims/badgeReims';
import { BadgeRes2024Parking } from '../documents/customBadges/res/badgeRes2024Parking';
import { ContremarqueRes2024 } from '../documents/customBadges/res/contremarqueRes2024';
import { BadgeRoiArthur } from '../documents/customBadges/roiArthur/badgeRoiArthur';
import { BadgeRose } from '../documents/customBadges/rose/badgeRose';
import { BadgeRoseParking } from '../documents/customBadges/rose/badgeRoseParking';
import { BadgeSalamandreA4 } from '../documents/customBadges/salamandre/badgeSalamandreA4';
import { BadgeSalamandreA7 } from '../documents/customBadges/salamandre/badgeSalamandreA7';
import { BadgesSalamandre } from '../documents/customBadges/salamandre/badgesSalamandre';
import { BadgeSqy } from '../documents/customBadges/sqy/badgeSqy';
import { BadgeSqyTraitCoupe } from '../documents/customBadges/sqy/badgeSqyTraitCoupe';
import { T24Assignment } from '../documents/customBadges/t24/t24Assignment';
import { BadgeTerresDuSon } from '../documents/customBadges/terresDuSon/badgeTerresDuSon';
import { TriathlonLaRochelleBadge } from '../documents/customBadges/triathlonLaRochelle/triathlonLaRochelleBadge';
import { BadgeTvj } from '../documents/customBadges/tvj/badgeTvj';
import { UltraMarinBenevole } from '../documents/customBadges/ultraMarin/benevole/ultraMarinBenevole';
import { UltraMarinExposant } from '../documents/customBadges/ultraMarin/exposant/ultraMarinExposant';
import { UltraMarinMedia } from '../documents/customBadges/ultraMarin/media/ultraMarinMedia';
import { UltraMarinMedical } from '../documents/customBadges/ultraMarin/medical/ultraMarinMedical';
import { UltraMarinOrganisation } from '../documents/customBadges/ultraMarin/organisation/ultraMarinOrganisation';
import { UltraMarinPartenaire } from '../documents/customBadges/ultraMarin/partenaire/ultraMarinPartenaire';
import { UltraMarinPresse } from '../documents/customBadges/ultraMarin/presse/ultraMarinPresse';
import { UltraMarinAssignment } from '../documents/customBadges/ultraMarin/ultraMarinAssignment';
import { BadgeValbon } from '../documents/customBadges/valbon/badgeValbon';
import { ContremarqueVandb } from '../documents/customBadges/vanb/contremarqueVandb';
import { VandbInvitation } from '../documents/customBadges/vanb/vandbInvitation';
import { BadgeVannesA6 } from '../documents/customBadges/vannes/badgeVannesA6';
import { BadgeVendanges } from '../documents/customBadges/vendanges/badgeVendanges';
import { BadgeWeezdaysA6 } from '../documents/customBadges/weezdays/badgeWeezdaysA6';
import { BadgeZamna } from '../documents/customBadges/zamna/badgeZamna';
import { CustomBadge } from '../generated/types';
import { assertUnreachable } from '../util/assertUnreachable';
import { Emptyable } from '../util/emptyable';
import { getDefaultBruleursDocumentOptions } from './eventDocument';

export function getCustomBadgeReactNode(
    badgeType: CustomBadge,
    event: any,
    logoUrl: Emptyable<string>,
    options: Emptyable<any>
) {
    switch (badgeType) {
        case CustomBadge.Alldayin:
            return <AllDayInBadge event={event} />;
        case CustomBadge.Asoassignment:
            return <AsoAssignment event={event} options={options!} />;
        case CustomBadge.Auborddeleau:
            return <BadgeAuborddeleau event={event} />;
        case CustomBadge.Axe:
            return <AxeA4 event={event} />;
        case CustomBadge.Belgiumhousebadge:
            return <BelgiumHouseBadge event={event} />;
        case CustomBadge.Belgiumhousebadge2:
            return <BelgiumHouseBadge2 event={event} />;
        case CustomBadge.Belgiumhousecontremaque:
            return <BelgiumHouseContremarque event={event} />;
        case CustomBadge.Belgiumhouseguestbadge:
            return <BelgiumHouseGuestBadge event={event} />;
        case CustomBadge.Blot:
            return <BadgeBlot event={event} />;
        case CustomBadge.Blotrennes:
            return <BadgeBlotRennes event={event} />;
        case CustomBadge.Bobital:
            return <BadgeBobital event={event} />;
        case CustomBadge.Bruleurs:
            return <BadgeBruleursA6 event={event} options={options!} />;
        case CustomBadge.Clubsqy:
            return <BadgeClubSqy event={event} />;
        case CustomBadge.Clubfrance:
            return <BadgeClubFrance event={event} />;
        case CustomBadge.Clubfrancecontremarque:
            return <ContremarqueClubFrance event={event} />;
        case CustomBadge.Croixrouge:
            return <BadgeCroixRouge event={event} />;
        case CustomBadge.Croixrougeordremission:
            return <PositionSheetCroixRouge event={event} />;
        case CustomBadge.Delta:
            return <BadgeDelta event={event} />;
        case CustomBadge.Dfcoa4:
            return <BadgeDfcoA4 event={event} />;
        case CustomBadge.Dfcosmall:
            return <BadgeDfcoSmall event={event} />;
        case CustomBadge.Dino:
            return <BadgeDinoA4 event={event} />;
        case CustomBadge.Doxart:
            return <BadgeDoxart event={event} />;
        case CustomBadge.Dubcamp:
            return <BadgeDubcamp event={event} />;
        case CustomBadge.Elephants:
            return <BadgeElephants event={event} />;
        case CustomBadge.Eurockeennes:
            return <EurockeennesA4 event={event} />;
        case CustomBadge.Fcg:
            return <BadgeFcgA4 event={event} />;
        case CustomBadge.Fisebadge:
            return <FiseBadge event={event} />;
        case CustomBadge.Fisebadgevide:
            return <FiseBadgeVide event={event} />;
        case CustomBadge.Fisebadgea4:
            return <FiseBadgeA4 event={event} />;
        case CustomBadge.Fisecontremarque:
            return <FiseContremarque event={event} />;
        case CustomBadge.Francofoliesesch:
            return <BadgeFrancofoliesEsch event={event} />;
        case CustomBadge.Garorockbadge:
            return <BadgeGarorock event={event} />;
        case CustomBadge.Garorockplanning:
            return <PlanningGarorock event={event} />;
        case CustomBadge.Highfive:
            return <BadgeHighfiveA6 event={event} />;
        case CustomBadge.Jardinmichel:
            return <BadgeJardinMichel event={event} />;
        case CustomBadge.Jardinmichelpro:
            return <BadgeJardinMichelPro event={event} />;
        case CustomBadge.Jardinsonorebadgepartenaire:
            return <BadgeJardinSonorePartenaire event={event} />;
        case CustomBadge.Jardinsonorebadgephotographe:
            return <BadgeJardinSonorePhotographe event={event} />;
        case CustomBadge.Jardinsonorecontremarquebenevole:
            return <ContremarqueJardinSonoreBenevole event={event} />;
        case CustomBadge.Jardinsonorecontremarqueparking:
            return <ContremarqueJardinSonoreParking event={event} />;
        case CustomBadge.Jardinsonorecontremarquepartenaire:
            return <ContremarqueJardinSonorePartenaire event={event} />;
        case CustomBadge.Jivahill:
            return <BadgeJivahill event={event} />;
        case CustomBadge.Jokersfranceslovenia:
            return <BadgeJokersfrancesloveniaA4 event={event} />;
        case CustomBadge.Kermesse:
            return <BadgeKermesse event={event} />;
        case CustomBadge.Lnh:
            return <BadgeLnh event={event} />;
        case CustomBadge.Mafiozik:
            return <BadgeMafiozik event={event} />;
        case CustomBadge.Moorea:
            return <BadgeMoorea event={event} />;
        case CustomBadge.Nuitssecretes:
            return <BadgeNuitsSecretes event={event} />;
        case CustomBadge.P2nassignment:
            return <P2nAssignment event={event} />;
        case CustomBadge.P2nassignmentmontagedemontage:
            return <P2nAssignmentMontageDemontage event={event} />;
        case CustomBadge.P2nbadge:
            return <P2NBadge event={event} />;
        case CustomBadge.Paris20km:
            return <BadgeParis20km event={event} />;
        case CustomBadge.Parisaeroport:
            return <ParisAeroportBadge event={event} />;
        case CustomBadge.Pastourelle:
            return <BadgePastourelle event={event} />;
        case CustomBadge.Pastourelleexposant:
            return <BadgePastourelleexposant event={event} />;
        case CustomBadge.Pec:
            return <BadgePec event={event} options={options!} />;
        case CustomBadge.Poc:
            return <BadgePoc event={event} logoUrl={logoUrl} />;
        case CustomBadge.Primrose:
            return <BadgePrimrose event={event} />;
        case CustomBadge.Res2024contremarque:
            return <ContremarqueRes2024 event={event} />;
        case CustomBadge.Res2024parking:
            return <BadgeRes2024Parking event={event} />;
        case CustomBadge.Recrewdays:
            return <BadgeRecrewdaysA6 event={event} />;
        case CustomBadge.Recrewdayspass:
            return <RecrewdaysPass event={event} />;
        case CustomBadge.Reims:
            return <BadgeReims event={event} />;
        case CustomBadge.Roiarthur:
            return <BadgeRoiArthur event={event} />;
        case CustomBadge.Rose:
            return <BadgeRose event={event} />;
        case CustomBadge.Roseparking:
            return <BadgeRoseParking event={event} />;
        case CustomBadge.Salamandre:
            return <BadgeSalamandreA4 event={event} />;
        case CustomBadge.Sqy:
            return <BadgeSqy event={event} />;
        case CustomBadge.Sqytraitcoupe:
            return <BadgeSqyTraitCoupe event={event} />;
        case CustomBadge.T24assignment:
            return <T24Assignment event={event} options={options!} />;
        case CustomBadge.Terresduson:
            return <BadgeTerresDuSon event={event} />;
        case CustomBadge.Triathlonlarochellebadge:
            return <TriathlonLaRochelleBadge event={event} />;
        case CustomBadge.Tvj:
            return <BadgeTvj event={event} />;
        case CustomBadge.Ultramarinassignment:
            return <UltraMarinAssignment event={event} />;
        case CustomBadge.Ultramarinbenevole:
            return <UltraMarinBenevole event={event} />;
        case CustomBadge.Ultramarinexposant:
            return <UltraMarinExposant event={event} />;
        case CustomBadge.Ultramarinmedia:
            return <UltraMarinMedia event={event} />;
        case CustomBadge.Ultramarinmedical:
            return <UltraMarinMedical event={event} />;
        case CustomBadge.Ultramarinorganisation:
            return <UltraMarinOrganisation event={event} />;
        case CustomBadge.Ultramarinpartenaire:
            return <UltraMarinPartenaire event={event} />;
        case CustomBadge.Ultramarinpresse:
            return <UltraMarinPresse event={event} />;
        case CustomBadge.Valbon:
            return <BadgeValbon event={event} />;
        case CustomBadge.Vandbcontremarque:
            return <ContremarqueVandb event={event} />;
        case CustomBadge.Vandbinvitation:
            return <VandbInvitation event={event} />;
        case CustomBadge.Vannes:
            return <BadgeVannesA6 event={event} />;
        case CustomBadge.Vendanges:
            return <BadgeVendanges event={event} />;
        case CustomBadge.Weezdays:
            return <BadgeWeezdaysA6 event={event} />;
        case CustomBadge.Wfa24:
            return <BadgeAmbert event={event} />;
        case CustomBadge.Wfa24planning:
            return <PlanningAmbert event={event} />;
        case CustomBadge.Zamna:
            return <BadgeZamna event={event} />;
        default:
            return assertUnreachable(badgeType);
    }
}

export function getCustomBadgesReactNode(badgeType: CustomBadge, event: any) {
    switch (badgeType) {
        case CustomBadge.Alldayin:
            throw new Error('Not implemented');
        case CustomBadge.Asoassignment:
            throw new Error('Not implemented');
        case CustomBadge.Auborddeleau:
            throw new Error('Not implemented');
        case CustomBadge.Axe:
            throw new Error('Not implemented');
        case CustomBadge.Belgiumhousebadge:
        case CustomBadge.Belgiumhousebadge2:
        case CustomBadge.Belgiumhousecontremaque:
        case CustomBadge.Belgiumhouseguestbadge:
            throw new Error('Not implemented');
        case CustomBadge.Blot:
        case CustomBadge.Blotrennes:
            throw new Error('Not implemented');
        case CustomBadge.Bobital:
            throw new Error('Not implemented');
        case CustomBadge.Bruleurs:
            return <BadgesBruleurs event={event} options={getDefaultBruleursDocumentOptions()} />;
        case CustomBadge.Clubsqy:
            return <BadgesClubSqy event={event} />;
        case CustomBadge.Clubfrance:
        case CustomBadge.Clubfrancecontremarque:
            throw new Error('Not implemented');
        case CustomBadge.Croixrouge:
            throw new Error('Not implemented');
        case CustomBadge.Croixrougeordremission:
            throw new Error('Not implemented');
        case CustomBadge.Delta:
        case CustomBadge.Dfcoa4:
        case CustomBadge.Dfcosmall:
            throw new Error('Not implemented');
        case CustomBadge.Dino:
            return <BadgesDino event={event} />;
        case CustomBadge.Doxart:
            return <BadgesDoxart event={event} />;
        case CustomBadge.Dubcamp:
            throw new Error('Not implemented');
        case CustomBadge.Elephants:
            throw new Error('Not implemented');
        case CustomBadge.Eurockeennes:
            return <Eurockeennes event={event} />;
        case CustomBadge.Fcg:
            return <BadgesFcg event={event} />;
        case CustomBadge.Fisebadge:
        case CustomBadge.Fisebadgevide:
        case CustomBadge.Fisebadgea4:
        case CustomBadge.Fisecontremarque:
            throw new Error('Not implemented');
        case CustomBadge.Francofoliesesch:
            throw new Error('Not implemented');
        case CustomBadge.Garorockbadge:
        case CustomBadge.Garorockplanning:
            throw new Error('Not implemented');
        case CustomBadge.Highfive:
            return <BadgesHighfive event={event} />;
        case CustomBadge.Jardinmichel:
            return <BadgesJardinMichel event={event} />;
        case CustomBadge.Jardinmichelpro:
            throw new Error('Not implemented');
        case CustomBadge.Jardinsonorebadgepartenaire:
        case CustomBadge.Jardinsonorebadgephotographe:
        case CustomBadge.Jardinsonorecontremarquebenevole:
        case CustomBadge.Jardinsonorecontremarqueparking:
        case CustomBadge.Jardinsonorecontremarquepartenaire:
            throw new Error('Not implemented');
        case CustomBadge.Jivahill:
            throw new Error('Not implemented');
        case CustomBadge.Jokersfranceslovenia:
            return <BadgesJokersfranceslovenia event={event} />;
        case CustomBadge.Kermesse:
        case CustomBadge.Lnh:
        case CustomBadge.Mafiozik:
        case CustomBadge.Moorea:
        case CustomBadge.Nuitssecretes:
        case CustomBadge.P2nassignment:
        case CustomBadge.P2nassignmentmontagedemontage:
        case CustomBadge.P2nbadge:
            throw new Error('Not implemented');
        case CustomBadge.Paris20km:
            throw new Error('Not implemented');
        case CustomBadge.Parisaeroport:
            throw new Error('Not implemented');
        case CustomBadge.Pastourelle:
            throw new Error('Not implemented');
        case CustomBadge.Pastourelleexposant:
            throw new Error('Not implemented');
        case CustomBadge.Pec:
        case CustomBadge.Poc:
        case CustomBadge.Primrose:
        case CustomBadge.Res2024contremarque:
        case CustomBadge.Res2024parking:
        case CustomBadge.Recrewdays:
        case CustomBadge.Recrewdayspass:
            throw new Error('Not implemented');
        case CustomBadge.Reims:
            throw new Error('Not implemented');
        case CustomBadge.Roiarthur:
            throw new Error('Not implemented');
        case CustomBadge.Rose:
        case CustomBadge.Roseparking:
            throw new Error('Not implemented');
        case CustomBadge.Salamandre:
            return <BadgesSalamandre event={event} />;
        case CustomBadge.Sqy:
        case CustomBadge.Sqytraitcoupe:
            throw new Error('Not implemented');
        case CustomBadge.T24assignment:
            throw new Error('Not implemented');
        case CustomBadge.Terresduson:
            throw new Error('Not implemented');
        case CustomBadge.Triathlonlarochellebadge:
            throw new Error('Not implemented');
        case CustomBadge.Tvj:
        case CustomBadge.Ultramarinassignment:
        case CustomBadge.Ultramarinbenevole:
        case CustomBadge.Ultramarinexposant:
        case CustomBadge.Ultramarinmedia:
        case CustomBadge.Ultramarinmedical:
        case CustomBadge.Ultramarinorganisation:
        case CustomBadge.Ultramarinpartenaire:
        case CustomBadge.Ultramarinpresse:
            throw new Error('Not implemented');
        case CustomBadge.Valbon:
            throw new Error('Not implemented');
        case CustomBadge.Vandbcontremarque:
        case CustomBadge.Vandbinvitation:
            throw new Error('Not implemented');
        case CustomBadge.Vannes:
            throw new Error('Not implemented');
        case CustomBadge.Vendanges:
            throw new Error('Not implemented');
        case CustomBadge.Weezdays:
            throw new Error('Not implemented');
        case CustomBadge.Wfa24:
        case CustomBadge.Wfa24planning:
            throw new Error('Not implemented');
        case CustomBadge.Zamna:
            throw new Error('Not implemented');
        default:
            return assertUnreachable(badgeType);
    }
}

export function hasGroupedExport(badgeType: CustomBadge) {
    try {
        getCustomBadgesReactNode(badgeType, {});

        return true;
    } catch (e) {
        if (e.message === 'Not implemented') {
            return false;
        } else {
            return true;
        }
    }
}

export function getCustomBadgeFrontNode(
    badgeType: CustomBadge,
    event: any,
    logoUrl: Emptyable<string>,
    options: Emptyable<any>
) {
    switch (badgeType) {
        case CustomBadge.Alldayin:
            return <AllDayInBadge event={event} />;
        case CustomBadge.Asoassignment:
            return <AsoAssignment event={event} options={options!} />;
        case CustomBadge.Auborddeleau:
            return <BadgeAuborddeleau event={event} />;
        case CustomBadge.Axe:
            return <AxeA4 event={event} />;
        case CustomBadge.Belgiumhousebadge:
            return <BelgiumHouseBadge event={event} />;
        case CustomBadge.Belgiumhousebadge2:
            return <BelgiumHouseBadge2 event={event} />;
        case CustomBadge.Belgiumhousecontremaque:
            return <BelgiumHouseContremarque event={event} />;
        case CustomBadge.Belgiumhouseguestbadge:
            return <BelgiumHouseGuestBadge event={event} />;
        case CustomBadge.Blot:
            return <BadgeBlot event={event} />;
        case CustomBadge.Blotrennes:
            return <BadgeBlotRennes event={event} />;
        case CustomBadge.Bobital:
            return <BadgeBobital event={event} />;
        case CustomBadge.Bruleurs:
            return <BadgeBruleursA6 event={event} options={options!} />;
        case CustomBadge.Clubsqy:
            return <BadgeClubSqy event={event} />;
        case CustomBadge.Clubfrance:
            return <BadgeClubFrance event={event} />;
        case CustomBadge.Clubfrancecontremarque:
            return <ContremarqueClubFrance event={event} />;
        case CustomBadge.Croixrouge:
            return <BadgeCroixRouge event={event} />;
        case CustomBadge.Croixrougeordremission:
            return <PositionSheetCroixRouge event={event} />;
        case CustomBadge.Delta:
            return <BadgeDelta event={event} />;
        case CustomBadge.Dfcoa4:
            return <BadgeDfcoA4 event={event} />;
        case CustomBadge.Dfcosmall:
            return <BadgeDfcoSmall event={event} />;
        case CustomBadge.Dino:
            return <BadgeDinoA6 event={event} />;
        case CustomBadge.Doxart:
            return <BadgeDoxart event={event} />;
        case CustomBadge.Dubcamp:
            return <BadgeDubcamp event={event} />;
        case CustomBadge.Elephants:
            return <BadgeElephants event={event} />;
        case CustomBadge.Eurockeennes:
            return <EurockeennesA4 event={event} />;
        case CustomBadge.Fcg:
            return <BadgeFcgA6 event={event} />;
        case CustomBadge.Fisebadge:
            return <FiseBadge event={event} />;
        case CustomBadge.Fisebadgevide:
            return <FiseBadgeVide event={event} />;
        case CustomBadge.Fisebadgea4:
            return <FiseBadgeA4 event={event} />;
        case CustomBadge.Fisecontremarque:
            return <FiseContremarque event={event} />;
        case CustomBadge.Francofoliesesch:
            return <BadgeFrancofoliesEsch event={event} />;
        case CustomBadge.Garorockbadge:
            return <BadgeGarorock event={event} />;
        case CustomBadge.Garorockplanning:
            return <PlanningGarorock event={event} />;
        case CustomBadge.Highfive:
            return <BadgeHighfiveA6 event={event} />;
        case CustomBadge.Jardinmichel:
            return <BadgeJardinMichel event={event} />;
        case CustomBadge.Jardinmichelpro:
            return <BadgeJardinMichelPro event={event} />;
        case CustomBadge.Jardinsonorebadgepartenaire:
            return <BadgeJardinSonorePartenaire event={event} />;
        case CustomBadge.Jardinsonorebadgephotographe:
            return <BadgeJardinSonorePhotographe event={event} />;
        case CustomBadge.Jardinsonorecontremarquebenevole:
            return <ContremarqueJardinSonoreBenevole event={event} />;
        case CustomBadge.Jardinsonorecontremarqueparking:
            return <ContremarqueJardinSonoreParking event={event} />;
        case CustomBadge.Jardinsonorecontremarquepartenaire:
            return <ContremarqueJardinSonorePartenaire event={event} />;
        case CustomBadge.Jivahill:
            return <BadgeJivahill event={event} />;
        case CustomBadge.Jokersfranceslovenia:
            return <BadgeJokersfrancesloveniaA6 event={event} />;
        case CustomBadge.Kermesse:
            return <BadgeKermesse event={event} />;
        case CustomBadge.Lnh:
            return <BadgeLnh event={event} />;
        case CustomBadge.Mafiozik:
            return <BadgeMafiozik event={event} />;
        case CustomBadge.Moorea:
            return <BadgeMoorea event={event} />;
        case CustomBadge.Nuitssecretes:
            return <BadgeNuitsSecretes event={event} />;
        case CustomBadge.P2nassignment:
            return <P2nAssignment event={event} />;
        case CustomBadge.P2nassignmentmontagedemontage:
            return <P2nAssignmentMontageDemontage event={event} />;
        case CustomBadge.P2nbadge:
            return <P2NBadge event={event} />;
        case CustomBadge.Paris20km:
            return <BadgeParis20km event={event} />;
        case CustomBadge.Parisaeroport:
            return <ParisAeroportBadge event={event} />;
        case CustomBadge.Pastourelle:
            return <BadgePastourelle event={event} />;
        case CustomBadge.Pastourelleexposant:
            return <BadgePastourelleexposant event={event} />;
        case CustomBadge.Pec:
            return <BadgePec event={event} options={options!} />;
        case CustomBadge.Poc:
            return <BadgePoc event={event} logoUrl={logoUrl} />;
        case CustomBadge.Primrose:
            return <BadgePrimrose event={event} />;
        case CustomBadge.Res2024contremarque:
            return <ContremarqueRes2024 event={event} />;
        case CustomBadge.Res2024parking:
            return <BadgeRes2024Parking event={event} />;
        case CustomBadge.Recrewdays:
            return <BadgeRecrewdaysA6 event={event} />;
        case CustomBadge.Recrewdayspass:
            return <RecrewdaysPass event={event} />;
        case CustomBadge.Reims:
            return <BadgeReims event={event} />;
        case CustomBadge.Roiarthur:
            return <BadgeRoiArthur event={event} />;
        case CustomBadge.Rose:
            return <BadgeRose event={event} />;
        case CustomBadge.Roseparking:
            return <BadgeRoseParking event={event} />;
        case CustomBadge.Salamandre:
            return <BadgeSalamandreA7 event={event} />;
        case CustomBadge.Sqy:
            return <BadgeSqy event={event} />;
        case CustomBadge.Sqytraitcoupe:
            return <BadgeSqyTraitCoupe event={event} />;
        case CustomBadge.T24assignment:
            return <T24Assignment event={event} options={options!} />;
        case CustomBadge.Terresduson:
            return <BadgeTerresDuSon event={event} />;
        case CustomBadge.Triathlonlarochellebadge:
            return <TriathlonLaRochelleBadge event={event} />;
        case CustomBadge.Tvj:
            return <BadgeTvj event={event} />;
        case CustomBadge.Ultramarinassignment:
            return <UltraMarinAssignment event={event} />;
        case CustomBadge.Ultramarinbenevole:
            return <UltraMarinBenevole event={event} />;
        case CustomBadge.Ultramarinexposant:
            return <UltraMarinExposant event={event} />;
        case CustomBadge.Ultramarinmedia:
            return <UltraMarinMedia event={event} />;
        case CustomBadge.Ultramarinmedical:
            return <UltraMarinMedical event={event} />;
        case CustomBadge.Ultramarinorganisation:
            return <UltraMarinOrganisation event={event} />;
        case CustomBadge.Ultramarinpartenaire:
            return <UltraMarinPartenaire event={event} />;
        case CustomBadge.Ultramarinpresse:
            return <UltraMarinPresse event={event} />;
        case CustomBadge.Valbon:
            return <BadgeValbon event={event} />;
        case CustomBadge.Vandbcontremarque:
            return <ContremarqueVandb event={event} />;
        case CustomBadge.Vandbinvitation:
            return <VandbInvitation event={event} />;
        case CustomBadge.Vannes:
            return <BadgeVannesA6 event={event} />;
        case CustomBadge.Vendanges:
            return <BadgeVendanges event={event} />;
        case CustomBadge.Weezdays:
            return <BadgeWeezdaysA6 event={event} />;
        case CustomBadge.Wfa24:
            return <BadgeAmbert event={event} />;
        case CustomBadge.Wfa24planning:
            return <PlanningAmbert event={event} />;
        case CustomBadge.Zamna:
            return <BadgeZamna event={event} />;
        default:
            return assertUnreachable(badgeType);
    }
}

export function getDisplayName(badgeType: CustomBadge) {
    switch (badgeType) {
        case CustomBadge.Alldayin:
            return `Badge All Day In`;
        case CustomBadge.Asoassignment:
            return `Fiche d'affectation ASO`;
        case CustomBadge.Auborddeleau:
            return `Badge Festival Week End au Bord de l'Eau`;
        case CustomBadge.Axe:
            return `Badge Axe Ceremonia`;
        case CustomBadge.Belgiumhousebadge:
            return `Badge Belgium House`;
        case CustomBadge.Belgiumhousebadge2:
            return `Badge Belgium House Delegation`;
        case CustomBadge.Belgiumhousecontremaque:
            return `Contremarque Belgium House`;
        case CustomBadge.Belgiumhouseguestbadge:
            return `Badge Belgium House Guest`;
        case CustomBadge.Blot:
            return `Badge Blot Open de Bretagne`;
        case CustomBadge.Blotrennes:
            return `Badge Blot Open de Rennes`;
        case CustomBadge.Bobital:
            return `Badge Bobital`;
        case CustomBadge.Bruleurs:
            return `Badge Brûleurs de Loups`;
        case CustomBadge.Clubsqy:
            return `Badge Club 24 SQY`;
        case CustomBadge.Clubfrance:
            return `Badge Club France`;
        case CustomBadge.Clubfrancecontremarque:
            return `Contremarque Club France`;
        case CustomBadge.Croixrouge:
            return `Badge Croix Rouge`;
        case CustomBadge.Croixrougeordremission:
            return `Ordre de mission Croix Rouge`;
        case CustomBadge.Delta:
            return `Badge Delta`;
        case CustomBadge.Dfcoa4:
            return `Badge Dijon DFCO A4`;
        case CustomBadge.Dfcosmall:
            return `Badge Dijon DFCO Carte`;
        case CustomBadge.Dino:
            return `Accréditation Dino`;
        case CustomBadge.Doxart:
            return `Badge Dox'Art`;
        case CustomBadge.Dubcamp:
            return `Badge Dub Camp`;
        case CustomBadge.Elephants:
            return `Badge Elephants`;
        case CustomBadge.Eurockeennes:
            return `Planning Les Eurockéennes`;
        case CustomBadge.Fcg:
            return `Accréditation FCG`;
        case CustomBadge.Fisebadge:
            return `Badge FISE`;
        case CustomBadge.Fisebadgea4:
            return `Badge FISE format A4`;
        case CustomBadge.Fisebadgevide:
            return `Badge FISE (sans le fond)`;
        case CustomBadge.Fisecontremarque:
            return `Contremarque FISE`;
        case CustomBadge.Francofoliesesch:
            return `Contremarque Francofolies Esch/Alzette`;
        case CustomBadge.Garorockbadge:
            return `Badge Garorock`;
        case CustomBadge.Garorockplanning:
            return `Planning Garorock`;
        case CustomBadge.Highfive:
            return `Badge High Five`;
        case CustomBadge.Jardinmichel:
            return `Badge Jardin du Michel`;
        case CustomBadge.Jardinmichelpro:
            return `Badge Jardin des Pro`;
        case CustomBadge.Jardinsonorebadgepartenaire:
            return `Badge Jardin Sonore Partenaire`;
        case CustomBadge.Jardinsonorebadgephotographe:
            return `Badge Jardin Sonore Photographe`;
        case CustomBadge.Jardinsonorecontremarquebenevole:
            return `Contremarque Jardin Sonore Bénévole`;
        case CustomBadge.Jardinsonorecontremarqueparking:
            return `Contremarque Jardin Sonore Parking`;
        case CustomBadge.Jardinsonorecontremarquepartenaire:
            return `Contremarque Jardin Sonore Partenaire`;
        case CustomBadge.Jivahill:
            return `Badge Jiva Hill`;
        case CustomBadge.Jokersfranceslovenia:
            return `Accréditation France Slovenia`;
        case CustomBadge.Kermesse:
            return `Badge Kermesse`;
        case CustomBadge.Lnh:
            return `Badge LNH`;
        case CustomBadge.Mafiozik:
            return `Badge Mafio'zik`;
        case CustomBadge.Moorea:
            return `Badge Moorea`;
        case CustomBadge.Nuitssecretes:
            return `Badge Nuits Secrètes`;
        case CustomBadge.P2nassignment:
            return `Fiche d'affectation P2N (Exploit)`;
        case CustomBadge.P2nassignmentmontagedemontage:
            return `Fiche d'affectation P2N (Montage/Démontage)`;
        case CustomBadge.P2nbadge:
            return `Badge P2N`;
        case CustomBadge.Paris20km:
            return `Badge 20 km de Paris`;
        case CustomBadge.Parisaeroport:
            return `Badge Paris Aéroport`;
        case CustomBadge.Pastourelle:
            return `Badge Pastourelle bénévoles`;
        case CustomBadge.Pastourelleexposant:
            return `Badge Pastourelle staffs`;
        case CustomBadge.Pec:
            return 'Badge Paris Entertainment Company';
        case CustomBadge.Poc:
            return `Badge démonstration`;
        case CustomBadge.Primrose:
            return `Badge Primrose`;
        case CustomBadge.Res2024contremarque:
            return 'Contremarque et parkings Rock en Seine 2024';
        case CustomBadge.Res2024parking:
            return 'Parkings seulement Rock en Seine 2024';
        case CustomBadge.Recrewdays:
            return `Badge Recrewdays`;
        case CustomBadge.Recrewdayspass:
            return `Contremarque Recrewdays`;
        case CustomBadge.Reims:
            return `Badge Reims Champagne Run 2023`;
        case CustomBadge.Roiarthur:
            return `Badge Roi Arthur`;
        case CustomBadge.Rose:
            return `Contremarque Rose Festival`;
        case CustomBadge.Roseparking:
            return `Rose Festival Pass Parking`;
        case CustomBadge.Salamandre:
            return `Badge Salamandre`;
        case CustomBadge.Sqy:
            return `Badge SQY`;
        case CustomBadge.Sqytraitcoupe:
            return `Badge SQY avec trait coupe`;
        case CustomBadge.T24assignment:
            return `Fiche d'affectation T24`;
        case CustomBadge.Terresduson:
            return `Badge Terres du Son`;
        case CustomBadge.Triathlonlarochellebadge:
            return `Badge La Rochelle Triathlon`;
        case CustomBadge.Tvj:
            return `Badge TVJ`;
        case CustomBadge.Ultramarinassignment:
            return `Fiche d'affectation Ultra Marin`;
        case CustomBadge.Ultramarinbenevole:
            return `Badge Ultra Marin bénévole`;
        case CustomBadge.Ultramarinexposant:
            return `Badge Ultra Marin exposant`;
        case CustomBadge.Ultramarinmedia:
            return `Badge Ultra Marin média`;
        case CustomBadge.Ultramarinmedical:
            return `Badge Ultra Marin médical`;
        case CustomBadge.Ultramarinorganisation:
            return `Badge Ultra Marin organisation`;
        case CustomBadge.Ultramarinpartenaire:
            return `Badge Ultra Marin partenaire`;
        case CustomBadge.Ultramarinpresse:
            return `Badge Ultra Marin presse`;
        case CustomBadge.Valbon:
            return `Badge Valbon`;
        case CustomBadge.Vandbcontremarque:
            return `V and B Fest' Convocation`;
        case CustomBadge.Vandbinvitation:
            return `V and B Fest' Soirée des anciens bénévoles`;
        case CustomBadge.Vannes:
            return `Badge Marathon de Vannes`;
        case CustomBadge.Vendanges:
            return `Badge Les Vendanges Musicales`;
        case CustomBadge.Weezdays:
            return `Badge Weezdays`;
        case CustomBadge.Wfa24:
            return `Contremarque WFA24`;
        case CustomBadge.Wfa24planning:
            return `Planning WFA24`;
        case CustomBadge.Zamna:
            return `Badge Zamna`;
        default:
            return assertUnreachable(badgeType);
    }
}
