import { Blank } from 'common-front/src/components/blank/blank';
import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { MemberQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { IntervalService } from 'common/src/services/intervalService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { MembersPaths } from 'common/src/util/membersPaths';
import { sortBy } from 'lodash-es';
import * as React from 'react';

interface IMemberRegisteredEventProps {
    registration: MemberQuery['userInfo']['volunteersRegistrations'][0];
}

export const MemberAssignment = ({ registration }: IMemberRegisteredEventProps) => {
    const {
        history,
        translate,
        params: { organizationId, userInfoId }
    } = useHeavent();
    const intervalService = useService(IntervalService);
    const dateTimeService = useService(DateTimeService);
    const psuis = React.useMemo(() => sortBy(
            registration.positionsSlotsUsersInfos,
            (psui) => psui.positionSlot.range.start?.toMillis() ?? 0
        ), [registration]);
    const auis = React.useMemo(() => sortBy(
            registration.accreditationsUsersInfos,
            (aui) => aui.accreditationSlot.date?.toMillis() ?? 0
        ), [registration]);

    return (
        <Flex
            direction="column"
            css={{
                bd: true,
                overflow: 'hidden'
            }}
        >
            <Flex
                direction={{ '@initial': 'column', '@tablet': 'row' }}
                align={{ '@tablet': 'center' }}
                gap="4"
                css={{
                    background: 'white',
                    borderBottom: '1px solid $gray200',
                    padding: '$6 $4',
                    '@tablet': {
                        padding: '$6'
                    }
                }}
            >
                <Flex direction="column" gap="1" css={{ flex: '1' }}>
                    <Box color="gray800" fontSize="textMd" fontWeight="medium">
                        {registration.event.name}
                    </Box>

                    {registration.event.range && (
                        <Box color="gray500">
                            {intervalService.toDisplayString(registration.event.range)}
                        </Box>
                    )}
                </Flex>

                <Button
                    color="white"
                    leftIcon="eye"
                    onClick={() => {
                        history.push(
                            MembersPaths.MEMBER_REGISTRATION({
                                organizationId,
                                userInfoId,
                                eventId: registration.event.id
                            })
                        );
                    }}
                >
                    {translate('voir_le_d_tail_72056')}
                </Button>
            </Flex>

            <Flex
                direction="column"
                gap="2"
                css={{
                    background: psuis.length === 0 ? 'white' : '$gray100',
                    padding: '$4',
                    '@tablet': {
                        padding: '$6'
                    }
                }}
            >
                <Box fontWeight={'bold'}>{translate('mes_affectation_73224')}</Box>

                {isNonEmptyArray(psuis) ? (
                    psuis.map((psui) => (
                            <React.Fragment key={psui.id}>
                                <Flex
                                    direction="column"
                                    gap="1"
                                    css={{
                                        background: 'white',
                                        bd: true,
                                        padding: '$3 $6'
                                    }}
                                >
                                    <Box color="gray800" fontWeight="semiBold">
                                        {psui.position.name}
                                    </Box>

                                    <Box color="gray500">
                                        {intervalService.toDisplayString(psui.positionSlot.range)}
                                    </Box>
                                </Flex>
                            </React.Fragment>
                        ))
                ) : (
                    <Blank title={translate('un_peu_de_patie_47754')} subtitle="" />
                )}

                <Box fontWeight={'bold'} css={{ marginBlockStart: '$2' }}>
                    {translate('accr_ditations_39450')}
                </Box>

                {isNonEmptyArray(auis) ? (
                    auis.map((aui) => (
                            <React.Fragment key={aui.id}>
                                <Flex
                                    direction="column"
                                    gap="1"
                                    css={{
                                        background: 'white',
                                        bd: true,
                                        padding: '$3 $6'
                                    }}
                                >
                                    <Box color="gray800" fontWeight="semiBold">
                                        {aui.accreditationSlot.name ||
                                            aui.accreditation.name ||
                                            aui.accreditationCategory.name ||
                                            translate('untitled')}
                                    </Box>

                                    {aui.accreditationSlot.date && (
                                        <Box color="gray500">
                                            {dateTimeService.toLocaleString(
                                                aui.accreditationSlot.date,
                                                LocaleFormats.ShortDateTime
                                            )}
                                        </Box>
                                    )}
                                </Flex>
                            </React.Fragment>
                        ))
                ) : (
                    <Blank title={translate('un_peu_de_patie_41250')} subtitle="" />
                )}
            </Flex>
        </Flex>
    );
};

export const MemberAssignmentSkeleton = () => (
        <Flex
            direction="column"
            width={1}
            css={{
                bd: true,
                overflow: 'hidden'
            }}
        >
            <Flex
                direction="column"
                gap="1"
                width={1}
                css={{
                    background: 'white',
                    padding: '$6 $4',
                    '@tablet': {
                        padding: '$6'
                    }
                }}
            >
                <Skeleton height={22} width={1} borderRadius="$1" />

                <Skeleton height={20} width={1} borderRadius="$1" />
            </Flex>

            <Flex
                direction="column"
                gap="2"
                width={1}
                css={{
                    background: '$gray100',
                    padding: '$4',
                    '@tablet': {
                        padding: '$6'
                    }
                }}
            >
                <Skeleton height={70} width={1} borderRadius="$2" />

                <Skeleton height={70} width={1} borderRadius="$2" />
            </Flex>
        </Flex>
    );
