import * as React from 'react';
import { getSortIcon, onSort, Sort } from '../../../vo/sort';
import { I } from '../i';
import { Spacer } from '../spacer';
import { CSS } from '../stitches';
import { HeaderCell } from './headerCell';

interface IHeaderCellSortProps<T> {
    attribute: T;
    children: React.ReactNode;
    css?: CSS;
    sort: Sort<T> | null;
    width?: number;

    setSort(sort: Sort<T> | null): void;
}

export const HeaderCellSort = <T extends {}>(props: IHeaderCellSortProps<T>) => (
        <HeaderCell
            width={props.width}
            css={{
                cursor: 'pointer',
                userSelect: 'none',
                ...((props.css || {}) as any)
            }}
            onClick={() => {
                props.setSort(onSort(props.sort, props.attribute));
            }}
        >
            {props.children}
            <Spacer width="2" />
            <I icon={getSortIcon(props.sort, props.attribute)} />
        </HeaderCell>
    );
