import { injectable } from 'inversify';
import * as yup from 'yup';
import {
    ALL_FIELDTYPE,
    CustomFieldInput,
    CustomFieldsCategory,
    CustomFieldToEditQuery,
    CustomFieldVariety,
    FieldType
} from '../generated/types';
import { TranslationService } from '../services/translationService';
import { isNonEmptyArray } from '../util/array';
import { DocumentInputService } from './documentInput';
import { InputService } from './inputService';

export interface ICreateUpdateCustomFieldValues {
    customField: CustomFieldInput;
}

@injectable()
export class CustomFieldInputService extends InputService {
    constructor(
        private documentInputService: DocumentInputService,
        translationService: TranslationService
    ) {
        super(translationService);
    }

    customFieldInputDefault(
        customField: CustomFieldToEditQuery['organization']['customField'] | null,
        categories: Array<Pick<CustomFieldsCategory, 'id' | 'name'>>
    ): CustomFieldInput {
        return {
            name: customField?.name ?? '',
            customFieldCategoryId: customField?.customFieldCategoryId ?? categories[0].id,
            description: customField?.description ?? '',
            variety: customField?.variety ?? CustomFieldVariety.UserInfo,
            fieldType: customField?.fieldType ?? FieldType.Text,
            isPrivate: customField?.isPrivate ?? false,
            values: isNonEmptyArray(customField?.values) ? customField!.values : [],
            hasCondition: customField?.hasCondition ?? false,
            conditionCustomFieldId: customField?.conditionCustomFieldId ?? null,
            conditionValue: customField?.conditionValue ?? false,
            canSelectMultiple: customField?.canSelectMultiple ?? false,
            document: customField?.document || this.documentInputService.documentInputDefault()
        };
    }

    customFieldInputSchema() {
        return yup.object().shape({
            name: yup.string().required(this.t('le_nom_du_champ_17959')),
            customFieldCategoryId: yup
                .number()
                .required(this.t('la_cat_gorie_es_14008'))
                .typeError(this.t('la_cat_gorie_es_14008'))
                .min(1, this.t('la_cat_gorie_es_14008')),
            fieldType: yup
                .string()
                .required(this.t('le_type_est_req_41180'))
                .oneOf(ALL_FIELDTYPE, this.t('le_type_est_req_41180')),
            isPrivate: yup.boolean().nullable(),
            hasCondition: yup.boolean().nullable(),
            values: yup.array().when('fieldType', {
                is: FieldType.Select,
                then: (s) =>
                    s
                        .of(
                            yup.object().shape({
                                id: yup.number(),
                                value: yup.string().required(this.t('la_valeur_est_r_70136'))
                            })
                        )
                        .required(this.t('vous_devez_s_le_37639'))
                        .min(2, this.t('vous_devez_s_le_37639'))
            }),
            conditionCustomFieldId: yup.number().when('hasCondition', {
                is: true,
                then: (s) => s.required(),
                otherwise: (s) => s.nullable()
            }),
            conditionValue: yup.mixed(),
            canSelectMultiple: yup.boolean().nullable(),
            document: this.documentInputService.documentInputSchemaNotRequired()
        });
    }

    createUpdateCustomFieldSchema() {
        return yup.object().shape({
            customField: this.customFieldInputSchema()
        });
    }
}
