import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { noop } from 'lodash-es';
import * as React from 'react';
import { Checkbox } from '../../designSystem/components/checkbox';

export const ExistingUserRowSkeleton = () => (
        <Flex
            align="center"
            css={{
                borderBottom: '1px solid $gray200',
                cursor: 'pointer',
                py: '$3',
                userSelect: 'none',
                width: '100%',
                '&:last-child': {
                    border: 'none'
                }
            }}
        >
            <Checkbox state="unchecked" onClick={noop} />

            <Spacer width="3" />

            <Skeleton variant="circular" size={40} />

            <Spacer width="4" />

            <Flex direction="column" gap="1" css={{ flex: '1', overflow: 'hidden' }}>
                <Skeleton width={1} height={12} borderRadius="$1" />

                <Skeleton width={1} height={12} borderRadius="$1" />
            </Flex>
        </Flex>
    );
